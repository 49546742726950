import { combineReducers } from 'redux';

// import your Module reducers here and combine them
import appState from './state/redux/reducers';

const rootReducer = combineReducers({
    appState,
});

export default rootReducer;
