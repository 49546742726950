import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, distinctUntilChanged } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { FETCH_UPLOAD_URLS_START } from '../state/redux/types';
import {
    doUploadFilesToBucket,
    doFetchPresignedUploadURLsFulfilled,
    doFetchPresignedUploadURLsFailed,
    doFetchPresignedDownloadURLs,
    doRunConversionJobFail,
    doSetFilesUploadedFromFolder,
    doSetModelLoading,
    doSendJobToSmartOrientFailed,
    doSetNotification,
} from '../state/redux/actions';
import { compareEpicPayload, getFailedSliceJob } from '../helpers/utils';
import { URL_FETCHING_GOALS } from '../consts';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResponseToActions = ({ response }, action) => {
    if (
        action.payload.goal === URL_FETCHING_GOALS.STL_PREVIEW ||
        action.payload.goal === URL_FETCHING_GOALS.THING_EXPORT ||
        action.payload.goal === URL_FETCHING_GOALS.SMART_ORIENT
    ) {
        const payload = {
            ...action.payload,
            urls: response,
        };

        return of(doFetchPresignedUploadURLsFulfilled(payload), doUploadFilesToBucket(payload));
    }

    if (action.payload.goal === URL_FETCHING_GOALS.MAKERBOT_PREVIEW) {
        const payload = {
            ...action.payload,
            urls: response[action.payload.file.name],
        };
        return of(doFetchPresignedUploadURLsFulfilled(payload), doFetchPresignedDownloadURLs(payload));
    }

    if (action.payload.goal === URL_FETCHING_GOALS.STL_CONVERT) {
        let payload;
        if (action.payload.file) {
            payload = {
                ...action.payload,
                urls: response[action.payload.file.name],
            };
        } else {
            payload = {
                ...action.payload,
                urls: response,
            };
        }
        return of(doFetchPresignedUploadURLsFulfilled(payload), doUploadFilesToBucket(payload));
    }

    return of(doFetchPresignedUploadURLsFulfilled(action.payload));
};

const parseErrorToActions = (error, action) => {
    console.log(`Failed to get pre-signed urls from storage...`);
    console.log(`Error:`, error);

    if (action.payload.goal === URL_FETCHING_GOALS.STL_CONVERT) {
        return of(
            doSetFilesUploadedFromFolder(false),
            doSetModelLoading({ modelLoading: false }),
            doSetNotification(NOTIFICATION_TYPE.CONVERSION_FAILED),
            doRunConversionJobFail()
        );
    }

    if (action.payload.goal === URL_FETCHING_GOALS.SMART_ORIENT) {
        return of(doSendJobToSmartOrientFailed());
    }

    return of(
        doFetchPresignedUploadURLsFailed({
            failedSliceJob: getFailedSliceJob(action.payload.currentJob),
        })
    );
};

const fetchPresignedUploadURLs = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_UPLOAD_URLS_START),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const payload = action.payload;
            let filesToUpload = [];

            if (payload.goal === URL_FETCHING_GOALS.MAKERBOT_PREVIEW) {
                payload.file && filesToUpload.push(payload.file.name);
            }

            if (payload.goal === URL_FETCHING_GOALS.STL_CONVERT) {
                payload.file && filesToUpload.push(payload.file.name);
                if (payload.files) filesToUpload = payload.files.map(x => x.name);
            }

            if (
                payload.goal === URL_FETCHING_GOALS.SMART_ORIENT ||
                payload.goal === URL_FETCHING_GOALS.STL_PREVIEW ||
                payload.goal === URL_FETCHING_GOALS.THING_EXPORT
            ) {
                filesToUpload = payload.files.map(x => x.name);
            }

            const { cloudslicerToken } = state$.value?.appState?.userState;
            console.log(`Fetching storage upload URLs`);
            return ajax({
                url: `https://${urls.cloudslicer}/api/v3/files`,
                body: JSON.stringify({ filesToBeUploaded: filesToUpload }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cloudslicerToken}`,
                },
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default fetchPresignedUploadURLs;
