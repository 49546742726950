import {
    Scene,
    WebGLRenderer,
    PCFSoftShadowMap,
    BasicShadowMap,
    BufferGeometry,
    ExtrudeGeometry,
    Mesh /*, WebGLRenderTarget*/,
} from 'three';

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import WebGL from 'three/examples/jsm/capabilities/WebGL';
import TWEEN from '@tweenjs/tween.js';

import { crossFrameStorage } from './CrossFrameStorage';

import { COLOR_CANVAS_BACKGROUND, LOCAL_STORAGE_KEYS, newPerspectiveCamera, QUALITY_SETTINGS } from '../consts';

import Renderer2D from './Renderer2D';
import ThreeAxisTriad from './ThreeAxisTriad';
import { isDesktop, isLightTheme } from './utils/environment';

let showShadows = true;
let qualityLevel = QUALITY_SETTINGS.quality.id;

const delay = ms => new Promise(r => setTimeout(r, ms));

const ThreeInstance = {
    loaded: false,
    waitUntilLoaded: async () => {
        if (ThreeInstance.loaded) return true;
        else {
            await delay(150);
            return ThreeInstance.waitUntilLoaded();
        }
    },
};

crossFrameStorage.fetchItem(LOCAL_STORAGE_KEYS.systemPreferences).then(systemPreference => {
    if (systemPreference?.graphics) {
        showShadows = systemPreference.graphics.showShadows;
        qualityLevel = systemPreference.graphics.level.id;
    }

    const renderTargetPars = {};
    let webglVersion = 'webgl';
    if (WebGL.isWebGL2Available()) {
        renderTargetPars.samples = 2; // multisampling
        webglVersion = 'webgl2';
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext(webglVersion);

    const renderer = new WebGLRenderer({
        antialias: qualityLevel === QUALITY_SETTINGS.quality.id,
        alpha: true,
        canvas,
        context,
        pixelRatio: window.devicePixelRatio,
        logarithmicDepthBuffer: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(COLOR_CANVAS_BACKGROUND(isLightTheme()));
    renderer.shadowMap.enabled = showShadows;
    renderer.shadowMap.type = qualityLevel === QUALITY_SETTINGS.quality.id ? PCFSoftShadowMap : BasicShadowMap;
    renderer.localClippingEnabled = true;
    const composer = new EffectComposer(renderer);
    /*    qualityLevel === QUALITY_SETTINGS.quality.id
				        ? new EffectComposer(renderer, new WebGLRenderTarget(window.innerWidth, window.innerHeight, renderTargetPars))
				        : new EffectComposer(renderer);*/
    composer.setSize(window.innerWidth, window.innerHeight);

    const canvas2D = document.createElement('canvas');
    canvas2D.classList.add('canvas2d');
    const renderer2D = new Renderer2D(canvas2D);

    const scene = new Scene();
    const camera = newPerspectiveCamera();

    if (!isDesktop()) {
        BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
        BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
        ExtrudeGeometry.prototype.computeBoundsTree = computeBoundsTree;
        ExtrudeGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
        Mesh.prototype.raycast = acceleratedRaycast;
    }

    ThreeInstance.scene = scene;
    ThreeInstance.camera = camera;
    ThreeInstance.renderer = renderer;
    ThreeInstance.renderer2D = renderer2D;
    ThreeInstance.composer = composer;
    ThreeInstance.renderScene = null;
    ThreeInstance.orbitControls = null;
    ThreeInstance.dragControls = null;
    ThreeInstance.dragGroup = [];

    let renderRequested = false;
    const render = time => {
        renderRequested = false;
        TWEEN.update(time);
        ThreeInstance.orbitControls && ThreeInstance.orbitControls.update();
        ThreeInstance.composer.render();
        ThreeAxisTriad.updateRotation(ThreeInstance.camera.quaternion);
        ThreeAxisTriad.render();
    };

    ThreeInstance.renderScene = () => {
        if (renderRequested) {
            return;
        }

        renderRequested = true;
        requestAnimationFrame(render);
    };
    ThreeInstance.loaded = true;
});

export default ThreeInstance;
