import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { CANCEL_SLICE_JOB } from '../state/redux/types';
import { doCancelSliceJobFail, doCancelSliceJobSuccess } from '../state/redux/actions';

const cancelSlicerJob = (action$, state$) =>
    action$.pipe(
        ofType(CANCEL_SLICE_JOB),
        mergeMap(action => {
            const apiUrl = `https://${urls.cloudslicer}/slices/cancel`;
            const curJob = action.payload;
            if (!curJob) {
                return of(doCancelSliceJobFail());
            }
            const { notBasicSliceId, basicSliceId } = curJob;
            const data = {
                notBasicSliceId,
                basicSliceId,
            };

            const { cloudslicerToken } = state$.value?.appState?.userState;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cloudslicerToken}`,
            };

            return ajax({
                url: apiUrl,
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }).pipe(
                mergeMap(() => {
                    console.log('Slice job is canceled');
                    return of(doCancelSliceJobSuccess());
                }),
                catchError(error => {
                    console.log('Canceling of slice job is failed', error);
                    return of(doCancelSliceJobFail());
                })
            );
        })
    );

export default cancelSlicerJob;
