import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import Pendo from '../helpers/pendo';
import { isInternalUser, isProduction } from '../helpers/utils/environment';
import {
    doFetchAboutInfo,
    doFetchAvailableArtifacts,
    doFetchAvailableArtifactsFulfilled,
    doFetchPrintersConfigs,
    doFetchUserFromOnionFailed,
    doFetchUserFromOnionFulfilled,
} from '../state/redux/actions';
import { FETCH_USER_FROM_ONION_START } from '../state/redux/types';

const parseResToActions = ({ response }) => {
    Pendo.initialize(response);

    const args = [doFetchUserFromOnionFulfilled(response)];

    const userEmail = response.email || '';
    const internalUser = isInternalUser(userEmail) || !isProduction();
    if (internalUser) {
        args.push(doFetchAvailableArtifacts());
    } else {
        args.push(doFetchPrintersConfigs({}), doFetchAboutInfo(), doFetchAvailableArtifactsFulfilled([]));
    }

    return of(...args);
};

const parseErrorToActions = error => {
    console.log(`Error:`, error.message);
    const onionUrl = urls.onion;
    const cloudPrintUrl = urls.cloudprint;
    if (error.message.indexOf('401') !== -1) {
        window.location.href = `${onionUrl.includes('local') ? 'http' : 'https'}://${onionUrl}/login?redirect=${
            cloudPrintUrl.includes('local') ? 'http' : 'https'
        }://${cloudPrintUrl}/`;
    }
    return of(doFetchPrintersConfigs({}), doFetchUserFromOnionFailed(error));
};

const fetchUser = action$ =>
    action$.pipe(
        ofType(FETCH_USER_FROM_ONION_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_USER_FROM_ONION),
        mergeMap(() => {
            const url = urls.onion;
            return ajax({
                url: `${url.includes('local') ? 'http' : 'https'}://${url}/identity?includeTokens=true`,
                method: 'POST',
                withCredentials: true,
            }).pipe(
                mergeMap(response => parseResToActions(response)),
                catchError(error => parseErrorToActions(error))
            );
        })
    );

export default fetchUser;
