export const round = (value, precision = 3) => {
    value = value ? Number(value.toFixed(precision)) : 0;
    const p = Math.pow(10, precision);
    return Math.round((value + Number.EPSILON) * p) / p;
};

export const truncateWithoutRounding = (value, digits) => {
    const z = 0;
    let numOfDigit = '1';

    for (let i = 0; i < digits; i++) {
        numOfDigit += z;
    }

    return Math.floor(value * numOfDigit) / numOfDigit;
};

export const getWeekDay = date => {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = date.getDay();
    return weekdays[day];
};

export const getDateStringFromSec = (sec = 0) => {
    const seconds = Number(sec) || 0;
    const now = new Date();
    now.setSeconds(now.getSeconds() + seconds);
    const time = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return `${getWeekDay(now)}, ${time}`;
};

export const getQueryStringParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getTimestamp = unformattedDate => {
    return unformattedDate.toLocaleDateString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
    });
};

export const getTimeStringFormSec = (sec = 0) => {
    const seconds = Number(sec);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const dDisplay = d > 0 ? `${d}d ` : '';
    const hDisplay = h > 0 ? `${h}h ` : '';
    const mDisplay = m > 0 ? `${m}m` : '';

    return dDisplay + hDisplay + mDisplay;
};

export const getMostFrequentValue = array => {
    if (!array.length) return null;

    const modeMap = {};
    let maxEl = array[0];
    let maxCount = 1;

    for (let i = 0; i < array.length; i++) {
        const el = array[i];
        if (modeMap[el] == null) {
            modeMap[el] = 1;
        } else {
            modeMap[el]++;
        }

        if (modeMap[el] > maxCount) {
            maxEl = el;
            maxCount = modeMap[el];
        }
    }

    return maxEl;
};

export const rgbToHex = (r, g, b) =>
    `#${[r, g, b]
        .map(x => {
            const hex = x.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        })
        .join('')}`;

export const removeExtension = name => {
    if (!name) return '';

    const splitted = name.split('.');
    if (splitted.length === 1) {
        // if there is no extension return full name
        return name;
    }

    return splitted.slice(0, -1).join('.');
};

export const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

    return d;
};

export const getHashCodeForArray = array => {
    let hash = 0;
    for (let i = 0; i < array.length; i++) {
        const character = array[i];
        hash = (hash << 5) - hash + character;
        hash = hash & hash;
    }
    return hash;
};

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
};
