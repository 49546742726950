import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, distinctUntilChanged } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { SEND_JOB_TO_SMART_ORIENT_START } from '../state/redux/types';
import {
    doSendJobToSmartOrientFailed,
    doSendJobToSmartOrientFulfilled,
    doTrackProgressForSmartOrient,
} from '../state/redux/actions';
import { compareEpicPayload } from '../helpers/utils';

const parseResponseToActions = ({ response }, action) => {
    const id = response.url.split('/').slice(-1)[0];
    const payload = { ...action.payload, id };
    return of(doSendJobToSmartOrientFulfilled(payload), doTrackProgressForSmartOrient(payload));
};

const parseErrorToActions = (error, action) => {
    console.log(`Error:`, error);

    return of(doSendJobToSmartOrientFailed());
};

const sendJobToSmartOrient = (action$, state$) =>
    action$.pipe(
        ofType(SEND_JOB_TO_SMART_ORIENT_START),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const apiUrl = `https://${urls.cloudslicer}/api/v1/smart-orient`;
            const data = {
                url: action.payload.thingUrl,
                settings: action.payload.currentJob.settings,
            };

            const { selectedArtifact, cloudslicerToken } = state$.value?.appState?.userState;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cloudslicerToken}`,
            };
            if (selectedArtifact) {
                headers['x-mb-artifacts'] = selectedArtifact;
            }

            console.log(`Starting a new auto orient job via CloudSlicer`);
            return ajax({
                url: apiUrl,
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default sendJobToSmartOrient;
