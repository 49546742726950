import { ofType } from 'redux-observable';
import { EMPTY, fromEvent, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { HANDLE_PARENT_POST_MESSAGE_START } from '../state/redux/types';
import {
    doFetchWorkspacePrintersFulfilled,
    doHandleParentPostMessageFailed,
    doHandleDigitalFactoryToken,
    doFetchPrintersConfigs,
    doFetchAvailableArtifacts,
    doSendJobToSlicer,
    doSetHeldSlicePayload,
    doShowTopOverlayBar,
    doSetThingLinkAvailable,
    doTrackProgressFromSlicer,
    doSetActiveJobType,
    doAddOperatingJob,
} from '../state/redux/actions';

import { thingStorageUrlSelector, heldSlicePayloadSelector } from '../state/redux/selectors';

import { JOB_TYPE } from '../consts';
import { getPrinterBuildVolume, getPrinterName } from '../helpers/printerUtils';
import { isValidParentOrigin, ultimakerInternalOrgs, getPayloadFromJWT } from '../helpers/epicUtils';

const handleWorkspacePrinters = workspace_printers => {
    const workspace_archetypes = workspace_printers
        .reduce((archetypes, printer) => {
            if (!archetypes.includes(printer.type)) archetypes.push(printer.type);
            return archetypes;
        }, [])
        .map(archetype => ({
            name: getPrinterName(archetype),
            volume: getPrinterBuildVolume(archetype),
            printer_id: archetype,
            type: archetype,
        }));

    workspace_printers = workspace_printers
        // filter out offline printers with no .status
        .filter(printer => printer.status)
        // sort printer by name
        .sort((a, b) => a.name.localeCompare(b.name))
        // add flag to designated workspace printers
        .map(printer => ({ ...printer, isWorkspacePrinter: true }));

    return of(doFetchWorkspacePrintersFulfilled({ workspace_archetypes, workspace_printers }));
};

const handleAccessToken = access_token => {
    const args = [doHandleDigitalFactoryToken(`um.${access_token}`), doFetchPrintersConfigs({})];
    const payload = getPayloadFromJWT(access_token);
    if (ultimakerInternalOrgs.includes(payload.organization?.organization_name)) {
        // For internal orgs we will show artifacts
        args.push(doFetchAvailableArtifacts());
    }
    return of(...args);
};

const handleParentPostMessage = (action$, state$) =>
    action$.pipe(
        ofType(HANDLE_PARENT_POST_MESSAGE_START),
        mergeMap(action => {
            const { target } = action.payload;

            /**
             * Let the Host application be aware that cloudprint is ready
             *
             * NOTE: When embedded in an iframe, `window.opener` is not a thing,
             * and instead is the parent. If we end up using it in more than one place,
             * please move this somewhere else, so it's globally accessible!
             */
            console.log('Sending postMessage telling host application that Cloudprint is ready...');
            const opener = target.opener || target.parent;
            opener.postMessage(
                {
                    __print: true,
                    type: 'cloudprint_ready',
                    value: true,
                },
                '*'
            );

            const getJobFromStore = jobId =>
                state$.value.appState.previewState.sliceJobs.find(job => job.localId === jobId);

            return fromEvent(target, 'message').pipe(
                filter(event => isValidParentOrigin(event.origin)),
                mergeMap(event => {
                    if (event.data?.workspace_printers) {
                        return handleWorkspacePrinters(event.data.workspace_printers);
                    } else if (event.data?.access_token) {
                        return handleAccessToken(event.data.access_token);
                    } else if (event.data?.keep_in_queue) {
                        const slicePayload = heldSlicePayloadSelector(state$.value);

                        if (!slicePayload) return EMPTY;

                        slicePayload.currentJob.jobType = JOB_TYPE.queue;
                        slicePayload.currentJob.printNow = false;
                        slicePayload.thingStorageURL = thingStorageUrlSelector(state$.value);

                        return of(
                            doSetActiveJobType(JOB_TYPE.queue),
                            doSendJobToSlicer(slicePayload),
                            doSetHeldSlicePayload(null)
                        );
                    } else if (event.data?.abort_slice) {
                        const currentSliceJobId = event.data.abort_slice.job_id;
                        const currentSliceJob = getJobFromStore(currentSliceJobId);

                        if (!currentSliceJob) return EMPTY;

                        return of(
                            doShowTopOverlayBar(false),
                            doSetHeldSlicePayload(null),
                            doSetThingLinkAvailable(false)
                        );
                    } else if (event.data?.start_print) {
                        const currentSliceJobId = event.data.start_print.job_id;
                        const currentSliceJob = getJobFromStore(currentSliceJobId);
                        if (!currentSliceJob) return EMPTY;

                        const currentSliceJobCopy = { ...currentSliceJob, id: event.data.start_print.slice_hash };

                        return of(
                            doTrackProgressFromSlicer({ currentJob: currentSliceJobCopy }),
                            doAddOperatingJob({
                                localId: currentSliceJobCopy.localId,
                                sliceJobId: currentSliceJobCopy.id,
                                jobType: currentSliceJobCopy.jobType,
                                printerId: currentSliceJobCopy.device.printer_id,
                                ready: false,
                                fileName: currentSliceJobCopy.fileName,
                                printerName: currentSliceJobCopy.device.name,
                            })
                        );
                    }

                    return EMPTY;
                }),
                catchError(error => {
                    console.error(error);
                    return of(doHandleParentPostMessageFailed(error));
                })
            );
        })
    );

export default handleParentPostMessage;
