import { ofType } from 'redux-observable';
import { fromEvent, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { compareEpicPayload, getFailedSliceJob } from '../helpers/utils';
import { BuildVolumes } from '../consts/printers';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';
import { doFetchProfileFailed, doFetchProfileFulfilled, doRunZipJob } from '../state/redux/actions';
import { FETCH_PROFILE_FAIL, FETCH_PROFILE_START, FETCH_PROFILE_SUCCESS } from '../state/redux/types';

const parseResponseToActions = (response, action) => {
    if (response.error) {
        return parseErrorToActions(response.error);
    }

    const payload = {
        ...action.payload,
        profileKey: response,
    };

    return of(doRunZipJob(payload), doFetchProfileFulfilled());
};

const parseErrorToActions = (error, action) => {
    console.log(`Error:`, error);
    const unformattedDate = new Date();

    return of(
        doFetchProfileFailed({
            failedSliceJob: getFailedSliceJob({
                localId: action.payload.currentJob.localId,
                jobType: action.payload.currentJob.jobType,
                device: action.payload.currentJob.device,
                tokens: action.payload.currentJob.tokens,
                fileName: action.payload.currentJob.fileName,
                unformattedDate,
            }),
        })
    );
};

const fetchProfile = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_PROFILE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_PROFILE),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;

            const printerType = action.payload.currentJob.device.type;
            const machineBounds = [...(BuildVolumes[printerType]?.bounds || [])];
            const expandAxisX = state$.value?.appState?.printerState?.printer?.expandAxisX;
            const xMinIndex = 2;
            machineBounds[xMinIndex] += expandAxisX;

            const data = {
                printerType,
                printMode: action.payload.overrides.printMode,
                extruders: action.payload.extruders,
                materials: action.payload.materials,
                overrides: { ...action.payload.overrides, machineBounds },
                gaggles: action.payload.gaggles,
            };

            if (selectedArtifact) {
                data['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('profile', data)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'profile').pipe(
                        mergeMap(response => parseResponseToActions(response, action)),
                        catchError(error => parseErrorToActions(error, action)),
                        takeUntil(action$.pipe(ofType(FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAIL)))
                    )
                )
            );
        })
    );

export default fetchProfile;
