import { ofType } from 'redux-observable';
import { catchError, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import JSZip from 'jszip';

import { RUN_ZIP_JOB_START } from '../state/redux/types';
import {
    doRunZipJobFulfilled,
    doRunZipJobFailed,
    doFetchPresignedUploadURLs,
    doSetNotification,
    doDownloadFileFromSignedUrlFulfilled,
    doFetchPresignedUploadURLsFulfilled,
    doShowTopOverlayBar,
    doSetThingLinkAvailable,
} from '../state/redux/actions';
import { compareEpicPayload, getFailedSliceJob } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME, URL_FETCHING_GOALS } from '../consts';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const saveFile = async blob => {
    const a = document.createElement('a');
    a.download = blob.name;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', e => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

const parseResponseToActions = (response, action) => {
    const payload = {
        ...action.payload,
        files: [...response],
        isFilesTracking: action.payload.goal !== URL_FETCHING_GOALS.SMART_ORIENT,
    };

    if (action.payload.goal === URL_FETCHING_GOALS.THING_EXPORT) {
        const thingBlob = payload.files.find(file => file.name.endsWith('.thing'));
        saveFile(thingBlob);
        return of(
            doShowTopOverlayBar(false),
            doSetNotification(NOTIFICATION_TYPE.EXPORTED),
            doDownloadFileFromSignedUrlFulfilled(action.payload),
            doFetchPresignedUploadURLsFulfilled(payload)
        );
    }

    return of(doRunZipJobFulfilled(), doFetchPresignedUploadURLs(payload), doSetThingLinkAvailable(true));
};

const parseErrorToActions = (error, action) => {
    console.log(`Failed to zip files`);
    console.log(`Error:`, error);
    const sliceJob = action.payload.currentJob;
    sliceJob.unformattedDate = new Date();
    return of(
        doRunZipJobFailed({
            failedSliceJob: getFailedSliceJob(sliceJob),
        })
    );
};

const getZipPromise = async (archiveName, filesToZip) => {
    const zip = new JSZip();
    filesToZip.forEach(file => {
        zip.file(file.name, file);
    });
    const blob = await zip.generateAsync({ type: 'blob' });
    const octetStreamBlob = blob.slice(0, blob.size, 'application/octet-stream');
    octetStreamBlob.name = archiveName;
    return octetStreamBlob;
};

const runZipJob = (action$, state$) =>
    action$.pipe(
        ofType(RUN_ZIP_JOB_START),
        debounceTime(EPICS_DEBOUNCE_TIME.RUN_ZIP_JOB),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const payload = action.payload;
            const thingZipPromise = getZipPromise(`${payload.currentJob?.fileName}.thing`, payload.files);
            const promises = [thingZipPromise];
            if (action.payload.goal !== URL_FETCHING_GOALS.SMART_ORIENT) {
                promises.push(getZipPromise('thumbnails.zip', payload.images));
            }
            return from(Promise.all(promises)).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default runZipJob;
