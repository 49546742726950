import { of, fromEvent } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    FETCH_CUSTOM_PRINT_SETTINGS_FAIL,
    FETCH_CUSTOM_PRINT_SETTINGS_START,
    FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS,
} from '../state/redux/types';
import {
    doFetchCustomPrintSettingsSuccess,
    doFetchCustomPrintSettingsFail,
    doClearPrinterOverrides,
    doSetNotification,
} from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResponseToAction = (responses, action) => {
    if (responses.error) {
        return parseErrorToAction(responses.error, action);
    }

    const customPrintModeOverrides = responses.settings;

    return of(doFetchCustomPrintSettingsSuccess({ customPrintModeOverrides }));
};

const parseErrorToAction = (error, action) => {
    console.log('Error fetching custom print settings', error);
    return of(
        doSetNotification(NOTIFICATION_TYPE.FETCHING_CUSTOM_PRINT_SETTINGS_ERROR),
        doClearPrinterOverrides(),
        doFetchCustomPrintSettingsFail(error)
    );
};

const fetchCustomPrintSettings = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_CUSTOM_PRINT_SETTINGS_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_CUSTOM_PRINT_SETTINGS),
        mergeMap(action => {
            const { customPrintModeId, userToken } = action.payload;
            const wsClient = getSchemaSettingsWsClient();
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            const params = { id: customPrintModeId, userToken: `Bearer ${userToken}` };
            if (selectedArtifact) {
                params['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('print-mode', params)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'print-mode').pipe(
                        mergeMap(response => parseResponseToAction(response, action)),
                        catchError(error => parseErrorToAction(error, action)),
                        takeUntil(
                            action$.pipe(ofType(FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS, FETCH_CUSTOM_PRINT_SETTINGS_FAIL))
                        )
                    )
                )
            );
        })
    );

export default fetchCustomPrintSettings;
