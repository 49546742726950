import { ajax } from 'rxjs/ajax';
import { timer, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, concatMap, catchError, takeUntil, debounceTime } from 'rxjs/operators';

import urls from '@makerbot/urls';

import {
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_START,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_FAILED,
} from '../state/redux/types';
import {
    doTrackProgressFromKaitenMethodExecFulfilled,
    doTrackProgressFromKaitenMethodExecFail,
    doUpdateProgressFromKaitenMethodExec,
} from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';

const parseResToActions = ({ response }) => {
    if (response.isError) {
        return of(doTrackProgressFromKaitenMethodExecFail());
    } else if (response.isReady) {
        return of(
            doTrackProgressFromKaitenMethodExecFulfilled({
                spoolInfo: response.result,
            })
        );
    } else {
        return of(doUpdateProgressFromKaitenMethodExec());
    }
};

const parseErrorToActions = error => {
    console.log('Error: ', error);
    return of(doTrackProgressFromKaitenMethodExecFail(error));
};

const trackProgressFromKaitenMethodExec = action$ =>
    action$.pipe(
        ofType(TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_START),
        debounceTime(EPICS_DEBOUNCE_TIME.TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC),
        mergeMap(action => {
            const { printer_id, commandId } = action.payload;
            const apiUrl = `https://${urls.teams}/queue/printer/${printer_id}/commands/get-result/${commandId}`;

            const fetchProgress$ = ajax({
                url: apiUrl,
                method: 'GET',
                crossDomain: true,
                withCredentials: true,
            });

            const trigger$ = timer(0, 1000);

            return trigger$.pipe(
                concatMap(() => fetchProgress$),
                mergeMap(response => parseResToActions(response)),
                catchError(error => parseErrorToActions(error)),
                takeUntil(
                    action$.pipe(
                        ofType(
                            TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS,
                            TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_FAILED
                        )
                    )
                )
            );
        })
    );

export default trackProgressFromKaitenMethodExec;
