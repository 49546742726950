import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootEpic from './rootEpic';
import rootReducer from './rootReducer';
import * as actionCreators from './state/redux/actions';

const epicMiddleware = createEpicMiddleware();
const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25,
    actionsDenylist: '_SUCCESS', // toggle on/off to filter Success responses while debugging in Redux devtools
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)));

epicMiddleware.run(rootEpic);

export default store;
