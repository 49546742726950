import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { handleError } from '../../state/redux/actions';

class ErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        this.props.throwError(errorInfo);
    }

    static getDerivedStateFromError(error) {
        return { errorMessage: error };
    }

    render() {
        if (this.props.errorMessage) {
            return (
                <div>
                    <h2>{this.props.errorMessage}</h2>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    errorMessage: state.appState.errorState.errorMessage,
});

const mapDispatchToProps = dispatch => {
    return {
        throwError: errorMessage => dispatch(handleError({ errorMessage })),
    };
};

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    errorCode: PropTypes.number,
    errorMessage: PropTypes.string,
    throwError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
