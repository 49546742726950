import { io } from 'socket.io-client';
import urls from '@makerbot/urls';

import { SOCKET_CLIENT_TYPES, getWindowMockedSocketIO } from '../../cypress/support/socket-mocks';

let cloudslicerWsClient, schemaSettingsWsClient;

const getCloudslicerWsClient = () => {
    if (!cloudslicerWsClient) {
        //If window already contains socket io client - it means it has been mocked, and should be used.
        cloudslicerWsClient = window[SOCKET_CLIENT_TYPES.CLOUDSLICER]
            ? getWindowMockedSocketIO(SOCKET_CLIENT_TYPES.CLOUDSLICER)
            : io(`https://${urls.cloudslicer}`, {
                  transports: ['websocket'],
                  path: '/api/v2/events/',
              });
    }
    return cloudslicerWsClient;
};

const getSchemaSettingsWsClient = () => {
    if (!schemaSettingsWsClient) {
        schemaSettingsWsClient = io(`https://${urls.schemaSettings}`, {
            transports: ['websocket'],
        });
    }
    return schemaSettingsWsClient;
};

export { getCloudslicerWsClient, getSchemaSettingsWsClient };
