import urls from '@makerbot/urls';

import {
    AmbientLight,
    Color,
    DirectionalLight,
    DoubleSide,
    FrontSide,
    MeshBasicMaterial,
    MeshPhongMaterial,
    OrthographicCamera,
    PerspectiveCamera,
    Plane,
    SpotLight,
    Vector3,
} from 'three';

import { getInitialSettings } from '../helpers/utils/model-settings';
import { getAppName, isLightTheme } from '../helpers/utils/environment';

const SYSTEM_UNITS = 'mm';
const MEASUREMENTS_UNITS = {
    mm: { id: 'mm', value: 'mm', label: 'Millimeters', shortLabel: 'mm' },
    cm: { id: 'cm', value: 'cm', label: 'Centimeters', shortLabel: 'cm' },
    m: { id: 'm', value: 'm', label: 'Meters', shortLabel: 'm' },
    in: { id: 'in', value: 'in', label: 'Inches', shortLabel: '"' },
    seconds: { id: 'seconds', value: 's', label: 'Seconds', shortLabel: 's' },
    mmPerSec: { id: 'mmPerSec', value: 'mm/s', label: 'Millimeters per second', shortLabel: 'mm/s' },
    percents: { id: 'percents', value: '%', label: 'Percents', shortLabel: '%' },
    celsius: { id: 'celsius', value: '°C', label: 'Celcius', shortLabel: '°C' },
    degree: { id: 'degree', value: 'º', label: 'Degree', shortLabel: 'º' },
};
const EXTRUDER_ID_SPLITTER = '#';

// three js objects names
const BOX_NAME = 'box';
const PLANE_NAME = 'plane';
const GRID_PLANE_TRANSPARENT_NAME = 'grid-plane-transparent';
const PLANE_INVISIBLE_NAME = 'plate-invisible';
const GRID_NAME = 'grid';
const PLANE_BORDER_NAME = 'plane-border';
const AXES_HELPER_NAME = 'axes-helper';
const PURGE_TOWER_NAME = 'purge-tower';
const MODEL_AXES_NAME = 'model-axes';
const ORIENT_PLANE_NAME = 'orient-plane';
const PROGRESS_BOX_NAME = 'progress-box';
const MEASURE_TOOL_SPRITE_ONE = 'measure-tool-sprite-1';
const MEASURE_TOOL_SPRITE_TWO = 'measure-tool-sprite-2';
const MEASURE_TOOL_LINE = 'measure-tool-line';

// The list of objects which we want to intersect besides of uploaded models
const OBJECTS_TO_INTERSECT = [PLANE_INVISIBLE_NAME, PURGE_TOWER_NAME, AXES_HELPER_NAME, PLANE_NAME];

// The list of objects which we want to select by box selection
const OBJECTS_TO_BOX_SELECTION = [PURGE_TOWER_NAME];

const ROTATE_BUTTON_STEP = 45;
const POSITION_BUTTON_STEP = 1;
const SCALE_BUTTON_STEP = 1;
const INFILL_DENSITY_STEP = 1;
const ARRANGE_BUTTON_STEP = 1;

// REG
const REG_STL_FILE_EXTENSION = /.(stl){1}$/i;
const REG_3MF_FILE_EXTENSION = /.(3mf){1}$/i;
const REG_DAE_FILE_EXTENSION = /.(dae){1}$/i;
const REG_SLDASM_FILE_EXTENSION = /.(sldasm){1}$/i;
const REG_SLDPRT_FILE_EXTENSION = /.(sldprt){1}$/i;
const REG_OBJ_FILE_EXTENSION = /.(obj){1}$/i;
const REG_JSON_FILE_EXTENSION = /.(json){1}$/i;
const REG_THING_FILE_EXTENSION = /.(thing){1}$/i;
const REG_MAKERBOT_FILE_EXTENSION = /.(makerbot){1}$/i;
const REG_PRINT_MODE_EXTENSION = /.(printmode){1}$/i;
const REG_GLTF_FILE_EXTENSION = /.(gltf){1}$/i;
const REG_GLB_FILE_EXTENSION = /.(glb){1}$/i;
const REG_BIN_FILE_EXTENSION = /.(bin){1}$/i;
const REG_ZIP_FILE_EXTENSION = /.(zip){1}$/i;
const REG_NUMBERS = /^[0-9|.|-]*$/;

// colors
const COLOR_CANVAS_BACKGROUND = (isDF = false) => (isDF ? '#FBFAF9' : '#F2F2F2');
const COLOR_RED_OUTLINE_PASS = new Color(0xff0000);
const COLOR_BLUE_HOVER_OUTLINE_PASS = new Color(0xbbd7e6);
const COLOR_BLUE_ACTIVE_OUTLINE_PASS = new Color(0x569bc1);
const COLOR_YELLOW_OUTLINE_PASS = new Color(0xfca833);
const COLOR_LIGHT_BLUE = new Color(0x569bc1);
const COLOR_DARK_BLUE = new Color(0x33769b);
const COLOR_YELLOW = new Color(0xfca833);
const COLOR_GRAY_BOX_BORDER = new Color(0xdedede);

//acceptable formats
//NOTE: commented formats have non-standard conversion behavior - need to be tested
const FILE_FORMATS = Object.freeze({
    stl: '.stl',
    thing: '.thing',
    makerbot: '.makerbot',
    zip: '.zip',
    CATPart: '.catpart',
    dae: '.dae',
    glb: '.glb',
    iges: '.iges',
    igs: '.igs',
    ipt: '.ipt',
    obj: '.obj',
    par: '.par',
    rfa: '.rfa',
    prt: '.prt',
    sab: '.sab',
    sat: '.sat',
    sldprt: '.sldprt',
    wrl: '.wrl',
    x_t: '.x_t',
    _3mf: '.3mf',
    jt: '.jt',
    x_b: '.x_b',
    iam: '.iam',
    CATProduct: '.catproduct',
    sldasm: '.sldasm',
    stp: '.stp',
    step: '.step',
    _3dxml: '.3dxml',
    asm: '.asm',
    u3d: '.u3d',
    gltf: '.gltf',
    bin: '.bin',
});

const POCKET_PRINT_FILE_FORMATS = Object.freeze({
    stl: FILE_FORMATS.stl,
    thing: FILE_FORMATS.thing,
    makerbot: FILE_FORMATS.makerbot,
    zip: FILE_FORMATS.zip,
    _3fm: FILE_FORMATS._3mf,
    dae: FILE_FORMATS.dae,
    obj: FILE_FORMATS.obj,
    glb: FILE_FORMATS.glb,
    gltf: FILE_FORMATS.gltf,
    bin: FILE_FORMATS.bin,
});

const PRINT_MODE_FILE_FORMATS = Object.freeze({
    printMode: '.printmode',
});

// modals
const UPLOAD_MODAL_FITS_HEADER = `The model is bigger than the selected printer's build volume`;
const UPLOAD_MODAL_FITS_BODY = `The model can be scaled (50%) to fit the current printer, manually reoriented for a better fit, or scaled down after import.`;

const UNITS_MISMATCH_HEADER = 'Unit mismatch';
const UNITS_MISMATCH_BODY = `The uploaded model's units differ from the current system units. Model sizes or positions may be incorrect. The uploaded model is in $UNITS_LOWERCASE. Please select "$UNITS" through Settings > Application Units.`;

const MODAL_EXTENSION_HEADER = 'Error opening file';
const MODAL_EXTENSION_NOT_VALID_BODY =
    'There was an error opening the file. Please confirm that it is a valid $FORMAT file.';

const MODAL_WRONG_FORMATS_HEADER = 'Incompatible file formats detected';
const MODAL_WRONG_FORMATS_BODY = () => {
    return `Some or all of the files you're trying to import are not supported and have not been imported. The following models are not loaded:`;
};

const MODAL_DOWNLOAD_IMPOSSIBLE_HEADER = 'Unable to download file';
const MODAL_DOWNLOAD_IMPOSSIBLE_BODY =
    'Unfortunately, it is impossible to download this file, because it was sliced too long ago. Please, re-slice it again to download.';

const MODAL_FILE_TOO_OLD_HEADER = 'Unable to import model';
const MODAL_FILE_TOO_OLD_BODY =
    'The selected model was not exported from a supported version of the CAD application. Please try a newer version.';

const MODAL_FILE_TOO_RECENT_HEADER = 'Unable to import model';
const MODAL_FILE_TOO_RECENT_BODY =
    'The selected model was not exported from a supported version of the CAD application. Please try a less recent version.';

const MODAL_UPLOAD_HEADER = 'To start a new print insert a .stl, .Thing or .makerbot file';
const MODAL_UPLOAD_BODY = 'The following formats are also supported:';
const MODAL_COMPARE_MODE_HEADER = 'Please add model for comparison';

const MODAL_SIZE_HEADER = 'Exported file too large';
const MODAL_SIZE_BODY =
    'The uploaded file size exceeds the recommended 256 MB size for MakerBot Cloud. Please consider reducing the file size and uploading again.';

const MODAL_IMPORT_CUSTOM_MODE_HEADER = 'Please select your preferred Import option';
const MODAL_EXPORT_CUSTOM_MODE_HEADER = 'Please select your preferred export option';
const MODAL_SAVE_CUSTOM_MODE_HEADER = 'Please add your custom print mode name';

const FILESIZE_TOO_LARGE_HEADER = 'File too large';
const FILESIZE_STL_TOO_LARGE_BODY =
    'There was an error uploading the file. Please upload a .STL file that is smaller than 256MB.';
const FILESIZE_STL_TOO_LARGE_SIMPLIFY_BODY = () => {
    const appNameShortVersion = getAppName();
    return `The file is too large. The maximum file size is 256MB. ${appNameShortVersion} can simplify the file for you, or you can modify the model yourself to reduce the file size. Do you want ${appNameShortVersion} to simplify the uploaded file?`;
};
const FILESIZE_MAKERBOT_TOO_LARGE_BODY =
    'The uploaded .makerbot file size exceeds the recommended 75 MB size. Please consider reducing the file size and uploading again.';

const MISSING_PARTS_HEADER = 'Assembly is incomplete';
const MISSING_PARTS_BODY = 'This assembly is incomplete. Please ensure its components are in the same directory.';

const MODAL_MAKERBOT_ERROR_HEADER = '.makerbot Error';
const MODAL_MAKERBOT_DETECTED_HEADER = '.makerbot file detected';
const MODAL_MAKERBOT_DETECTED_IN_FOLDER_BODY = "One or more files in this folder has already been prepared."
const MODAL_MAKERBOT_DETECTED_IN_FOLDER_ACTION_BODY = "Would you like to open in a separate window?"
const MODAL_MAKERBOT_ERROR_FOR_NON_EMPTY_PLATE_BODY =
    '$FILENAME is prepared for a $PRINTER_TYPE printer with $EXTRUDER_TYPE $EXTRUDER and with $MATERIAL_TYPE $MATERIAL. Open the .makerbot in new window and choose a compatible printer. A .makerbot file also can not be added to build plate with models.';
const MODAL_MAKERBOT_ERROR_FOR_NON_EMPTY_PLATE_BODY_ELECTRON =
    'A .makerbot file can not be added to build plate with models. Please clear the build plate, then retry import.';
const MODAL_MAKERBOT_ERROR_FOR_EMPTY_PLATE_BODY =
    '$FILENAME is prepared for a $PRINTER_TYPE printer with $EXTRUDER_TYPE $EXTRUDER and with $MATERIAL_TYPE $MATERIAL. The selected printer will be changed to compatible printer type.';
const MODAL_MAKERBOT_ERROR_FOR_COMPARISON_BODY =
    'The selected models were prepared for different hardware. Please select a second model prepared for the same hardware as the first.';

const MODAL_MAKERBOT_CORRUPTED_ERROR = 'Corrupted File';
const MODAL_MAKERBOT_ERROR_CORRUPTED_FILE_BODY =
    'The uploaded .makerbot file is corrupted. Please choose a different one.';

const MODAL_PRINT_MODE_OVERRIDES_ERROR_HEADER = 'Incompatible settings in custom print mode';
const MODAL_PRINT_MODE_OVERRIDES_ERROR_BODY = `Due to changes in system configurations, this mode can no longer be used. If you want to continue using "$PRINT_MODE_NAME", please ignore the settings of $OVERRIDES. If you want to remove "$PRINT_MODE_NAME" from the print modes list, please click on "Remove this Mode" button below.`;

const MODAL_SAVE_APPLICATION_SETTINGS_HEADER = 'Your settings have not been saved.';
const MODAL_SAVE_APPLICATION_SETTINGS_BODY = 'Are you sure you would like to proceed?';

const MODAL_SAVE_APPLICATION_GRAPHICS_SETTINGS_HEADER = 'Graphics settings were changed.';
const MODAL_SAVE_APPLICATION_GRAPHICS_SETTINGS_BODY = 'You need to reload the page to apply graphics preferences.';

const MODAL_CLEAR_BUILD_PLATE_HEADER = 'Do you want to clear the build plate?';
const MODAL_CLEAR_BUILD_PLATE_BODY = `You will lose all your current progress. Print settings won't be removed.`;

const UNABLE_TO_CONVERT_FILETYPE_HEADER = 'Unable to convert filetype for this operating system';
const UNABLE_TO_CONVERT_FILETYPE_BODY =
    'Your current operating system does not support file conversions from the type you uploaded. Please upload a supported file type: .stl, .obj, .3mf, .dae.';

const SETTINGS_INPUT_ERROR = 'Settings formatting error';

const PRINT_PREVIEW_FAILED_MODEL_TOO_LARGE = 'Print Preview cannot be completed because the model is too large';

// Banner messages
const BANNER_PLACE_FACE_ROTATE_MODEL =
    'Clicking on the face of the model will rotate the model to face the buildplate.';

const REDIRECT_BLOCKED_MESSAGE = 'Opening one or more files was blocked by the browser. Please, allow to send pop-ups and use redirects'

// tooltip messages
const SCALE_TO_MAX = 'Scale to Max';
const SEARCH_BY_SETTING_NAME = 'Search by Setting Name';
const ARRANGE_BUILD_PLATE = 'Automatically arrange items on the build plate.';
const PLACE_FACE = 'Select face of model to align to build plate.';
const SAVE_CUSTOM_PRINT_MODE_NOT_AVAILABLE =
    'To save custom print mode, you must first change print settings from default values.';
const PRINT_MODE =
    'Print modes are a series of parameters tuned by MakerBot to help users achieve an outcome for the final part without having to make manual parameter changes. It is highly recommended to use print modes to get the best print results.';
const SUPPORTED_FILE_FORMATS = 'Supported file formats: \n$FILE_FORMATS';
const ASSEMBLE_CHECKBOX = 'Assemble';
const ASSEMBLE_CHECKBOX_WITH_INCORRECT_FOLDER_STRUCTURE = 'Files have been edited. Assembly not possible';
const MATERIAL_IS_NOT_LOADED_TOOLTIP = 'This material is not currently loaded in the printer';
const REQUIRES_DIFFERENT_EXTRUDER_TOOTIP = 'Requires Different Extruder';
const REQUIRES_DIFFERENT_SUPPORT_MATERIAL_TOOLTIP = 'Requires Different Support Material';
const REQUIRES_DIFFERENT_MODEL_MATERIAL_TOOLTIP = 'Requires Different Model Material';
const MATERIAL_LOADED_TOOLTIP = 'Material Loaded';
const PURGE_TOWER_TOOLTIP = 'The purge tower improves print accuracy by priming the nozzle after each nozzle switch.';

// cameras
const CAMERAS_TYPE = Object.freeze({
    perspectiveCamera: 'PerspectiveCamera',
    orthographicCamera: 'OrthographicCamera',
});

const defaultOptics = {
    fov: 45,
    near: 1,
    far: 5000,
};

const initCamera = camera => {
    camera.up.set(0, 0, 1);
    camera.position.z = 250;
    camera.position.y = -750;
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
};

const newPerspectiveCamera = () => {
    const camera = new PerspectiveCamera(
        defaultOptics.fov,
        window.innerWidth / window.innerHeight,
        defaultOptics.near,
        defaultOptics.far
    );

    initCamera(camera);

    return camera;
};

const newOrthographicCamera = () => {
    const camera = new OrthographicCamera(
        -window.innerWidth,
        window.innerWidth,
        window.innerHeight,
        -window.innerHeight,
        defaultOptics.near,
        defaultOptics.far
    );
    initCamera(camera);

    return camera;
};

const MODEL_MATERIAL = new MeshPhongMaterial({
    color: 0x999999,
    specular: 0x242424,
    // "side: DoubleSide" was added for some GLTF models
    side: DoubleSide,
});

const MODEL_HOVER_MATERIAL = new MeshPhongMaterial({
    color: 0xa6a6a6,
    side: DoubleSide,
});

const PURGE_TOWER_MATERIAL = new MeshPhongMaterial({
    color: MODEL_MATERIAL.color,
    specular: 0x242424,
    opacity: 1,
    side: FrontSide,
});

const MAX_ANIMATION_LAYER_Z = 99999;

const MODEL_TRANSPARENT_MATERIAL = new MeshPhongMaterial({
    color: 0x999999,
    specular: 0x000000,
    opacity: 0.5,
    transparent: true,
});

const MODEL_PREVIEW_MATERIAL = new MeshPhongMaterial({
    color: 0x569bc1,
    specular: 0x242424,
    opacity: 0.25,
    side: FrontSide,
    transparent: true,
    clippingPlanes: [new Plane(new Vector3(0, 0, -1), MAX_ANIMATION_LAYER_Z)],
    clipShadows: true,
});

const MODEL_INVISIBLE_MATERIAL = new MeshPhongMaterial({
    color: 0x999999,
    specular: 0x000000,
    opacity: 0,
    transparent: true,
});

const MODEL_OUTSIDE_OVERLAP_MATERIAL = new MeshPhongMaterial({
    color: 0xb7a68e,
    specular: 0x242424,
    side: DoubleSide,
});

const DEFAULT_ORIENT_PLANE_SIZE = 40;

const ORIENT_PLANE_MATERIAL = new MeshPhongMaterial({
    color: 0x32aadd,
    transparent: true,
    opacity: 0.3,
    side: FrontSide,
    depthTest: false,
    depthWrite: false,
    polygonOffset: true,
    polygonOffsetFactor: -2,
    polygonOffsetUnits: -2,
});

const ORIENT_PLANE_BORDER_MATERIAL = new MeshBasicMaterial({
    color: 0x33aadd,
    transparent: true,
    opacity: 1.0,
    polygonOffset: true,
    depthTest: false,
    depthWrite: false,
});

const ORIENT_PLANE_ARROW_MATERIAL = new MeshPhongMaterial({
    color: 0x33aadd,
    shininess: 40,
    transparent: true,
    opacity: 1.0,
    depthTest: false,
    depthWrite: true,
});

const MODEL_BOUNDING_BOX_MATERIAL = new MeshBasicMaterial();

const LIGHTS = [
    {
        light: new SpotLight(0xffffff, 4, 750, Math.PI / 4, 0, 1),
        position: {
            x: 0,
            y: 0,
            z: 650.5,
        },
    },
    {
        light: new DirectionalLight(0xdddddd, 0.6),
        position: {
            x: 0.75,
            y: 0.25,
            z: 0.25,
        },
    },
    {
        light: new DirectionalLight(0xdddddd, 0.6),
        position: {
            x: -0.75,
            y: 0.25,
            z: 0.25,
        },
    },
    {
        light: new DirectionalLight(0xdddddd, 0.3),
        position: {
            x: 0,
            y: 0,
            z: 1,
        },
    },
    {
        light: new DirectionalLight(0xdddddd, 0.6),
        position: {
            x: -0.75,
            y: -0.25,
            z: -0.25,
        },
    },
    {
        light: new AmbientLight(0x505050, 0.8),
    },
];

const PRINT_JOB_ORIGIN = Object.freeze({
    thing: 'THING',
    makerbot: 'MAKERBOT',
});

const PRINTER_CHANGE_STATUS = Object.freeze({
    changed: 'SWITCHED',
    inProgress: 'SWITCHING',
    notChanged: 'NOT_SWITCHED',
});

const EXPORT_BUTTON_TEXT = Object.freeze({
    export: 'Export',
    queue: 'Queue',
    print: 'Print',
});

const URL_FETCHING_GOALS = Object.freeze({
    MAKERBOT_PREVIEW: 'MAKERBOT_PREVIEW',
    STL_PREVIEW: 'STL_PREVIEW',
    THING_EXPORT: 'THING_EXPORT',
    //COMPARISON_BUILD: 'COMPARISON_BUILD',
    STL_CONVERT: 'STL_CONVERT',
    SMART_ORIENT: 'SMART_ORIENT',
});

const SETTINGS = Object.freeze({
    printMode: 'printMode',
    shells: 'shells',
    infillDensity: 'infillDensity',
    supports: 'supports',
    scaleX: 'scaleX',
    scaleY: 'scaleY',
    scaleZ: 'scaleZ',
    scaleUnitsX: 'scaleUnitsX',
    scaleUnitsY: 'scaleUnitsY',
    scaleUnitsZ: 'scaleUnitsZ',
    uniformScaling: 'uniformScaling',
    xRotation: 'xRotation',
    yRotation: 'yRotation',
    zRotation: 'zRotation',
    xPosition: 'xPosition',
    yPosition: 'yPosition',
    zPosition: 'zPosition',
});

// ENDPOINTS
const QUERY_FILE_PARAM = 'file_url';
const QUERY_FILE_NAME_PARAM = 'fileName';

const expandAxisX = -37.6;

const PRINT_PREVIEW_MODES = Object.freeze({
    threeD: '3D',
    layer: 'layer',
});

const PRINT_PREVIEW_ITEMS = [
    {
        label: '3D',
        mode: PRINT_PREVIEW_MODES.threeD,
    },
    {
        label: 'Layer',
        mode: PRINT_PREVIEW_MODES.layer,
    },
];

const PRINT_PREVIEW_SCENE_MESHES_NAMES = Object.freeze({
    reconstructedSupport: 'reconstructedSupport',
    reconstructedRaft: 'reconstructedRaft',
    reconstructedBrim: 'reconstructedBrim',
    reconstructedMaterial: 'reconstructedMaterial',
    reconstructedPurgeSupport: 'reconstructedPurgeSupport',
    reconstructedPurgeMaterial: 'reconstructedPurgeMaterial',
});

const APP_SETINGS_MODES = Object.freeze({
    settings: 'SETTINGS',
    shortcuts: 'SHORTCUTS',
    about: 'ABOUT',
});

const APP_SETTINGS_ITEMS = [
    {
        label: 'Settings',
        mode: APP_SETINGS_MODES.settings,
    },
    {
        label: 'Shortcuts',
        mode: APP_SETINGS_MODES.shortcuts,
    },
    {
        label: 'About',
        mode: APP_SETINGS_MODES.about,
    },
];

// files max size
const MAX_UPLOAD_STL_FILE_SIZE = 256 * 1024 * 1024;
const MAX_UPLOAD_MAKERBOT_FILE_SIZE = 75 * 1024 * 1024;
const MAX_EXPORT_FILE_SIZE = MAX_UPLOAD_STL_FILE_SIZE;

const BOT_TYPE_ENUM = Object.freeze({
    mini_8: { id: 'mini_8', label: 'Replicator Mini+' },
    replicator_5: { id: 'replicator_5', label: 'Replicator 5th Gen' },
    replicator_b: { id: 'replicator_b', label: 'Replicator+' },
    z18_6: { id: 'z18_6', label: 'Replicator Z18' },
    fire_e: { id: 'fire_e', label: 'Method' },
    lava_f: { id: 'lava_f', label: 'Method X' },
    sketch: { id: 'sketch', label: 'Sketch' },
    magma_10: { id: 'magma_10', label: 'Method XL' },
    sketch_large: { id: 'sketch_large', label: 'Sketch Large' },
});

const PRINT_MODES_ENUM = Object.freeze({
    'balanced': {
        id: 'balanced',
        label: 'Balanced',
        description: 'A balance between surface quality and quick print time. Perfect for most prints.',
    },
    'draft': {
        id: 'draft',
        label: 'Draft',
        description: 'Favors speed over surface quality. A great mode for printing in iterations. ',
    },
    'high-quality': {
        id: 'high-quality',
        label: 'High Quality',
        description: 'A focus on high surface quality but at a slower print time. Great for small and detailed parts.',
    },
    'solid': {
        id: 'solid',
        label: 'Solid',
        description: 'A highly dense and strong part but at a slower print time. Great for functional parts.',
    },
    'minfill': {
        id: 'minfill',
        label: 'Minfill',
        description:
            "The fastest mode using the minimum amount of internal structure. Ideal for large prints that don't need to be durable.",
    },
    'mixed': {
        id: 'mixed',
        label: 'Mixed',
        description: 'Multiple print modes have been selected for files visible on the build plate.',
    },
});

const MATERIAL_TYPES = Object.freeze({
    'pla': { id: 'pla', label: 'PLA' },
    'im-pla': { id: 'im-pla', label: 'Tough' },
    'hips': { id: 'hips', label: 'HIPS' },
    'abs': { id: 'abs', label: 'ABS' },
    'asa': { id: 'asa', label: 'ASA' },
    'pcl': { id: 'pcl', label: 'PCL' },
    'pet': { id: 'pet', label: 'PETG' },
    'pva': { id: 'pva', label: 'PVA' },
    'sr30': { id: 'sr30', label: 'SR-30' },
    'nylon': { id: 'nylon', label: 'Nylon' },
    'nylon-cf': { id: 'nylon-cf', label: 'Nylon Carbon Fiber', shortLabel: 'Nylon CF' },
    'nylon12-cf': { id: 'nylon12-cf', label: 'Nylon 12 Carbon Fiber', shortLabel: 'Nylon 12 CF' },
    'pc-abs': { id: 'pc-abs', label: 'PC-ABS' },
    'pc-abs-fr': { id: 'pc-abs-fr', label: 'PC-ABS-FR' },
    'metal': { id: 'metal', label: 'Metal' },
    'abs-wss1': { id: 'abs-wss1', label: 'ABS-R' },
    'wss1': { id: 'wss1', label: 'RapidRinse' },
    'abs-cf10': { id: 'abs-cf10', label: 'ABS-CF' },
    'unknown': { id: 'unknown', label: 'Unknown' },
    'null': { id: 'null', label: 'Null' },
    'polymaker-polymax-pc': { id: 'polymaker-polymax-pc', label: 'Polymaker PolyMax PC' },
    'lehvoss-paht-9891': { id: 'lehvoss-paht-9891', label: 'Luvocom 3F PAHT CF 9891' },
    'jabil-sebs-95a': { id: 'jabil-sebs-95a', label: 'Jabil SEBS 1300 95A' },
    'generic': { id: 'generic', label: 'Generic' },
    'none': { id: 'none', label: 'None' },
});

const PARTNER_MATERIAL_TYPES = [
    { id: 'paht', label: 'paht', value: 'paht' },
    { id: 'polymaker-polymax-pc', label: 'Polymaker PolyMax PC', value: 'polymaker-polymax-pc' },
    { id: 'lehvoss-paht-9891', label: 'Luvocom 3F PAHT CF 9891', value: 'lehvoss-paht-9891' },
    { id: 'jabil-sebs-95a', label: 'Jabil SEBS 1300 95A', value: 'jabil-sebs-95a' },
];
const PARTNER_MATERIALS = ['polymaker-polymax-pc', 'lehvoss-paht-9891', 'jabil-sebs-95a'];

const EXTRUDERS = Object.freeze({
    mk14_hot: { id: 'mk14_hot', label: 'Model 1XA', shortLabel: '1XA' },
    mk14_hot_s: { id: 'mk14_hot_s', label: 'Support 2XA', shortLabel: '2XA' },
    mk14_c: { id: 'mk14_c', label: 'Model 1C', shortLabel: '1C' },
    mk14_e: { id: 'mk14_e', label: 'LABS Extruder', shortLabel: 'LABS' },
    mk14: { id: 'mk14', label: 'Model 1A', shortLabel: '1A' },
    mk14_s: { id: 'mk14_s', label: 'Support 2A', shortLabel: '2A' },
    mk13: { id: 'mk13', label: 'Smart Extruder+', shortLabel: 'Smart+' },
    mk13_impla: { id: 'mk13_impla', label: 'Tough Smart Extruder+', shortLabel: 'Tough Smart+' },
    mk13_experimental: { id: 'mk13_experimental', label: 'Experimental Extruder', shortLabel: 'Exp.' },
    sketch_extruder: { id: 'sketch_extruder', label: 'Sketch Extruder', shortLabel: 'Sketch' },
    sketch_l_extruder: { id: 'sketch_l_extruder', label: 'Sketch Extruder', shortLabel: 'Sketch' },
});

const MATERIAL_COLORS = Object.freeze({
    'black': '#191919',
    'onyx black': '#191919',
    'stone white': '#fff7e9',
    'safety orange': '#ff4000',
    'slate grey': '#2b2828',
    'natural': '#fff5d6',
    'true red': '#ff001e',
    'true black': '#0b1411',
    'true white': '#ffffff',
    'cool grey': '#565f67',
    'true orange': '#ff3200',
    'warm gray': '#eee8dd',
    'khaki': '#727d54',
    'true green': '#00cc1f',
    'neon pink': '#ff00fb',
    'neon orange': '#ff8400',
    'true brown': '#59362c',
    'true blue': '#0047e0',
    'true yellow': '#ffe72e',
    'glow in the dark': '#dbff9e',
    'gray': '#cfcfcf',
    'translucent red': '#f50000',
    'translucent blue': '#0021c7',
    'translucent purple': '#94007e',
});

const DEFAULT_FILAMENT_COLOR = '#555555';

const SUPPORT_TYPES = Object.freeze({
    none: { id: 'none', label: 'None' },
    modelBreakaway: { id: 'modelBreakaway', label: 'Breakaway - Model Material' },
    supportBreakaway: { id: 'supportBreakaway', label: 'Breakaway - Support Material' },
    dissolvable: { id: 'dissolvable', label: 'Dissolvable - Column' },
    taperIn: { id: 'taperIn', label: 'Dissolvable - Tapered' },
});

const BASE_LAYER_TYPES = Object.freeze({
    none: { id: 'none', label: 'None' },
    raft: { id: 'raft', label: 'Raft' },
    paddedBase: { id: 'paddedBase', label: 'Padded Base' },
    brims: { id: 'brims', label: 'Brims' },
    paddedBaseBrims: { id: 'paddedBaseBrims', label: 'Padded Base Brims' },
});

const NUMBER_OF_SHELLS = [
    { value: '0', label: '0' },
    { value: '1', label: '1 - Very Delicate' },
    { value: '2', label: '2 - Recommended' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5 - Very Durable' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
];

const JOB_TYPE = Object.freeze({
    export: 'EXPORT',
    exportThing: 'EXPORT_THING',
    //This job type is legacy from the time we used PrintPrep as separate app. We should get rid of it.
    print: 'PRINT',
    //This one as well.
    printMakerbot: 'PRINT_MAKERBOT',
    queue: 'QUEUE',
    immediatePrint: 'IMMEDIATE_PRINT',
    preview: 'PREVIEW',
    previewMakerbot: 'PREVIEW_MAKERBOT',
    upload: 'UPLOAD',
    convert: 'CONVERT',
    downloadDemo: 'DOWNLOAD_DEMO',
    simplify: 'SIMPLIFY',
});

const FILE_UPLOAD_ACTION = Object.freeze({
    upload: 'UPLOAD',
    uploadAndSend: 'UPLOAD_AND_SEND',
});

const MOUSE_BUTTONS = {
    Left: 0,
    Middle: 1,
    Right: 2,
};

const MOUSE_DIRECTIONS = {
    leftToRight: 'leftToRight',
    rightToLeft: 'rightToLeft',
};

const AXES = {
    x: 'X',
    y: 'Y',
    z: 'Z',
};

const SMART_ORIENT_STATE = Object.freeze({
    idle: 'idle',
    active: 'active',
    fail: 'fail',
});

const SMART_ARRANGE_STATE = Object.freeze({
    idle: 'idle',
    active: 'active',
});

const APPLICATION_UNITS = [MEASUREMENTS_UNITS.mm, MEASUREMENTS_UNITS.cm, MEASUREMENTS_UNITS.m, MEASUREMENTS_UNITS.in];

const SYSTEM_PREFERENCES_KEYS_OR_SPLITTER = 'or';
const SYSTEM_PREFERENCES_ACTION_KEYS = {
    zoom: [
        {
            value: '-scrWhl-',
            label: 'Scroll Wheel',
            isActive: mouseEvent => {
                return mouseEvent instanceof WheelEvent;
            },
        }, // should be default value for zoom
        {
            value: `-scrWhl-${SYSTEM_PREFERENCES_KEYS_OR_SPLITTER}-MidBut-`,
            label: 'Scroll Wheel or Middle mouse button',
            isActive: mouseEvent => {
                return (
                    mouseEvent instanceof WheelEvent ||
                    (mouseEvent.button === MOUSE_BUTTONS.Middle && !mouseEvent.shiftKey && !mouseEvent.ctrlKey)
                );
            },
        },
        {
            value: '-ctrlAndRBut-',
            label: 'Right mouse button + CTRL',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Right && mouseEvent.ctrlKey && !mouseEvent.shiftKey;
            },
        },
        {
            value: '-ctrlAndMidBut-',
            label: 'Middle mouse button + CTRL',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Middle && mouseEvent.ctrlKey && !mouseEvent.shiftKey;
            },
        },
        {
            value: '-shiftAndMidBut-',
            label: 'Middle mouse button + SHIFT',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Middle && mouseEvent.shiftKey && !mouseEvent.ctrlKey;
            },
        },
    ],

    pan: [
        {
            value: `-midBut-${SYSTEM_PREFERENCES_KEYS_OR_SPLITTER}-ShiftAndRbut-`,
            label: 'Middle mouse button or Right mouse button + SHIFT',
            isActive: mouseEvent => {
                return (
                    (mouseEvent.button === MOUSE_BUTTONS.Middle && !mouseEvent.shiftKey && !mouseEvent.ctrlKey) ||
                    (mouseEvent.button === MOUSE_BUTTONS.Right && mouseEvent.shiftKey && !mouseEvent.ctrlKey)
                );
            },
        }, // should be default value for pan
        {
            value: '-shiftAndMidBut-',
            label: 'Middle mouse button + SHIFT',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Middle && mouseEvent.shiftKey && !mouseEvent.ctrlKey;
            },
        },
        {
            value: '-shiftAndRBut-',
            label: 'Right mouse button + SHIFT',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Right && mouseEvent.shiftKey && !mouseEvent.ctrlKey;
            },
        },
        {
            value: '-ctrlAndMidBut-',
            label: 'Middle mouse button + CTRL',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Middle && mouseEvent.ctrlKey && !mouseEvent.shiftKey;
            },
        },
    ],

    rotate: [
        {
            value: '-rBut-',
            label: 'Right mouse button',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Right && !mouseEvent.shiftKey && !mouseEvent.ctrlKey;
            },
        }, //should be default value for rotate
        {
            value: '-midBut-',
            label: 'Middle mouse button',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Middle && !mouseEvent.shiftKey && !mouseEvent.ctrlKey;
            },
        },
        {
            value: '-ctrlAndShiftAndRBut-',
            label: 'Right mouse button + CTRL + SHIFT',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Right && mouseEvent.shiftKey && mouseEvent.ctrlKey;
            },
        },
    ],
};

const QUALITY_SETTINGS = {
    performance: {
        id: 'performance',
        value: 'performance',
        label: 'Performance',
    },
    quality: {
        id: 'quality',
        value: 'quality',
        label: 'Quality',
    },
};

const APPLICATION_QUALITY_SETTINGS = [QUALITY_SETTINGS.performance, QUALITY_SETTINGS.quality];

// Related to ABCP-417 and ABCP-629. The current options should be available only for MAC
// The option is added to the rest of the list when initializing the state of the viewer.

const MAC_SYSTEM_PREFERENCE = {
    pan: [
        {
            value: '-ctrlAndLBut-',
            label: 'Left mouse button + CTRL',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.ctrlKey && !mouseEvent.shiftKey;
            },
        },
        {
            value: '-shiftAndLBut-',
            label: 'Left mouse button + SHIFT',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.shiftKey && !mouseEvent.ctrlKey;
            },
        },
    ],
    rotate: [
        {
            value: '-ctrlAndShiftAndLBut-',
            label: 'Left mouse button + CTRL + SHIFT',
            isActive: mouseEvent => {
                return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.shiftKey && mouseEvent.ctrlKey;
            },
        },
    ],
};

// Related to ABCP-417 and ABCP-629. Default pan, rotate action key.
// Should be used as the default option if the previously selected option is not available on the device.
const DEFAULT_SYSTEM_PREFERENCE = {
    mac: {
        pan: MAC_SYSTEM_PREFERENCE.pan[0],
        rotate: MAC_SYSTEM_PREFERENCE.rotate[0],
        zoom: SYSTEM_PREFERENCES_ACTION_KEYS.zoom[0],
    },
    pc: {
        pan: SYSTEM_PREFERENCES_ACTION_KEYS.pan[0],
        rotate: SYSTEM_PREFERENCES_ACTION_KEYS.rotate[0],
        zoom: SYSTEM_PREFERENCES_ACTION_KEYS.zoom[0],
    },
};

const LOCAL_STORAGE_KEYS = {
    systemPreferences: 'systemPreferences',
    localPrinters: 'localPrinters',
    clearBuildPlate: 'doNotAskClearBuildPlate',
    dontShowBreakAssemblyModal: 'dontShowBreakAssemblyModal',
    featureFlags: 'featureFlags',
    makerBotPreviewFileNames: 'makerBotPreviewFileNames',
    sliceJobs: 'sliceJobs',
};

const IMPORT_INPUT_ID = 'importFile';
const IMPORT_INPUT_COMPARISON_ID = 'importComparisonFile';
const UPLOAD_MAKERBOT_FILE_ID = 'uploadMakerbotFile';
const IMPORT_INPUT_PRINT_MODE_ID = 'importPrintMode';

const OUTLINE_SETTINGS = Object.freeze({
    default: {
        glow: 0,
        strength: 1.5,
        thickness: 1,
    },
    intersectsAndOutside: {
        strength: 2,
    },
    activeOutline: {
        glow: 0,
        strength: 4,
        thickness: 1,
    },
    hoverOutline: {
        glow: 0,
        strength: 3,
        thickness: 2,
    },
});

// This object use for override default styles in @makerbot/ui-library
const COMMON_CUSTOM_DROPDOWN_STYLES = () => ({
    singleValue: provided => ({
        ...provided,
        paddingRight: 'inherit',
    }),
    menuList: provided => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transition: 'transform .3s',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        maxHeight: '27px',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        maxHeight: '100%',
        minHeight: '100%',
    }),
    option: isLightTheme()
        ? (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              'minHeight': '28px',
              'display': 'flex',
              'alignItems': 'center',
              'margin': '0px',
              'fontSize': '0.75rem',
              'fontWeight': '400',
              'padding': '2px 10px',
              'backgroundColor': isDisabled ? '#FFFFFF' : isSelected ? '#E7E7FD' : '#FFFFFF',
              'color': isDisabled ? '#8D8D8D' : '#161616',
              'cursor': isDisabled ? 'not-allowed' : 'pointer',
              ':active': {
                  ...provided[':active'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#E7E7FD' : '#F1F1F1',
              },
              ':hover': {
                  ...provided[':hover'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#E7E7FD' : '#F1F1F1',
                  color: isDisabled ? '#8D8D8D' : isSelected ? '#100AED' : '#161616',
              },
              '&::after': isSelected
                  ? {
                        content: `''`,
                        display: 'block',
                        position: 'relative',
                        width: '6px',
                        height: '11px',
                        margin: '8px',
                        marginLeft: 'auto',
                        background: '#E7E7FD',
                        borderRight: isFocused ? '1px solid #100AED' : '1px solid #161616',
                        borderBottom: isFocused ? '1px solid #100AED' : '1px solid #161616',
                        transform: 'rotate(45deg)',
                    }
                  : { display: 'none' },
          })
        : (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              'minHeight': '28px',
              'display': 'flex',
              'alignItems': 'center',
              'margin': '0px',
              'fontSize': '0.75rem',
              'fontWeight': '400',
              'padding': '2px 10px',
              'backgroundColor': isDisabled ? '' : isSelected ? '#569bc1cc' : isFocused ? 'rgba(86,155,193,0.4)' : null,
              'color': isSelected ? '#ffffff' : isDisabled ? '#999999cc' : '#555555cc',
              'cursor': isDisabled ? 'not-allowed' : isSelected ? 'default' : isFocused ? 'pointer' : 'default',
              ':active': {
                  ...provided[':active'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#569bc1cc' : 'rgba(86,155,193,0.4)',
                  color: !isDisabled && '#ffffff',
              },
              ':hover': {
                  ...provided[':hover'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#569bc1cc' : 'rgba(86,155,193,0.4)',
                  color: isSelected ? '#ffffff' : isFocused && '#555555cc',
              },
          }),
});

const CUSTOM_DROPDOWN_STYLES = () => ({
    ...COMMON_CUSTOM_DROPDOWN_STYLES(isLightTheme()),
    menu: provided => ({
        ...provided,
        'borderRadius': isLightTheme() ? '4px !important' : '2px !important',
        'borderWidth': isLightTheme() ? '0 !important' : '0.01rem !important',
        'marginTop': '10px !important',
        '&::before': isLightTheme()
            ? { display: 'none' }
            : {
                  content: `''`,
                  display: 'block',
                  position: 'absolute',
                  top: '-5px',
                  right: '10px',
                  width: '10px',
                  height: '10px',
                  background: '#ffffff',
                  borderRight: '0.01rem solid #000000',
                  borderBottom: '0.01rem solid #000000',
                  transform: 'rotate(-140deg) skewX(-10deg)',
              },
    }),
});

const CUSTOM_DROPDOWN_STYLES_ON_TOP = () => ({
    ...COMMON_CUSTOM_DROPDOWN_STYLES(isLightTheme()),
    menu: provided => ({
        ...provided,
        'borderRadius': isLightTheme() ? '4px !important' : '2px !important',
        'borderWidth': isLightTheme() ? '0 !important' : '0.01rem !important',
        'marginBottom': '10px !important',
        'bottom': '100% !important',
        'top': 'none !important',
        '&::before': isLightTheme()
            ? { display: 'none' }
            : {
                  content: `''`,
                  display: 'block',
                  position: 'absolute',
                  top: '-5px',
                  right: '10px',
                  width: '10px',
                  height: '10px',
                  background: '#ffffff',
                  borderRight: '0.01rem solid #000000',
                  borderBottom: '0.01rem solid #000000',
                  transform: 'rotate(-140deg) skewX(-10deg)',
              },
    }),
});

const CUSTOM_DROPDOWN_STYLES_ON_TOP_WITH_TOOLTIP = () => ({
    ...COMMON_CUSTOM_DROPDOWN_STYLES(isLightTheme()),
    menu: provided => ({
        ...provided,
        'borderRadius': isLightTheme() ? '4px !important' : '2px !important',
        'borderWidth': isLightTheme() ? '0 !important' : '0.01rem !important',
        'marginBottom': '10px !important',
        'bottom': '100% !important',
        'top': 'none !important',
        '&::before': isLightTheme()
            ? { display: 'none' }
            : {
                  content: `''`,
                  display: 'block',
                  position: 'absolute',
                  top: '-5px',
                  right: '10px',
                  width: '10px',
                  height: '10px',
                  background: '#ffffff',
                  borderRight: '0.01rem solid #000000',
                  borderBottom: '0.01rem solid #000000',
                  transform: 'rotate(-140deg) skewX(-10deg)',
              },
    }),
    valueContainer: provided => ({
        ...provided,
        paddingLeft: '0px',
        marginLeft: '-2px',
    }),
    option: isLightTheme()
        ? (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              'minHeight': '28px',
              'display': 'flex',
              'alignItems': 'center',
              'margin': '0px',
              'fontSize': '0.75rem',
              'fontWeight': '400',
              'padding': '2px 10px',
              'backgroundColor': isDisabled ? '#FFFFFF' : isSelected ? '#E7E7FD' : '#FFFFFF',
              'color': isDisabled ? '#8D8D8D' : '#161616',
              'cursor': isDisabled ? 'not-allowed' : 'pointer',
              ':active': {
                  ...provided[':active'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#E7E7FD' : '#F1F1F1',
              },
              ':hover': {
                  ...provided[':hover'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#E7E7FD' : '#F1F1F1',
                  color: isDisabled ? '#8D8D8D' : isSelected ? '#100AED' : '#161616',
              },
              '&::after': isSelected
                  ? {
                        content: `''`,
                        display: 'block',
                        position: 'relative',
                        width: '6px',
                        height: '11px',
                        margin: '8px',
                        marginLeft: 'auto',
                        background: '#E7E7FD',
                        borderRight: isFocused ? '1px solid #100AED' : '1px solid #161616',
                        borderBottom: isFocused ? '1px solid #100AED' : '1px solid #161616',
                        transform: 'rotate(45deg)',
                    }
                  : { display: 'none' },
          })
        : (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              'margin': '0px',
              'fontSize': '0.75rem',
              'fontWeight': '400',
              'padding': '0px',
              'backgroundColor': isDisabled ? '' : isSelected ? '#569bc1cc' : isFocused ? 'rgba(86,155,193,0.4)' : null,
              'color': isSelected ? '#ffffff' : isDisabled ? '#999999cc' : '#555555cc',
              'cursor': isDisabled ? 'not-allowed' : isSelected ? 'default' : isFocused ? 'pointer' : 'default',
              ':active': {
                  ...provided[':active'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#569bc1cc' : 'rgba(86,155,193,0.4)',
                  color: !isDisabled && '#ffffff',
              },
              ':hover': {
                  ...provided[':hover'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#569bc1cc' : 'rgba(86,155,193,0.4)',
                  color: isSelected ? '#ffffff' : isFocused && '#555555cc',
              },
          }),
});

const CUSTOM_DROPDOWN_STYLES_MEDIUM = () => ({
    ...COMMON_CUSTOM_DROPDOWN_STYLES(isLightTheme()),
    menu: provided => ({
        ...provided,
        'borderRadius': isLightTheme() ? '4px !important' : '2px !important',
        'borderWidth': isLightTheme() ? '0 !important' : '0.01rem !important',
        'marginTop': '10px !important',
        'width': '186px !important',
        'left': 0,
        '&::before': isLightTheme()
            ? { display: 'none' }
            : {
                  content: `''`,
                  display: 'block',
                  position: 'absolute',
                  top: '-5px',
                  left: '88px',
                  width: '10px',
                  height: '10px',
                  background: '#ffffff',
                  borderRight: '0.01rem solid #000000',
                  borderBottom: '0.01rem solid #000000',
                  transform: 'rotate(-140deg) skewX(-10deg)',
              },
    }),
});

const CUSTOM_DROPDOWN_STYLES_SMALL = () => ({
    ...COMMON_CUSTOM_DROPDOWN_STYLES(isLightTheme()),
    menu: provided => ({
        ...provided,
        'borderRadius': isLightTheme() ? '4px !important' : '2px !important',
        'borderWidth': isLightTheme() ? '0 !important' : '0.01rem !important',
        'marginTop': '10px !important',
        'width': '186px',
        'right': 0,
        '&::before': isLightTheme()
            ? { display: 'none' }
            : {
                  content: `''`,
                  display: 'block',
                  position: 'absolute',
                  top: '-5px',
                  right: '10px',
                  width: '10px',
                  height: '10px',
                  background: '#ffffff',
                  borderRight: '0.01rem solid #000000',
                  borderBottom: '0.01rem solid #000000',
                  transform: 'rotate(-140deg) skewX(-10deg)',
              },
    }),
});

const CUSTOM_DROPDOWN_STYLES_WITH_TOOLTIP = () => ({
    ...COMMON_CUSTOM_DROPDOWN_STYLES(isLightTheme()),
    menu: provided => ({
        ...provided,
        'borderRadius': isLightTheme() ? '4px !important' : '2px !important',
        'borderWidth': isLightTheme() ? '0 !important' : '0.01rem !important',
        'marginTop': '10px !important',
        '&::before': isLightTheme()
            ? { display: 'none' }
            : {
                  content: `''`,
                  display: 'block',
                  position: 'absolute',
                  top: '-5px',
                  right: '10px',
                  width: '10px',
                  height: '10px',
                  background: '#ffffff',
                  borderRight: '0.01rem solid #000000',
                  borderBottom: '0.01rem solid #000000',
                  transform: 'rotate(-140deg) skewX(-10deg)',
              },
    }),
    valueContainer: provided => ({
        ...provided,
        paddingLeft: '0px',
        marginLeft: '-2px',
    }),
    option: isLightTheme()
        ? (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              'minHeight': '28px',
              'display': 'flex',
              'alignItems': 'center',
              'margin': '0px',
              'fontSize': '0.75rem',
              'fontWeight': '400',
              'padding': '2px 10px',
              'backgroundColor': isDisabled ? '#FFFFFF' : isSelected ? '#E7E7FD' : '#FFFFFF',
              'color': isDisabled ? '#8D8D8D' : '#161616',
              'cursor': isDisabled ? 'not-allowed' : 'pointer',
              ':active': {
                  ...provided[':active'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#E7E7FD' : '#F1F1F1',
              },
              ':hover': {
                  ...provided[':hover'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#E7E7FD' : '#F1F1F1',
                  color: isDisabled ? '#8D8D8D' : isSelected ? '#100AED' : '#161616',
              },
              '&::after': isSelected
                  ? {
                        content: `''`,
                        display: 'block',
                        position: 'relative',
                        width: '6px',
                        height: '11px',
                        margin: '8px',
                        marginLeft: 'auto',
                        background: '#E7E7FD',
                        borderRight: isFocused ? '1px solid #100AED' : '1px solid #161616',
                        borderBottom: isFocused ? '1px solid #100AED' : '1px solid #161616',
                        transform: 'rotate(45deg)',
                    }
                  : { display: 'none' },
          })
        : (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              'margin': '0px',
              'fontSize': '0.75rem',
              'fontWeight': '400',
              'padding': '0px',
              'backgroundColor': isDisabled ? '' : isSelected ? '#569bc1cc' : isFocused ? 'rgba(86,155,193,0.4)' : null,
              'color': isSelected ? '#ffffff' : isDisabled ? '#999999cc' : '#555555cc',
              'cursor': isDisabled ? 'not-allowed' : isSelected ? 'default' : isFocused ? 'pointer' : 'default',
              ':active': {
                  ...provided[':active'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#569bc1cc' : 'rgba(86,155,193,0.4)',
                  color: !isDisabled && '#ffffff',
              },
              ':hover': {
                  ...provided[':hover'],
                  backgroundColor: isDisabled ? '' : isSelected ? '#569bc1cc' : 'rgba(86,155,193,0.4)',
                  color: isSelected ? '#ffffff' : isFocused && '#555555cc',
              },
          }),
});

const EXTRUDER_MOVE_TYPE = {
    MATERIAL: 1,
    SUPPORT: 2,
    TRAVEL: 3,
    RAFT: 4,
    BRIM: 5,
};

const TRAVEL_MOVE_TYPE = {
    RETRACT: 1,
    RESTART: 2,
    TRAVEL: 3,
};

const PRINT_STATUSES = {
    awaitingcloudslice: 'Preparing File',
    initializing: 'Initializing',
    cleaning_up: 'Cleaning Up',
    initial_heating: 'Initial Heating',
    loading_filament: 'Loading Filament',
    printing: 'Printing',
    suspended: 'Paused',
    suspending: 'Paused',
    completed: 'Completed',
    failed: 'Error',
    x_calibration_intro: 'Setting Up',
    y_calibration_intro: 'Setting Up',
    z_calibration_intro: 'Setting Up',
    setup_complete: 'Setting Up',

    // Sketch
    pausing: 'Pausing',
    pause: 'Pause',
    canceling: 'Cancelling',
};

const EXPORT_STATUSES = {
    preparing: 'Preparing Files',
    basic: 'Generating Basic Slice',
    slicing: 'Slicing',
    completed: 'Completed',
    failed: 'Failed',
};

const MAKERBOT_FILE_DEFAULT_OBJECT = Object.freeze({
    showPrintPreview: false,
    isIncompatibleFileOpended: false,
    printerType: '',
    materialType: '',
    extruderType: '',
    originalFileMaterials: '',
    originalFileExtruders: '',
    fileName: '',
    file: null,
});

const INCOMPATIBLE_PRINTMODE_DEFAULT_OBJECT = Object.freeze({
    showDialogForIncompFile: false,
    fileIncompatibleError: false,
    fileName: '',
});

const INCOMPATIBLE_OVERRIDES_DEFAULT_OBJECT = Object.freeze({
    showModalForIncompOverrides: false,
    overridesIncompatibleError: false,
    incompatibleOverrides: [],
    overridedCategories: [],
    printMode: {},
});

const expertSettingsCheckboxId = 'expertSettingsCheckboxId';
const materialCheckboxId = 'materialCheckboxId';
const supportCheckboxId = 'supportCheckboxId';
const raftCheckboxId = 'raftCheckboxId';
const brimCheckboxId = 'brimCheckboxId';
const travelCheckboxId = 'travelCheckboxId';
const retractCheckboxId = 'retractCheckboxId';
const restartCheckboxId = 'restartCheckboxId';
const supportUnderBridgeCheckboxId = 'doSupportUnderBridges';
const fixedShellStartCheckboxId = 'doFixedShellStart';
const smartZipperCheckboxId = 'doSmartZipper';
const purgeEarlyEndCheckboxId = 'doPurgeEarlyEnd';
const purgeWallCheckboxId = 'doPurgeWall';
const PTVisibilityCheckboxId = 'PTVisibilityCheckboxId';
const displayLayerDetailsCheckboxId = 'displayLayerDetailsCheckboxId';
const displayToolpathCommandsCheckboxId = 'displayToolpathCommandsCheckboxId';

const SETTINGS_TYPE = Object.freeze({
    dropdown: 'dropdown',
    checkbox: 'checkbox',
    input: 'input',
    text: 'text',
    openPath: 'openPath',
});

const SCHEMA_SETTINGS_TYPES = Object.freeze({
    boolean: 'boolean',
    string: 'string',
    scalar: 'scalar',
    unsignedInt: 'unsigned_integer',
    integer: 'integer',
    percent: 'percent',
    openPath: 'openpath',
    temperature: 'temperature',
});

const INPUT_OPERATION = Object.freeze({
    decrease: 'decrease',
    increase: 'increase',
});

const KEY_CODES = Object.freeze({
    backspace: 8,
    arrowLeft: 37,
    arrowUp: 38,
    arrowRight: 39,
    arrowDown: 40,
    minus: 189,
    plus: 187,
    numMinus: 109,
    numPlus: 107,
    delete: 46,
    a: 65,
    b: 66,
    c: 67,
    d: 68,
    e: 69,
    f: 70,
    g: 71,
    h: 72,
    i: 73,
    j: 74,
    k: 75,
    l: 76,
    m: 77,
    n: 78,
    o: 79,
    p: 80,
    q: 81,
    r: 82,
    s: 83,
    t: 84,
    u: 85,
    v: 86,
    w: 87,
    x: 88,
    y: 89,
    z: 90,
    f12: 123,
});

const SHORTCUTS_FOR_INPUTS = Object.freeze({
    copySelected: 'Copy Selected',
    pasteSelected: 'Paste Selected',
    cutSelected: 'Cut Selected',
    multiSelected: 'Multi Select',
    selectAll: 'Select All',
    hide: 'Hide Selected',
    undo: 'Undo',
    redo: 'Redo',
    autoArrange: 'Smart Arrange',
    lockAxisMove: 'Lock Axis Move',
    rotateSelected: 'Continuously Rotate Selected',
    uploadModels: 'Upload file',
    exportModels: 'Export',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    addInsert: 'Add/Insert Model',
    printQueue: '',
    measure: 'Measure',
});

const SHORTCUTS = Object.freeze({
    pc: {
        copyPasteCutCategory: {
            copy: {
                name: SHORTCUTS_FOR_INPUTS.copySelected,
                keys: ['Ctrl', 'C'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.c
                    );
                },
            },
            paste: {
                name: SHORTCUTS_FOR_INPUTS.pasteSelected,
                keys: ['Ctrl', 'V'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.v
                    );
                },
            },
            cut: {
                name: SHORTCUTS_FOR_INPUTS.cutSelected,
                keys: ['Ctrl', 'X'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.x
                    );
                },
            },
        },
        selectCategory: {
            multiSelect: {
                name: SHORTCUTS_FOR_INPUTS.multiSelected,
                keys: ['Ctrl', 'Left Click'],
                isActive: mouseEvent => {
                    return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.ctrlKey && !mouseEvent.shiftKey;
                },
            },
            selectAll: {
                name: SHORTCUTS_FOR_INPUTS.selectAll,
                keys: ['Ctrl', 'A'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.a
                    );
                },
            },
        },
        hideCatagory: {
            hide: {
                name: SHORTCUTS_FOR_INPUTS.hide,
                keys: ['Backspace/Delete'],
                isActive: keyboardEvent => {
                    return (
                        !keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        (keyboardEvent.keyCode === KEY_CODES.backspace || keyboardEvent.keyCode === KEY_CODES.delete)
                    );
                },
            },
        },
        undoRedoCatagory: {
            undo: {
                name: SHORTCUTS_FOR_INPUTS.undo,
                keys: ['Ctrl', 'Z'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.z
                    );
                },
            },
            redo: {
                name: SHORTCUTS_FOR_INPUTS.redo,
                keys: ['Ctrl', 'Y'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.y
                    );
                },
            },
        },
        shiftCatagory: {
            lockAxisMove: {
                name: SHORTCUTS_FOR_INPUTS.lockAxisMove,
                keys: ['Shift', 'Left Click'],
                isActive: mouseEvent => {
                    return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.shiftKey;
                },
            },
            rotateSelected: {
                name: SHORTCUTS_FOR_INPUTS.rotateSelected,
                keys: ['Shift', 'Left Drag'],
                isActive: mouseEvent => {
                    return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.shiftKey;
                },
            },
        },
        importCategory: {
            addInsert: {
                name: SHORTCUTS_FOR_INPUTS.addInsert,
                keys: ['Ctrl', 'I'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.i
                    );
                },
            },
            uploadModels: {
                name: SHORTCUTS_FOR_INPUTS.uploadModels,
                keys: ['Ctrl', 'O'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.o
                    );
                },
            },
        },
        zoomCategory: {
            zoomIn: {
                name: SHORTCUTS_FOR_INPUTS.zoomIn,
                keys: ['Ctrl', 'Alt', '+'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        keyboardEvent.altKey &&
                        (keyboardEvent.keyCode === KEY_CODES.plus || keyboardEvent.keyCode === KEY_CODES.numPlus)
                    );
                },
            },
            zoomOut: {
                name: SHORTCUTS_FOR_INPUTS.zoomOut,
                keys: ['Ctrl', 'Alt', '-'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        keyboardEvent.altKey &&
                        (keyboardEvent.keyCode === KEY_CODES.minus || keyboardEvent.keyCode === KEY_CODES.numMinus)
                    );
                },
            },
        },
        printQueueCategory: {
            printQueue: {
                name: SHORTCUTS_FOR_INPUTS.printQueue,
                keys: ['Ctrl', 'P'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.p
                    );
                },
            },
            exportModels: {
                name: SHORTCUTS_FOR_INPUTS.exportModels,
                keys: ['Ctrl', 'E'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.e
                    );
                },
            },
        },
        toolsCategory: {
            measure: {
                name: SHORTCUTS_FOR_INPUTS.measure,
                keys: ['M'],
                isActive: keyboardEvent => {
                    return (
                        !keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.m
                    );
                },
            },
            autoArrange: {
                name: SHORTCUTS_FOR_INPUTS.autoArrange,
                keys: ['Ctrl', 'Alt', 'A'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        keyboardEvent.altKey &&
                        !keyboardEvent.shiftKey &&
                        keyboardEvent.keyCode === KEY_CODES.a
                    );
                },
            },
        },
        pocketPrintCategory: {
            hideHint: true,
            openDevTools: {
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        keyboardEvent.altKey &&
                        keyboardEvent.shiftKey &&
                        keyboardEvent.keyCode === KEY_CODES.f12
                    );
                },
            },
        },
    },
    mac: {
        copyPasteCutCategory: {
            copy: {
                name: SHORTCUTS_FOR_INPUTS.copySelected,
                keys: ['⌘', 'C'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.c
                    );
                },
            },
            paste: {
                name: SHORTCUTS_FOR_INPUTS.pasteSelected,
                keys: ['⌘', 'V'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.v
                    );
                },
            },
            cut: {
                name: SHORTCUTS_FOR_INPUTS.cutSelected,
                keys: ['⌘', 'X'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.x
                    );
                },
            },
        },
        selectCategory: {
            multiSelect: {
                name: SHORTCUTS_FOR_INPUTS.multiSelected,
                keys: ['⌘', 'Left Click'],
                isActive: mouseEvent => {
                    return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.metaKey && !mouseEvent.shiftKey;
                },
            },
            selectAll: {
                name: SHORTCUTS_FOR_INPUTS.selectAll,
                keys: ['⌘', 'A'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.a
                    );
                },
            },
        },
        hideCatagory: {
            hide: {
                name: SHORTCUTS_FOR_INPUTS.hide,
                keys: ['Backspace/Delete'],
                isActive: keyboardEvent => {
                    return (
                        !keyboardEvent.ctrlKey &&
                        (keyboardEvent.keyCode === KEY_CODES.backspace || keyboardEvent.keyCode === KEY_CODES.delete)
                    );
                },
            },
        },
        undoRedoCatagory: {
            undo: {
                name: SHORTCUTS_FOR_INPUTS.undo,
                keys: ['⌘', 'Z'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.z
                    );
                },
            },
            redo: {
                name: SHORTCUTS_FOR_INPUTS.redo,
                keys: ['⌘', 'Y'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.y
                    );
                },
            },
        },
        shiftCatagory: {
            lockAxisMove: {
                name: SHORTCUTS_FOR_INPUTS.lockAxisMove,
                keys: ['Shift', 'Left Click'],
                isActive: mouseEvent => {
                    return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.shiftKey;
                },
            },
            rotateSelected: {
                name: SHORTCUTS_FOR_INPUTS.rotateSelected,
                keys: ['Shift', 'Left Drag'],
                isActive: mouseEvent => {
                    return mouseEvent.button === MOUSE_BUTTONS.Left && mouseEvent.shiftKey;
                },
            },
        },
        importCategory: {
            addInsert: {
                name: SHORTCUTS_FOR_INPUTS.addInsert,
                keys: ['⌘', 'I'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.i
                    );
                },
            },
            uploadModels: {
                name: SHORTCUTS_FOR_INPUTS.uploadModels,
                keys: ['Ctrl', 'O'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.o
                    );
                },
            },
        },
        zoomCategory: {
            zoomIn: {
                name: SHORTCUTS_FOR_INPUTS.zoomIn,
                keys: ['Ctrl', '⌥', '+'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        keyboardEvent.altKey &&
                        (keyboardEvent.keyCode === KEY_CODES.plus || keyboardEvent.keyCode === KEY_CODES.numPlus)
                    );
                },
            },
            zoomOut: {
                name: SHORTCUTS_FOR_INPUTS.zoomOut,
                keys: ['Ctrl', '⌥', '-'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.ctrlKey &&
                        !keyboardEvent.shiftKey &&
                        keyboardEvent.altKey &&
                        (keyboardEvent.keyCode === KEY_CODES.minus || keyboardEvent.keyCode === KEY_CODES.numMinus)
                    );
                },
            },
        },
        printQueueCategory: {
            printQueue: {
                name: SHORTCUTS_FOR_INPUTS.printQueue,
                keys: ['⌘', 'P'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.p
                    );
                },
            },
            exportModels: {
                name: SHORTCUTS_FOR_INPUTS.exportModels,
                keys: ['⌘', 'E'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.e
                    );
                },
            },
        },
        toolsCategory: {
            measure: {
                name: SHORTCUTS_FOR_INPUTS.measure,
                keys: ['M'],
                isActive: keyboardEvent => {
                    return (
                        !keyboardEvent.metaKey &&
                        !keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.m
                    );
                },
            },
            autoArrange: {
                name: SHORTCUTS_FOR_INPUTS.autoArrange,
                keys: ['⌘', 'Shift', 'A'],
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        keyboardEvent.shiftKey &&
                        !keyboardEvent.altKey &&
                        keyboardEvent.keyCode === KEY_CODES.a
                    );
                },
            },
        },
        pocketPrintCategory: {
            hideHint: true,
            openDevTools: {
                isActive: keyboardEvent => {
                    return (
                        keyboardEvent.metaKey &&
                        keyboardEvent.altKey &&
                        keyboardEvent.shiftKey &&
                        keyboardEvent.keyCode === KEY_CODES.f12
                    );
                },
            },
        },
    },
});

const UNDOABLE_OPERATIONS = Object.freeze({
    add: 'add',
    delete: 'delete',
    rotate: 'rotate',
    scale: 'scale',
    move: 'move',
    scaleToMax: 'scaleToMax',
    autoArrange: 'autoArrange',
    shown: 'shown',
    assembleCheckbox: 'assembleCheckbox',
    manipulation: 'manipulation',
    folderStructure: 'folderStructure',
});

const OPERATION_TYPE = Object.freeze({
    undo: 'undo',
    redo: 'redo',
});

const MAX_UNDOSTACK_SIZE = 20;
const FILE_DOWNLOAD_IFRAME_ID = 'FILE_DOWNLOAD_IFRAME_ID';

const getInitialModel = () => ({
    name: '',
    mesh: null,
    geometry: null,
    settings: getInitialSettings(),
    prevSettings: getInitialSettings(),
    modelFits: true,
    file: {
        name: '',
        url: '',
    },
});

const EPICS_DEBOUNCE_TIME = Object.freeze({
    DEFAULT: 300,
    DOWNLOAD_SLICE_FILE: 300,
    FETCH_ABOUT_INFO: 300,
    FETCH_AVAILABLE_ARTIFACTS: 300,
    FETCH_BASIC_SLICE: 300,
    FETCH_PRINTER_SCHEMA: 300,
    FETCH_PRINTERS_CONFIGS: 300,
    FETCH_PROFILE: 300,
    FETCH_RECONSTRUCTED_MODEL: 300,
    FETCH_REFLECTOR_PRINTERS: 100,
    FETCH_USER_FROM_ONION: 100,
    GENERATE_PREVIEW: 300,
    GET_SPOOLS_INFO: 300,
    HANDLE_TARGET_DEVICE_CHANGE: 200,
    SEND_JOB_TO_SLICER: 300,
    SEND_PRINTER_COMMAND: 300,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC: 300,
    TRACK_UPDATE_PROGRESS_FROM_SLICER: 300,
    TRACK_UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR: 300,
    UPLOAD_FILE_TO_SLICER: 300,
    UPLOAD_FILE_TO_STORAGE: 300,
    UPLOAD_THUMBNAIL_TO_SLICER: 300,
    FETCH_UPLOAD_URLS: 300,
    RUN_ZIP_JOB: 300,
    UPLOAD_TO_BUCKET: 300,
    DOWNLOAD_FILE_FROM_SIGNED: 300,
    RUN_CONVERSION_JOB: 300,
    TRACK_CONVERSION_JOB: 300,
    STOP_CONVERSION_JOB: 300,
    FETCH_CUSTOM_PRINT_MODES: 300,
    IMPORT_CUSTOM_PRINT_MODE: 300,
    CREATE_CUSTOM_PRINT_MODE: 300,
    FETCH_CUSTOM_PRINT_SETTINGS: 300,
    DELETE_CUSTOM_PRINT_MODE: 300,
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE: 300,
    EXPORT_CUSTOM_PRINT_MODE: 300,
    DOWNLOAD_DEMO_FILE: 300,
});

const TIMING = Object.freeze({
    SNACKBAR_AUTOHIDE_DELAY: 10000,
    EXTERNAL_SNACKBAR_AUTOHIDE_DELAY: 5000,
    SLICING_TIMEOUT: 3600000,
});

const CLOUD_PRINT_URL = `${urls.get('teams', 'https')}/team/printers`;

const COLOR_PALETTE = [
    '#d00202',
    '#e1241e',
    '#f73f29',
    '#f76129',
    '#f76d29',
    '#f67f28',
    '#dd8c28',
    '#b5911c',
    '#c09d2a',
    '#a4ad4a',
    '#8ac02d',
    '#66cb39',
    '#33c87d',
    '#30b992',
    '#2aa6a4',
    '#2795b8',
    '#2380c3',
    '#2774d2',
    '#1f5eed',
    '#1b28fb',
];

const VISIBILITY_SETTINGS_ID = 'visiblity-settings';

const DISPLAY_PATH_PARAMETERS = {
    none: 'none',
    extrusionSpeed: 'extrudeSpeed',
    extrusionTemperature: 'temperature',
    coolingFanSpeed: 'coolingFanSpeed',
};

const MINIFIED_DISPLAY_PATH_PARAMETERS = {
    [DISPLAY_PATH_PARAMETERS.extrusionSpeed]: 'es',
    [DISPLAY_PATH_PARAMETERS.extrusionTemperature]: 't',
    [DISPLAY_PATH_PARAMETERS.coolingFanSpeed]: 'cfs',
};

const EXPAND_AXIS_X_SUPPORT_TYPES = [SUPPORT_TYPES.modelBreakaway.id];

const REMOVE_PURGE_TOWER_SUPPORT_TYPES = [SUPPORT_TYPES.modelBreakaway.id];

const CUSTOM_PRINT_MODE_VALIDATORS = Object.freeze({
    VALIDATOR_SOURCE_KEY: 'source',
    VALIDATOR_PRINT_MODE_KEY: 'printMode',
    VALIDATOR_PRINT_BOT_KEY: 'botType',
    VALIDATOR_EXTRUDERS_KEY: 'extruders',
    VALIDATOR_MATERIALS_KEY: 'materials',
    VALIDATOR_SOURCE_KEY_VALUE: 'CloudPrint',
});

const FILE_READER_TYPES = Object.freeze({
    textReader: 'readAsText',
    dataUrlReader: 'readAsDataURL',
    arrayBufferReader: 'readAsArrayBuffer',
    binaryStringReader: 'readAsBinaryString',
});

const UPLOAD_JOB_TYPE = Object.freeze({
    _3mfJob: '3mfJob',
    daeJob: 'daeJob',
    objJob: 'objJob',
    stlJob: 'stlJob',
    thingJob: 'thingJob',
    gltfJob: 'gltfJob',
    cadJob: 'cadJob',
});

const CONVERTER_ERROR_IDS = Object.freeze({
    A3D_LOAD_FILE_TOO_RECENT: 'A3D_LOAD_FILE_TOO_RECENT',
    A3D_LOAD_FILE_TOO_OLD: 'A3D_LOAD_FILE_TOO_OLD',
});

const EMPTY_JOB_NAME = 'Untitled';
const DEFAULT_MODEL_NAME = 'model';

const FLOW_TYPE = Object.freeze({
    EMPTY_FLOW: 'EMPTY_FLOW',
    APPROVAL_STL_MODEL: 'APPROVAL_STL_MODEL',
    PRINTER_SELECTED_BY_ID: 'PRINTER_SELECTED_BY_ID',
    EXTERNAL_MODEL: 'EXTERNAL_MODEL',
    CLOUDPRINT_MAKERBOT_PREVIEW: 'CLOUDPRINT_MAKERBOT_PREVIEW',
});

const LOCAL_STORAGE_PRINTERS_KEY = 'PRINTERS';

const TCP_PORT = 12309;
const LOCAL_AUTHORIZATION = {
    // this is visible when the user confirms new auth
    username: 'Local User',
    // used on local network, non consequential
    local_secret: 'local_secret',
};
const CONNECTION_ERROR_SUPPORT_LINK = 'https://support.makerbot.com/s/article/MakerBot-Print-Connection-Error';

const BASE64_HEADER = 'data:model/3d;base64';

const PRINTER_DROPDOWN_ID = 'printer-dropdown-id';

const BUILD_VOLUME_LINES = {
    BOTTOM_RIGHT: 'BOTTOM_RIGHT',
    BOTTOM_FRONT: 'BOTTOM_FRONT',
    MIDDLE_FRONT_RIGHT: 'MIDDLE_FRONT_RIGHT',
    MIDDLE_BACK_RIGHT: 'MIDDLE_BACK_RIGHT',
    BOTTOM_BACK: 'BOTTOM_BACK',
    MIDDLE_FRONT_LEFT: 'MIDDLE_FRONT_LEFT',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    TOP_FRONT: 'TOP_FRONT',
    TOP_BACK: 'TOP_BACK',
    TOP_LEFT: 'TOP_LEFT',
    MIDDLE_BACK_LEFT: 'MIDDLE_BACK_LEFT',
};

const BUILD_VOLUME_WALLS = {
    LEFT: 'left-wall',
    RIGHT: 'right-wall',
    FRONT: 'front-wall',
    BACK: 'back-wall',
    TOP: 'top-wall',
};

const MAX_JOB_NAME_LENGTH = 160;
const MAX_MODEL_NAME_LENGTH = 160;

const WARNING_CONVEX_HULL_EXTRUDE_SETTINGS = {
    depth: 0.001,
    bevelEnabled: false,
    steps: 1,
};

// If convex hull z is set to 0, convex hull sometimes overlays with the build plate
const DEFAULT_CONVEX_HULL_Z = 0.2;

// The more models and submodels are on the build plate the longer it takes to compute
// So, we want to run the algorithm with less population and generations for such case
// It will give us a worse result, but will place all models fast
const FAST_SMART_ARRANGE_CONFIG = {
    spacing: 0, // space between parts (please note: spacing makes the algorithm much slower)
    rotationSteps: 8, // # of angles for available rotation (ex. 4 means [0, 90, 180, 270] angles from 360 / 4 )
    population: 2, // # of population in genetic algorithm
    generations: 4, // # of additional generations in genetic algorithm
    mutationRate: 0.2, // mutation rate in genetic algorithm
    seed: 0.2, // seed of random value in genetic algorithm
};

// When the user uploads a large model which barely fits the build plate, it won't be placed with the fast config
// That happens because the algorithm cannot find the best rotation for the model
// To avoid the issue we run the quality config which works fast when there is not many models on the build plate
// The main idea here that it doesn't make sense for the user (at least I think/hope so)
// to upload many large models at the same time, because they cannot be fitted on the build plate
const QUALITY_SMART_ARRANGE_CONFIG = {
    spacing: 0, // space between parts (please note: spacing makes the algorithm much slower)
    rotationSteps: 8, // # of angles for available rotation (ex. 4 means [0, 90, 180, 270] angles from 360 / 4 )
    population: 14, // # of population in genetic algorithm
    generations: 10, // # of additional generations in genetic algorithm
    mutationRate: 0.2, // mutation rate in genetic algorithm
    seed: 0.2, // seed of random value in genetic algorithm
};

const SMART_ARRANGE_BIN_IDS = {
    first: 1,
    second: 2,
};

const ROENTGEN_SETTINGS = {
    x_res: 1,
    y_res: 1,
};

const POCKET_PRINT_LOCAL_SERVER_URL = `http://127.0.0.1:4242`;

const TOOOLPATH_FETCH_STATUSES = Object.freeze({
    loading: 'loading',
    success: 'success',
    initial: 'initial',
});

// print preview can crash the browser if there are too many travel moves
const MAX_PREVIEW_TRAVEL_MOVES = 700000;
// basic preview can crash the browser if there are too many chunks (layers)
const MAX_BASIC_MODEL_PREVIEW_CHUNKS = 4000;

export {
    TIMING,
    COLOR_PALETTE,
    PRINTER_DROPDOWN_ID,
    SYSTEM_UNITS,
    EXTRUDER_ID_SPLITTER,
    SMART_ORIENT_STATE,
    SMART_ARRANGE_STATE,
    BOX_NAME,
    PLANE_NAME,
    GRID_PLANE_TRANSPARENT_NAME,
    PLANE_INVISIBLE_NAME,
    GRID_NAME,
    PLANE_BORDER_NAME,
    AXES_HELPER_NAME,
    PURGE_TOWER_NAME,
    PROGRESS_BOX_NAME,
    MODEL_AXES_NAME,
    ORIENT_PLANE_NAME,
    MEASURE_TOOL_SPRITE_ONE,
    MEASURE_TOOL_SPRITE_TWO,
    MEASURE_TOOL_LINE,
    OBJECTS_TO_INTERSECT,
    OBJECTS_TO_BOX_SELECTION,
    ROTATE_BUTTON_STEP,
    POSITION_BUTTON_STEP,
    SCALE_BUTTON_STEP,
    INFILL_DENSITY_STEP,
    ARRANGE_BUTTON_STEP,
    REG_STL_FILE_EXTENSION,
    REG_3MF_FILE_EXTENSION,
    REG_DAE_FILE_EXTENSION,
    REG_SLDASM_FILE_EXTENSION,
    REG_SLDPRT_FILE_EXTENSION,
    REG_OBJ_FILE_EXTENSION,
    REG_JSON_FILE_EXTENSION,
    REG_MAKERBOT_FILE_EXTENSION,
    REG_PRINT_MODE_EXTENSION,
    REG_GLTF_FILE_EXTENSION,
    REG_GLB_FILE_EXTENSION,
    REG_BIN_FILE_EXTENSION,
    REG_ZIP_FILE_EXTENSION,
    REG_NUMBERS,
    COLOR_CANVAS_BACKGROUND,
    COLOR_RED_OUTLINE_PASS,
    COLOR_BLUE_HOVER_OUTLINE_PASS,
    COLOR_BLUE_ACTIVE_OUTLINE_PASS,
    COLOR_YELLOW_OUTLINE_PASS,
    COLOR_LIGHT_BLUE,
    COLOR_DARK_BLUE,
    COLOR_YELLOW,
    COLOR_GRAY_BOX_BORDER,
    UPLOAD_MODAL_FITS_HEADER,
    UPLOAD_MODAL_FITS_BODY,
    MODAL_EXTENSION_HEADER,
    MODAL_EXTENSION_NOT_VALID_BODY,
    MODAL_WRONG_FORMATS_HEADER,
    MODAL_WRONG_FORMATS_BODY,
    MODAL_UPLOAD_HEADER,
    MODAL_UPLOAD_BODY,
    MODAL_COMPARE_MODE_HEADER,
    MODAL_SIZE_HEADER,
    MODAL_SIZE_BODY,
    MODAL_IMPORT_CUSTOM_MODE_HEADER,
    MODAL_EXPORT_CUSTOM_MODE_HEADER,
    MODAL_SAVE_CUSTOM_MODE_HEADER,
    FILESIZE_TOO_LARGE_HEADER,
    FILESIZE_STL_TOO_LARGE_BODY,
    FILESIZE_STL_TOO_LARGE_SIMPLIFY_BODY,
    FILESIZE_MAKERBOT_TOO_LARGE_BODY,
    MISSING_PARTS_HEADER,
    MISSING_PARTS_BODY,
    MODAL_MAKERBOT_ERROR_HEADER,
    MODAL_MAKERBOT_ERROR_FOR_NON_EMPTY_PLATE_BODY,
    MODAL_MAKERBOT_ERROR_FOR_NON_EMPTY_PLATE_BODY_ELECTRON,
    MODAL_MAKERBOT_ERROR_FOR_EMPTY_PLATE_BODY,
    MODAL_MAKERBOT_ERROR_FOR_COMPARISON_BODY,
    MODAL_MAKERBOT_CORRUPTED_ERROR,
    MODAL_MAKERBOT_ERROR_CORRUPTED_FILE_BODY,
    MODAL_PRINT_MODE_OVERRIDES_ERROR_HEADER,
    MODAL_PRINT_MODE_OVERRIDES_ERROR_BODY,
    MODAL_SAVE_APPLICATION_GRAPHICS_SETTINGS_HEADER,
    MODAL_SAVE_APPLICATION_GRAPHICS_SETTINGS_BODY,
    MODAL_CLEAR_BUILD_PLATE_HEADER,
    MODAL_CLEAR_BUILD_PLATE_BODY,
    BANNER_PLACE_FACE_ROTATE_MODEL,
    REDIRECT_BLOCKED_MESSAGE,
    CAMERAS_TYPE,
    defaultOptics,
    newPerspectiveCamera,
    newOrthographicCamera,
    MODEL_MATERIAL,
    MODEL_HOVER_MATERIAL,
    PURGE_TOWER_MATERIAL,
    MODEL_TRANSPARENT_MATERIAL,
    MODEL_PREVIEW_MATERIAL,
    MODEL_INVISIBLE_MATERIAL,
    MODEL_OUTSIDE_OVERLAP_MATERIAL,
    ORIENT_PLANE_MATERIAL,
    ORIENT_PLANE_BORDER_MATERIAL,
    ORIENT_PLANE_ARROW_MATERIAL,
    MODEL_BOUNDING_BOX_MATERIAL,
    DEFAULT_ORIENT_PLANE_SIZE,
    LIGHTS,
    SETTINGS,
    expandAxisX,
    PRINT_PREVIEW_MODES,
    PRINT_PREVIEW_ITEMS,
    APP_SETINGS_MODES,
    APP_SETTINGS_ITEMS,
    MAX_UPLOAD_STL_FILE_SIZE,
    MAX_EXPORT_FILE_SIZE,
    MAX_UPLOAD_MAKERBOT_FILE_SIZE,
    BOT_TYPE_ENUM,
    PRINT_MODES_ENUM,
    JOB_TYPE,
    FILE_FORMATS,
    POCKET_PRINT_FILE_FORMATS,
    PRINT_MODE_FILE_FORMATS,
    APPLICATION_UNITS,
    SYSTEM_PREFERENCES_ACTION_KEYS,
    QUALITY_SETTINGS,
    APPLICATION_QUALITY_SETTINGS,
    MAC_SYSTEM_PREFERENCE,
    DEFAULT_SYSTEM_PREFERENCE,
    MEASUREMENTS_UNITS,
    LOCAL_STORAGE_KEYS,
    QUERY_FILE_PARAM,
    QUERY_FILE_NAME_PARAM,
    AXES,
    PRINT_JOB_ORIGIN,
    IMPORT_INPUT_ID,
    IMPORT_INPUT_COMPARISON_ID,
    UPLOAD_MAKERBOT_FILE_ID,
    IMPORT_INPUT_PRINT_MODE_ID,
    EXTRUDERS,
    MATERIAL_COLORS,
    DEFAULT_FILAMENT_COLOR,
    SUPPORT_TYPES,
    BASE_LAYER_TYPES,
    NUMBER_OF_SHELLS,
    MATERIAL_TYPES,
    PARTNER_MATERIALS,
    PARTNER_MATERIAL_TYPES,
    SYSTEM_PREFERENCES_KEYS_OR_SPLITTER,
    MOUSE_BUTTONS,
    MOUSE_DIRECTIONS,
    OUTLINE_SETTINGS,
    CUSTOM_DROPDOWN_STYLES,
    CUSTOM_DROPDOWN_STYLES_MEDIUM,
    CUSTOM_DROPDOWN_STYLES_SMALL,
    CUSTOM_DROPDOWN_STYLES_ON_TOP,
    CUSTOM_DROPDOWN_STYLES_ON_TOP_WITH_TOOLTIP,
    CUSTOM_DROPDOWN_STYLES_WITH_TOOLTIP,
    PRINT_STATUSES,
    EXPORT_STATUSES,
    FILE_UPLOAD_ACTION,
    MAKERBOT_FILE_DEFAULT_OBJECT,
    INCOMPATIBLE_PRINTMODE_DEFAULT_OBJECT,
    INCOMPATIBLE_OVERRIDES_DEFAULT_OBJECT,
    EXTRUDER_MOVE_TYPE,
    TRAVEL_MOVE_TYPE,
    expertSettingsCheckboxId,
    materialCheckboxId,
    supportCheckboxId,
    raftCheckboxId,
    brimCheckboxId,
    travelCheckboxId,
    retractCheckboxId,
    restartCheckboxId,
    supportUnderBridgeCheckboxId,
    fixedShellStartCheckboxId,
    smartZipperCheckboxId,
    purgeEarlyEndCheckboxId,
    purgeWallCheckboxId,
    PTVisibilityCheckboxId,
    displayLayerDetailsCheckboxId,
    displayToolpathCommandsCheckboxId,
    SETTINGS_TYPE,
    SCHEMA_SETTINGS_TYPES,
    INPUT_OPERATION,
    SCALE_TO_MAX,
    SEARCH_BY_SETTING_NAME,
    KEY_CODES,
    SHORTCUTS,
    UNDOABLE_OPERATIONS,
    OPERATION_TYPE,
    MAX_UNDOSTACK_SIZE,
    FILE_DOWNLOAD_IFRAME_ID,
    getInitialModel,
    EPICS_DEBOUNCE_TIME,
    CLOUD_PRINT_URL,
    VISIBILITY_SETTINGS_ID,
    DISPLAY_PATH_PARAMETERS,
    MINIFIED_DISPLAY_PATH_PARAMETERS,
    PRINTER_CHANGE_STATUS,
    EXPORT_BUTTON_TEXT,
    ARRANGE_BUILD_PLATE,
    SAVE_CUSTOM_PRINT_MODE_NOT_AVAILABLE,
    PLACE_FACE,
    URL_FETCHING_GOALS,
    MODAL_DOWNLOAD_IMPOSSIBLE_HEADER,
    MODAL_DOWNLOAD_IMPOSSIBLE_BODY,
    REG_THING_FILE_EXTENSION,
    EXPAND_AXIS_X_SUPPORT_TYPES,
    REMOVE_PURGE_TOWER_SUPPORT_TYPES,
    UNITS_MISMATCH_BODY,
    UNITS_MISMATCH_HEADER,
    CUSTOM_PRINT_MODE_VALIDATORS,
    FILE_READER_TYPES,
    UPLOAD_JOB_TYPE,
    MODAL_FILE_TOO_OLD_HEADER,
    MODAL_FILE_TOO_OLD_BODY,
    MODAL_FILE_TOO_RECENT_HEADER,
    MODAL_FILE_TOO_RECENT_BODY,
    CONVERTER_ERROR_IDS,
    SHORTCUTS_FOR_INPUTS,
    EMPTY_JOB_NAME,
    DEFAULT_MODEL_NAME,
    PRINT_MODE,
    FLOW_TYPE,
    MODAL_SAVE_APPLICATION_SETTINGS_HEADER,
    MODAL_SAVE_APPLICATION_SETTINGS_BODY,
    MAX_ANIMATION_LAYER_Z,
    LOCAL_STORAGE_PRINTERS_KEY,
    TCP_PORT,
    LOCAL_AUTHORIZATION,
    CONNECTION_ERROR_SUPPORT_LINK,
    BASE64_HEADER,
    SUPPORTED_FILE_FORMATS,
    ASSEMBLE_CHECKBOX,
    ASSEMBLE_CHECKBOX_WITH_INCORRECT_FOLDER_STRUCTURE,
    MATERIAL_IS_NOT_LOADED_TOOLTIP,
    REQUIRES_DIFFERENT_EXTRUDER_TOOTIP,
    REQUIRES_DIFFERENT_SUPPORT_MATERIAL_TOOLTIP,
    REQUIRES_DIFFERENT_MODEL_MATERIAL_TOOLTIP,
    MATERIAL_LOADED_TOOLTIP,
    WARNING_CONVEX_HULL_EXTRUDE_SETTINGS,
    DEFAULT_CONVEX_HULL_Z,
    BUILD_VOLUME_LINES,
    BUILD_VOLUME_WALLS,
    MAX_JOB_NAME_LENGTH,
    MAX_MODEL_NAME_LENGTH,
    UNABLE_TO_CONVERT_FILETYPE_HEADER,
    UNABLE_TO_CONVERT_FILETYPE_BODY,
    POCKET_PRINT_LOCAL_SERVER_URL,
    PRINT_PREVIEW_SCENE_MESHES_NAMES,
    SETTINGS_INPUT_ERROR,
    FAST_SMART_ARRANGE_CONFIG,
    QUALITY_SMART_ARRANGE_CONFIG,
    SMART_ARRANGE_BIN_IDS,
    PURGE_TOWER_TOOLTIP,
    ROENTGEN_SETTINGS,
    TOOOLPATH_FETCH_STATUSES,
    PRINT_PREVIEW_FAILED_MODEL_TOO_LARGE,
    MAX_PREVIEW_TRAVEL_MOVES,
    MAX_BASIC_MODEL_PREVIEW_CHUNKS,
    MODAL_MAKERBOT_DETECTED_HEADER,
    MODAL_MAKERBOT_DETECTED_IN_FOLDER_BODY,
    MODAL_MAKERBOT_DETECTED_IN_FOLDER_ACTION_BODY,
};
