import { getInitialSettings } from '../../../helpers/utils/model-settings';
import { filterOutPurgeTower, getAllSubmodelsRecursive } from '../../../helpers/utils/viewer';
import {
    MODELS_SET_MODEL_LOADING_PROGRESS,
    MODELS_SET_MODEL_LOADING,
    MODELS_SET_WRONG_FORMAT,
    MODELS_SET_UNABLE_TO_CONVERT_FILETYPE,
    MODELS_SET_EXCEEDED_FILE_SIZE,
    MODELS_CANCEL_IMPORT,
    MODELS_SET_SHOW_UPLOAD_MODEL_ERROR,
    MODELS_SET_SHOULD_RESET_MODEL_SETTINGS,
    MODELS_SET_SETTINGS_ON_ONE_ACTIVE_MODEL,
    MODELS_SET_ACTIVE_MODELS,
    MODELS_SET_COPIED_MODELS,
    MODELS_SET_MODELS,
    MODELS_DELETE_MODELS,
    MODELS_ADD_PURGE_TOWER,
    MODELS_REMOVE_PURGE_TOWER,
    MODELS_SET_MAKERBOT_DOWNLOAD_IMPOSSIBLE,
    CONVERT_FILE_START,
    CONVERT_FILE_SUCCESS,
    SET_CONVERSION_STATUS,
    SET_CONVERT_FILE_FAIL,
    CONVERT_FILE_FAIL,
    SET_CONVERTED_MODELS_BLOBS,
    TRACK_CONVERT_FILE_START,
    MODELS_ADD_MODELS,
    STOP_UPDATE_CONVERT_FILE_PROGRESS,
    MODELS_CLEAR_CONVERTER_META,
    MODELS_SET_WRONG_FILES,
    MODELS_DO_BROADCAST_UPLOAD_JOB,
    MODELS_DO_RESET_UPLOAD_JOB,
    MODELS_DO_ADD_LOADER_DESCRIPTORS,
    MODELS_DO_RESET_LOADER_DESCRIPTORS,
    MODELS_DO_SET_UPLOAD_JOB_COUNT,
    MODELS_DO_SET_JOB_NAME,
    MODELS_DO_SET_JOB_NAME_FROM_DEFAULT,
    MODELS_SET_MODEL_ORIGINAL_NAME,
    MODELS_DO_SET_CONVERT_JOB_COUNT,
    MODELS_DO_UPDATE_CONVERT_JOB_COUNT,
    MODELS_DO_ADD_COMPLETED_UPLOAD_JOB_COUNT,
    MODELS_SET_FILE_TOO_OLD_ERROR,
    MODELS_SET_FILE_TOO_RECENT_ERROR,
    MODELS_DO_UPDATE_DEFAULT_JOB_NAME,
    MODELS_PERMODEL_SET_INSTANCE,
    MODELS_DOWNLOAD_DEMO_FILE_START,
    MODELS_STOP_DOWNLOAD_DEMO_FILE,
    MODELS_DOWNLOAD_DEMO_FILE_FULFILLED,
    MODELS_DOWNLOAD_DEMO_FILE_FAILED,
    SET_HOVERED_MODEL_ID,
    SET_OVERLAP_MODELS_IDS,
    SET_IS_SMART_ARRANGE_HOVERED,
    MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES,
    MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES,
    MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FULFILLED,
    MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FAILED,
    SET_IS_DRAGGING,
    SET_IS_MODEL_HOVERED_FROM_TREE,
    MODELS_UPDATE_SETTINGS,
    MODELS_UPDATE_MODELS,
    SET_IS_DRAG_ROTATION,
    MODELS_SET_IMPORT_FAILED,
    MODELS_SET_UPLOADED_BIN_FILES,
    MODELS_SET_LARGE_FILES,
    MODELS_CLEAR_LARGE_FILES,
    MODELS_SET_IS_MODEL_SIMPLIFYING,
    MODELS_SET_IS_MODEL_SIMPLIFYING_FAILED,
} from './types';

const initialState = {
    models: [],
    activeModels: [],
    copiedModels: [],
    largeFiles: [],
    isUploadedModelFits: true,
    showUploadModelError: false,
    isFileTooOld: false,
    isFileTooRecent: false,
    modelLoading: false,
    modelLoadingPercent: 0,
    modelConverting: false,
    convertedModelName: '',
    convertedModelLinks: null,
    convertedModelBlobs: null,
    metaInfo: null,
    convertJobId: null,
    uploadedModelName: '',
    settings: getInitialSettings(),
    isWrongFormat: false,
    unableToConvertFileType: false,
    wrongFilesInfo: {
        fileNames: [],
        isShowError: false,
        uploadJobs: [],
    },
    isWrongExportFileSize: false,
    shouldResetModelRotation: false,
    shouldResetModelScale: false,
    isMakerbotDownloadImpossible: false,
    filesCount: 0,
    uploadJob: null,
    loaderDescriptors: [],
    uploadJobsCount: 0,
    completedUploadJobsCount: 0,
    convertJobsCount: 0,
    isJobNameSetByUser: false,
    jobName: '',
    defaultJobName: '',
    isModelImportFailed: false,
    isModelDownloading: false,
    isModelSimplifying: false,
    isModelSimplifyingFailed: false,
    isModelDownloadFailed: false,
    hoveredModelsIds: [],
    overlapModelsIds: [],
    isSmartArrangeHovered: false,
    isDragging: false,
    isModelHoveredFromTree: false,
    isDragRotation: false,
    uploadedBinFiles: [],
};

const modelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODELS_SET_MODEL_LOADING:
            return {
                ...state,
                ...action.payload,
                isModelDownloadFailed: false,
                isModelImportFailed: false,
            };
        case MODELS_SET_MODEL_LOADING_PROGRESS:
        case MODELS_SET_WRONG_FILES:
        case MODELS_SET_WRONG_FORMAT:
        case MODELS_SET_UNABLE_TO_CONVERT_FILETYPE:
        case MODELS_SET_EXCEEDED_FILE_SIZE:
        case MODELS_SET_SHOW_UPLOAD_MODEL_ERROR:
        case SET_IS_DRAG_ROTATION:
        case MODELS_SET_IMPORT_FAILED:
        case MODELS_SET_IS_MODEL_SIMPLIFYING:
        case MODELS_SET_IS_MODEL_SIMPLIFYING_FAILED:
            return {
                ...state,
                ...action.payload,
            };
        case MODELS_CANCEL_IMPORT:
            return {
                ...state,
                models: action.payload.newModels,
            };
        case MODELS_SET_SHOULD_RESET_MODEL_SETTINGS:
            return {
                ...state,
                ...action.payload.resetObject,
            };
        case MODELS_SET_SETTINGS_ON_ONE_ACTIVE_MODEL:
            const { xRotation, yRotation, zRotation, ...newSettings } = action.payload.settings; // eslint-disable-line
            return {
                ...state,
                settings: { ...state.settings, ...newSettings },
            };
        case MODELS_UPDATE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload,
                },
            };
        case MODELS_SET_ACTIVE_MODELS:
            return {
                ...state,
                activeModels: action.payload.activeModels,
            };

        case SET_HOVERED_MODEL_ID:
        case SET_OVERLAP_MODELS_IDS:
        case SET_IS_SMART_ARRANGE_HOVERED: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case MODELS_SET_COPIED_MODELS:
            return {
                ...state,
                copiedModels: filterOutPurgeTower(state.activeModels),
            };
        case MODELS_SET_MODELS:
            return {
                ...state,
                models: action.payload.newModels,
            };
        case MODELS_UPDATE_MODELS:
            return {
                ...state,
                models: [...state.models],
            };
        case MODELS_ADD_MODELS:
            const models = [...state.models, ...action.payload.newModels];
            return {
                ...state,
                models,
            };
        case MODELS_DELETE_MODELS:
            return {
                ...state,
                models: state.models.filter(
                    model => !action.payload.deletedModels.find(deletedModel => deletedModel.name === model.name)
                ),
                activeModels: [],
            };
        case MODELS_ADD_PURGE_TOWER:
            return {
                ...state,
                models: [...state.models, action.payload.purgeTower],
            };
        case MODELS_REMOVE_PURGE_TOWER:
            return {
                ...state,
                activeModels: state.activeModels.filter(m => m.name !== action.payload.ptName),
                models: state.models.filter(m => m.name !== action.payload.ptName),
            };
        case MODELS_SET_MAKERBOT_DOWNLOAD_IMPOSSIBLE:
            return {
                ...state,
                isMakerbotDownloadImpossible: action.payload,
            };
        case SET_CONVERSION_STATUS: {
            return {
                ...state,
                modelConverting: action.payload.modelConverting,
                convertedModelName: action.payload.fileName,
            };
        }
        case TRACK_CONVERT_FILE_START: {
            return {
                ...state,
                convertJobId: action.payload.convertId,
            };
        }
        case CONVERT_FILE_START: {
            return Object.assign({}, state, {
                convertedModelLinks: null,
                modelConverting: true,
            });
        }
        case CONVERT_FILE_SUCCESS: {
            return {
                ...state,
                convertedModelLinks: action.payload.links,
                metaInfo: action.payload.metaInfo,
                modelConverting: false,
                convertJobId: null,
            };
        }
        case STOP_UPDATE_CONVERT_FILE_PROGRESS: {
            return {
                ...state,
                modelConverting: false,
                convertJobId: null,
            };
        }
        case MODELS_CLEAR_CONVERTER_META: {
            return {
                ...state,
                convertedModelLinks: null,
                metaInfo: null,
            };
        }
        case SET_CONVERT_FILE_FAIL: {
            return {
                ...state,
                isConversionFailed: action.payload,
                convertJobId: null,
            };
        }
        case CONVERT_FILE_FAIL: {
            return {
                ...state,
                convertedModelLinks: null,
                isConversionFailed: true,
                modelConverting: false,
                convertJobId: null,
            };
        }
        case SET_CONVERTED_MODELS_BLOBS: {
            return {
                ...state,
                convertedModelBlobs: action.payload.data,
            };
        }
        case MODELS_SET_MODEL_ORIGINAL_NAME: {
            const models = state.models;
            const { name, originalName } = action.payload;
            let allModels = [];
            filterOutPurgeTower(models).forEach(model => {
                allModels = [...allModels, ...getAllSubmodelsRecursive(model)];
            });
            const model = allModels?.find(m => m.name === name);
            if (model) {
                model.originalName = originalName;
            }
            return {
                ...state,
                models,
            };
        }
        case MODELS_DO_BROADCAST_UPLOAD_JOB: {
            return Object.assign({}, state, {
                uploadJob: action.payload.uploadJob,
            });
        }
        case MODELS_DO_RESET_UPLOAD_JOB: {
            return Object.assign({}, state, {
                uploadJob: null,
            });
        }
        case MODELS_SET_UPLOADED_BIN_FILES: {
            return Object.assign({}, state, {
                uploadedBinFiles: action.payload,
            });
        }
        case MODELS_SET_LARGE_FILES: {
            return Object.assign({}, state, {
                largeFiles: action.payload,
            });
        }
        case MODELS_CLEAR_LARGE_FILES: {
            return Object.assign({}, state, {
                largeFiles: [],
            });
        }
        case MODELS_DO_ADD_LOADER_DESCRIPTORS: {
            return Object.assign({}, state, {
                loaderDescriptors: [...state.loaderDescriptors, ...action.payload.loaders],
            });
        }
        case MODELS_DO_RESET_LOADER_DESCRIPTORS: {
            return Object.assign({}, state, { loaderDescriptors: [] });
        }
        case MODELS_DO_SET_UPLOAD_JOB_COUNT: {
            return Object.assign({}, state, {
                uploadJobsCount: action.payload,
                completedUploadJobsCount: 0,
            });
        }
        case MODELS_DO_ADD_COMPLETED_UPLOAD_JOB_COUNT: {
            if (state.completedUploadJobsCount + action.payload >= state.uploadJobsCount) {
                return Object.assign({}, state, {
                    uploadJobsCount: 0,
                    completedUploadJobsCount: 0,
                    convertJobsCount: 0,
                    modelLoading: false,
                    uploadedBinFiles: [],
                });
            }
            return Object.assign({}, state, {
                completedUploadJobsCount: state.completedUploadJobsCount + action.payload,
            });
        }
        case MODELS_DO_SET_CONVERT_JOB_COUNT: {
            return Object.assign({}, state, { convertJobsCount: action.payload });
        }
        case MODELS_DO_UPDATE_CONVERT_JOB_COUNT: {
            if (state.convertJobsCount > 0 && state.convertJobsCount !== action.payload) {
                const diff = state.convertJobsCount - action.payload;
                return Object.assign({}, state, {
                    convertJobsCount: action.payload,
                    uploadJobsCount: state.uploadJobsCount - diff,
                });
            }
            return state;
        }
        case MODELS_DO_SET_JOB_NAME_FROM_DEFAULT: {
            return Object.assign({}, state, {
                jobName: state.defaultJobName,
                isJobNameSetByUser: false,
            });
        }
        case MODELS_DO_UPDATE_DEFAULT_JOB_NAME: {
            return Object.assign({}, state, {
                defaultJobName: action.payload,
            });
        }
        case MODELS_DO_SET_JOB_NAME: {
            const setByUser = action.payload.setByUser;
            return Object.assign({}, state, {
                jobName: action.payload.name,
                isJobNameSetByUser: !!setByUser,
                defaultJobName: !setByUser ? action.payload.name : state.defaultJobName,
            });
        }
        case MODELS_SET_FILE_TOO_OLD_ERROR:
        case MODELS_SET_FILE_TOO_RECENT_ERROR: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case MODELS_PERMODEL_SET_INSTANCE: {
            const models = state.models;
            const perModelSettingsFlag = action.payload.perModelSettingsFlag;
            let i = 0;
            filterOutPurgeTower(models).forEach(model => {
                const meshes = model.mesh();
                meshes.forEach(mesh => {
                    mesh.instancePosition = perModelSettingsFlag ? `instance${i++}` : `instance0`;
                });
            });
            return {
                ...state,
                models,
            };
        }
        case MODELS_DOWNLOAD_DEMO_FILE_START:
        case MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES: {
            return Object.assign({}, state, {
                isModelDownloading: true,
                isModelDownloadFailed: false,
            });
        }
        case MODELS_DOWNLOAD_DEMO_FILE_FULFILLED:
        case MODELS_STOP_DOWNLOAD_DEMO_FILE:
        case MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES: {
            return Object.assign({}, state, {
                isModelDownloading: false,
                isModelDownloadFailed: false,
            });
        }
        case MODELS_DOWNLOAD_DEMO_FILE_FAILED: {
            return Object.assign({}, state, {
                isModelDownloading: false,
                isModelDownloadFailed: true,
            });
        }
        case MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FAILED: {
            return Object.assign({}, state, {
                isModelDownloading: false,
                isModelDownloadFailed: true,
                metaInfo: null,
                convertedModelBlobs: null,
            });
        }
        case MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FULFILLED: {
            return Object.assign({}, state, {
                isModelDownloading: false,
                isModelDownloadFailed: false,
                convertedModelBlobs: action.payload.files,
                metaInfo: action.payload.metaInfo,
            });
        }
        case SET_IS_DRAGGING: {
            return {
                ...state,
                isDragging: action.payload.isDragging,
            };
        }
        case SET_IS_MODEL_HOVERED_FROM_TREE: {
            return {
                ...state,
                isModelHoveredFromTree: action.payload.isModelHoveredFromTree,
            };
        }
        default:
            return state;
    }
};

export default modelsReducer;
