import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime } from 'rxjs/operators';

import { DOWNLOAD_FILE_FROM_SIGNED_START } from '../state/redux/types';
import {
    doDownloadFileFromSignedUrlFulfilled,
    doDownloadFileFromSignedUrlFailed,
    doSetNotification,
} from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { loadWithIframe } from '../helpers/epicUtils';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseErrorToActions = error => {
    console.log('Error downloading file -', error);
    return of(doDownloadFileFromSignedUrlFailed(error));
};

const downloadFileFromSignedUrl = action$ =>
    action$.pipe(
        ofType(DOWNLOAD_FILE_FROM_SIGNED_START),
        debounceTime(EPICS_DEBOUNCE_TIME.DOWNLOAD_FILE_FROM_SIGNED),
        mergeMap(action => {
            console.log(`Downloading from ${action.payload.fileUrl}`);
            loadWithIframe(action.payload.fileUrl);
            return of(
                doSetNotification(NOTIFICATION_TYPE.EXPORTED),
                doDownloadFileFromSignedUrlFulfilled(action.payload)
            );
        }),
        catchError(error => parseErrorToActions(error))
    );

export default downloadFileFromSignedUrl;
