export const FETCH_USER_FROM_ONION_START = 'FETCH_USER_FROM_ONION_START';
export const FETCH_USER_FROM_ONION_SUCCESS = 'FETCH_USER_FROM_ONION_SUCCESS';
export const FETCH_USER_FROM_ONION_FAILED = 'FETCH_USER_FROM_ONION_FAILED';

// Get available artifacts
export const FETCH_AVAILABLE_ARTIFACTS_START = 'FETCH_AVAILABLE_ARTIFACTS_START';
export const FETCH_AVAILABLE_ARTIFACTS_SUCCESS = 'FETCH_AVAILABLE_ARTIFACTS_SUCCESS';
export const FETCH_AVAILABLE_ARTIFACTS_FAILED = 'FETCH_AVAILABLE_ARTIFACTS_FAILED';
export const SET_SELECTED_ARTIFACT = 'SET_SELECTED_ARTIFACT';

// Get about info from the slicer
export const FETCH_ABOUT_INFO_START = 'FETCH_ABOUT_INFO_START';
export const FETCH_ABOUT_INFO_SUCCESS = 'FETCH_ABOUT_INFO_SUCCESS';
export const FETCH_ABOUT_INFO_FAILED = 'FETCH_ABOUT_INFO_FAILED';

// Get token (for when embedded in DF)
export const HANDLE_DIGITAL_FACTORY_TOKEN = 'HANDLE_DIGITAL_FACTORY_TOKEN';
