import { ofType } from 'redux-observable';
import { fromEvent, of } from 'rxjs';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { FETCH_ABOUT_INFO_FAILED, FETCH_ABOUT_INFO_START, FETCH_ABOUT_INFO_SUCCESS } from '../state/redux/types';
import { doFetchAboutInfoFulfilled, doFetchAboutInfoFailed } from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';

const parseResponseToActions = response => {
    if (response.error) {
        return parseErrorToActions(response.error);
    }

    const miracleGrue = response.find(d => d.name === 'Miracle-Grue');
    const sliceconfig = response.find(d => d.name === 'sliceconfig');

    return of(doFetchAboutInfoFulfilled({ miracleGrue, sliceconfig }));
};

const parseErrorToActions = error => {
    console.log('Failed fetching about info - ', error);
    return of(doFetchAboutInfoFailed(error));
};

const fetchAboutInfo = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_ABOUT_INFO_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_ABOUT_INFO),
        mergeMap(() => {
            const wsClient = getSchemaSettingsWsClient();
            const config = {};
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('artifacts', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'artifacts').pipe(
                        mergeMap(response => parseResponseToActions(response)),
                        catchError(error => parseErrorToActions(error)),
                        takeUntil(action$.pipe(ofType(FETCH_ABOUT_INFO_SUCCESS, FETCH_ABOUT_INFO_FAILED)))
                    )
                )
            );
        })
    );

export default fetchAboutInfo;
