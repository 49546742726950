import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, takeUntil, switchMap, filter } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';

import {
    doSetNotification,
    doSetSmartOrient,
    doTrackProgressForSmartOrientFailed,
    doTrackProgressForSmartOrientFulfilled,
} from '../state/redux/actions';
import { SMART_ORIENT_STATE, EXPORT_STATUSES } from '../consts';
import { getCloudslicerWsClient } from '../helpers/webSocketUtils';
import {
    TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED,
    TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED,
    TRACK_PROGRESS_FOR_SMART_ORIENT_START,
} from '../state/redux/types';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResToActions = (response, action) => {
    const { failed, completed } = response;

    if (failed) {
        console.log(`Auto Orient Job ${response.id} ${EXPORT_STATUSES.failed}...`);
        return of(doSetNotification(NOTIFICATION_TYPE.SMART_ORIENT_FAILED), doTrackProgressForSmartOrientFailed());
    }

    if (completed) {
        console.log('Smart Orient completed.');
        const result = response.result;
        return of(doTrackProgressForSmartOrientFulfilled({ name: action.payload.currentJob.name, result }));
    }

    console.log(`Polling CloudSlicer for auto orient progress...`, `${response.progress}%`);
    return of(doSetSmartOrient({ state: SMART_ORIENT_STATE.active }));
};

const parseErrorToActions = (error, action) => {
    console.log(`Auto Orient failed...`);
    console.log(`Error:`, error);
    return of(doSetNotification(NOTIFICATION_TYPE.SMART_ORIENT_FAILED), doTrackProgressForSmartOrientFailed());
};

const trackUpdateProgressForSmartOrient = action$ =>
    action$.pipe(
        ofType(TRACK_PROGRESS_FOR_SMART_ORIENT_START),
        mergeMap(action => {
            const wsClient = getCloudslicerWsClient();

            const jobDataEvent = fromEvent(wsClient, 'jobData');
            const reconnectEvent = fromEvent(wsClient.io, 'reconnect');
            const allEvents = merge(jobDataEvent, reconnectEvent);

            return of(wsClient.emit('subscribe', { jobId: action.payload.id })).pipe(
                switchMap(() =>
                    allEvents.pipe(
                        filter(response => {
                            return response.id === action.payload.id || Number.isInteger(response);
                        }),
                        mergeMap(response => parseResToActions(response, action)),
                        catchError(error => parseErrorToActions(error, action)),
                        takeUntil(
                            action$.pipe(
                                ofType(
                                    TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED,
                                    TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED
                                )
                            )
                        )
                    )
                )
            );
        })
    );

export default trackUpdateProgressForSmartOrient;
