import { ofType } from 'redux-observable';
import { fromEvent, of } from 'rxjs';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    FETCH_PRINTERS_CONFIGS_FAILED,
    FETCH_PRINTERS_CONFIGS_START,
    FETCH_PRINTERS_CONFIGS_SUCCESS,
} from '../state/redux/types';
import { doFetchPrintersConfigsFulfilled, doFetchPrintersConfigsFailed } from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';

const parseResponseToActions = (response, action) => {
    if (response.error) {
        return parseErrorToActions(response.error);
    }

    if (response) {
        Object.assign(action.payload, { printersConfigs: response });
    }
    return of(doFetchPrintersConfigsFulfilled(action.payload));
};

const parseErrorToActions = error => {
    console.log('Failed fetching printers configs - ', error);
    return of(doFetchPrintersConfigsFailed(error));
};

const fetchPrintersConfigs = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_PRINTERS_CONFIGS_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_PRINTERS_CONFIGS),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            const config = {};
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('configs', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'configs').pipe(
                        mergeMap(response => parseResponseToActions(response, action)),
                        catchError(error => parseErrorToActions(error)),
                        takeUntil(action$.pipe(ofType(FETCH_PRINTERS_CONFIGS_SUCCESS, FETCH_PRINTERS_CONFIGS_FAILED)))
                    )
                )
            );
        })
    );

export default fetchPrintersConfigs;
