import { Vector3 } from 'three';

import { SETTINGS } from '../../consts';

export const getInitialSettings = () => ({
    [SETTINGS.xRotation]: 0,
    [SETTINGS.yRotation]: 0,
    [SETTINGS.zRotation]: 0,
    [SETTINGS.scaleX]: 100,
    [SETTINGS.scaleY]: 100,
    [SETTINGS.scaleZ]: 100,
    [SETTINGS.scaleUnitsX]: 100,
    [SETTINGS.scaleUnitsY]: 100,
    [SETTINGS.scaleUnitsZ]: 100,
    [SETTINGS.uniformScaling]: true,
    [SETTINGS.xPosition]: 0,
    [SETTINGS.yPosition]: 0,
    [SETTINGS.zPosition]: 0,
});

export const getPositionSettingsFromModel = model => {
    const mesh = model.instance();

    const { position } = mesh;
    const positionSettings = {
        [SETTINGS.xPosition]: position.x,
        [SETTINGS.yPosition]: position.y,
        [SETTINGS.zPosition]: position.z,
    };

    return positionSettings;
};

export const getRotationSettingsFromModel = model => {
    const mesh = model.instance();

    const { rotation } = mesh;
    const rotationSettings = {
        [SETTINGS.xRotation]: rotation.x,
        [SETTINGS.yRotation]: rotation.y,
        [SETTINGS.zRotation]: rotation.z,
    };

    return rotationSettings;
};

export const getScaleSettingsFromModel = model => {
    const mesh = model.instance();
    const parseScale = v => v * 100;
    const { scale } = mesh;
    const { scaleUnitsX, scaleUnitsY, scaleUnitsZ } = model.settings;

    const scaleSettings = {
        [SETTINGS.scaleX]: parseScale(scale.x),
        [SETTINGS.scaleY]: parseScale(scale.y),
        [SETTINGS.scaleZ]: parseScale(scale.z),
        [SETTINGS.scaleUnitsX]: scaleUnitsX,
        [SETTINGS.scaleUnitsY]: scaleUnitsY,
        [SETTINGS.scaleUnitsZ]: scaleUnitsZ,
        [SETTINGS.uniformScaling]: model.settings.uniformScaling,
    };

    return scaleSettings;
};

export const getSettingsFromModel = model => ({
    ...getInitialSettings(),
    ...getPositionSettingsFromModel(model),
    ...getRotationSettingsFromModel(model),
    ...getScaleSettingsFromModel(model),
});

export const getScaleFromSettings = settings => {
    const parseScale = v => v / 100;

    const { scaleX, scaleY, scaleZ } = settings;
    const scale = new Vector3(parseScale(scaleX), parseScale(scaleY), parseScale(scaleZ));

    return scale;
};

export const getPositionFromSettings = settings => {
    const { xPosition, yPosition, zPosition } = settings;
    const position = new Vector3(xPosition, yPosition, zPosition);

    return position;
};

export const getRotationFromSettings = settings => {
    const { xRotation, yRotation, zRotation } = settings;
    const rotation = new Vector3(xRotation, yRotation, zRotation);

    return rotation;
};

export const getAxisNameFromSettingName = settingName => {
    const SETTING_NAME_TO_AXIS = {
        [SETTINGS.xRotation]: 'x',
        [SETTINGS.yRotation]: 'y',
        [SETTINGS.zRotation]: 'z',
        [SETTINGS.scaleX]: 'x',
        [SETTINGS.scaleY]: 'y',
        [SETTINGS.scaleZ]: 'z',
        [SETTINGS.scaleUnitsX]: 'x',
        [SETTINGS.scaleUnitsY]: 'y',
        [SETTINGS.scaleUnitsZ]: 'z',
        [SETTINGS.xPosition]: 'x',
        [SETTINGS.yPosition]: 'y',
        [SETTINGS.zPosition]: 'z',
    };

    return SETTING_NAME_TO_AXIS[settingName];
};
