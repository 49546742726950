import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { crossFrameStorage } from './helpers/CrossFrameStorage';
import ThreeInstance from './helpers/ThreeInstance';
import ErrorBoundary from './components/common/ErrorBoundary';
import Router from './components/Router';

import { FEATURE_FLAGS, initFeatureFlags } from './helpers/utils/feature-flags';
import { isLightTheme } from './helpers/utils/environment';
import { handleBeforeUnloadFnSelector, modelsSelector, overridesSelector } from './state/redux/selectors';

import s from './App.module.scss';
import { filterOutPurgeTower } from './helpers/utils/viewer';

const App = () => {
    const handleBeforeUnloadFn = useSelector(handleBeforeUnloadFnSelector);
    const models = useSelector(modelsSelector);
    const overrides = useSelector(overridesSelector);
    const [loaded, setLoaded] = useState(false);
    const appClassNames = classNames(s.app, { 'theming--light': isLightTheme() });

    useEffect(() => {
        crossFrameStorage.install();
    }, []);

    useEffect(() => {
        ThreeInstance.waitUntilLoaded().then(() => setLoaded(true));
    }, []);

    useEffect(() => {
        initFeatureFlags(FEATURE_FLAGS);
    }, []);

    useEffect(() => {
        const overridesBesidesPrintMode = Object.keys(overrides).filter(key => key !== 'printMode');
        const modelsNoPurgeTower = filterOutPurgeTower(models);
        if (modelsNoPurgeTower.length || overridesBesidesPrintMode.length) {
            return window.addEventListener('beforeunload', handleBeforeUnloadFn);
        }
        window.removeEventListener('beforeunload', handleBeforeUnloadFn);
    }, [handleBeforeUnloadFn, models, overrides]);

    if (loaded) {
        return (
            <div className={appClassNames}>
                <ErrorBoundary>
                    <Router />
                </ErrorBoundary>
            </div>
        );
    }
    return <div className={appClassNames}></div>;
};

export default App;
