import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, debounceTime } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import urls from '@makerbot/urls';

import { SEND_PRINTER_COMMAND_START } from '../state/redux/types';
import { doSendPrinterCommandFulfilled, doSendPrinterCommandFail } from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';

const parseResponseToActions = (res, action) => {
    // If commands were valid, an array of commandIds will be returned.
    // Otherwise, an error message response will be returned.

    if (Array.isArray(res.response)) {
        if (action.payload.windowId) {
            action.payload.windowId.location = `https://${urls.myMakerBot}/printers`;
        }
        return of(doSendPrinterCommandFulfilled(res.response));
    } else {
        console.log(`Error:`, res);
        return of(doSendPrinterCommandFail(res));
    }
};

const parseErrorToActions = error => {
    console.log(`Error:`, error.message);
    return of(doSendPrinterCommandFail(error));
};

const sendPrinterCommand = action$ =>
    action$.pipe(
        ofType(SEND_PRINTER_COMMAND_START),
        debounceTime(EPICS_DEBOUNCE_TIME.SEND_PRINTER_COMMAND),
        mergeMap(action => {
            const { printer_id } = action.payload.device;
            const apiUrl = `https://${urls.reflector}/printers/${printer_id}/commands`;
            const { data } = action.payload;

            console.log(
                `Sending Print Command "${
                    action.payload.data.method ? action.payload.data.method : action.payload.data.action
                }" to ${action.payload.device.name}`
            );

            return ajax({
                url: apiUrl,
                headers: {
                    'authorization': `Bearer ${action.payload.userToken}`,
                    'content-type': 'application/json',
                },
                body: JSON.stringify(data),
                method: 'POST',
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(err => parseErrorToActions(err))
            );
        })
    );

export default sendPrinterCommand;
