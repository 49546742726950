import { SocketIO, Server } from 'mock-socket';
import { generateUUID } from 'three/src/math/MathUtils';

import { timeouts } from './utils';
const BUCKET_NAME = 'cloudslicer-staging';
const STORAGE_URL = `https://storage.googleapis.com/${BUCKET_NAME}`;

const SOCKET_URL = 'ws://localhost:8080';
const STUB = 'does not really matter';

export const FILE_ID = generateUUID();
export const FILE_NAME = `20mm_Calibration_Box`;

//The type of socket to mock.
//To mock schema-settings websocket or any other - it's type should be added here
//Make sure the mocked instanse is being used as a socket client during testing.
export const SOCKET_CLIENT_TYPES = {
    CLOUDSLICER: 'cloudslicer_io',
};

//The mocked socket server object
let mockedIOServer = null;
const getMocketIOServer = () => {
    if (!mockedIOServer) {
        mockedIOServer = new Server(SOCKET_URL);
    }
    return mockedIOServer;
};

//Since cloudslicer socket have the same handler for different steps of slice flow
//the count of calls affects the result
let sliceSubscribtionCalls = 0;
const subscribeResponses = [
    //First call - zipping files into .thing file
    () => {
        return {
            result: STUB,
            zipUrl: `gs://${BUCKET_NAME}/${FILE_ID}/${FILE_NAME}.stl`,
        };
    },
    //Secont call - zipping thumbnails
    () => {
        return {
            result: STUB,
            zipUrl: STUB,
        };
    },
    //Third call - creating actual .makerbot file
    data => {
        return {
            result: `${STORAGE_URL}/${data.jobId}/${FILE_NAME}.makerbot`,
            zipUrl: STUB,
        };
    },
];

const getSliceSubscribtionResponse = data => {
    const response = {
        id: data.jobId,
        completed: true,
        failed: false,
    };
    return {
        ...response,
        ...subscribeResponses[sliceSubscribtionCalls](data),
    };
};

//This prevents developers from calling mock methods with invalid values ince we don't have TS yet
const checkProvidedType = type => {
    if (!Object.values(SOCKET_CLIENT_TYPES).includes(type))
        throw new Error(
            `Given IO mock type ${type} does not correspond with any type declared in SOCKET_CLIENT_TYPES.`
        );
};

//This method modifies the mocked socket client attached to window by adding new event listener.
//The io event listener is important for slicing flow since it listens for reconnect event that not exist in SocketIO from 'mock-socket' lib.
export const getWindowMockedSocketIO = type => {
    checkProvidedType(type);
    const socket = window[type](SOCKET_URL);
    socket.io = new EventTarget();
    socket.io.addEventListener('reconnect', () => {});
    return socket;
};

//This method mocks the server responses
export const mockSocketServer = (win, type) => {
    const mockedServer = getMocketIOServer();
    checkProvidedType(type);
    if (type !== SOCKET_CLIENT_TYPES.CLOUDSLICER) {
        //For now this is not needed, but it's good to have for the future.
        throw new Error('Not Implemented');
    }
    mockedServer.on('connection', socket => {
        socket.on('subscribe', data => {
            const response = getSliceSubscribtionResponse(data);
            sliceSubscribtionCalls += 1;
            setTimeout(() => {
                socket.emit('jobData', response);
            }, timeouts.socketResponse);
        });
    });

    //This is the most important part. We attach only those type of socket client that should be mocked.
    win[type] = SocketIO;
};

export const closeMockedServerSocket = () => {
    if (mockedIOServer) mockedIOServer.close();
};
