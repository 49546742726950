import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { mergeMap, catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import {
    doDownloadDemoAssemblyFilesFailed,
    doDownloadDemoAssemblyFilesFulfilled,
    doSetModelLoading,
    doShowTopOverlayBar,
} from '../state/redux/actions';
import {
    MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES,
    MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES,
} from '../state/redux/types';
import { getDemoFileUrl } from '../helpers/utils';

const changeAssemblyRootName = (structure, name) => {
    structure.trees[0].children[0].instanceName = name;
};

const parseResToActions = (response, action) => {
    const structure = JSON.parse(response.shift().response);
    changeAssemblyRootName(structure, action.payload.assembly.originalName);
    const metaInfo = {
        assemblyCads: action.payload.assembly.submodels.map(submodel => ({ name: submodel.originalName })),
        cads: [],
        trees: structure.trees,
    };

    const tasks = response.map(r => from(r.blob()));
    return forkJoin(tasks).pipe(
        mergeMap(blobs => {
            //fork join returns values in the same order
            const files = blobs.map((blob, index) => ({
                name: action.payload.assembly.submodels[index].originalName,
                blob,
            }));

            return of(
                doDownloadDemoAssemblyFilesFulfilled({ files, metaInfo }),
                doShowTopOverlayBar(false),
                doSetModelLoading({ modelLoading: true })
            );
        }),
        catchError(error => parseErrorToActions(error))
    );
};

const parseErrorToActions = error => {
    console.log('Failed to download assembly demo files:', error);
    return of(doDownloadDemoAssemblyFilesFailed());
};

const downloadDemoAssemblyFiles = action$ =>
    action$.pipe(
        ofType(MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES),
        debounceTime(EPICS_DEBOUNCE_TIME.DOWNLOAD_DEMO_FILE),
        mergeMap(action => {
            const tasks = [];
            tasks.push(
                ajax({
                    url: getDemoFileUrl(
                        action.payload.printerType,
                        action.payload.assembly.structureFile,
                        action.payload.assembly.folder
                    ),
                    responseType: 'application/json',
                })
            );
            action.payload.assembly.submodels.forEach(submodel => {
                const url = getDemoFileUrl(
                    action.payload.printerType,
                    submodel.originalName,
                    action.payload.assembly.folder
                );
                tasks.push(fromFetch(url));
            });

            return forkJoin(tasks).pipe(
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(error, action)),
                takeUntil(action$.pipe(ofType(MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES)))
            );
        })
    );

export default downloadDemoAssemblyFiles;
