import 'indexeddb-getall-shim';
import { crossFrameStorage } from '../CrossFrameStorage';
import { LOCAL_STORAGE_KEYS } from '../../consts';

export const savePreviewFileNameToLocalStorage = (key, fileName, fileUrl, openInNewWindow) => {
    return new Promise((resolve, reject) => {
        if (!/Edge\/\d./i.test(navigator.userAgent)) {
            const arr = crossFrameStorage.getItem(LOCAL_STORAGE_KEYS.makerBotPreviewFileNames) || [];
            if (arr.length > 5) {
                arr.shift();
            }
            arr.push({ [key]: { fileName, fileUrl, openInNewWindow } });
            crossFrameStorage.setItem(LOCAL_STORAGE_KEYS.makerBotPreviewFileNames, arr);
            resolve();
            return;
        }

        const openRequest = indexedDB.open('idb_store');
        openRequest.onupgradeneeded = () => {
            const db = openRequest.result;
            if (!db.objectStoreNames.contains('makerBotPreviewFileNames')) {
                const previews = db.createObjectStore('makerBotPreviewFileNames', { keyPath: 'key' });
                previews.createIndex('timestamp_idx', 'timestamp');
            }
        };

        openRequest.onerror = () => {
            reject(openRequest.error);
        };

        openRequest.onsuccess = () => {
            const db = openRequest.result;
            const transaction = db.transaction(['makerBotPreviewFileNames'], 'readwrite');
            const previews = transaction.objectStore('makerBotPreviewFileNames');
            const preview = {
                key,
                fileName,
                fileUrl,
                openInNewWindow,
                timestamp: new Date(),
            };

            const addRequest = previews.add(preview);
            addRequest.onsuccess = () => {
                console.log('[IndexDB] operation result:', addRequest.result);
                resolve();
            };
            addRequest.onerror = () => {
                reject(addRequest.error);
            };

            const date = new Date();
            date.setDate(date.getDate() - 7); // 7 days before now
            const timestampIndex = previews.index('timestamp_idx');
            const clearRequest = timestampIndex.getAll(IDBKeyRange.upperBound(date));
            clearRequest.onsuccess = () => {
                if (clearRequest.result) {
                    clearRequest.result.forEach(p => {
                        p.key && previews.delete(p.key);
                    });
                }
            };
        };
    });
};

export const getPreviewFileNameFromLocalStorage = key => {
    return new Promise((resolve, reject) => {
        if (!/Edge\/\d./i.test(navigator.userAgent)) {
            const arr = crossFrameStorage.getItem(LOCAL_STORAGE_KEYS.makerBotPreviewFileNames) || [];
            const entry = arr.find(e => e[key]);
            resolve(entry ? entry[key] : {});
            return;
        }

        const openRequest = indexedDB.open('idb_store');
        openRequest.onerror = () => {
            reject(openRequest.error);
        };

        openRequest.onsuccess = () => {
            const db = openRequest.result;
            const transaction = db.transaction(['makerBotPreviewFileNames'], 'readonly');
            const previews = transaction.objectStore('makerBotPreviewFileNames');

            const previewRequest = previews.get(key);
            previewRequest.onsuccess = () => {
                resolve(previewRequest.result || {});
            };
        };
    });
};
