import { HANDLE_ERROR } from './types';

const initialState = {
    errorMessage: null,
};

const errorState = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_ERROR:
            return {
                errorMessage: action.payload.errorMessage,
            };
        default:
            return state;
    }
};

export default errorState;
