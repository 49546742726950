import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, debounceTime } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { FETCH_AVAILABLE_ARTIFACTS_START } from '../state/redux/types';
import { doFetchAvailableArtifactsFailed, doFetchAvailableArtifactsFulfilled } from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';

const parseResponseToActions = ({ response }, action) => {
    return of(doFetchAvailableArtifactsFulfilled(response));
};

const parseErrorToActions = error => {
    console.log('Failed fetching available artifacts - ', error);
    return of(doFetchAvailableArtifactsFailed(error));
};

const fetchAvailableArtifacts = action$ =>
    action$.pipe(
        ofType(FETCH_AVAILABLE_ARTIFACTS_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_AVAILABLE_ARTIFACTS),
        mergeMap(action => {
            const apiUrl = `https://${urls.cloudslicer}/api/v1/artifacts-global`;

            return ajax({
                url: apiUrl,
                method: 'GET',
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error))
            );
        })
    );

export default fetchAvailableArtifacts;
