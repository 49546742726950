import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { mergeMap, catchError, distinctUntilChanged } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { UPLOAD_FILE_TO_STORAGE_START } from '../state/redux/types';
import {
    doSendPrinterCommand,
    doGenerateModelPreview,
    doUploadFileToStorageFulfilled,
    doUploadFileToStorageFailed,
    doGenerateModelPreviewFailed,
    doSetModelLoading,
    doShowTopOverlayBar,
    doSetNotification,
    doSetPreviewModels,
    doUpdateJobFileName,
} from '../state/redux/actions';

import { EXPORT_STATUSES, FILE_UPLOAD_ACTION, JOB_TYPE, PRINT_JOB_ORIGIN } from '../consts';
import { compareEpicPayload } from '../helpers/utils';
import { putFileToStorage } from '../helpers/epicUtils';
import { getTimestamp } from '../helpers/utils/common';
import { savePreviewFileNameToLocalStorage } from '../helpers/utils/storage';
import { NOTIFICATION_TYPE } from '../consts/notifications';
import { sendSliceCompleteNotificationEvent } from '../helpers/utils/notification-event';
import { openNewWindowInDigitalFactory } from '../helpers/utils/environment';

const parseResponseToActions = (response, action, canHideTopBar, userToken, fileUploadAction) => {
    delete action.payload.file;
    console.log(`Finished uploading file to AWS S3`);
    const unformattedDate = new Date();
    const payload = action.payload;
    const currentJob = payload.currentJob;

    currentJob.id = payload.urls.presignedGetURL.split('/')[3];
    currentJob.status = EXPORT_STATUSES.completed;
    currentJob.timestamp = getTimestamp(unformattedDate);
    currentJob.progress = null;
    currentJob.unformattedDate = unformattedDate;
    currentJob.fileUrl = payload.urls.presignedGetURL;

    if (action.payload.openInNewWindow) {
        if (!action.payload.windowId) {
            return of(doGenerateModelPreviewFailed());
        }

        currentJob.openInNewWindow = action.payload.openInNewWindow;
        currentJob.botTypeForPreview = action.payload.botTypeForPreview;

        savePreviewFileNameToLocalStorage(
            currentJob.localId,
            currentJob.fileName,
            currentJob.fileUrl,
            action.payload.openInNewWindow
        ).then(() => {
            const botType = action.payload.botTypeForPreview || 'replicator_b';
            let url = `https://${urls.teams}/print?type=mb_preview&printer_id=${botType}&preview_id=${currentJob.localId}`;
            if (openNewWindowInDigitalFactory()) {
                url = `https://${urls.digitalFactory}/app/print?type=mb_preview&printer_id=${botType}&preview_id=${currentJob.localId}&site=df`;
            }
            action.payload.windowId.location = url;
            delete action.payload.windowId;
        });

        const args = [doUploadFileToStorageFulfilled(payload)];
        if (canHideTopBar) {
            args.push(doSetModelLoading({ modelLoading: false }));
        }
        return of(...args);
    }

    if (currentJob.jobType === JOB_TYPE.print) {
        const printer_id = payload.printer_id;
        const printer_type = payload.printer_type;
        sendSliceCompleteNotificationEvent({
            printer_id,
            printer_type,
            makerbot_url: currentJob.fileUrl,
            print_now: false,
            print_job_origin: PRINT_JOB_ORIGIN.makerbot,
        });

        const sendPrinterCommandPayload = {
            data: {
                method: 'external_print',
                params: {
                    url: currentJob.fileUrl,
                    ensure_build_plate_clear: false,
                },
            },
            device: { printer_id: payload.printer_id },
            userToken,
        };
        return of(doShowTopOverlayBar(false), doSendPrinterCommand(sendPrinterCommandPayload));
    }

    if (currentJob.jobType === JOB_TYPE.queue) {
        const printer_id = payload.printer_id;
        const printer_type = payload.printer_type;
        sendSliceCompleteNotificationEvent({
            printer_id,
            printer_type,
            makerbot_url: currentJob.fileUrl,
            print_job_origin: PRINT_JOB_ORIGIN.makerbot,
            job_id: currentJob.id,
            job_type: currentJob.jobType,
            print_now: false,
            upload_and_queue: fileUploadAction === FILE_UPLOAD_ACTION.uploadAndSend,
        });

        const args = [
            doShowTopOverlayBar(false),
            doUploadFileToStorageFulfilled(payload),
            doSetNotification(NOTIFICATION_TYPE.JOB_QUEUED),
        ];

        if (action.payload.previewData) {
            const { jobFileName, previewModels } = action.payload.previewData;
            args.push(doSetPreviewModels({ previewModels }));
            args.push(doUpdateJobFileName({ fileName: jobFileName }));
        }
        return of(...args);
    }

    return of(doUploadFileToStorageFulfilled(payload), doGenerateModelPreview(currentJob));
};

const parseErrorToActions = (error, action) => {
    console.log(`Uploading file to AWS fail:`, error.message);
    return of(doUploadFileToStorageFailed(action.payload));
};

const uploadFileToStorage = (action$, state$) =>
    action$.pipe(
        ofType(UPLOAD_FILE_TO_STORAGE_START),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const putTask = putFileToStorage(
                action.payload.file,
                action.payload.urls.presignedURL,
                'application/octet-stream'
            );

            const { uploadJobsCount } = state$.value?.appState?.modelsState;
            const { userToken } = state$.value?.appState?.userState;
            const { fileUploadAction } = state$.value?.appState?.viewerState;
            console.log(`Starting file upload to AWS S3...`);
            return from(putTask).pipe(
                mergeMap(response => parseResponseToActions(response, action, !uploadJobsCount, userToken, fileUploadAction)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default uploadFileToStorage;
