import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { compareEpicPayload } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { STOP_CONVERT_FILE } from '../state/redux/types';
import { doStopConversionJobFulfilled, doStopConversionJobFailed } from '../state/redux/actions';

const parseResToActions = (_, action) => {
    return of(doStopConversionJobFulfilled(action.payload));
};

const parseErrorToActions = (error, action) => {
    console.log(`Error:`, error);
    return of(doStopConversionJobFailed(action.payload));
};

const stopConversionJob = action$ =>
    action$.pipe(
        ofType(STOP_CONVERT_FILE),
        debounceTime(EPICS_DEBOUNCE_TIME.STOP_CONVERSION_JOB),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            console.log(`Stop conversion`);
            return ajax({
                url: `https://${urls.converter}/stop/${action.payload}`,
                method: 'PUT',
            }).pipe(
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default stopConversionJob;
