import { FEATURE_FLAGS, getFeatureFlagsFromStorage } from './utils/feature-flags';

class Pendo {
    static fetch(apiKey) {
        (function(p, e, n, d, o) {
            o = p[d] = p[d] || {};
            o._q = [];
            const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
            for (let w = 0, x = v.length; w < x; ++w) {
                (function(m) {
                    o[m] =
                        o[m] ||
                        function() {
                            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                        };
                })(v[w]);
            }
            const y = e.createElement(n);
            y.async = !0;
            y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
            const z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
    }

    static getVisitorStorageData() {
        const featureFlagsArr = Object.values(FEATURE_FLAGS);
        const featureFlagsFromStorage = getFeatureFlagsFromStorage();
        const storageData = {
            enabled_experimental_features: Object.entries(featureFlagsFromStorage)
                .filter(([, value]) => value)
                .map(([name]) => featureFlagsArr.find(featureFlag => featureFlag.name === name))
                .filter(v => !!v)
                .map(featureData => featureData.pendoValue || featureData.name)
                .join(', '),
        };

        return storageData;
    }

    static initialize(visitorData) {
        if (!window.pendo) return;
        const visitorStorageData = Pendo.getVisitorStorageData();

        window.pendo.initialize({
            visitor: { ...visitorData, ...visitorStorageData },
            account: {
                id: 'print-prep-1000',
                name: 'Viewer user',
                is_paying: false,
                monthly_value: false,
            },
        });
    }

    static update(visitorData) {
        if (!window.pendo) return;
        window.pendo.updateOptions(visitorData);
    }

    static track(name, options) {
        if (!window.pendo) return;
        window.pendo.track(name, options);
    }
}

export default Pendo;
