import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AppLoader from '../common/AppLoader';
// Code splitting with dynamic import
// https://reactjs.org/docs/code-splitting.html
const Viewer = React.lazy(() => import('../ConnectedViewer/'));

const ViewerRouter = () => {
    const existingParams = new URLSearchParams(window.location.search);
    const setParams = {
        printer_id: 'lava_f',
    };
    const preview_id = existingParams.get('preview_id');
    if (preview_id) setParams.preview_id = preview_id;
    const newParams = new URLSearchParams(setParams);
    return (
        <Router>
            <Suspense fallback={<AppLoader />}>
                <Switch>
                    <Route exact path='/printers' component={Viewer} />

                    <Redirect to={`/printers?${newParams.toString()}`} />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default ViewerRouter;
