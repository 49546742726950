export const SET_ACTIVE_JOB_TYPE = 'SET_ACTIVE_JOB_TYPE';
export const SHOW_TOP_OVERLAY_BAR = 'SHOW_TOP_OVERLAY_BAR';
export const SHOW_STL_FILE_SIZE_MODAL = 'SHOW_STL_FILE_SIZE_MODAL';
export const SHOW_MAKERBOT_FILE_SIZE_MODAL = 'SHOW_MAKERBOT_FILE_SIZE_MODAL';
export const SHOW_MAKERBOT_FILE_CORRUPTED_MODAL = 'SHOW_MAKERBOT_FILE_CORRUPTED_MODAL';
export const SHOW_MAKERBOT_FILE_ERROR_FOR_NON_EMPTY_PLATE_MODAL = 'SHOW_MAKERBOT_FILE_ERROR_FOR_NON_EMPTY_PLATE_MODAL';
export const SHOW_MAKERBOT_FILE_ERROR_INCOMPATIBLE_MODAL = 'SHOW_MAKERBOT_FILE_ERROR_INCOMPATIBLE_MODAL';
export const SHOW_MISSING_PARTS_MODAL = 'SHOW_MISSING_PARTS_MODAL';
export const SET_UPLOADED_MAKERBOT_FILE = 'SET_UPLOADED_MAKERBOT_FILE';
export const SET_UPLOADED_FILE_FORMAT = 'SET_UPLOADED_FILE_FORMAT';
export const SET_IS_UPLOAD_MODAL_OPEN = 'SET_IS_UPLOAD_MODAL_OPEN';
export const SET_FOCUSED_INPUT_NAME = 'SET_FOCUSED_INPUT_NAME';
export const SET_UNDO_REDO = 'SET_UNDO_REDO';
export const ADD_TO_UNDO_STACK = 'ADD_TO_UNDO_STACK';
export const SET_UNDO_REDO_FLAG = 'SET_UNDO_REDO_FLAG';
export const SET_CONTEXT_MENU = 'SET_CONTEXT_MENU';
export const SET_MEASURE_TOOL = 'SET_MEASURE_TOOL';
export const SET_PLACE_FACE = 'SET_PLACE_FACE';
export const SHOW_APP_SETTINGS = 'SHOW_APP_SETTINGS';
export const SET_SMART_ORIENT = 'SET_SMART_ORIENT';
export const SET_SMART_ARRANGE_STATE = 'SET_SMART_ARRANGE_STATE';
export const SET_SMART_ARRANGE_PROGRESS = 'SET_SMART_ARRANGE_PROGRESS';
export const SEND_JOB_TO_SMART_ORIENT_START = 'SEND_JOB_TO_SMART_ORIENT_START';
export const SEND_JOB_TO_SMART_ORIENT_FULFILLED = 'SEND_JOB_TO_SMART_ORIENT_FULFILLED';
export const SEND_JOB_TO_SMART_ORIENT_FAILED = 'SEND_JOB_TO_SMART_ORIENT_FAILED';
export const TRACK_PROGRESS_FOR_SMART_ORIENT_START = 'TRACK_PROGRESS_FOR_SMART_ORIENT_START';
export const TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED = 'TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED';
export const TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED = 'TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED';
export const SET_URLS = 'SET_URLS';
export const SET_IS_PERSPECTIVE_CAMERA = 'SET_IS_PERSPECTIVE_CAMERA';
export const SET_IS_PRINTER_DROPDOWN_OPEN = 'SET_IS_PRINTER_DROPDOWN_OPEN';
export const SET_SHORTCUTS = 'SET_SHORTCUTS';
export const SET_BUILD_VOLUMES = 'SET_BUILD_VOLUMES';
export const SET_SYSTEM_PREFERENCES = 'SET_SYSTEM_PREFERENCES';
export const SET_UNITS_MISMATCH = 'SET_UNITS_MISMATCH';
export const SET_SHOW_PLACE_FACE = 'SET_SHOW_PLACE_FACE';
export const SET_SHOULD_PLACE_FACE = 'SET_SHOULD_PLACE_FACE';
export const SET_FILES_UPLOADED_FROM_FOLDER = 'SET_FILES_UPLOADED_FROM_FOLDER';
export const SET_TIMESTAMP_SCROLL = 'SET_TIMESTAMP_SCROLL';
export const SHOW_SAVE_APP_SETTINGS_MODAL = 'SHOW_SAVE_APP_SETTINGS_MODAL';
export const SHOW_GRAPHICS_SETTINGS_CHANGED_MODAL = 'SHOW_GRAPHICS_SETTINGS_CHANGED_MODAL';
export const SET_ACTIVE_APP_SETTINGS_SAVE_BUTTON = 'SET_ACTIVE_APP_SETTINGS_SAVE_BUTTON';
export const SHOW_CLEAR_BUILD_PLATE_MODAL = 'SHOW_CLEAR_BUILD_PLATE_MODAL';
export const SHOW_SLICING_ERROR_MODAL = 'SHOW_SLICING_ERROR_MODAL';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const SET_FILE_UPLOAD_ACTION = 'SET_FILE_UPLOAD_ACTION';
