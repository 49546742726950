import { fromEvent, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    DELETE_CUSTOM_PRINT_MODE_FAIL,
    DELETE_CUSTOM_PRINT_MODE_START,
    DELETE_CUSTOM_PRINT_MODE_SUCCESS,
} from '../state/redux/types';
import { doDeleteCustomPrintModeSuccess, doDeleteCustomPrintModeFail, doSetNotification } from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResponseToAction = (response, action) => {
    if (response.error) {
        return parseErrorToAction(response.error);
    }

    return of(
        doSetNotification(NOTIFICATION_TYPE.CUSTOM_MODE_SUCCESSFULLY_DELETED),
        doDeleteCustomPrintModeSuccess({ id: action.payload.id })
    );
};

const parseErrorToAction = (error, action) => {
    console.log('Error deleting custom print mode', error);
    return of(
        doSetNotification(NOTIFICATION_TYPE.CUSTOM_MODE_DELETE_FAILED),
        doDeleteCustomPrintModeFail({ error, printMode: action.payload.printMode })
    );
};

const deleteCustomPrintMode = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_CUSTOM_PRINT_MODE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.DELETE_CUSTOM_PRINT_MODE),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const { printMode, userToken } = action.payload;
            const { id } = printMode;
            const config = { id, userToken: `Bearer ${userToken}` };
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('print-mode-delete', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'print-mode-delete').pipe(
                        mergeMap(response => parseResponseToAction(response, action)),
                        catchError(error => parseErrorToAction(error, action)),
                        takeUntil(action$.pipe(ofType(DELETE_CUSTOM_PRINT_MODE_SUCCESS, DELETE_CUSTOM_PRINT_MODE_FAIL)))
                    )
                )
            );
        })
    );

export default deleteCustomPrintMode;
