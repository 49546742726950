import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'rxjs';
import dotenv from 'dotenv';
import urls from '@makerbot/urls';

import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { getCloudslicerWsClient, getSchemaSettingsWsClient } from './helpers/webSocketUtils';
import Pendo from './helpers/pendo';
import { isDevelopment, isLab, isProduction, isVercel } from './helpers/utils/environment';

dotenv.config();
Pendo.fetch(process.env.REACT_APP_PENDO_API_KEY);

if (isProduction()) {
    urls.set('production');
} else if (isDevelopment()) {
    // once Dev env is up - this should be flipped with Staging, making dev default
    urls.set('dev');
} else {
    urls.set('staging');
}

const inIframe = () => {
    try {
        const parentHostname = window.top.location.hostname;
        if (parentHostname === urls.teams || parentHostname === urls.digitalFactory) {
            return true;
        }
    } catch (err) {
        return true;
    }
    return false;
};

const environment = process.env.REACT_APP_ENVIRONMENT;
const isDev = environment === 'development';

if (inIframe() || isVercel() || isDev || isLab()) {
    const root = createRoot(document.getElementById('root'));
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );

    // init web sockets
    getCloudslicerWsClient();
    getSchemaSettingsWsClient();

    serviceWorker.register({
        onUpdate: registration => {
            const waitingServiceWorker = registration.waiting;

            if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener('statechange', event => {
                    if (event.target.state === 'activated') {
                        window.location.reload();
                    }
                });
                waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
            }
        },
        onSuccess: registration => {
            console.log('service worker on success state');
            console.log(registration);
        },
    });
} else {
    window.location.replace(`https://${urls.teams}`);
}
