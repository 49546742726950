import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';

import { DOWNLOAD_CUSTOM_PRINT_MODE_START } from '../state/redux/types';
import { doDownloadCustomPrintModeSuccess, doDownloadCustomPrintModeFail } from '../state/redux/actions';

const parseErrorToActions = error => {
    console.log('Error downloading custom print mode -', error);
    return of(doDownloadCustomPrintModeFail(error));
};

const downloadCustomPrintMode = action$ =>
    action$.pipe(
        ofType(DOWNLOAD_CUSTOM_PRINT_MODE_START),
        mergeMap(action => {
            const fileName = `${action.payload.printMode.name}.printmode`;
            const blob = new Blob([JSON.stringify(action.payload.printMode, null, 2)], {
                type: 'application/octet-stream',
            });
            const url = window.URL.createObjectURL(blob);
            // Edge of 44 version can not download blob object using <a> tag
            // When Edge of 44 version we will stop supporting should be removed
            if ('msSaveOrOpenBlob' in navigator) {
                navigator.msSaveOrOpenBlob(blob, fileName);
                return of(doDownloadCustomPrintModeSuccess());
            }
            const link = document.createElement('a');
            link.style.display = 'none';
            link.download = fileName;
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            return of(doDownloadCustomPrintModeSuccess());
        }),
        catchError(error => parseErrorToActions(error))
    );

export default downloadCustomPrintMode;
