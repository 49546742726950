import {
    FETCH_ABOUT_INFO_SUCCESS,
    FETCH_AVAILABLE_ARTIFACTS_SUCCESS,
    FETCH_USER_FROM_ONION_SUCCESS,
    SET_SELECTED_ARTIFACT,
    HANDLE_DIGITAL_FACTORY_TOKEN,
} from './types';

const initialState = {
    userToken: null,
    cloudslicerToken: null,
    email: null,
    artifacts: null,
    selectedArtifact: null,
    about: {
        miracleGrue: {},
        sliceconfig: {},
    },
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_FROM_ONION_SUCCESS:
            const tokens = action.payload.tokens;
            const email = action.payload.email;

            return {
                ...state,
                userToken: tokens['my-makerbot'],
                cloudslicerToken: tokens['cloudslicer'],
                email,
            };
        case HANDLE_DIGITAL_FACTORY_TOKEN:
            const token = action.payload;
            return {
                ...state,
                cloudslicerToken: token,
                userToken: token,
            };
        case FETCH_AVAILABLE_ARTIFACTS_SUCCESS:
            return {
                ...state,
                artifacts: action.payload,
                selectedArtifact: action.payload[0],
            };
        case SET_SELECTED_ARTIFACT:
            return {
                ...state,
                selectedArtifact: action.payload,
            };
        case FETCH_ABOUT_INFO_SUCCESS:
            return {
                ...state,
                about: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;
