import SettingsUI from '@makerbot/settingsui';

import {
    FETCH_REFLECTOR_AUTH_PRINTERS_SUCCESS,
    FETCH_LOCAL_PRINTERS_SUCCESS,
    FETCH_WORKSPACE_PRINTERS_SUCCESS,
    FETCH_REFLECTOR_AUTH_PRINTERS_FAIL,
    FETCH_LOCAL_PRINTERS_FAIL,
    HANDLE_CHANGE_PRINTER_START,
    HANDLE_CHANGE_PRINTER_SUCCESS,
    PRINTER_SET_PRINTED_MAKERBOT_FILENAME,
    PRINTER_SET_PRINTER_CONFIG,
    PRINTER_UPDATE_SETTINGS,
    PRINTER_UPDATE_SETTINGS_FOR_6th_GEN,
    PRINTER_UPDATE_PRINT_MODE,
    PRINTER_SET_MODELS_OUTSIDE,
    PRINTER_SET_MODELS_FITS,
    PRINTER_SET_HIGHLIGHTED_WALLS,
    PRINTER_SET_STATUS,
    PRINTER_CLEAR_PRINTER_OVERRIDES,
    PRINTER_SET_OVERRIDES,
    PRINTER_SET_UNKNOW_MATERIAL,
    PRINTER_SET_PRINTER_MISSING,
    FETCH_PRINTER_SCHEMA_SUCCESS,
    FETCH_PRINTERS_CONFIGS_SUCCESS,
    PRINTER_SET_PRINT_PREP_PARAMS,
    PRINTER_SET_BUILD_VOLUMES,
    SET_PRINT_FILES_BLOB,
    SET_THUMBNAILS,
    SET_PRINT_FILE_DATA,
    SET_FILE_NAME,
    UPDATE_PRINTER_SCHEMA,
    FETCH_CUSTOM_PRINT_MODES_SUCCESS,
    SET_SHOW_IMPORT_PRINT_MODE_MODAL,
    SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT,
    IMPORT_CUSTOM_PRINT_MODE_SUCCESS,
    SET_SHOW_SAVE_PRINT_MODE_MODAL,
    SET_CUSTOM_PRINT_MODE_OVERRIDES,
    SET_PRESELECTED_CUSTOM_PRINT_SETTINGS,
    CREATE_CUSTOM_PRINT_MODE_SUCCESS,
    FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS,
    CLEAR_CUSTOM_PRINT_SETTINGS_OVERRIDES,
    SET_EXPORT_PRINT_MODE_ID_FOR_MODAL,
    SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OVERRIDES_OBJECT,
    SET_VALID_CUSTOM_PRINT_MODE_OVERRIDES,
    UPDATE_SETTINGS_IN_CUSTOM_PRINT_MODE,
    SET_SHOW_PRINT_MODE_WRONG_FORMAT_MODAL,
    DELETE_CUSTOM_PRINT_MODE_START,
    DELETE_CUSTOM_PRINT_MODE_FAIL,
    PRINTER_SET_PRETTY_STATUS,
    IMPORT_CUSTOM_PRINT_MODE_FAIL,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS,
    SET_SPOOL_INFO,
    FETCH_PRINTER_SCHEMA_START,
    PRINTER_SET_NULL_MATERIAL,
    PRINTER_SET_QUEUE_LENGTH,
    REMOVE_LOCAL_PRINTER,
    AUTHORIZE_LOCAL_PRINTER,
    REAUTHORIZE_LOCAL_PRINTER,
    AUTHORIZE_LOCAL_PRINTER_FAIL,
    REAUTHORIZE_LOCAL_PRINTER_FAIL,
    AUTHORIZE_LOCAL_PRINTER_SUCCESS,
    SET_INITIAL_EXTRUDER_MATERIAL_STATE,
    PRINTER_SET_INVALID_MATERIALS_COMBINATION,
    PRINTER_PERMODEL_SET_OVERRIDES,
    FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS,
    SELECT_PRINT_MODE,
    PRINTER_PERMODEL_CLEAR_OVERRIDES,
    PRINTER_CLEAR_PRINTER_OVERRIDE,
    PRINTER_PERMODEL_CLEAR_OVERRIDE,
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
    SET_NOTIFICATIONS,
    SHAKE_BLOCKING_CTA_SNACKBARS,
    PRINTER_SET_MODELS_BREAKS_VOLUME,
    PRINTER_SET_IS_MAIN_EXTRUDER_MISSING,
    RESET_SECOND_EXTRUDER_MATERIAL,
    SET_IS_SELECTED_CONFIG_EQUAl_LOADED,
} from './types';
import { PRINTER_CHANGE_STATUS, EXPORT_BUTTON_TEXT, PRINT_MODES_ENUM } from '../../../consts';

import { getVolumeWithOutsets, getReferrerLink } from '../../../helpers/utils';
import { handleBeforeUnloadFn } from '../../../helpers/utils/dom';
import { getUpdatedExpandAxisXValue, isCustomPrintMode } from '../../../helpers/printerUtils';
import { isDesktop } from '../../../helpers/utils/environment';
import { SNACKBAR_MESSAGE } from '../../../consts/notifications';

const initialState = {
    devices: isDesktop() ? [] : null,
    connectedLocalPrinterIp: '',
    printExportData: {
        filesBlob: [],
        thimbFiles: [],
        fileUrl: null,
        fileName: '',
        printedMakerbotFileName: '',
    },
    printer: null,
    settings: {
        activePrintMode: PRINT_MODES_ENUM.balanced.id,
        schema: {},
        overrides: {},
        perModelOverrides: {},
    },
    initialSchemas: {},
    SettingsUIFullSchema: {},
    showSavePrintModeModal: false,
    showImportPrintModeModal: false,
    exportPrintModeIdForModal: null,
    showPrintModeWrongFormatModal: false,
    shakeBlockingCTASnackbars: false,
    isInvalidMaterialsCombination: false,
    importCustomPrintModeError: SNACKBAR_MESSAGE.SNACKBAR_IMPORT_PRINT_MODE_ERROR,
    customPrintModes: [],
    customPrintModeIncompatibleObject: {},
    customPrintModeOverrides: {},
    validCustomPrintModeOverrides: {},
    customPrintModeIncompatibleOverridesObject: {},
    preselectedCustomPrintSettings: null,
    buildVolumes: null,
    exportButtonText: EXPORT_BUTTON_TEXT.export,
    isFits: true,
    isOutside: false,
    isModelsBreaksVolume: false,
    modelsOutsideArr: [],
    highlightedWalls: {},
    isOffline: true,
    isUnknownMaterial: false,
    isNullMaterial: false,
    isPrinterMissing: false,
    printerChangeStatus: PRINTER_CHANGE_STATUS.notChanged,
    spoolInfo: null,
    schemaRequestTimestamp: Date.now(),
    handleBeforeUnloadFn,
    isMainExtruderMissing: false,
    initialExtruderMaterialState: [],
    notifications: [],
    isSelectedConfigMismatchLoaded: true,
};

const printerReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRINTER_SET_PRETTY_STATUS:
            return {
                ...state,
                printer: {
                    ...state.printer,
                    prettyStatus: action.payload.status,
                },
            };
        case RESET_SECOND_EXTRUDER_MATERIAL:
            if (!state.printer.config.extrudersPairs) {
                return state;
            }

            const defaultExtrudersPairConfig = state.printer.config.extrudersPairs.find(({ isDefault }) => isDefault);

            if (!defaultExtrudersPairConfig?.extrudersList) {
                return state;
            }
            const [, { material }] = defaultExtrudersPairConfig.extrudersList;

            return {
                ...state,
                settings: {
                    ...state.settings,
                    materials: [state.settings.materials[0], material],
                },
            };
        case FETCH_REFLECTOR_AUTH_PRINTERS_SUCCESS:
            return {
                ...state,
                devices: action.payload.printers,
            };
        case FETCH_LOCAL_PRINTERS_SUCCESS: {
            const newPrinter = action.payload.local_printer;
            if (state.devices?.find(d => d.iserial === newPrinter.txt?.iserial)) {
                return { ...state };
            }
            const newDevice = {
                status: {
                    bot_type: newPrinter.txt.bot_type,
                },
                name: newPrinter.txt.machine_name,
                ip: newPrinter.txt.ip,
                iserial: newPrinter.txt.iserial,
                printer_id: newPrinter.txt.machine_name,
            };
            return {
                ...state,
                devices: [...(state.devices || []), newDevice],
            };
        }
        case REMOVE_LOCAL_PRINTER: {
            const newPrinter = action.payload.local_printer;
            const devices = state.devices?.filter(d => d.iserial !== newPrinter.txt?.iserial);
            return {
                ...state,
                devices,
            };
        }
        case AUTHORIZE_LOCAL_PRINTER:
            return {
                ...state,
                selectedLocalPrinter: action.payload.selected_local_printer,
            };
        case AUTHORIZE_LOCAL_PRINTER_SUCCESS:
            return {
                ...state,
                connectedLocalPrinterIp: action.payload.ip,
            };
        case REAUTHORIZE_LOCAL_PRINTER:
            return {
                ...state,
                selectedLocalPrinter: action.payload.selected_local_printer,
            };
        case FETCH_WORKSPACE_PRINTERS_SUCCESS:
            return {
                ...state,
                devices: action.payload.workspace_printers,
            };
        case FETCH_REFLECTOR_AUTH_PRINTERS_FAIL:
            return state;
        case FETCH_LOCAL_PRINTERS_FAIL:
            return state;
        case AUTHORIZE_LOCAL_PRINTER_FAIL:
            return state;
        case REAUTHORIZE_LOCAL_PRINTER_FAIL:
            return state;
        case HANDLE_CHANGE_PRINTER_START:
            return {
                ...state,
                printerChangeStatus: PRINTER_CHANGE_STATUS.inProgress,
            };
        case HANDLE_CHANGE_PRINTER_SUCCESS:
            const newState = {
                ...state,
                printer: {
                    ...action.payload.device,
                },
                isOffline: action.payload.device.printer_id === action.payload.device.type,
                isPrinterMissing: action.payload.device.isPrinterMissing,
                initialSchemas: {},
            };

            if (state.settings?.schema?.supportType) {
                newState.settings = {
                    ...state.settings,
                    schema: {
                        ...state.settings.schema,
                        supportType: {
                            ...state.settings.schema.supportType,
                            value: null,
                        },
                    },
                };
            }

            return newState;
        case PRINTER_UPDATE_PRINT_MODE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activePrintMode: action.payload.printMode,
                },
            };
        case PRINTER_SET_PRINTED_MAKERBOT_FILENAME:
            return {
                ...state,
                printExportData: {
                    ...state.printExportData,
                    printedMakerbotFileName: action.payload.printedMakerbotFileName,
                },
            };
        case PRINTER_SET_PRINTER_CONFIG:
            return {
                ...state,
                printer: {
                    ...state.printer,
                    config: action.payload.printerConfig,
                },
            };
        case FETCH_PRINTER_SCHEMA_START: {
            return {
                ...state,
                schemaRequestTimestamp: action.payload.config.timestamp,
            };
        }
        case FETCH_PRINTER_SCHEMA_SUCCESS: {
            if (state.schemaRequestTimestamp !== action.payload.config.timestamp) {
                return state;
            }

            // TODO: remove to the epic
            const { schema, overrides } = action.payload;
            const parameters = schema.custom_parameters;
            const newSettings = {};
            newSettings.schema = { ...parameters };
            if (state.settings.activePrintMode) {
                const validStrings = parameters?.printMode?.validStrings || [];
                if (isCustomPrintMode(state.settings.activePrintMode)) {
                    newSettings.activePrintMode = state.settings.activePrintMode;
                } else if (validStrings.includes(state.settings.activePrintMode)) {
                    newSettings.activePrintMode = state.settings.activePrintMode;
                } else if (validStrings.includes(PRINT_MODES_ENUM.balanced.id)) {
                    newSettings.activePrintMode = PRINT_MODES_ENUM.balanced.id;
                } else if (validStrings.length) {
                    newSettings.activePrintMode = validStrings[0];
                }
            }

            if (state.settings.extruders) newSettings.extruders = state.settings.extruders;
            if (state.settings.materials) newSettings.materials = state.settings.materials;

            for (const k in newSettings.schema) {
                if (typeof newSettings.schema[k] === 'string') continue;
                if (!newSettings.schema[k].value) {
                    newSettings.schema[k].value =
                        newSettings.schema[k].initial_value === null
                            ? newSettings.schema[k].min
                            : newSettings.schema[k].initial_value;
                }
            }

            const newOverrides = {
                ...state.settings.overrides,
            };

            const overidesArr = [...Object.entries(state.settings.overrides), ...Object.entries(overrides || {})];
            if (overidesArr.length) {
                for (const [k, v] of overidesArr) {
                    if (newSettings.schema[k]) {
                        newSettings.schema[k].value = v;
                    } else {
                        delete newOverrides[k];
                    }
                }
            }
            newSettings.overrides = { ...newOverrides };
            newSettings.perModelOverrides = { ...state.settings.perModelOverrides };

            const printer = { ...state.printer };
            printer.volumeWithOutsets = getVolumeWithOutsets(printer, newSettings.schema);
            // Update build volume based on new settings' support type
            printer.expandAxisX = getUpdatedExpandAxisXValue(
                printer.type,
                newSettings.schema.supportType?.value,
                newSettings.schema
            );

            const settingsUISchema = new SettingsUI.SettingsUISchema(schema);
            const newUpdateStatus =
                state.printerChangeStatus === PRINTER_CHANGE_STATUS.inProgress
                    ? PRINTER_CHANGE_STATUS.changed
                    : PRINTER_CHANGE_STATUS.notChanged;

            const newState = {
                ...state,
                settings: newSettings,
                SettingsUIFullSchema: settingsUISchema.getFullSchema(),
                printer,
                printerChangeStatus: newUpdateStatus,
                supportedPrintModes: [...schema.custom_parameters.printMode.validStrings],
            };

            if (Object.keys(schema.preferences).length === 0 || Object.keys(state.initialSchemas).length === 0) {
                const printMode = schema.custom_parameters.printMode.initial_value;
                newState.settings.activePrintMode = printMode;
                newState.settings.overrides.printMode = printMode;
                newState.settings.perModelOverrides = {}; // ??
                newState.supportedPrintModes = [newSettings.activePrintMode];
                newState.initialSchemas = {};
                newState.initialSchemas[printMode] = JSON.parse(JSON.stringify(schema));
            }

            if (
                isCustomPrintMode(state.settings.activePrintMode) &&
                !newState.initialSchemas[newSettings.activePrintMode]
            ) {
                newState.initialSchemas[newSettings.activePrintMode] = JSON.parse(JSON.stringify(schema));
            }

            return newState;
        }
        case FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS: {
            const printModes = [];
            const initialSchemas = {};
            action.payload.forEach(schema => {
                const printMode = schema.preferences.printMode;
                printModes.push(printMode);
                initialSchemas[printMode] = schema;
            });

            return {
                ...state,
                supportedPrintModes: [...state.supportedPrintModes, ...printModes],
                initialSchemas: {
                    ...state.initialSchemas,
                    ...initialSchemas,
                },
            };
        }
        case SELECT_PRINT_MODE: {
            const printMode = action.payload;
            const initialSchema = state.initialSchemas[printMode];
            const schema = JSON.parse(JSON.stringify(initialSchema));
            const parameters = schema.custom_parameters;
            const newSettings = {};
            newSettings.schema = { ...parameters };
            newSettings.activePrintMode = printMode;
            newSettings.overrides = { ...state.settings.overrides };
            newSettings.perModelOverrides = { ...state.settings.perModelOverrides };

            if (state.settings.extruders) newSettings.extruders = state.settings.extruders;
            if (state.settings.materials) newSettings.materials = state.settings.materials;

            for (const k in newSettings.schema) {
                if (typeof newSettings.schema[k] === 'string') continue;
                if (!newSettings.schema[k].value) {
                    newSettings.schema[k].value =
                        newSettings.schema[k].initial_value === null
                            ? newSettings.schema[k].min
                            : newSettings.schema[k].initial_value;
                }
            }

            if (printMode === newSettings.overrides.printMode) {
                const overidesArr = [...Object.entries(state.settings.overrides)];
                if (overidesArr.length) {
                    for (const [k, v] of overidesArr) {
                        if (newSettings.schema[k]) {
                            newSettings.schema[k].value = v;
                        }
                    }
                }
            }

            const printer = { ...state.printer };
            printer.volumeWithOutsets = getVolumeWithOutsets(printer, newSettings.schema);
            // Update build volume based on new settings' support type
            printer.expandAxisX = getUpdatedExpandAxisXValue(
                printer.type,
                newSettings.schema.supportType?.value,
                newSettings.schema
            );

            const newInitialSchemas = { ...state.initialSchemas };
            Object.keys(newInitialSchemas).forEach(key => {
                if (isCustomPrintMode(key) && key !== printMode) {
                    delete newInitialSchemas[key];
                }
            });

            const settingsUISchema = new SettingsUI.SettingsUISchema(schema);

            return {
                ...state,
                settings: newSettings,
                SettingsUIFullSchema: settingsUISchema.getFullSchema(),
                printer,
                initialSchemas: newInitialSchemas,
            };
        }
        case FETCH_PRINTERS_CONFIGS_SUCCESS: {
            const printersConfigs = action.payload.printersConfigs;
            return {
                ...state,
                printersConfigs,
            };
        }
        case PRINTER_UPDATE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    extruders: [...action.payload.extruders],
                    materials: [...action.payload.materials],
                },
                supportedPrintModes: [PRINT_MODES_ENUM.balanced.id],
            };
        case PRINTER_UPDATE_SETTINGS_FOR_6th_GEN:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    extruders: [...action.payload.extruders],
                    materials: [...action.payload.materials],
                },
                supportedPrintModes: [PRINT_MODES_ENUM.balanced.id],
            };
        case PRINTER_SET_OVERRIDES: {
            // clear purgeWallCenter if purgeTowerShape were changed
            if (
                !!action.payload.overrides.purgeTowerShape &&
                state.settings.overrides.purgeTowerShape !== action.payload.overrides.purgeTowerShape
            ) {
                delete state.settings.overrides.purgeWallCenter;
                delete action.payload.overrides.purgeWallCenter;
            }

            const hasPrintMode = !!action.payload.overrides.printMode;

            const activePrintMode = hasPrintMode ? action.payload.overrides.printMode : state.settings.activePrintMode;
            const perModelOverrides = hasPrintMode ? {} : { ...state.settings.perModelOverrides };
            const overrides = hasPrintMode
                ? { ...action.payload.overrides }
                : {
                      ...state.settings.overrides,
                      ...action.payload.overrides,
                  };

            const newInitialSchemas = { ...state.initialSchemas };
            if (hasPrintMode) {
                Object.keys(newInitialSchemas).forEach(key => {
                    if (isCustomPrintMode(key) && key !== activePrintMode) {
                        delete newInitialSchemas[key];
                    }
                });
            }

            const defaultSchema = newInitialSchemas[activePrintMode]?.custom_parameters;
            if (defaultSchema) {
                Object.keys(overrides).forEach(key => {
                    if (key === 'printMode') {
                        return;
                    }

                    const setting = defaultSchema[key];
                    if (!setting) {
                        return;
                    }

                    let initVal = setting.initial_value;
                    if (isCustomPrintMode(activePrintMode)) {
                        if (state.customPrintModeOverrides?.[key]) {
                            initVal = state.customPrintModeOverrides[key];
                        }
                    }

                    if (initVal === overrides[key]) {
                        state.settings.schema[key] = {
                            ...state.settings.schema[key],
                            value: initVal,
                        };
                        delete overrides[key];
                    }
                });
            }

            return {
                ...state,
                settings: {
                    ...state.settings,
                    activePrintMode,
                    overrides,
                    perModelOverrides,
                },
                initialSchemas: newInitialSchemas,
            };
        }
        case PRINTER_CLEAR_PRINTER_OVERRIDE: {
            const name = action.payload.name;
            const overrides = { ...state.settings.overrides };
            if (overrides[name]) {
                delete overrides[name];
            }

            const perModelOverrides = { ...state.settings.perModelOverrides };
            Object.keys(perModelOverrides).forEach(id => {
                if (perModelOverrides[id][name]) {
                    delete perModelOverrides[id][name];
                }
            });
            return {
                ...state,
                settings: {
                    ...state.settings,
                    overrides,
                    perModelOverrides,
                },
            };
        }
        case PRINTER_CLEAR_PRINTER_OVERRIDES: {
            const keepPrintMode = action.payload.keepPrintMode;
            const overrides = {};
            const perModelOverrides = {};
            let activePrintMode = state.settings.activePrintMode;
            if (keepPrintMode) {
                overrides.printMode = state.settings.overrides.printMode;
                Object.keys(state.settings.perModelOverrides).forEach(key => {
                    const override = state.settings.perModelOverrides[key];
                    if (override.printMode) {
                        perModelOverrides[key] = {
                            printMode: override.printMode,
                        };
                    }
                });
            } else {
                activePrintMode = state.supportedPrintModes[0];
            }

            return {
                ...state,
                settings: {
                    ...state.settings,
                    activePrintMode,
                    overrides,
                    perModelOverrides,
                },
            };
        }
        case PRINTER_SET_MODELS_FITS:
            return {
                ...state,
                isFits: action.payload.isFits,
            };
        case PRINTER_SET_MODELS_OUTSIDE:
        case PRINTER_SET_MODELS_BREAKS_VOLUME:
            return {
                ...state,
                ...action.payload,
            };
        case PRINTER_SET_HIGHLIGHTED_WALLS:
            return {
                ...state,
                ...action.payload,
            };
        case PRINTER_SET_STATUS:
            return {
                ...state,
                printer: {
                    ...state.printer,
                    status: action.payload.status,
                },
            };
        case PRINTER_SET_QUEUE_LENGTH:
            return {
                ...state,
                printer: {
                    ...state.printer,
                    queue_length: action.payload.queue_length,
                },
            };
        case PRINTER_SET_UNKNOW_MATERIAL:
            return {
                ...state,
                isUnknownMaterial: action.payload.isUnknownMaterial,
            };
        case PRINTER_SET_NULL_MATERIAL:
            return {
                ...state,
                isNullMaterial: action.payload.isNullMaterial,
            };
        case PRINTER_SET_INVALID_MATERIALS_COMBINATION:
            return {
                ...state,
                isInvalidMaterialsCombination: action.payload.isInvalidMaterialsCombination,
            };
        case PRINTER_SET_PRINTER_MISSING:
            return {
                ...state,
                isPrinterMissing: action.payload.isPrinterMissing,
            };
        case PRINTER_SET_PRINT_PREP_PARAMS:
            const { printPrepParams } = action.payload;
            return {
                ...state,
                closeButtonText: printPrepParams.close_button_text,
                exportButtonText: printPrepParams.export_button_text || EXPORT_BUTTON_TEXT.export,
                isOffline: printPrepParams.export_only === 'true' || state.printer.printer_id === state.printer.type,
                lockedInPrinterType:
                    printPrepParams.printer_type && printPrepParams.printer_archetype_dropdown === 'true',
                isEmbedded: printPrepParams.embedded === 'true',
                referrer: getReferrerLink(document.referrer, printPrepParams.referrer),
                single_file: printPrepParams.single_file === 'true',
                callback_type: printPrepParams.callback_type,
                flow_type: printPrepParams.flow_type?.toUpperCase(),
            };
        case PRINTER_SET_BUILD_VOLUMES:
            return {
                ...state,
                buildVolumes: action.payload.buildVolumes,
            };
        case SET_FILE_NAME:
        case SET_PRINT_FILE_DATA:
        case SET_THUMBNAILS:
        case SET_PRINT_FILES_BLOB:
            return {
                ...state,
                printExportData: {
                    ...state.printExportData,
                    ...action.payload,
                },
            };
        case UPDATE_PRINTER_SCHEMA:
            const newSettings = {
                ...state.settings,
            };
            const overrides = action.payload;
            Object.keys(overrides).forEach(key => {
                newSettings.schema[key].value = overrides[key];
            });
            newSettings.overrides = { ...overrides };

            const printer = { ...state.printer };
            printer.volumeWithOutsets = getVolumeWithOutsets(printer, newSettings.schema);
            // Update build volume based on new settings' support type
            printer.expandAxisX = getUpdatedExpandAxisXValue(
                printer.type,
                newSettings.schema.supportType?.value,
                newSettings.schema
            );

            return {
                ...state,
                settings: newSettings,
                printer,
            };
        case FETCH_CUSTOM_PRINT_MODES_SUCCESS: {
            return Object.assign({}, state, { customPrintModes: [...action.payload] });
        }
        case CREATE_CUSTOM_PRINT_MODE_SUCCESS:
        case IMPORT_CUSTOM_PRINT_MODE_SUCCESS:
        case DELETE_CUSTOM_PRINT_MODE_FAIL: {
            return Object.assign({}, state, {
                customPrintModes: [
                    ...state.customPrintModes,
                    {
                        ...action.payload.printMode,
                    },
                ],
            });
        }
        case IMPORT_CUSTOM_PRINT_MODE_FAIL: {
            if (!action.payload) {
                return state;
            }

            if (typeof action.payload === 'string') {
                return Object.assign({}, state, {
                    importCustomPrintModeError: action.payload,
                });
            }

            return Object.assign({}, state, {
                importCustomPrintModeError: SNACKBAR_MESSAGE.SNACKBAR_IMPORT_PRINT_MODE_ERROR,
            });
        }
        case PRINTER_SET_IS_MAIN_EXTRUDER_MISSING:
        case SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT:
        case SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OVERRIDES_OBJECT:
        case SET_SHOW_IMPORT_PRINT_MODE_MODAL:
        case SET_SHOW_SAVE_PRINT_MODE_MODAL:
        case SET_EXPORT_PRINT_MODE_ID_FOR_MODAL:
        case SET_SHOW_PRINT_MODE_WRONG_FORMAT_MODAL:
        case SET_CUSTOM_PRINT_MODE_OVERRIDES:
        case SET_VALID_CUSTOM_PRINT_MODE_OVERRIDES:
        case SET_PRESELECTED_CUSTOM_PRINT_SETTINGS:
        case SET_NOTIFICATIONS:
        case SHAKE_BLOCKING_CTA_SNACKBARS: {
            return Object.assign({}, state, {
                ...action.payload,
            });
        }
        case CLEAR_CUSTOM_PRINT_SETTINGS_OVERRIDES: {
            return Object.assign({}, state, {
                customPrintModeOverrides: {},
                validCustomPrintModeOverrides: {},
            });
        }
        case FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS: {
            return Object.assign({}, state, {
                customPrintModeOverrides: {
                    ...action.payload.customPrintModeOverrides,
                },
            });
        }
        case DELETE_CUSTOM_PRINT_MODE_START: {
            const { printMode } = action.payload;
            const index = state.customPrintModes.findIndex(mode => mode.id === printMode.id);

            if (index === -1) {
                return state;
            }

            return Object.assign({}, state, {
                customPrintModes: [
                    ...state.customPrintModes.slice(0, index),
                    ...state.customPrintModes.slice(index + 1),
                ],
            });
        }
        case UPDATE_SETTINGS_IN_CUSTOM_PRINT_MODE: {
            return Object.assign({}, state, {
                customPrintModeOverrides: {
                    ...action.payload.settings,
                },
            });
        }
        case SET_SPOOL_INFO:
        case TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS: {
            return {
                ...state,
                spoolInfo: action.payload.spoolInfo,
            };
        }
        case SET_INITIAL_EXTRUDER_MATERIAL_STATE: {
            return Object.assign({}, state, {
                initialExtruderMaterialState: action.payload.initialExtruderMaterialState,
            });
        }
        case PRINTER_PERMODEL_SET_OVERRIDES: {
            const { models, overrides } = action.payload.overrides;
            const perModelOverrides = { ...state.settings.perModelOverrides };
            models.forEach(id => {
                if (overrides.printMode) {
                    perModelOverrides[id] = { ...overrides };
                } else {
                    perModelOverrides[id] = { ...perModelOverrides[id], ...overrides };
                }
            });

            return {
                ...state,
                settings: {
                    ...state.settings,
                    perModelOverrides,
                },
            };
        }
        case PRINTER_PERMODEL_CLEAR_OVERRIDE: {
            const { models, name } = action.payload.override;
            const perModelOverrides = { ...state.settings.perModelOverrides };
            models.forEach(id => {
                if (perModelOverrides[id]?.[name]) {
                    delete perModelOverrides[id][name];
                }
            });
            return {
                ...state,
                settings: {
                    ...state.settings,
                    perModelOverrides,
                },
            };
        }
        case PRINTER_PERMODEL_CLEAR_OVERRIDES: {
            const { models } = action.payload;
            const perModelOverrides = { ...state.settings.perModelOverrides };
            models.forEach(id => {
                delete perModelOverrides[id];
            });
            return {
                ...state,
                settings: {
                    ...state.settings,
                    perModelOverrides,
                },
            };
        }
        case ADD_NOTIFICATION: {
            return {
                ...state,
                notifications: [...state.notifications, action.payload.notification],
            };
        }
        case DELETE_NOTIFICATION: {
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.notification_id !== action.payload.notificationId
                ),
            };
        }
        case SET_IS_SELECTED_CONFIG_EQUAl_LOADED: {
            return {
                ...state,
                isSelectedConfigMismatchLoaded: action.payload,
            };
        }
        default:
            return state;
    }
};

export default printerReducer;
