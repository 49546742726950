import { isCustomPrintMode } from '../../../helpers/printerUtils';

export const printerStateSelector = state => state.appState.printerState;
export const printerInfoSelector = state => state.appState.printerState.printer;
export const printerTypeSelector = state => state.appState.printerState?.printer?.type;
export const printerIdSelector = state => state.appState.printerState.printer?.printer_id;
export const printerNameSelector = state => state.appState.printerState.printer?.name;
export const printerStatusSelector = state => state.appState.printerState.printer.status;
export const printerQueueLengthSelector = state => state.appState.printerState.printer?.queue_length;
export const printerPrettyStatusSelector = state => state.appState.printerState.printer?.prettyStatus;
export const printerVolumeSelector = state => state.appState.printerState?.printer?.volume;
export const printerVolumeWithOutsetsSelector = state => state.appState.printerState.printer.volumeWithOutsets;
export const expandAxisXSelector = state => state.appState.printerState?.printer?.expandAxisX;
export const printerSettingsSelector = state => state.appState.printerState.settings;
export const printerModeSelector = state => state.appState.printerState.settings.activePrintMode;
export const overridesSelector = state => state.appState.printerState.settings.overrides;
export const perModelOverridesSelector = state => state.appState.printerState.settings.perModelOverrides;
export const initialSchemasSelector = state => state.appState.printerState.initialSchemas;
export const extrudersSelector = state => state.appState.printerState.settings.extruders;
export const materialsSelector = state => state.appState.printerState.settings.materials;
export const printerSchemaSelector = state => state.appState.printerState.settings.schema;
export const fullSchemaSelector = state => state.appState.printerState.SettingsUIFullSchema;
export const isMainExtruderMissingSelector = state => state.appState.printerState.isMainExtruderMissing;

export const isEmbeddedSelector = state => state.appState.printerState.isEmbedded;
export const callbackTypeSelector = state => state.appState.printerState.callback_type;
export const singleFileSelector = state => state.appState.printerState.single_file;
export const isWorkspacePrinterSelector = state => state.appState.printerState?.printer?.isWorkspacePrinter;
export const closeButtonTextSelector = state => state.appState.printerState.closeButtonText;
export const exportButtonTextSelector = state => state.appState.printerState.exportButtonText;
export const lockedInPrinterTypeSelector = state => state.appState.printerState.lockedInPrinterType;
export const flowTypeSelector = state => state.appState.printerState.flow_type;

export const printExportSelector = state => state.appState.printerState.printExportData;
export const isFitsSelector = state => state.appState.printerState.isFits;
export const isOutsideSelector = state => state.appState.printerState.isOutside;
export const isModelsBreakVolumeSelector = state => state.appState.printerState.isModelsBreakVolume;
export const modelsOutsideArrSelector = state => state.appState.printerState.modelsOutsideArr;
export const highlightedWallsSelector = state => state.appState.printerState.highlightedWalls;
export const devicesSelector = state => state.appState.printerState.devices;
export const selectedLocalPrinterSelector = state => state.appState.printerState.selectedLocalPrinter;
export const connectedLocalPrinterIpSelector = state => state.appState.printerState.connectedLocalPrinterIp;
export const supportedPrintModesSelector = state => state.appState.printerState.supportedPrintModes;
export const isUnknownMaterialSelector = state => state.appState.printerState.isUnknownMaterial;
export const isNullMaterialSelector = state => state.appState.printerState.isNullMaterial;
export const isInvalidMaterialsCombinationSelector = state => state.appState.printerState.isInvalidMaterialsCombination;
export const isPrinterMissingSelector = state => state.appState.printerState.isPrinterMissing;
export const printerConfigSelector = state => state.appState.printerState.printer.config;
export const printerConfigsSelector = state => state.appState.printerState.printersConfigs;
export const printerIsOfflineSelector = state => state.appState.printerState.isOffline;
export const handleBeforeUnloadFnSelector = state => state.appState.printerState.handleBeforeUnloadFn;
export const referrerSelector = state => state.appState.printerState.referrer;
export const printerChangeStatusSelector = state => state.appState.printerState.printerChangeStatus;
export const showPrintModeWrongFormatModalSelector = state => state.appState.printerState.showPrintModeWrongFormatModal;
export const customPrintModesSelector = state => state.appState.printerState.customPrintModes;
export const showImportPrintModeModalSelector = state => state.appState.printerState.showImportPrintModeModal;
export const showSavePrintModeModalSelector = state => state.appState.printerState.showSavePrintModeModal;
export const exportPrintModeIdForModalSelector = state => state.appState.printerState.exportPrintModeIdForModal;
export const importCustomPrintModeErrorSelector = state => state.appState.printerState.importCustomPrintModeError;

export const shakeBlockingCTASnackbarsSelector = state => state.appState.printerState.shakeBlockingCTASnackbars;

export const customPrintModeIncompatibleObjectSelector = state =>
    state.appState.printerState.customPrintModeIncompatibleObject;
export const customPrintModeIncompatibleOverridesObjectSelector = state =>
    state.appState.printerState.customPrintModeIncompatibleOverridesObject;
export const customPrintModeOverridesSelector = state => state.appState.printerState.customPrintModeOverrides;
export const validCustomPrintModeOverridesSelector = state => state.appState.printerState.validCustomPrintModeOverrides;
export const preselectedCustomPrintSettingsSelector = state =>
    state.appState.printerState.preselectedCustomPrintSettings;
export const isCustomPrintModeSelectedSelector = state => {
    const printMode =
        state.appState.printerState.settings.overrides?.printMode ||
        state.appState.printerState.settings.activePrintMode;
    return isCustomPrintMode(printMode);
};
export const spoolInfoSelector = state => state.appState.printerState?.spoolInfo;

export const initialExtruderMaterialStateSelector = state => state.appState.printerState?.initialExtruderMaterialState;

export const notificationsSelector = state => state.appState.printerState.notifications;

export const isSelectedConfigMismatchLoadedSelector = state => state.appState.printerState.isSelectedConfigMismatchLoaded;
