import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { FETCH_TOOLPATH_START } from '../state/redux/types';
import { doFetchToolpathFailed, doFetchToolpathFulfilled } from '../state/redux/actions';
import { compareEpicPayload } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { previewToolpath, comparisonPreviewToolpath, SLICE_FORMAT } from '../helpers/Toolpath';
import ToolpathDecoder from '../helpers/ToolpathDecoder';

const parseResToActions = (response, action, state$) => {
    const modelPreviews = state$.value?.appState?.previewState?.modelsPreview;
    const modelPreviewIndex = modelPreviews.findIndex(
        modelPreview => modelPreview.previewId === action.payload.previewId
    );

    if (modelPreviewIndex === 0) {
        previewToolpath.updateData(response, action.payload.format, modelPreviews[modelPreviewIndex].sortedModel);
    } else {
        comparisonPreviewToolpath.updateData(response, action.payload.format, modelPreviews[modelPreviewIndex].sortedModel);
    }

    return of(doFetchToolpathFulfilled());
};

const parseErrorToActions = error => {
    console.log(`Error:`, error.message);
    return of(doFetchToolpathFailed(error));
};

const fetchToolpath = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_TOOLPATH_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_RECONSTRUCTED_MODEL),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const id = action.payload.id;
            const url = `https://${urls.reconstructor}/originalToolpath/${id}`;

            console.log(`Fetching original toolpath start...`);
            return new Observable(async observer => {
                const res = await fetch(url);
                if (action.payload.format === SLICE_FORMAT.toolpath) {
                    const reader = res.body.getReader();
                    const toolpathDecoder = new ToolpathDecoder(reader);
                    const commands = await toolpathDecoder.decode();
                    observer.next(commands);
                } else {
                    const commands = await res.text();
                    observer.next(commands);
                }
            }).pipe(
                mergeMap(response => parseResToActions(response, action, state$)),
                catchError(error => parseErrorToActions(error))
            );
        })
    );

export default fetchToolpath;
