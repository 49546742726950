import { ofType } from 'redux-observable';
import { of, forkJoin, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mergeMap, debounceTime, distinctUntilChanged, switchMap, retry, catchError } from 'rxjs/operators';

import { UPLOAD_TO_BUCKET_START } from '../state/redux/types';
import {
    doUploadFilesToBucketFulfilled,
    doUploadFilesToBucketFailed,
    doRunConversionJob,
    doSendJobToSlicer,
    doSendJobToSmartOrient,
    doSetThumbnailsLink,
    doSetHeldSlicePayload,
    doFetchPresignedDownloadURLs,
} from '../state/redux/actions';
import { compareEpicPayload, getFailedSliceJob } from '../helpers/utils';
import { sendInitNotificationEvent } from '../helpers/utils/notification-event';
import { isTeamsEnabled } from '../helpers/utils/environment';
import { getUrlsForFileType } from '../helpers/epicUtils';
import { EPICS_DEBOUNCE_TIME, URL_FETCHING_GOALS, JOB_TYPE } from '../consts';

const getContentTypeForFile = fileName => {
    if (!fileName) return 'application/octet-stream';
    const ext = fileName.split('.').pop();
    switch (ext) {
        case 'png':
            return 'image/png';
        case 'json':
            return 'text/plain';
        default:
            return 'application/octet-stream';
    }
};

const parseResponseToActions = (responses, action, state) => {
    const { payload } = action;
    for (const response of responses) {
        if (response.status !== 200) {
            return parseErrorToActions('Files failed to load to storage bucket', action);
        }
    }

    if (payload.goal === URL_FETCHING_GOALS.STL_CONVERT) {
        return of(doUploadFilesToBucketFulfilled(payload), doRunConversionJob(payload));
    }

    const thingStorageURLs = getUrlsForFileType(payload.urls, 'thing');
    payload.thingStorageURL = thingStorageURLs.gcsUrl;

    if (payload.goal === URL_FETCHING_GOALS.SMART_ORIENT) {
        const smartOrientPayload = {
            ...payload,
            thingUrl: thingStorageURLs.gcsUrl,
        };
        return of(doUploadFilesToBucketFulfilled(payload), doSendJobToSmartOrient(smartOrientPayload));
    }

    const thumbnailsStorageURLs = getUrlsForFileType(payload.urls, 'zip');
    payload.thumbnailsStorageURL = thumbnailsStorageURLs.gcsUrl;

    if (isTeamsEnabled() && payload.currentJob.printNow && payload.currentJob.jobType === JOB_TYPE.immediatePrint) {
        const eventData = {
            printer_id: payload.currentJob.device.printer_id,
            thing_url: thingStorageURLs.gcsUrl,
            thumbnails_url: thumbnailsStorageURLs.gcsUrl,
            selected_artifact: state.value.appState.userState.selectedArtifact,
            job_name: payload.currentJob.fileName,
            profile_key: payload.profileKey,
            job_id: payload.currentJob.localId,
        };
        sendInitNotificationEvent(eventData);

        return of(
            doSetThumbnailsLink(thumbnailsStorageURLs.gcsUrl),
            doSetHeldSlicePayload(payload),
            doUploadFilesToBucketFulfilled(payload)
        );
    }
    const args = [];
    if (payload.isFilesTracking) {
        payload.isFilesTracking = false;
        payload.urls = thingStorageURLs;
        args.push(doFetchPresignedDownloadURLs(payload));
    }
    args.push(doUploadFilesToBucketFulfilled(payload), doSendJobToSlicer(payload));
    return of(...args);
};

const parseErrorToActions = (error, action) => {
    console.log(`Upload file to GCS failed...`);
    console.log(`Error:`, error);
    return of(
        doUploadFilesToBucketFailed({
            failedSliceJob: getFailedSliceJob(action.payload.currentJob),
        })
    );
};

const uploadFilesToBucket = (action$, state$) =>
    action$.pipe(
        ofType(UPLOAD_TO_BUCKET_START),
        debounceTime(EPICS_DEBOUNCE_TIME.UPLOAD_TO_BUCKET),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            console.log('Uploading files to bucket');
            const payload = action.payload;
            const files = [];
            payload.files &&
                payload.files.forEach(file => {
                    files.push({ file, url: payload.urls[file.name].presignedURL });
                });

            payload.file && files.push({ file: payload.file, url: payload.urls.presignedURL });

            const tasks = [];
            files.forEach((task, i) => {
                const delay$ = timer(i * 100);
                tasks.push(
                    delay$.pipe(
                        switchMap(_ =>
                            ajax({
                                url: task.url,
                                method: 'PUT',
                                headers: {
                                    'Content-Type': task.file.type || getContentTypeForFile(task.file.name),
                                },
                                body: task.file,
                            }).pipe(retry(3))
                        )
                    )
                );
            });

            return forkJoin(tasks).pipe(
                mergeMap(response => parseResponseToActions(response, action, state$)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default uploadFilesToBucket;
