import { BehaviorSubject } from 'rxjs';

const SLICE_FORMAT = {
    toolpath: 'toolpath',
    gcode: 'gcode',
}

class Toolpath {
    constructor() {
        this.curState = new BehaviorSubject({
            format: null,
            data: null,
        });
        this.state$ = this.curState.asObservable();
    }

    get state() {
        return this.curState.value;
    }

    clear() {
        this.curState.next({
            format: null,
            data: null,
        });
    }

    updateData(data, format, sortedModel) {
        this.curState.next({
            format,
            data: this.parseRawData(data, format, sortedModel),
        });
    }

    parseRawData(data, format, sortedModel) {
        let lines = [];
        if (format === SLICE_FORMAT.toolpath) {
            lines = data.map(el => {
                if (el.command) {
                    return JSON.stringify(el.command);
                }
                if (el.comment) {
                    return JSON.stringify(el.comment);
                }
                return JSON.stringify(el);
            });
        } else {
            lines = data.match(/[^\r\n]+/g);
        }

        const toolpathData = {};
        const keys = Object.keys(sortedModel);
        keys.sort((a, b) => a - b);
        let firstLine = 0,
            lastLine = 0;

        keys.forEach((key, i) => {
            const layer = sortedModel[key];
            const lastIndex = lastLine;
            if (i > 0) {
                firstLine = lastLine + 1;
            }
            lastLine = layer[layer.length - 1].ln;
            const segment = lines.slice(firstLine, lastLine);
            toolpathData[key] = {
                lines: segment.join('\n'),
                linesIndexes: layer.map(line => line.ln),
                size: segment.length,
                previouseLayerIndex: lastIndex,
            };
        });

        return toolpathData;
    }
}

const previewToolpath = new Toolpath();
const comparisonPreviewToolpath = new Toolpath();

export { previewToolpath, comparisonPreviewToolpath, SLICE_FORMAT };
