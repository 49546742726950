import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import tls from 'tls';
import urls from '@makerbot/urls';

import { FILE_DOWNLOAD_IFRAME_ID, LOCAL_STORAGE_KEYS } from '../consts';
import { crossFrameStorage } from './CrossFrameStorage';

// In staging we need to allow either the staging or dev domain origin
const digitalFactoryDevURL = 'http://localhost:4100';
const printPrepDevURL = 'http://localhost:3000';

export const ultimakerInternalOrgs = ['Ultimaker B.V. Test'];

const tlsOptions = {
    rejectUnauthorized: false,
};

export const jsonContentType = {
    'Content-Type': 'application/json',
};

export const defaultUserResponse = {
    email: 'local@user',
    first_name: 'Local',
    full_name: 'Local User',
    last_name: 'User',
    name: 'localuser',
    tokens: {
        'cloudslicer': '1',
        'my-makerbot': '2',
    },
    username: 'localuser',
};

export const putFileToStorage = (file, putUrl, fileType = 'text/plain') => {
    console.log(`Uploading ${file.name} to storage`);
    return ajax({
        url: putUrl,
        method: 'PUT',
        headers: {
            'Content-Type': file.type ? file.type : fileType,
        },
        body: file,
    });
};

export const loadFileFromPresigned = signedGetUrl => {
    return ajax({
        url: signedGetUrl,
        method: 'GET',
    }).toPromise();
};

export const loadWithIframe = fileUrl => {
    let iframe = document.getElementById(FILE_DOWNLOAD_IFRAME_ID);
    if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.id = FILE_DOWNLOAD_IFRAME_ID;
        iframe.style.display = 'none';
        iframe.src = fileUrl;
        document.body.appendChild(iframe);
    } else {
        iframe.src = fileUrl;
    }
};

export const jsonRpcObserver = (method, params, host, port) =>
    Observable.create(observer => {
        const client = tls.connect(port, host, tlsOptions, () => {
            client.write(
                JSON.stringify({
                    jsonrpc: '2.0',
                    id: +new Date(),
                    method,
                    params,
                })
            );
        });
        client.on('data', result => {
            const parsed = JSON.parse(result.toString());
            if (parsed.error) {
                observer.error(parsed.error);
            }
            observer.next(parsed);
        });
        client.on('error', result => observer.error(JSON.parse(result)));
        client.on('close', observer.complete);
    });

export const addOrUpdatePrinterInLocalStorage = printer => {
    if (!printer || !printer.iserial || !printer.auth?.username) {
        throw new Error('Failed to persist printer - malformed data');
    }
    const addedPrinters = crossFrameStorage.getItem(LOCAL_STORAGE_KEYS.localPrinters) || {};
    addedPrinters[printer.iserial] = printer;
    crossFrameStorage.setItem(LOCAL_STORAGE_KEYS.localPrinters, addedPrinters);
};

export const isValidParentOrigin = origin => {
    const teamsUrl = urls.teams;
    const teamsOrigin = `${teamsUrl.includes('local') ? 'http' : 'https'}://${teamsUrl}`;
    return origin.includes('digitalfactory') || [teamsOrigin, digitalFactoryDevURL, printPrepDevURL].includes(origin);
};

export const getPayloadFromJWT = token => {
    if (!token || !token.includes('.')) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export const getUrlsForFileType = (urls, fileType) => {
    for (const [key, value] of Object.entries(urls)) {
        if (key.includes(`.${fileType}`)) return value;
    }
};
