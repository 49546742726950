import { Euler, Quaternion, Box3, Vector3 } from 'three';
import { partition, isEqual } from 'lodash';
import urls from '@makerbot/urls';
import { getPrinterMaterials } from '@makerbot/will-it-print/lib/printer.utils';
import protobuf from 'protobufjs';
import { v4 as uuidv4 } from 'uuid';
import { Polygon, Collisions } from 'collisions';

import {
    SETTINGS,
    MEASUREMENTS_UNITS,
    PURGE_TOWER_NAME,
    MATERIAL_TYPES,
    EXTRUDERS,
    ORIENT_PLANE_NAME,
    CAMERAS_TYPE,
    BOX_NAME,
    PLANE_NAME,
    PLANE_BORDER_NAME,
    PLANE_INVISIBLE_NAME,
    GRID_PLANE_TRANSPARENT_NAME,
    CLOUD_PRINT_URL,
    EXPORT_STATUSES,
    EXTRUDER_MOVE_TYPE,
    MAX_UPLOAD_STL_FILE_SIZE,
    SCHEMA_SETTINGS_TYPES,
    INCOMPATIBLE_OVERRIDES_DEFAULT_OBJECT,
    CUSTOM_PRINT_MODE_VALIDATORS,
    QUERY_FILE_NAME_PARAM,
    BOT_TYPE_ENUM,
    BASE64_HEADER,
    PRINT_MODES_ENUM,
    MODEL_OUTSIDE_OVERLAP_MATERIAL,
    MODEL_MATERIAL,
    BUILD_VOLUME_LINES,
    PRINT_PREVIEW_SCENE_MESHES_NAMES,
    MEASURE_TOOL_SPRITE_ONE,
    MEASURE_TOOL_SPRITE_TWO,
    BUILD_VOLUME_WALLS,
    BASE_LAYER_TYPES,
    FILE_FORMATS,
    POCKET_PRINT_FILE_FORMATS,
} from '../consts';
import { getAttachedToPrinterExtruders, isSixthGen, isSketchPrinter } from './printerUtils';
import ThreeInstance from './ThreeInstance';
import { getPositionSettingsFromModel, getScaleFromSettings } from './utils/model-settings';
import { getMostFrequentValue, getTimestamp, round, truncateWithoutRounding } from './utils/common';
import {
    filterOutEmptyFolder,
    filterOutPurgeTower,
    getAllSubmodelsRecursive,
    getAllVisibleModels,
    getMinfitBB,
    isModelBreaksVolume,
} from './utils/viewer';
import { isDesktop, isHostEquals } from './utils/environment';
import { DEMO_FILES } from '../../demo.files';

const getCollisionPolygon = model => {
    const threeTransform = model.instance().userData.convexHull.matrix.clone();
    const xyPointsArray = bufferGeometryToXYPoints(
        model.instance().userData.convexHull.geometry.attributes.position.array,
        threeTransform
    );
    const collisionPolygon = new Polygon(0, 0, xyPointsArray);
    return collisionPolygon;
};

export const checkIfModelsIntesects = models => {
    if (ThreeInstance.intersectOutlinePass) {
        ThreeInstance.intersectOutlinePass.selectedObjects = [];
    }

    let modelsForPrint = [];
    filterOutPurgeTower(models)
        .filter(filterOutEmptyFolder)
        .forEach(model => {
            if (model.assembleCheckbox === false) {
                const allVisibleSubmodels = getAllVisibleModels([model]).filter(filterOutEmptyFolder);
                modelsForPrint = modelsForPrint.concat(allVisibleSubmodels);
            } else {
                if (model.shown) {
                    modelsForPrint.push(model);
                }
            }
        });

    if (modelsForPrint.length <= 1) {
        return [];
    }

    const objectsWithIntersection = [];
    const collisionSystem = new Collisions();
    const modelPolygonPairs = [];
    for (let i = 0; i < modelsForPrint.length; i++) {
        const model = modelsForPrint[i];
        const threeTransform = model.instance().userData.convexHull.matrix.clone();
        const xyPointsArray = bufferGeometryToXYPoints(
            model.instance().userData.convexHull.geometry.attributes.position.array,
            threeTransform
        );
        const collisionsPolygon = new Polygon(0, 0, xyPointsArray);
        collisionSystem.insert(collisionsPolygon);
        modelPolygonPairs.push({
            model,
            collisionsPolygon,
        });
    }
    collisionSystem.update();

    for (let i = 0; i < modelPolygonPairs.length; i++) {
        const { model, collisionsPolygon } = modelPolygonPairs[i];
        if (checkIfModelIntersectsOtherModels(collisionsPolygon, collisionSystem)) {
            objectsWithIntersection.push(model.instance());
        }
    }

    return objectsWithIntersection;
};

export const checkWhichModelsAreIntersectedByModel = (mainModel, allModels) => {
    if (mainModel.isEmptyFolder) return [];

    let modelsToCheck = [];
    const submodelsNames = getAllSubmodelsRecursive(mainModel)
        .flat()
        .map(model => model.name)
        .filter(filterOutEmptyFolder);

    filterOutPurgeTower(allModels)
        .filter(filterOutEmptyFolder)
        .forEach(m => {
            if (m.name === mainModel.name) {
                return;
            }

            if (m.assembleCheckbox === false) {
                const allVisibleSubmodels = getAllVisibleModels([m]);
                const filtered = allVisibleSubmodels
                    .filter(submodel => !submodelsNames.includes(submodel.name))
                    .filter(filterOutEmptyFolder)
                    .filter(sm => !(sm.assembleCheckbox === true));
                modelsToCheck = modelsToCheck.concat(filtered);
            } else {
                if (m.shown) {
                    modelsToCheck.push(m);
                }
            }
        });

    const modelPolygonPairs = [];
    for (let i = 0; i < modelsToCheck.length; i++) {
        const model = modelsToCheck[i];
        const collisionPolygon = getCollisionPolygon(model);
        const modelPolygon = getCollisionPolygon(mainModel);

        const collisionSystem = new Collisions();
        collisionSystem.insert(modelPolygon);
        collisionSystem.insert(collisionPolygon);
        collisionSystem.update();

        modelPolygonPairs.push({
            model,
            collisionPolygon,
            collisionSystem,
        });
    }

    const objectsWithIntersection = [];
    for (let i = 0; i < modelPolygonPairs.length; i++) {
        const { model, collisionPolygon, collisionSystem } = modelPolygonPairs[i];
        if (checkIfModelIntersectsOtherModels(collisionPolygon, collisionSystem)) {
            objectsWithIntersection.push(model.instance());
        }
    }
    return objectsWithIntersection;
};

export const highlightOutsideModels = (modelsFitsObj, models) => {
    const highlightedModels = [];
    const modelsWithoutPurgeTower = filterOutPurgeTower(getAllVisibleModels(models));
    Object.values(modelsFitsObj).forEach((el, idx) => {
        if (!el) highlightedModels.push(modelsWithoutPurgeTower[idx].instance());
    });

    if (ThreeInstance.outsideOutlinePass) {
        ThreeInstance.outsideOutlinePass.selectedObjects = highlightedModels;
        ThreeInstance.renderScene();
    }
};

export function updateOutsideModelsMeshes(models, modelsOutsideData, overlapedModelsIds) {
    const modelsOutsideNames = modelsOutsideData.filter(model => model.isModelOutsidePlate).map(model => model.name);
    const modelsBreakVolumeNames = modelsOutsideData.filter(isModelBreaksVolume).map(model => model.name);

    filterOutPurgeTower(models).forEach(model => {
        const isModelOutside = modelsOutsideNames.includes(model.name);
        const isModelBreaksVolume = modelsBreakVolumeNames.includes(model.name);
        const isModelOverlaped = overlapedModelsIds.includes(model.name);
        model.instance().material =
            isModelOutside || isModelBreaksVolume || isModelOverlaped ? MODEL_OUTSIDE_OVERLAP_MATERIAL : MODEL_MATERIAL;
    });
}

export const getFailedSliceJob = (sliceJob, error) => {
    const status = EXPORT_STATUSES.failed;
    const timestamp = getTimestamp(sliceJob.unformattedDate || new Date());
    return {
        ...sliceJob,
        ...{
            status,
            progress: 0,
            timestamp,
            error: error?.message ?? error,
        },
    };
};

export const getScaleFactorForUnits = unit => {
    switch (unit) {
        case MEASUREMENTS_UNITS.mm.id:
            return 1;
        case MEASUREMENTS_UNITS.cm.id:
            return 10;
        case MEASUREMENTS_UNITS.m.id:
            return 1000;
        case MEASUREMENTS_UNITS.in.id:
            return 25.4;
        default:
            return;
    }
};

export const isValidInput = (name, value) => {
    switch (name) {
        case SETTINGS.scaleX:
        case SETTINGS.scaleY:
        case SETTINGS.scaleZ:
            return value <= 1000000 && value > 0;
        case SETTINGS.scaleUnitsX:
        case SETTINGS.scaleUnitsY:
        case SETTINGS.scaleUnitsZ:
            return value > 0;
        case SETTINGS.infillDensity:
            return value <= 100 && value >= 0;
        default:
            return true;
    }
};

export const getValueFromInput = (value, name) => {
    if (!value) return 0;
    switch (name) {
        case SETTINGS.xRotation:
        case SETTINGS.yRotation:
        case SETTINGS.zRotation:
            return value % 360;
        default:
            return value;
    }
};

export const calculateBBForMultipleSelection = activeModels => {
    const unionBB = new Box3();
    activeModels.forEach(m => {
        const bb = getMinfitBB(m, false);
        unionBB.union(bb);
    });
    return unionBB;
};

export const centerActiveModels = (activeModels, expandAxisX = 0) => {
    if (!activeModels.length) return;

    if (activeModels.length === 1) {
        const box = getMinfitBB(activeModels[0], true);
        const activeModelBBSize = new Vector3();
        box.getSize(activeModelBBSize);

        const xPosition = -(activeModelBBSize.x / 2 + box.min.x) + expandAxisX / 2;
        const yPosition = -(activeModelBBSize.y / 2 + box.min.y);

        const newPosition = new Vector3(xPosition, yPosition, -box.min.z);
        newPosition.applyMatrix4(
            activeModels[0]
                .instance()
                .parent.matrixWorld.clone()
                .invert()
        );
        activeModels[0].instance().position.copy(newPosition);
    } else {
        const bb = calculateBBForMultipleSelection(activeModels);
        const bbCenter = new Vector3();
        bb.getCenter(bbCenter);
        activeModels.forEach(m => {
            const mesh = m.instance();
            const centerTranslation = new Vector3(
                mesh.position.x - bbCenter.x,
                mesh.position.y - bbCenter.y,
                mesh.position.z
            );
            centerTranslation.applyMatrix4(mesh.parent.matrixWorld.clone().invert());
            mesh.position.set(centerTranslation.x, centerTranslation.y, centerTranslation.z);
        });
    }
};

export const getRoundedValue = (value, precision) => {
    return round(parseFloat(value), precision);
};

export const getVolumeWithOutsets = (printer, printersSettings) => {
    const { type, volume } = printer;

    const baseLayerAllowance = getBaseLayerAllowance(printersSettings, type);
    const heightAllowance = getHeightAllowance(printersSettings, type);
    return {
        x: volume.x - baseLayerAllowance,
        y: volume.y - baseLayerAllowance,
        z: volume.z - heightAllowance,
    };
};

// must be copied from eagle-print
export const getBaseLayerAllowance = (printerSettings, type) => {
    const raftMeasurement =
        printerSettings.baseLayer.value === BASE_LAYER_TYPES.raft.id
            ? printerSettings['raftProfiles.base.outsetFromModelEdge'].value * 2
            : 0;
    // Each brim is about 1mm, so the number of brims is the same as the measurement
    const brimsMeasurement =
        printerSettings.baseLayer.value === BASE_LAYER_TYPES.paddedBaseBrims.id ||
        printerSettings.baseLayer.value === BASE_LAYER_TYPES.brims.id
            ? printerSettings.numberOfBrims.value * 2
            : 0;

    // workaround, add extra allowance to fix the problem with the inaccuracy of the Raft layer
    const extraAllowance = (printerSettings.baseLayer.value !== BASE_LAYER_TYPES.none.id && type !== BOT_TYPE_ENUM.magma_10.id) ? 2 : 0;

    return raftMeasurement + brimsMeasurement + extraAllowance;
};

export const getHeightAllowance = (printerSettings, type) => {
    const hasRaft = printerSettings.baseLayer.value === BASE_LAYER_TYPES.raft.id;
    const raftHeightAllowance = hasRaft ? 1.5 : 0;
    const methodSeriesRaftHeightAllowance = (isSixthGen && hasRaft) ? 2 : 0;
    return raftHeightAllowance + methodSeriesRaftHeightAllowance;
};

export const getQuaternionFromSettings = settings => {
    const xRotation = settings.xRotation * (Math.PI / 180);
    const yRotation = settings.yRotation * (Math.PI / 180);
    const zRotation = settings.zRotation * (Math.PI / 180);
    const euler1 = new Euler(xRotation, 0, 0, 'XYZ');
    const euler2 = new Euler(0, yRotation, 0, 'XYZ');
    const euler3 = new Euler(0, 0, zRotation, 'XYZ');
    const quaternion1 = new Quaternion();
    const quaternion2 = new Quaternion();
    const quaternion3 = new Quaternion();
    quaternion1.setFromEuler(euler1);
    quaternion2.setFromEuler(euler2);
    quaternion3.setFromEuler(euler3);
    quaternion1.premultiply(quaternion2).premultiply(quaternion3);

    return quaternion1;
};

/**
 * @param {Function} func Callback function
 * @param {Number} ms Delay
 * @param  {...any} args Arguments for callback functions
 */
export const sleep = async (func, ms = 10, ...args) => {
    await new Promise(resolve => setTimeout(resolve, ms));
    return func(...args);
};

export const electronLoadFileFromPath = path => {
    if (!isDesktop()) {
        throw new Error('electronLoadFileFromPath should only run in Electron');
    }
    const fs = require('fs');
    const fileData = fs.readFileSync(path, 'base64');
    return `${BASE64_HEADER},${fileData}`;
};

export const localFileLoader = (content, loader, onProgress = () => {}, isDemoFile = false) => {
    if (!content) {
        throw new Error('localFileLoader content is empty');
    }

    if (!loader) {
        throw new Error('localFileLoader loader is undefined');
    }

    if (isDemoFile) {
        // Once we upgrade node to a version with Blob class (I think ^v15.7.0)
        // we can follow the same method as CloudPrint here and update the hooks in
        // useOnPremModelsUploader.js to handle files as Blobs like in useModelsUploader.js
        return new Promise((resolve, reject) => {
            loader.load(
                content,
                geometry => resolve(geometry),
                propress => onProgress(propress),
                error => {
                    loader.abort && loader.abort();
                    reject(error);
                }
            );
        });
    }

    return new Promise((resolve, reject) => {
        loader
            .parse(content, onProgress)
            .then(geometry => {
                resolve(geometry);
            })
            .catch(error => {
                loader.abort && loader.abort();
                reject(error);
            });
    });
};

export const removePreviewedModels = models => {
    if (ThreeInstance.scene.chunks) {
        ThreeInstance.scene.chunks.children.forEach(chunk => {
            chunk.geometry.dispose();
            chunk.material.dispose();
        });
        ThreeInstance.scene.remove(ThreeInstance.scene.chunks);
        ThreeInstance.scene.chunks = null;
    }

    Object.values(PRINT_PREVIEW_SCENE_MESHES_NAMES).forEach(key => {
        if (!ThreeInstance.scene[key]) return;
        ThreeInstance.scene[key].geometry.dispose();
        ThreeInstance.scene[key].material.dispose();
        ThreeInstance.scene.remove(ThreeInstance.scene[key]);
        ThreeInstance.scene[key] = null;
    });

    const sceneModels = ThreeInstance.scene.children;
    models.forEach(model => {
        const mesh = sceneModels.find(el => el.name === model.name);
        if (!mesh) {
            console.warn('Mesh with name', model.name, 'not found on the scene');
            return;
        }

        const meshes = model.mesh();
        meshes.forEach(m => {
            if (m.prevZ !== undefined) {
                m.position.setZ(m.prevZ);
                m.prevZ = undefined;
            }
        });
    });
};

export const getPrinterConfigurationObject = activePrinter => {
    const conf = {
        extruders: {
            extruder_0: {
                extruder_id: activePrinter.settings.extruders[0],
                material: activePrinter.settings.materials[0],
            },
        },
        machine_id: activePrinter.printer.type,
        print_mode: activePrinter.settings.printMode,
    };

    if (activePrinter.settings.extruders[1]) {
        conf.extruders.extruder_1 = {
            extruder_id: activePrinter.settings.extruders[1],
            material: activePrinter.settings.materials[1],
        };
    }

    return conf;
};

/**
 * Convert status from reflector to status for UI library
 * @param {String} status
 * @returns {String}
 */
export const getIconForStatus = status => {
    const pauseStatuses = ['suspended', 'suspending', 'pause', 'pausing'];
    const errorStatuses = ['failed', 'print_error', 'error_step'];
    const doneStatuses = ['completed'];
    const preppingStatuses = [
        'awaitingcloudslice',
        'initializing',
        'cleaning_up',
        'initial_heating',
        'loading_filament',
        'canceling',
        'setup_complete',
        'z_calibration_intro',
        'x_calibration_intro',
        'y_calibration_intro',
    ];

    switch (true) {
        case pauseStatuses.includes(status):
            return 'pause';
        case errorStatuses.includes(status):
            return 'error';
        case doneStatuses.includes(status):
            return 'done';
        case preppingStatuses.includes(status):
            return 'prepping';
        default:
            return status;
    }
};

export const isMaterialsAcceptable = printSettings => {
    const isMaterialsAcceptable =
        printSettings.materials &&
        !printSettings.materials.some((m, i) => {
            return (
                m === MATERIAL_TYPES.unknown.id &&
                printSettings.extruders[i] !== EXTRUDERS.mk14_e.id &&
                // ABCP-875 - Don't show snackbar when Method printer has 'unknown' material at 2 slot
                i !== 1
            );
        });

    return isMaterialsAcceptable;
};

export const isExtrudersOrMaterialsInLiveBotChanged = (prevStatus, newStatus) => {
    if (!prevStatus || !newStatus) return false;

    if ((!prevStatus || !prevStatus.toolheads || !prevStatus.toolheads.extruder) && newStatus) {
        return true;
    }

    if (prevStatus.toolheads.extruder.length !== newStatus.toolheads.extruder.length) {
        return true;
    }

    for (let i = 0; i < newStatus.toolheads.extruder.length; i++) {
        if (
            newStatus.toolheads.extruder[i].tool_id !== prevStatus.toolheads.extruder[i].tool_id ||
            newStatus.toolheads.extruder[i].tool_name !== prevStatus.toolheads.extruder[i].tool_name
        ) {
            return true;
        }
    }

    if (prevStatus.loaded_filaments && newStatus.loaded_filaments) {
        if (prevStatus.loaded_filaments.length !== newStatus.loaded_filaments.length) {
            return true;
        }
        for (let i = 0; i < newStatus.loaded_filaments.length; i++) {
            if (prevStatus.loaded_filaments[i] !== newStatus.loaded_filaments[i]) {
                return true;
            }
        }
    }

    return false;
};

export const resizeOrientPlane = () => {
    let scale;
    const orientPlane = ThreeInstance.scene.getObjectByName(ORIENT_PLANE_NAME);
    if (orientPlane && orientPlane.visible) {
        if (ThreeInstance.camera.type === CAMERAS_TYPE.orthographicCamera) {
            scale = 3.5 / ThreeInstance.camera.zoom;
        }
        if (!scale) scale = ThreeInstance.camera.position.distanceTo(orientPlane.position) / 350;
        orientPlane.scale.set(scale, scale, scale);
    }
};

export const resizeMeasureTool = () => {
    let scale;
    if (ThreeInstance.camera.type === CAMERAS_TYPE.orthographicCamera) {
        scale = 6 / ThreeInstance.camera.zoom;
    }

    const sprite1 = ThreeInstance.scene.getObjectByName(MEASURE_TOOL_SPRITE_ONE);
    const sprite2 = ThreeInstance.scene.getObjectByName(MEASURE_TOOL_SPRITE_TWO);
    if (sprite1) {
        if (ThreeInstance.camera.type === CAMERAS_TYPE.perspectiveCamera) {
            scale = ThreeInstance.camera.position.distanceTo(sprite1.position) / 100;
        }
        sprite1.scale.set(scale, scale, scale);
    }
    if (sprite2) {
        if (ThreeInstance.camera.type === CAMERAS_TYPE.perspectiveCamera) {
            scale = ThreeInstance.camera.position.distanceTo(sprite2.position) / 100;
        }
        sprite2.scale.set(scale, scale, scale);
    }
};

export const getLines = (x, y, z) => {
    const lines = [
        {
            point1: { x, y, z: 0 },
            point2: { x: 0, y, z: 0 },
            name: BUILD_VOLUME_LINES.BOTTOM_RIGHT,
        },
        {
            point1: { x, y, z: 0 },
            point2: { x, y: 0, z: 0 },
            name: BUILD_VOLUME_LINES.BOTTOM_FRONT,
        },
        {
            point1: { x, y, z: 0 },
            point2: { x, y, z },
            name: BUILD_VOLUME_LINES.MIDDLE_FRONT_RIGHT,
        },
        {
            point1: { x: 0, y, z: 0 },
            point2: { x: 0, y, z },
            name: BUILD_VOLUME_LINES.MIDDLE_BACK_RIGHT,
        },
        {
            point1: { x: 0, y, z: 0 },
            point2: { x: 0, y: 0, z: 0 },
            name: BUILD_VOLUME_LINES.BOTTOM_BACK,
        },
        {
            point1: { x, y: 0, z: 0 },
            point2: { x, y: 0, z },
            name: BUILD_VOLUME_LINES.MIDDLE_FRONT_LEFT,
        },
        {
            point1: { x, y: 0, z: 0 },
            point2: { x: 0, y: 0, z: 0 },
            name: BUILD_VOLUME_LINES.BOTTOM_LEFT,
        },
        {
            point1: { x, y, z },
            point2: { x: 0, y, z },
            name: BUILD_VOLUME_LINES.TOP_RIGHT,
        },
        {
            point1: { x, y, z },
            point2: { x, y: 0, z },
            name: BUILD_VOLUME_LINES.TOP_FRONT,
        },
        {
            point1: { x: 0, y, z },
            point2: { x: 0, y: 0, z },
            name: BUILD_VOLUME_LINES.TOP_BACK,
        },
        {
            point1: { x, y: 0, z },
            point2: { x: 0, y: 0, z },
            name: BUILD_VOLUME_LINES.TOP_LEFT,
        },
        {
            point1: { x: 0, y: 0, z: 0 },
            point2: { x: 0, y: 0, z },
            name: BUILD_VOLUME_LINES.MIDDLE_BACK_LEFT,
        },
    ];
    return lines;
};

export const getWalls = (x, y, z) => {
    const walls = [
        {
            name: BUILD_VOLUME_WALLS.LEFT,
            position: { x: 0, y: -y / 2, z: z / 2 },
            size: { width: x, height: z },
            rotation: {
                rotationX: Math.PI / 2,
                rotationY: 0,
            },
        },
        {
            name: BUILD_VOLUME_WALLS.RIGHT,
            position: { x: 0, y: y / 2, z: z / 2 },
            size: { width: x, height: z },
            rotation: {
                rotationX: Math.PI / 2,
                rotationY: 0,
            },
        },
        {
            name: BUILD_VOLUME_WALLS.FRONT,
            position: { x: x / 2, y: 0, z: z / 2 },
            size: { width: z, height: y },
            rotation: {
                rotationX: 0,
                rotationY: Math.PI / 2,
            },
        },
        {
            name: BUILD_VOLUME_WALLS.BACK,
            position: { x: -x / 2, y: 0, z: z / 2 },
            size: { width: z, height: y },
            rotation: {
                rotationX: 0,
                rotationY: Math.PI / 2,
            },
        },
        {
            name: BUILD_VOLUME_WALLS.TOP,
            position: { x: 0, y: 0, z },
            size: { width: x, height: y },
            rotation: {
                rotationX: 0,
                rotationY: 0,
            },
        },
    ];
    return walls;
};

export const getGridLines = (x, y) => {
    const lines = [];
    const lines10 = [];
    const linesCross = [];

    const z = 0;
    const linesCountX = x / 2;
    const linesCountY = y / 2;

    for (let i = 0; i < linesCountX; i += 1) {
        if (i === 0) {
            linesCross.push(new Vector3(0, linesCountY, z));
            linesCross.push(new Vector3(0, -linesCountY, z));
        } else if (i % 10 === 0) {
            lines10.push(new Vector3(i, linesCountY, z));
            lines10.push(new Vector3(i, -linesCountY, z));
            lines10.push(new Vector3(-i, linesCountY, z));
            lines10.push(new Vector3(-i, -linesCountY, z));
        } else {
            lines.push(new Vector3(i, linesCountY, z));
            lines.push(new Vector3(i, -linesCountY, z));
            lines.push(new Vector3(-i, linesCountY, z));
            lines.push(new Vector3(-i, -linesCountY, z));
        }
    }

    for (let i = 0; i < linesCountY; i += 1) {
        if (i === 0) {
            linesCross.push(new Vector3(linesCountX, 0, z));
            linesCross.push(new Vector3(-linesCountX, 0, z));
        } else if (i % 10 === 0) {
            lines10.push(new Vector3(linesCountX, i, z));
            lines10.push(new Vector3(-linesCountX, i, z));
            lines10.push(new Vector3(linesCountX, -i, z));
            lines10.push(new Vector3(-linesCountX, -i, z));
        } else {
            lines.push(new Vector3(linesCountX, i, z));
            lines.push(new Vector3(-linesCountX, i, z));
            lines.push(new Vector3(linesCountX, -i, z));
            lines.push(new Vector3(-linesCountX, -i, z));
        }
    }

    return { lines, lines10, linesCross };
};

const getSnapshotCamera = () => ({
    position: { x: -1, y: -1, z: 1 },
    up: { x: 0, y: 0, z: 1 },
});

const getSnapshotThumbnailsPart = () => ({
    dpi: 1,
    projection: 'orthographic',
    backgroundColor: 0xf2f2f2,
    backgroundOpacity: 0,
    boundingBoxCentered: true,
});

const getSnapshotThumbnails = () => ({
    isometricSmall: {
        ...getSnapshotThumbnailsPart(),
        name: 'isometric_thumbnail_120x120.png',
        width: 120,
        height: 120,
        camera: {
            ...getSnapshotCamera(),
            angleAndFitToModels: true,
        },
        objectsToHide: [
            BOX_NAME,
            PLANE_NAME,
            PLANE_BORDER_NAME,
            PLANE_INVISIBLE_NAME,
            GRID_PLANE_TRANSPARENT_NAME,
            PURGE_TOWER_NAME,
        ],
    },
    isometricMedium: {
        ...getSnapshotThumbnailsPart(),
        name: 'isometric_thumbnail_320x320.png',
        width: 320,
        height: 320,
        camera: {
            ...getSnapshotCamera(),
            angleAndFitToModels: true,
        },
        objectsToHide: [
            BOX_NAME,
            PLANE_NAME,
            PLANE_BORDER_NAME,
            PLANE_INVISIBLE_NAME,
            GRID_PLANE_TRANSPARENT_NAME,
            PURGE_TOWER_NAME,
        ],
    },
    isometricLarge: {
        ...getSnapshotThumbnailsPart(),
        name: 'isometric_thumbnail_640x640.png',
        width: 640,
        height: 640,
        camera: getSnapshotCamera(),
        objectsToHide: [
            PLANE_NAME,
            PLANE_BORDER_NAME,
            PLANE_INVISIBLE_NAME,
            GRID_PLANE_TRANSPARENT_NAME,
            PURGE_TOWER_NAME,
        ],
    },
});

export const getSnapshotOptions = printerType => {
    const thumbnails = getSnapshotThumbnails();

    if (isSixthGen(printerType)) {
        return {
            large: {
                name: 'thumbnail_960x1460.png',
                dpi: 1,
                width: 960,
                height: 1460,
                projection: 'orthographic',
                camera: getSnapshotCamera(),
                backgroundColor: 0x000000,
                boundingBoxCentered: true,
                objectsToHide: [PLANE_BORDER_NAME, PLANE_INVISIBLE_NAME, GRID_PLANE_TRANSPARENT_NAME, PURGE_TOWER_NAME],
            },
            medium: {
                name: 'thumbnail_212x300.png',
                dpi: 1,
                width: 212,
                height: 300,
                projection: 'orthographic',
                camera: getSnapshotCamera(),
                backgroundColor: 0x000000,
                boundingBoxCentered: true,
                objectsToHide: [PLANE_BORDER_NAME, PLANE_INVISIBLE_NAME, GRID_PLANE_TRANSPARENT_NAME, PURGE_TOWER_NAME],
            },
            small: {
                name: 'thumbnail_140x106.png',
                dpi: 1,
                width: 140,
                height: 106,
                projection: 'orthographic',
                camera: {
                    ...getSnapshotCamera(),
                    angleAndFitToModels: true,
                },
                backgroundColor: 0x000000,
                boundingBoxCentered: true,
                objectsToHide: [
                    BOX_NAME,
                    PLANE_NAME,
                    PLANE_BORDER_NAME,
                    PLANE_INVISIBLE_NAME,
                    GRID_PLANE_TRANSPARENT_NAME,
                    PURGE_TOWER_NAME,
                ],
            },
            ...thumbnails,
        };
    }

    if (isSketchPrinter(printerType)) {
        return {
            small: {
                name: 'thumbnail_90x90.png',
                dpi: 1,
                width: 90,
                height: 90,
                projection: 'orthographic',
                camera: {
                    ...getSnapshotCamera(),
                    angleAndFitToModels: true,
                },
                backgroundColor: 0x000000,
                backgroundOpacity: 0,
                boundingBoxCentered: true,
                objectsToHide: [
                    BOX_NAME,
                    PLANE_NAME,
                    PLANE_BORDER_NAME,
                    PLANE_INVISIBLE_NAME,
                    GRID_PLANE_TRANSPARENT_NAME,
                ],
            },
            ...thumbnails,
        };
    }

    return {
        small: {
            name: 'thumbnail_55x40.png',
            dpi: 1,
            width: 55,
            height: 40,
            projection: 'isometric',
            camera: {
                ...getSnapshotCamera(),
                fitToModels: true,
            },
            objectsToHide: [],
        },
        medium: {
            name: 'thumbnail_110x80.png',
            dpi: 1,
            width: 110,
            height: 80,
            projection: 'isometric',
            camera: {
                ...getSnapshotCamera(),
                fitToModels: true,
            },
            objectsToHide: [],
        },
        large: {
            name: 'thumbnail_320x200.png',
            dpi: 1,
            width: 320,
            height: 200,
            projection: 'isometric',
            camera: {
                ...getSnapshotCamera(),
                fitToModels: true,
            },
            objectsToHide: [],
        },
        ...thumbnails,
    };
};

export const convertBase64ToBlob = (base64, type) => {
    const buffer = Buffer.from(base64.split(',')[1], 'base64');
    if (isDesktop()) {
        return new Blob([buffer.toString('utf8')], { type });
    }
    return new Blob([buffer], { type });
};

export const getThumbnailFiles = snapshots => {
    if (!snapshots || !snapshots.length) {
        return;
    }

    const thumbFiles = [];
    snapshots.forEach(snapshot => {
        const { dataUrl, name } = snapshot;
        const file = convertBase64ToBlob(dataUrl, 'image/png');
        file.name = name;
        thumbFiles.push(file);
    });

    return thumbFiles;
};

export const getPrinterBoundingBox = (buildVolume, expandAxisX) => {
    let { x } = buildVolume;
    const { y, z } = buildVolume;
    x += Math.abs(expandAxisX);
    const max = { x: (x + expandAxisX) / 2, y: y / 2, z };
    const min = { x: -(x - expandAxisX) / 2, y: -y / 2, z: 0 };
    const bbox = new Box3(new Vector3(min.x, min.y, min.z), new Vector3(max.x, max.y, max.z));
    return bbox;
};

const isValidReferrerLink = referrer => {
    if (!referrer) {
        return false;
    }

    let isValidReferrer = false;
    let returnToUrl;
    try {
        if (referrer.indexOf('/') === 0) {
            return false;
        } else {
            returnToUrl = new URL(referrer);
        }
        isValidReferrer = returnToUrl.host === urls.get('myMakerBot');
    } catch (parseError) {
        console.log(`Referrer value was not able to be parsed. Got: "${referrer}"`, parseError);
    }

    return isValidReferrer;
};

const getReferrerLinkFromUrlParam = urlParamReferrer => {
    if (!isValidReferrerLink(urlParamReferrer)) {
        return CLOUD_PRINT_URL;
    }
    return urlParamReferrer;
};

export const getReferrerLink = (documentReferrer, urlParamReferrer) => {
    // Check native referrer header
    if (documentReferrer) {
        // If the referrer is a link to the login page, we must return a link to the СloudPrint
        if (isHostEquals(documentReferrer, urls.get('onion'))) {
            return CLOUD_PRINT_URL;
        }

        // If the referrer is a link to the current domain, then we must additionally check the parameter from the url
        if (isHostEquals(documentReferrer, window.location.host)) {
            return getReferrerLinkFromUrlParam(urlParamReferrer);
        }

        return documentReferrer;
    }

    return getReferrerLinkFromUrlParam(urlParamReferrer);
};

export const getUniqName = id => id + uuidv4();

export const compareEpicPayload = (previousAction, currentAction) => {
    return isEqual(previousAction.payload, currentAction.payload);
};

// There are isues with protobuf and jsdom which results in unit
// tests not being able to load this module. Skip this in tests for now.
let protoBufPromiseFromEnv = {};
if (process.env.NODE_ENV !== 'test') {
    protoBufPromiseFromEnv = protobuf.load('makerbotproto.json');
}
export const protoBufPromise = protoBufPromiseFromEnv;

export const checkIfModelPreviewAndPrinterCompatible = (device, previewBotType, previewExtruders, previewMaterials) => {
    if (device.type !== previewBotType) {
        return false;
    }

    if (!device.status.toolheads.extruder.length) {
        return false;
    }
    if (
        device.status.toolheads.extruder.find(ex => {
            return !ex.tool_name && ex.tool_id === 0;
        }) !== undefined
    ) {
        return false;
    }
    const attachedExtruders = getAttachedToPrinterExtruders(device);

    if (previewExtruders.length > attachedExtruders.length) {
        return false;
    }

    if (previewExtruders.length === attachedExtruders.length) {
        const mappedPreviewExtruders = previewExtruders.join(', ');
        const mappedAttachedExtruders = attachedExtruders.join(', ');

        if (mappedPreviewExtruders !== mappedAttachedExtruders) {
            return false;
        }
    } else {
        const isEveryExtruderExists = previewExtruders.every(e => attachedExtruders.includes(e));

        if (!isEveryExtruderExists) {
            return false;
        }
    }

    const attachedMaterials = getPrinterMaterials(device.status);

    if (previewMaterials.length > attachedMaterials.length) {
        return false;
    }

    if (previewMaterials.length === attachedMaterials.length) {
        // LABS extruders are in slot 1 then the material does not need to be checked.
        if (previewExtruders[0] === EXTRUDERS.mk14_e.id) {
            return true;
        }
        const mappedPreviewMaterials = previewMaterials.join(', ');
        const mappedAttachedMaterials = attachedMaterials.join(', ');

        if (mappedPreviewMaterials !== mappedAttachedMaterials) {
            return false;
        }
    } else {
        const isEveryMaterialExists = previewMaterials.every(m => attachedMaterials.includes(m));

        if (!isEveryMaterialExists) {
            return false;
        }
    }

    return true;
};

export const bufferGeometryToXYPoints = (bufferGeometry, transform) => {
    const pointsArray = [];
    for (let i = 0; i < bufferGeometry.length; i += 3) {
        const vector = new Vector3(bufferGeometry[i], bufferGeometry[i + 1], 0);
        const manipulatedPoint = vector.applyMatrix4(transform);
        // We skip the z axis, because this function is intended for flat shapes
        const xyPoint = [manipulatedPoint.x, manipulatedPoint.y];
        pointsArray.push(xyPoint);
    }
    return pointsArray;
};

export const boundingBoxToXYPoints = (bb, baseLayerAllowance) => {
    const expandXY = new Vector3(baseLayerAllowance, baseLayerAllowance, 0);
    bb.expandByVector(expandXY);
    const pointsArray = [
        [bb.min.x, bb.min.y],
        [bb.min.x, bb.max.y],
        [bb.max.x, bb.min.y],
        [bb.max.x, bb.max.y],
    ];
    return pointsArray;
};

export const checkIfModelIntersectsOtherModels = (polygon, collisionSystem) => {
    const potentialColliders = polygon.potentials();
    // check this model's convex hull against other models' convex hulls
    for (const otherModelHull of potentialColliders) {
        if (polygon.collides(otherModelHull)) {
            return true;
        }
    }
    return false;
};

export const getVolume = (geometry, matrix) => {
    const position = geometry.attributes.position;
    // workaround for some GLTF geometries that have a number of points that are not divisible by 3
    const faces = Math.floor(position.count / 3);
    const p1 = new Vector3(),
        p2 = new Vector3(),
        p3 = new Vector3();
    let sum = 0;

    for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, i * 3 + 0);
        p2.fromBufferAttribute(position, i * 3 + 1);
        p3.fromBufferAttribute(position, i * 3 + 2);
        p1.applyMatrix4(matrix);
        p2.applyMatrix4(matrix);
        p3.applyMatrix4(matrix);
        sum += Math.abs(signedVolumeOfTriangle(p1, p2, p3));
    }
    return sum;
};

export const checkIfModelsVolumeIsAcceptable = (models, layerHeight) => {
    let modelsVolumeAcceptable = false;
    let modelsSizeAcceptable = false;
    let modelsVolume = 0;

    models.forEach(model => {
        const meshes = model.mesh();
        meshes.forEach(mesh => {
            if (mesh.visible && mesh.geometry?.isBufferGeometry) {
                modelsVolume += getVolume(mesh.geometry, mesh.matrix);
            }
        });

        if (model.shown) {
            const bb = getMinfitBB(model, true);
            const size = new Vector3();
            bb.getSize(size);
            if (!modelsSizeAcceptable && size.z > layerHeight * 2 && size.x >= 1 && size.y >= 1) {
                modelsSizeAcceptable = true;
            }
        }
    });
    // Model volume should be at least 2 cubic mm
    if (modelsVolume >= 2) {
        modelsVolumeAcceptable = true;
    }

    return modelsVolumeAcceptable && modelsSizeAcceptable;
};

const signedVolumeOfTriangle = (p1, p2, p3) => {
    return p1.dot(p2.cross(p3)) / 6.0;
};

export const sortLayers = layers => {
    const unitedArray = [];

    for (let i = 0; i < layers.length; i++) {
        const arr = [];
        const mat = layers[i].mat || [];
        const sup = layers[i].sup || [];
        const raft = layers[i].rafts || [];
        const brim = layers[i].brims || [];
        const tra = layers[i].tm || [];
        const pt = layers[i].purgeTower || null;

        const allZ = [
            ...mat.map(move => move.s[2]),
            ...sup.map(move => move.s[2]),
            ...raft.map(move => move.s[2]),
            ...brim.map(move => move.s[2]),
            ...tra.map(move => move.s[2]),
        ];
        if (pt) {
            allZ.push(...pt?.mat?.map(move => move.s[2]), ...pt?.sup?.map(move => move.s[2]));
        }

        if (!allZ.length) {
            continue;
        }

        arr.layerZ = getMostFrequentValue(allZ);
        arr.layerCommentCount = layers[i].lcc;
        mat.forEach(m => {
            m.type = EXTRUDER_MOVE_TYPE.MATERIAL;
            m.layerHeight = layers[i].lh;
            m.layerWidth = layers[i].lw;
            arr.push(m);
        });
        sup.forEach(s => {
            s.type = EXTRUDER_MOVE_TYPE.SUPPORT;
            s.layerHeight = layers[i].lh;
            s.layerWidth = layers[i].lw;
            arr.push(s);
        });
        raft.forEach(r => {
            r.type = EXTRUDER_MOVE_TYPE.RAFT;
            r.layerHeight = layers[i].lh;
            r.layerWidth = layers[i].lw;
            arr.push(r);
        });
        brim.forEach(b => {
            b.type = EXTRUDER_MOVE_TYPE.BRIM;
            b.layerHeight = layers[i].lh;
            b.layerWidth = layers[i].lw;
            arr.push(b);
        });
        tra.forEach(t => {
            t.type = EXTRUDER_MOVE_TYPE.TRAVEL;
            t.layerHeight = layers[i].lh;
            t.layerWidth = layers[i].lw;
            arr.push(t);
        });
        if (pt) {
            pt.mat.forEach(m => {
                m.type = EXTRUDER_MOVE_TYPE.MATERIAL;
                m.layerHeight = layers[i].lh;
                m.layerWidth = layers[i].lw;
                arr.push(m);
            });
            pt.sup.forEach(s => {
                s.type = EXTRUDER_MOVE_TYPE.SUPPORT;
                s.layerHeight = layers[i].lh;
                s.layerWidth = layers[i].lw;
                arr.push(s);
            });
        }

        arr.sort((a, b) => {
            return a.i - b.i;
        });

        arr.extrusionSpeeds = layers[i].es;
        arr.extrusionTemperatures = layers[i].et;
        arr.coolingFanSpeeds = layers[i].cfss;
        arr.usedFilament = layers[i].uf;
        unitedArray.push(arr);
    }

    const sortedLayers = {};
    unitedArray.forEach(layer => {
        if (!sortedLayers[layer.layerZ]) {
            sortedLayers[layer.layerZ] = [...layer];
            sortedLayers[layer.layerZ].extrusionSpeeds = new Set(layer.extrusionSpeeds);
            sortedLayers[layer.layerZ].extrusionTemperatures = new Set(layer.extrusionTemperatures);
            sortedLayers[layer.layerZ].coolingFanSpeeds = new Set(layer.coolingFanSpeeds);
            sortedLayers[layer.layerZ].layerCommentCount = layer.layerCommentCount;
            sortedLayers[layer.layerZ].usedFilament = layer.usedFilament;
        } else {
            const len = sortedLayers[layer.layerZ].length;
            layer.forEach(el => {
                el.index += len;
            });
            sortedLayers[layer.layerZ].push(...layer);

            layer.extrusionSpeeds &&
                (sortedLayers[layer.layerZ].extrusionSpeeds = new Set([
                    ...sortedLayers[layer.layerZ].extrusionSpeeds,
                    ...layer.extrusionSpeeds,
                ]));
            layer.extrusionTemperatures &&
                (sortedLayers[layer.layerZ].extrusionTemperatures = new Set([
                    ...sortedLayers[layer.layerZ].extrusionTemperatures,
                    ...layer.extrusionTemperatures,
                ]));
            layer.coolingFanSpeeds &&
                (sortedLayers[layer.layerZ].coolingFanSpeeds = new Set([
                    ...sortedLayers[layer.layerZ].coolingFanSpeeds,
                    ...layer.coolingFanSpeeds,
                ]));

            for (let i = 0; i < sortedLayers[layer.layerZ].usedFilament.length; i++) {
                sortedLayers[layer.layerZ].usedFilament[i] += layer.usedFilament[i];
            }
        }
    });

    return sortedLayers;
};

//Checks file size
/**
 * @param file file to check
 * @return boolean
 */
export const isFileTooBig = file => {
    return file.size > MAX_UPLOAD_STL_FILE_SIZE;
};

export const blobToFile = (blobData, filename) => {
    //IE has a bug that prevents the usage of this constructor.
    // const file = new File([blobData], filename, {
    //     type: blobData.type,
    //     lastModified: new Date().getTime()
    // })

    //following code can be replaced with commented block after IE will not  be supported
    const fd = new FormData();
    fd.set('file', blobData, filename);

    const file = fd.get('file');
    if (!file.name) {
        file.name = filename;
    }
    return file;
};

export const isNumber = value => !isNaN(parseFloat(value)) && isFinite(value);

export const isPrintModeFileCompatible = (data, printerConfigs = null) => {
    const isFileCompatible =
        CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_SOURCE_KEY in data &&
        data[CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_SOURCE_KEY] ===
            CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_SOURCE_KEY_VALUE &&
        CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_PRINT_BOT_KEY in data &&
        typeof (data[CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_PRINT_BOT_KEY] === 'string') &&
        CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_EXTRUDERS_KEY in data &&
        typeof (data[CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_EXTRUDERS_KEY] === 'string') &&
        CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_MATERIALS_KEY in data &&
        typeof (data[CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_MATERIALS_KEY] === 'string') &&
        CUSTOM_PRINT_MODE_VALIDATORS.VALIDATOR_PRINT_MODE_KEY in data;

    if (!isFileCompatible) {
        return false;
    }

    const { botType, materials, extruders, printMode } = data;
    const printerConfig = printerConfigs[botType];
    if (!printerConfig) {
        return false;
    }

    const validExtrudersConfigs = printerConfig.filter(config => config.extruder_ids.join(', ') === extruders);
    if (!validExtrudersConfigs.length) {
        return false;
    }

    const validMaterialsConfigs = validExtrudersConfigs.filter(config => config.material_ids.join(', ') === materials);
    if (!validMaterialsConfigs.length) {
        return false;
    }

    if (!Object.keys(PRINT_MODES_ENUM).includes(printMode)) {
        return false;
    }

    return true;
};

export const checkOverrides = (schema, fullSchema, overrides, printMode = {}) => {
    const overridesSchemaParams = Object.keys(overrides);
    const incompatibleSettings = [];
    const categoriesList = new Set();

    for (const param of overridesSchemaParams) {
        const overridedValue = overrides[param];
        const schemaParam = schema[param];

        if (!schemaParam || !Object.keys(schemaParam).length) {
            incompatibleSettings.push({
                key: param,
                name: param,
                overridedValue,
            });
            continue;
        }

        const settingName = schemaParam.name || fullSchema?.user_settings[param]?.name || param;
        let isValidSetting = true;
        switch (schemaParam.type.toLowerCase()) {
            case SCHEMA_SETTINGS_TYPES.integer: {
                if (!isNumber(overridedValue)) {
                    incompatibleSettings.push({
                        key: param,
                        name: settingName,
                        overridedValue,
                        initialValue: schemaParam.initial_value,
                        categoryIDs: schemaParam.categoryIDs,
                    });
                    isValidSetting = false;
                }
                break;
            }

            case SCHEMA_SETTINGS_TYPES.boolean: {
                if (typeof overridedValue !== 'boolean') {
                    incompatibleSettings.push({
                        key: param,
                        name: settingName,
                        overridedValue,
                        categoryIDs: schemaParam.categoryIDs,
                    });
                    isValidSetting = false;
                }

                break;
            }

            case SCHEMA_SETTINGS_TYPES.unsignedInt:
            case SCHEMA_SETTINGS_TYPES.scalar:
            case SCHEMA_SETTINGS_TYPES.temperature: {
                if (!isNumber(overridedValue)) {
                    incompatibleSettings.push({
                        key: param,
                        name: settingName,
                        overridedValue,
                        min: schemaParam.min,
                        max: schemaParam.max,
                        type: schemaParam.type,
                        categoryIDs: schemaParam.categoryIDs,
                    });
                    isValidSetting = false;
                    break;
                }

                if (parseFloat(overridedValue) < schemaParam.min || parseFloat(overridedValue) > schemaParam.max) {
                    incompatibleSettings.push({
                        key: param,
                        name: settingName,
                        overridedValue,
                        min: schemaParam.min,
                        max: schemaParam.max,
                        type: schemaParam.type,
                        categoryIDs: schemaParam.categoryIDs,
                    });
                    isValidSetting = false;
                }

                break;
            }

            case SCHEMA_SETTINGS_TYPES.string: {
                const isStringValid = schemaParam.validStrings.includes(overridedValue);
                if (!isStringValid) {
                    incompatibleSettings.push({
                        key: param,
                        name: settingName,
                        overridedValue,
                        validStrings: schemaParam.validStrings,
                        type: schemaParam.type,
                        categoryIDs: schemaParam.categoryIDs,
                    });
                    isValidSetting = false;
                }

                break;
            }

            default:
                break;
        }
        if (isValidSetting) {
            schemaParam.categoryIDs.forEach(param => categoriesList.add(param));
        }
    }

    const isIncompatibleOverrides = !!incompatibleSettings.length;
    return Object.assign({}, INCOMPATIBLE_OVERRIDES_DEFAULT_OBJECT, {
        showModalForIncompOverrides: isIncompatibleOverrides,
        overridesIncompatibleError: isIncompatibleOverrides,
        incompatibleOverrides: incompatibleSettings,
        overridedCategories: [...categoriesList],
        printMode,
    });
};

export const getValidOverrides = (allOverrides, incompatibleOverrides) => {
    if (!allOverrides) {
        return {};
    }

    if (!incompatibleOverrides || !incompatibleOverrides.length) {
        return allOverrides;
    }

    const overridesKey = Object.keys(allOverrides);
    const incompatibleKeys = incompatibleOverrides.map(item => item.key);
    if (!overridesKey.length || !incompatibleKeys.length) {
        return allOverrides;
    }

    const validOverrides = {};
    for (const overrideKey of overridesKey) {
        if (!incompatibleKeys.includes(overrideKey)) {
            validOverrides[overrideKey] = allOverrides[overrideKey];
        }
    }
    return validOverrides;
};

export const getNameFromUrl = fileUrl => {
    const urlParams = new URLSearchParams(fileUrl);
    let fileName = urlParams.get(QUERY_FILE_NAME_PARAM); // TinkerCad sends file name in query param: https://csg.tinkercad.com/downloadExport?id=xxx.stl&fileName=test.stl
    if (fileName) {
        return fileName;
    }

    fileName = fileUrl;
    const baseUrl = fileUrl.split('?');
    if (baseUrl.length) {
        const baseUrlChunks = baseUrl[0].split('/');
        if (baseUrlChunks.length) {
            fileName = baseUrlChunks.pop();
        }
    }
    return fileName;
};

export const checkAndResetIncorrectCamera = () => {
    if (!ThreeInstance.camera || !ThreeInstance.orbitControls) return;
    if (
        isNaN(ThreeInstance.camera.position.x) ||
        isNaN(ThreeInstance.camera.position.y) ||
        isNaN(ThreeInstance.camera.position.z)
    ) {
        ThreeInstance.orbitControls.reset();
    }
};

export const updateRotationForModel = (model, newRotationSettings) => {
    const mesh = model.instance();
    const initialBB = new Box3().setFromObject(mesh);
    const bb0 = getMinfitBB(model, true);
    const quaternion = getQuaternionFromSettings(newRotationSettings);
    mesh.quaternion.copy(quaternion);
    const finalBB = new Box3().setFromObject(mesh);
    const bb1 = getMinfitBB(model, true);

    const x1 = (initialBB.max.x + initialBB.min.x) / 2;
    const x2 = (finalBB.max.x + finalBB.min.x) / 2;
    const deltaX = x2 - x1;
    const y1 = (initialBB.max.y + initialBB.min.y) / 2;
    const y2 = (finalBB.max.y + finalBB.min.y) / 2;
    const deltaY = y2 - y1;

    const { x, y, z } = mesh.position;
    mesh.position.copy(new Vector3(x - deltaX, y - deltaY, z + bb0.min.z - bb1.min.z));

    return getPositionSettingsFromModel(model);
};

export const calcBoundingBox = model => {
    const bb = new Box3();
    const submodels = getAllSubmodelsRecursive(model);
    model.updateBoundingBox();

    const [meshSubmodels, groupSubmodels] = partition(submodels, submodel => submodel.instance().isMesh);

    groupSubmodels.forEach(submodel => {
        submodel.instance().updateWorldMatrix(false, false);
    });

    meshSubmodels.forEach(submodel => {
        bb.union(getMinfitBB(submodel, false));
    });

    return bb;
};

export const updateScaleForModel = (model, newScaleSettings) => {
    const newScale = getScaleFromSettings(newScaleSettings);
    const mesh = model.instance();

    if (mesh.scale.equals(newScale)) return null;

    const bb = calcBoundingBox(model);
    mesh.scale.copy(newScale);
    const delta = mesh.position.clone().add(bb.min);
    const newBb = calcBoundingBox(model);
    const newPos = delta.clone().sub(newBb.min);
    mesh.position.copy(newPos);

    return getPositionSettingsFromModel(model);
};

export const recalculateScaleInUnits = (models, displayUnits) => {
    if (!models.length) {
        return;
    }
    let newScaleUnits;
    if (models.length === 1) {
        const mesh = models[0].instance();
        const {
            scale: { x, y, z },
        } = mesh;
        const modelBBSize = new Vector3();
        if (mesh.geometry) {
            if (!mesh.geometry.boundingBox) {
                mesh.geometry.computeBoundingBox();
            }
            mesh.geometry.boundingBox.getSize(modelBBSize);
        } else {
            const bbox = getMinfitBB(models[0], true);
            bbox.getSize(modelBBSize);
        }

        const unitsMultiplier = getScaleFactorForUnits(displayUnits);
        newScaleUnits = {
            scaleUnitsX:
                models[0].isThingGroup || !mesh.geometry
                    ? truncateWithoutRounding(modelBBSize.x / unitsMultiplier, 3)
                    : truncateWithoutRounding((x * modelBBSize.x) / unitsMultiplier, 3),
            scaleUnitsY:
                models[0].isThingGroup || !mesh.geometry
                    ? truncateWithoutRounding(modelBBSize.y / unitsMultiplier, 3)
                    : truncateWithoutRounding((y * modelBBSize.y) / unitsMultiplier, 3),
            scaleUnitsZ:
                models[0].isThingGroup || !mesh.geometry
                    ? truncateWithoutRounding(modelBBSize.z / unitsMultiplier, 3)
                    : truncateWithoutRounding((z * modelBBSize.z) / unitsMultiplier, 3),
        };
    } else {
        newScaleUnits = {
            scaleUnitsX: 0,
            scaleUnitsY: 0,
            scaleUnitsZ: 0,
        };
    }

    return newScaleUnits;
};

export const trimModelName = (name, oldName, maxLength) => {
    let newName = name;
    if (oldName.length >= maxLength && newName.length - oldName.length === 1) {
        // prevent typing new character
        return oldName;
    } else if (oldName.length - newName.length > 0) {
        // allow delete
        return newName;
    } else if (newName.length > maxLength) {
        // trim string if it was paste from clipboard
        newName = newName.substring(0, maxLength);
    }

    return newName;
};

export const getDemoFiles = printerType => {
    switch (printerType) {
        case BOT_TYPE_ENUM.fire_e.id:
        case BOT_TYPE_ENUM.lava_f.id:
        case BOT_TYPE_ENUM.magma_10.id:
            return DEMO_FILES.method;
        case BOT_TYPE_ENUM.mini_8.id:
        case BOT_TYPE_ENUM.replicator_5.id:
        case BOT_TYPE_ENUM.replicator_b.id:
        case BOT_TYPE_ENUM.z18_6.id:
        case BOT_TYPE_ENUM.sketch.id:
        case BOT_TYPE_ENUM.sketch_large.id:
            return DEMO_FILES.other;
        default:
            return null;
    }
};

export const getDemoFileUrl = (printerType, modelName, folder = null) => {
    let storageUrl = 'https://cloudprint-sample-files.makerbot.com';

    switch (printerType) {
        case BOT_TYPE_ENUM.fire_e.id:
        case BOT_TYPE_ENUM.lava_f.id:
        case BOT_TYPE_ENUM.magma_10.id:
            storageUrl += '/method';
            break;
        case BOT_TYPE_ENUM.mini_8.id:
        case BOT_TYPE_ENUM.replicator_5.id:
        case BOT_TYPE_ENUM.replicator_b.id:
        case BOT_TYPE_ENUM.z18_6.id:
        case BOT_TYPE_ENUM.sketch.id:
        case BOT_TYPE_ENUM.sketch_large.id:
            storageUrl += '/other';
            break;
        default:
            return null;
    }

    if (folder) {
        storageUrl += `/${folder}`;
    }

    return `${storageUrl}/${modelName}`;
};

export const getSupportedFileFormats = () => {
    const supportedFormats = isDesktop() ? POCKET_PRINT_FILE_FORMATS : FILE_FORMATS;
    return Object.keys(supportedFormats).map(x => supportedFormats[x]);
};

export const getAcceptableFileFormats = () => {
    const mimes = ['application/sla', 'application/vnd.ms-pki.stl', 'application/x-navistyle'];
    // Unfortunate hack for prt files were the extensions are numbered like part.prt.1 otherpart.prt.2
    // const prts = [...Array(1000)].map((_, i) => `.prt.${i}`);

    // Line below is a temporary workaround for UCP-447 until
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1423362&q=input&can=2 is fixed
    // Note: google chrome crash happens when there are two dots in a file format on macos machines
    const prts = [...Array(1000)].map((_, i) => `.${i}`);

    const formats = getSupportedFileFormats();

    if (isDesktop()) {
        return formats;
    }

    return [...mimes, ...prts, ...formats];
};

export const isArrayUndefinedOrEmpty = item => {
    return !(item && item.length);
};

export const materialLabels = items => {
    return items?.map(item => MATERIAL_TYPES[item].label) ?? [];
};

export const getAccessTokenFromPrefixedToken = token => {
    if (!token) return null;
    if (token.startsWith('um.')) {
        return token.slice(3);
    }
    return token;
};

export const isUserWithOrganization = tokenData => {
    const isParentWithOrgs = isDesktop();
    return isParentWithOrgs && Boolean(tokenData?.organizationName);
};

export const getTravelMovesFromLayers = layers => {
    let totalTravelMoves = 0;
    layers.forEach(layer => {
        totalTravelMoves += layer.tm.length;
    });
    return totalTravelMoves;
};
