import { MAC_SYSTEM_PREFERENCE } from '../../consts';
import { getQueryStringParameterByName } from './common';

export const MAKERBOT_DOMAIN = {
    DEVELOPMENT: 'mbot.dev',
    STAGING: 'mbot.me',
    PRODUCTION: 'makerbot.com',
    VERCEL: 'vercel.app',
    LAB: 'a.run.app',
    DF_STAGING: 'https://digitalfactory-staging.ultimaker.com',
    DF_PRODUCTION: 'https://digitalfactory.ultimaker.com',
    MB_DEV: 'http://localhost:3000',
    DF_DEV: 'http://localhost:4100',
};

const INTERNAL_EMAIL_DOMAINS = ['makerbot.com', 'ultimaker.com'];

export const isInternalUser = userEmail => {
    const emailDomain = userEmail
        .split('@')
        .pop()
        .toLowerCase();
    return INTERNAL_EMAIL_DOMAINS.includes(emailDomain);
};

const checkHost = domain => {
    if (window.location?.ancestorOrigins?.contains(domain)) {
        return true;
    } else if (window.document.referrer === domain) {
        // for Firefox
        return true;
    }
    return false;
};

const checkDomain = domain => !!(window.location && window.location.hostname && window.location.hostname.match(domain));

export const isDevelopment = () =>
    !window.Cypress && (checkDomain(MAKERBOT_DOMAIN.DEVELOPMENT) || checkHost(MAKERBOT_DOMAIN.MB_DEV));

export const isProduction = () => checkDomain(MAKERBOT_DOMAIN.PRODUCTION);

export const isVercel = () => checkDomain(MAKERBOT_DOMAIN.VERCEL);

export const isLab = () => checkDomain(MAKERBOT_DOMAIN.LAB);

const getParamFromWindowUrl = paramName => {
    const paramsArr = window.location.search.replace(/^\?/, '').split('&');
    const [, value] = paramsArr.map(p => p.split('=')).find(([key]) => key === paramName) ?? [];
    return value;
};

const memoize = fn => {
    let result;
    return () => {
        if (result === undefined) {
            result = fn();
        }
        return result;
    };
};

const isDigitalFactory = memoize(() => window && getParamFromWindowUrl('site') === 'df');

const isElectron = memoize(() => {
    if (window.Cypress) return false;
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    return (
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('Electron') >= 0
    );
});

export const isEmbedded = memoize(() => getQueryStringParameterByName('embedded') === 'true' || isDigitalFactory());

export const isMac = memoize(() => navigator.platform.indexOf('Mac') !== -1);

export const isMacPreference = (selectedPreference, type) => {
    return selectedPreference && MAC_SYSTEM_PREFERENCE[type].findIndex(p => p.value === selectedPreference.value) > -1;
};

export const isHostEquals = (url, host) => {
    if (!url || !host) {
        return false;
    }

    let isEquals = false;
    try {
        isEquals = new URL(url).host === host;
    } catch (parseError) {
        console.log(`URL was not able to be parsed. Got: "${url}"`, parseError);
    }

    return isEquals;
};

export const getAppName = (longVersion = false) => {
    const NAMES = {
        cloudPrint: {
            long: 'MakerBot CloudPrint',
            short: 'CloudPrint',
        },
        pocketPrint: {
            long: 'MakerBot OnPremise (Beta)',
            short: 'MakerBot OnPremise (Beta)',
        },
    };

    const curName = isDesktop() ? NAMES.pocketPrint : NAMES.cloudPrint;

    return longVersion ? curName.long : curName.short;
};

// convert the feature flags to more specific functions, so we can avoid env switches in the business logic
export const isLightTheme = isDigitalFactory;
export const shouldPreferSmallIcons = isDigitalFactory;
export const shouldShowSnackbar = () => !isDigitalFactory();
export const isDesktop = () => window.isElectron || isElectron(); // window.isElectron allows faking electron environment in tests
export const isTeamsEnabled = () => isEmbedded() && !isDigitalFactory() && !isDesktop();
export const supportImmediatePrint = isTeamsEnabled;
export const materialDataFromBay = isDigitalFactory;
export const isNotificationsEnabled = isTeamsEnabled;
export const shouldSendSliceComplete = isDigitalFactory;
export const persistentNotifications = isDigitalFactory;
export const openNewWindowInDigitalFactory = isDigitalFactory;
export const shouldFetchAboutForDigitalFactory = isDigitalFactory;
export const showNotificationsSidebar = () => !isDigitalFactory();
export const showStatusRing = () => !isDigitalFactory();
export const hideLeftBlock = isDesktop;
export const useOfflineArtifacts = isDesktop;
export const shouldCheckLicense = isDesktop;
export const useLocalPrinters = isDesktop;
export const isSmartOrientDisabled = isDesktop;
