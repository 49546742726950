import { getAppName } from '../helpers/utils/environment';

export const NOTIFICATION_TYPE = Object.freeze({
    SHOW_MODELS_DO_NOT_FIT_SNACKBAR: 'SHOW_MODELS_DO_NOT_FIT_SNACKBAR',
    PURGE_TOWER_OVERLAP: 'PURGE_TOWER_OVERLAP',
    MODEL_OVERLAP: 'MODEL_OVERLAP',
    MODEL_OUTSIDE: 'MODEL_OUTSIDE',
    PRINTER_MISSING: 'PRINTER_MISSING',
    UNKNOWN_MATERIAL_TYPE: 'UNKNOWN_MATERIAL_TYPE',
    UNKNOWN_MATERIAL_DISABLED_CONTROLS: 'UNKNOWN_MATERIAL_DISABLED_CONTROLS',
    INVALID_MATERIALS_COMBINATION: 'INVALID_MATERIALS_COMBINATION',
    CUSTOM_MODE_SUCCESSFULLY_CREATED: 'CUSTOM_MODE_SUCCESSFULLY_CREATED',
    CUSTOM_MODE_SUCCESSFULLY_DELETED: 'CUSTOM_MODE_SUCCESSFULLY_DELETED',
    CUSTOM_MODE_CREATION_ERROR: 'CUSTOM_MODE_CREATION_ERROR',
    CUSTOM_MODE_DELETE_FAILED: 'CUSTOM_MODE_DELETE_FAILED',
    IMPORT_CUSTOM_PRINT_MODE: 'IMPORT_CUSTOM_PRINT_MODE',
    IMPORT_CUSTOM_PRINT_MODE_ERROR: 'IMPORT_CUSTOM_PRINT_MODE_ERROR',
    SAVE_CHANGES_CUSTOM_PRINT_MODE: 'SAVE_CHANGES_CUSTOM_PRINT_MODE',
    SAVE_CHANGES_CUSTOM_PRINT_MODE_ERROR: 'SAVE_CHANGES_CUSTOM_PRINT_MODE_ERROR',
    INCOMPLETE_CUSTOM_PRINT_MODE: 'INCOMPLETE_CUSTOM_PRINT_MODE',
    FETCHING_CUSTOM_PRINT_SETTINGS_ERROR: 'FETCHING_CUSTOM_PRINT_SETTINGS_ERROR',
    FILES_DOESNT_CONTAIN_SUPPORTED_MODELS: 'FILES_DOESNT_CONTAIN_SUPPORTED_MODELS',
    FOLDER_DOESNT_CONTAIN_SUPPORTED_MODELS: 'FOLDER_DOESNT_CONTAIN_SUPPORTED_MODELS',
    NULL_MATERIAL_SELECTED: 'NULL_MATERIAL_SELECTED',
    NULL_MATERIAL_SELECTED_LABS_EXTRUDER: 'NULL_MATERIAL_SELECTED_LABS_EXTRUDER',
    NULL_EXTRUDER_SELECTED: 'NULL_EXTRUDER_SELECTED',
    CONVERSION_FAILED: 'CONVERSION_FAILED',
    EXPORTED: 'EXPORTED',
    JOB_QUEUED: 'JOB_QUEUED',
    SLICE_FAILED: 'SLICE_FAILED',
    PREVIEW_READY: 'PREVIEW_READY',
    NEED_TO_SELECT_MODELS: 'NEED_TO_SELECT_MODELS',
    SMART_ORIENT_FAILED: 'SMART_ORIENT_FAILED',
    NEED_TO_CHANGE_MATERIAL_BEFORE_PRINT: 'NEED_TO_CHANGE_MATERIAL_BEFORE_PRINT',
    NEED_TO_CHANGE_EXTRUDER_BEFORE_PRINT: 'NEED_TO_CHANGE_EXTRUDER_BEFORE_PRINT',
    MODELS_VOLUME_UNACCEPTABLE: 'MODELS_VOLUME_UNACCEPTABLE',
    MODELS_BREAK_BUILD_VOLUME: 'MODELS_BREAK_BUILD_VOLUME',
    NO_MODELS_UPLOADED: 'NO_MODELS_UPLOADED',
    ALL_MODELS_ARE_INVISIBLE: 'ALL_MODELS_ARE_INVISIBLE',
    SMART_ORIENT_IN_PROGRESS: 'SMART_ORIENT_IN_PROGRESS',
    SMART_ARRANGE_IN_PROGRESS: 'SMART_ARRANGE_IN_PROGRESS',
    SLICE_JOB_IN_PROGRESS: 'SLICE_JOB_IN_PROGRESS',
    MAKERBOT_PARSING_FAILED: 'MAKERBOT_PARSING_FAILED',
    PURGE_TOWER_CANT_BE_DELETED: 'PURGE_TOWER_CANT_BE_DELETED',
    LEGACY_FILE: 'LEGACY_FILE',
    SUBMODEL_CANT_BE_DELETED: 'SUBMODEL_CANT_BE_DELETED',
});

const appNameShortVersion = getAppName();
//Snackbar messages
export const SNACKBAR_MESSAGE = Object.freeze({
    SNACKBAR_MODEL_COLLISION: 'Model interference detected.',
    SNACKBAR_MODEL_FITS: `Model's outset from rafts is not wholly contained within the build volume.`,
    SNACKBAR_MODEL_OUTSIDE: `This model will not be printed on the build plate.`,
    SNACKBAR_PURGE_TOWER_OVERLAP: 'The model and purge tower may merge during the print.',
    SNACKBAR_PRINTER_MISSING:
        'The printer cannot be found online. Please check that the printer is online and try again.',
    SNACKBAR_UNKNOWN_MATERIAL_TYPE:
        'The material detected is not recognized. Please load MakerBot material to proceed or switch to MakerBot LABS extruder to print with non-MakerBot material.',
    SNACKBAR_UNKNOW_MATERIAL_DISABLED_CONTROLS:
        'An unknown material is detected, please load a MakerBot material and try again.',
    SNACKBAR_NULL_MATERIAL: 'Please exit this view, unload and reload your material, and return to this screen.',
    SNACKBAR_NULL_MATERIAL_LABS_EXTRUDER: 'Please select the material for your LABS Extruder.',
    SNACKBAR_NULL_EXTRUDER: 'Please mount an extruder into your printer and refresh the page.',
    SNACKBAR_INVALID_MATERIALS_COMBINATION:
        'The materials selected are incompatible. You need to select different material to queue or print.',
    SNACKBAR_PRINT_MODE_INCOMPATIBLE_OBJECT: `This .printmode is incompatible with ${appNameShortVersion}. Please create a new one or upload a ${appNameShortVersion} .printmode file.`,
    SNACKBAR_FETCHING_CUSTOM_PRINT_MODE_SETTINGS_ERROR: 'Error fetching custom print mode, please try again.',
    SNACKBAR_PRINT_MODE_SUCCESSFULLY_CREATED: 'Custom print mode has been successfully created.',
    SNACKBAR_ERROR_CREATION_CUSTOM_PRINT_MODE: 'Error creating custom print mode, please try again.',
    SNACKBAR_PRINT_MODE_SUCCESSFULLY_DELETED: 'Custom print mode has been successfully deleted.',
    SNACKBAR_PRINT_MODE_DELETE_FAILED: 'Error deleting custom print mode, please try again.',
    SNACKBAR_IMPORT_PRINT_MODE_SUCCESSFULLY: 'Custom print mode has been successfully imported.',
    SNACKBAR_IMPORT_PRINT_MODE_ERROR: 'Error importing custom print mode, please try again.',
    SNACKBAR_SAVE_CHANGES_PRINT_MODE_SUCCESSFULLY: 'Custom print mode has been successfully changed.',
    SNACKBAR_SAVE_CHANGES_PRINT_MODE_ERROR: 'Error saving changes, please try again.',
    SNACKBAR_FILES_DOESNT_CONTAIN_SUPPORTED_MODELS:
        'Please manually select all supported files you currently wish to import.',
    SNACKBAR_FOLDER_DOESNT_CONTAIN_SUPPORTED_MODELS: 'Folder doesn’t contain any supported models.',
    SNACKBAR_CONVERSION_FAILED: 'Conversion failed. Please try again.',
    SNACKBAR_PREVIEW_IS_READY: 'Print preview is ready.',
    SNACKBAR_JOB_QUEUED_FROM_PREVIEW: "Your print job was added to the printer's queue",
    SNACKBAR_JOB_QUEUED: '{jobName} has been added to the queue of {printerName}.',
    SNACKBAR_JOB_PRINTED: '{jobName} will start printing on {printerName}.',
    SNACKBAR_SMART_ORIENT_FAILED: 'Smart orient failed. Please try again.',
    SNACKBAR_NEED_TO_SELECT_MODEL: `You'll need to select a model to access this feature.`,
    SNACKBAR_NEED_TO_CHANGE_MATERIAL_BEFORE_PRINT:
        'This material is not currently loaded in the printer. You can queue this print but will need to change material before starting the print.',
    SNACKBAR_NEED_TO_CHANGE_EXTRUDER_BEFORE_PRINT:
        'This extruder is not currently loaded in the printer. You can queue this print but will need to change the extruder before starting the print.',
    SNACKBAR_MODELS_VOLUME_UNACCEPTABLE: `Model's size is too small to print`,
    SNACKBAR_MODEL_BREAKS_BUILD_VOLUME: 'Model outside build area. Move, scale, or rotate the model to make it fit.',
    SNACKBAR_MODEL_SLICE_FAILED: 'Slice has failed. You can try again.',
    SNACKBAR_MAKERBOT_PARSING_FAILED: 'Failed to open makerbot file.',
    SNACKBAR_NO_MODELS_UPLOADED: 'Please add a model to the build plate.',
    SNACKBAR_ALL_MODELS_ARE_INVISIBLE: 'Please add a visible model to the build plate.',
    SNACKBAR_SMART_ORIENT_IN_PROGRESS:
        'Smart Orient is in progress. You need to wait for Smart Orient to complete to queue or print.',
    SNACKBAR_SMART_ARRANGE_IN_PROGRESS:
        'Smart Arrange is in progress. You need to wait for Smart Arrange to complete to queue or print.',
    SNACKBAR_SLICE_JOB_IN_PROGRESS:
        'A slice is currently in progress. You need to wait for the slice to complete to queue or print.',
    SNACKBAR_EXPORT_COMPLETED: 'Your file exported successfully.',
    SNACKBAR_PURGE_TOWER_CANT_BE_DELETED:
        'The purge tower cannot be removed using “Delete” key. If you need to disable it, go to printer settings',
    SNACKBAR_LEGACY_FILE:
        'The file you are trying to open uses an outdated version of GLTF/GLB which is not supported by cloud print anymore. Try exporting the file with a more recent GLTF/GLB version or use a different format.',
    SNACKBAR_SUBMODEL_CANT_BE_DELETED:
        'Parts in an assembly cannot be deleted. Only the entire assembly model can be deleted. Select the top level of the model to use this feature',
});
