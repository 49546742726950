export const FETCH_UPLOAD_URLS_START = 'FETCH_UPLOAD_URLS_START';
export const FETCH_UPLOAD_URLS_SUCCESS = 'FETCH_UPLOAD_URLS_SUCCESS';
export const FETCH_UPLOAD_URLS_FAIL = 'FETCH_UPLOAD_URLS_FAIL';
export const UPLOAD_TO_BUCKET_START = 'UPLOAD_TO_BUCKET_START';
export const UPLOAD_TO_BUCKET_SUCCESS = 'UPLOAD_TO_BUCKET_SUCCESS';
export const UPLOAD_TO_BUCKET_FAIL = 'UPLOAD_TO_BUCKET_FAIL';
export const RUN_ZIP_JOB_START = 'RUN_ZIP_JOB_START';
export const RUN_ZIP_JOB_SUCCESS = 'RUN_ZIP_JOB_SUCCESS';
export const RUN_ZIP_JOB_FAIL = 'RUN_ZIP_JOB_FAIL';
export const STOP_UPDATE_ZIP_PROGRESS_JOB = 'STOP_UPDATE_ZIP_PROGRESS_JOB';
export const UPDATE_ZIP_PROGRESS_JOB = 'UPDATE_ZIP_PROGRESS_JOB';
export const FETCH_DOWNLOAD_URLS_START = 'FETCH_DOWNLOAD_URLS_START';
export const FETCH_DOWNLOAD_URLS_SUCCESS = 'FETCH_DOWNLOAD_URLS_SUCCESS';
export const FETCH_DOWNLOAD_URLS_FAIL = 'FETCH_DOWNLOAD_URLS_FAIL';
// Get spools info for 6th gen bot type
export const GET_SPOOLS_INFO_START = 'GET_SPOOLS_INFO_START';
export const GET_SPOOLS_INFO_SUCCESS = 'GET_SPOOLS_INFO_SUCCESS';
export const GET_SPOOLS_INFO_FAILED = 'GET_SPOOLS_INFO_FAIL';

export const PREVIEW_SET_THING_AVAILABLE = 'PREVIEW_SET_THING_AVAILABLE';
export const PREVIEW_SET_MAKERBOT_AVAILABLE = 'PREVIEW_SET_MAKERBOT_AVAILABLE';
export const MODELS_SET_CHUNKS_READY = 'MODELS_SET_CHUNKS_READY';
export const PREVIEW_SET_PRINT_PREVIEW_ENABLED = 'PREVIEW_SET_PRINT_PREVIEW_ENABLED';
export const PREVIEW_SET_PREVIEW_MODE = 'PREVIEW_SET_PREVIEW_MODE';
export const PREVIEW_SET_MAX_LAYER = 'PREVIEW_SET_MAX_LAYER';
export const PREVIEW_SET_MAX_MOVE = 'PREVIEW_SET_MAX_MOVE';
export const PREVIEW_SET_CURRENT_MOVE = 'PREVIEW_SET_CURRENT_MOVE';
export const PREVIEW_SET_CURRENT_LAYER = 'PREVIEW_SET_CURRENT_LAYER';
export const PREVIEW_SET_CURRENT_LAYER_INFO = 'PREVIEW_SET_CURRENT_LAYER_INFO';
export const PREVIEW_SET_MOVES = 'PREVIEW_SET_MOVES';
export const PREVIEW_SET_MODEL_VISIBILITY = 'PREVIEW_SET_MODEL_VISIBILITY';
export const PREVIEW_SET_TRAVEL_MOVES_VISIBILITY = 'PREVIEW_SET_TRAVEL_MOVES_VISIBILITY';
export const PREVIEW_SET_RETRACT_VISIBILTY = 'PREVIEW_SET_RETRACT_VISIBILTY';
export const PREVIEW_SET_RESTART_VISIBILTY = 'PREVIEW_SET_RESTART_VISIBILTY';
export const PREVIEW_SET_PT_VISIBILITY = 'PREVIEW_SET_PT_VISIBILITY';
export const PREVIEW_SET_RAFT_VISIBILITY = 'PREVIEW_SET_RAFT_VISIBILITY';
export const PREVIEW_SET_BRIM_VISIBILITY = 'PREVIEW_SET_BRIM_VISIBILITY';
export const PREVIEW_SET_TOOLPATH_VISIBILITY = 'PREVIEW_SET_TOOLPATH_VISIBILITY';
export const PREVIEW_SET_SHOW_LAYER_DETAILS = 'PREVIEW_SET_SHOW_LAYER_DETAILS';
export const PREVIEW_SET_IS_VOLUMETRIC_ANIMATION_PLAYING = 'PREVIEW_SET_IS_VOLUMETRIC_ANIMATION_PLAYING';

// Handle Model preview
export const HANDLE_MODEL_PREVIEW_START = 'HANDLE_MODEL_PREVIEW_START';
export const HANDLE_MODEL_PREVIEW_SUCCESS = 'HANDLE_MODEL_PREVIEW_SUCCESS';
export const HANDLE_MODEL_PREVIEW_FAIL = 'HANDLE_MODEL_PREVIEW_FAIL';

//Fetching Profile
export const FETCH_PROFILE_START = 'FETCH_PROFILE_START';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAIL = 'FETCH_PROFILE_FAIL';

// Upload Thumbnails To Cloud Slicer:
export const UPLOAD_THUMBNAIL_TO_SLICER_START = 'UPLOAD_THUMBNAIL_TO_SLICER_START';
export const UPLOAD_THUMBNAIL_TO_SLICER_SUCCESS = 'UPLOAD_THUMBNAIL_TO_SLICER_SUCCESS';
export const UPLOAD_THUMBNAIL_TO_SLICER_FAIL = 'UPLOAD_THUMBNAIL_TO_SLICER_FAIL';

// Send Job to Cloud Slicer:
export const SEND_JOB_TO_SLICER_START = 'SEND_JOB_TO_SLICER_START';
export const SEND_JOB_TO_SLICER_SUCCESS = 'SEND_JOB_TO_SLICER_SUCCESS';
export const SEND_JOB_TO_SLICER_FAIL = 'SEND_JOB_TO_SLICER_FAIL';

// Send Job to Cloud Slicer / BasicSlice:
export const TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_START = 'TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_START';
export const TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_SUCCESS = 'TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_SUCCESS';
export const TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_FAIL = 'TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_FAIL';

// Track Update Progress from Slicer
export const TRACK_PROGRESS_FROM_SLICER_START = 'TRACK_PROGRESS_FROM_SLICER_START';
export const TRACK_PROGRESS_FROM_SLICER_SUCCESS = 'TRACK_PROGRESS_FROM_SLICER_SUCCESS';
export const TRACK_PROGRESS_FROM_SLICER_FAIL = 'TRACK_PROGRESS_FROM_SLICER_FAIL';
export const STOP_UPDATE_POLLING_FROM_SLICER = 'STOP_UPDATE_POLLING_FROM_SLICER';
export const STOP_UPDATE_POLLING_FROM_SLICER_BASIC_SLICE = 'STOP_UPDATE_POLLING_FROM_SLICER_BASIC_SLICE';
export const UPDATE_PROGRESS_FROM_SLICER = 'UPDATE_PROGRESS_FROM_SLICER';
export const CANCEL_SLICE_JOB = 'CANCEL_SLICE_JOB';
export const CANCEL_SLICE_JOB_SUCCESS = 'CANCEL_SLICE_JOB_SUCCESS';
export const CANCEL_SLICE_JOB_FAIL = 'CANCEL_SLICE_JOB_FAIL';

export const TRACK_JOB_STATUS_FROM_TEAMS = 'TRACK_JOB_STATUS_FROM_TEAMS';
export const TRACK_FAIL_JOB_STATUS_FROM_TEAMS = 'TRACK_FAIL_JOB_STATUS_FROM_TEAMS';
export const TRACK_RESTART_SLICE_JOB_FROM_TEAMS = 'TRACK_RESTART_SLICE_JOB_FROM_TEAMS';

//Download a sliced file
export const DOWNLOAD_SLICE_FILE_START = 'DOWNLOAD_SLICE_FILE_START';
export const DOWNLOAD_SLICE_FILE_SUCCESS = 'DOWNLOAD_SLICE_FILE_SUCCESS';
export const DOWNLOAD_SLICE_FILE_FAIL = 'DOWNLOAD_SLICE_FILE_FAIL';

export const DOWNLOAD_FILE_FROM_SIGNED_START = 'DOWNLOAD_FILE_FROM_SIGNED_START';
export const DOWNLOAD_FILE_FROM_SIGNED_SUCCESS = 'DOWNLOAD_FILE_FROM_SIGNED_SUCCESS';
export const DOWNLOAD_FILE_FROM_SIGNED_FAIL = 'DOWNLOAD_FILE_FROM_SIGNED_FAIL';

// Upload files to storage
export const UPLOAD_FILE_TO_STORAGE_START = 'UPLOAD_FILE_TO_STORAGE_START';
export const UPLOAD_FILE_TO_STORAGE_SUCCESS = 'UPLOAD_FILE_TO_STORAGE_SUCCESS';
export const UPLOAD_FILE_TO_STORAGE_FAIL = 'UPLOAD_FILE_TO_STORAGE_FAIL';

export const PRINTER_SET_JOB_FILENAME = 'PRINTER_SET_JOB_FILENAME';

export const TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START = 'TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START';
export const TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS = 'TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS';
export const TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_FAIL = 'TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_FAIL';
export const STOP_UPDATE_POLLING_FROM_PREVIEW_GENERATOR = 'STOP_UPDATE_POLLING_FROM_PREVIEW_GENERATOR';
export const UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR = 'UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR';
export const FETCH_RECONSTRUCTED_MODEL_SUCCESS = 'FETCH_RECONSTRUCTED_MODEL_SUCCESS';
export const FETCH_RECONSTRUCTED_MODEL_FAIL = 'FETCH_RECONSTRUCTED_MODEL_FAIL';
export const RESET_RECONSTRUCTED_MODEL = 'RESET_RECONSTRUCTED_MODEL';
export const UPDATE_MAKERBOT_FILE_LAST_PREVIEW_LINK = 'UPDATE_MAKERBOT_FILE_LAST_PREVIEW_LINK';
export const FETCH_TOOLPATH_START = 'FETCH_TOOLPATH_START';
export const FETCH_TOOLPATH_SUCCESS = 'FETCH_TOOLPATH_SUCCESS';
export const FETCH_TOOLPATH_FAIL = 'FETCH_TOOLPATH_FAIL';
export const SET_SORTED_MODEL = 'SET_SORTED_MODEL';

export const INIT_SLICE_JOB = 'INIT_SLICE_JOB';
export const UPDATE_SLICE_JOB = 'UPDATE_SLICE_JOB';
export const UPDATE_JOB_FILE_NAME = 'UPDATE_JOB_FILE_NAME';
export const RESET_CURRENT_SLICE_JOB_ID = 'RESET_CURRENT_SLICE_JOB_ID';

export const ADD_OPERATING_JOB = 'ADD_OPERATING_JOB';
export const UPDATE_OPERATING_JOB = 'UPDATE_OPERATING_JOB';
export const DELETE_OPERATING_JOB = 'DELETE_OPERATING_JOB';

export const FETCH_BASIC_SLICE_START = 'FETCH_BASIC_SLICE_START';
export const FETCH_BASIC_SLICE_SUCCESS = 'FETCH_BASIC_SLICE_SUCCESS';
export const FETCH_BASIC_SLICE_FAIL = 'FETCH_BASIC_SLICE_FAIL';

export const SET_SORTED_CURRENT_TOOLPATH = 'SET_SORTED_CURRENT_TOOLPATH';

export const PREVIEW_SET_COMPARISON_MODE_ENABLED = 'PREVIEW_SET_COMPARISON_MODE_ENABLED';
export const PREVIEW_SET_COMPARISON_MODELS_LOADED = 'PREVIEW_SET_COMPARISON_MODELS_LOADED';
export const PREVIEW_SET_COMPARISON_MODELS = 'PREVIEW_SET_COMPARISON_MODELS';
export const SET_PREVIEW_MODELS = 'SET_PREVIEW_MODELS';
export const RESET_ALL_TOOLPATH_VISIBILITY = 'RESET_ALL_TOOLPATH_VISIBILITY';
export const RESET_ALL_CHUNKS = 'RESET_ALL_CHUNKS';

export const SET_THUMBNAILS_LINK = 'SET_THUMBNAILS_LINK';
export const SET_PREVIEW_ID_FOR_PREVIEW_MODEL = 'SET_PREVIEW_ID_FOR_PREVIEW_MODEL';
export const RESET_CURRENT_FAILED_SLICE_JOB = 'RESET_CURRENT_FAILED_SLICE_JOB';
export const SET_JOB_TO_RESTART = 'SET_JOB_TO_RESTART';
export const RESET_JOB_TO_RESTART = 'RESET_JOB_TO_RESTART';
export const SET_DEFAULT_MODELS_PREVIEW = 'SET_DEFAULT_MODELS_PREVIEW';
export const SET_HELD_SLICE_PAYLOAD = 'SET_HELD_SLICE_PAYLOAD';
