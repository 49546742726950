import { SET_NOTIFICATION } from './types';

const notificationReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_NOTIFICATION: {
            return Object.assign({}, state, {
                [action.payload.notificationType]: action.payload.show,
            });
        }

        default:
            return state;
    }
};

export default notificationReducer;
