import ToolpathCompressor from "./ToolpathCompressor";

const COMMANDS_SPLITER = '"command" : ';

// ToolpathDecoder is used to parse chunks of large json.toolpath data
// ajax returns an empty response meanwhile fetch cannot parse string into JSON
class ToolpathDecoder {
    constructor(reader) {
        this.decoder = new TextDecoder();
        this.compressor = new ToolpathCompressor();
        this.reader = reader;
        this.commands = [];
        // leftover data from previous chunk
        this.leftOverChunkData = '';
    }

    async getChunk() {
        const { value } = await this.reader.read();
        if (!value) {
            return;
        }

        const chunk = this.decoder.decode(value, { stream: true });
        return chunk;
    }

    // Removes unnecessary characters from chunk, so a command can be parsed into JSON
    removeRedundantCharacters(chunk) {
        return chunk.replaceAll(/(\n|\[{|},{)/g, '');
    }

    // removes end of file characters '}]'
    getLastCommand() {
        return this.leftOverChunkData.slice(0, -2);
    }

    parseCommand(command) {
        try {
            const parsedCommand = JSON.parse(command);
            return parsedCommand;
        } catch (err) {
            // if a command cannot be parsed, it means that it's just a part of it
            // and other part of data is in the next chunk
            this.leftOverChunkData = command;
        }
    }

    addCommand(command) {
        try {
            const parsedCommand = this.parseCommand(command);
            if (!parsedCommand) {
                return;
            }

            const compressedCommand = this.compressor.compress(parsedCommand);
            this.commands.push(compressedCommand);
        } catch (err) {
            console.error("Failed to add command: ", err);
        }
    }

    getCommandsFromChunk(chunk) {
        const preparedChunk = this.leftOverChunkData.concat(this.removeRedundantCharacters(chunk));
        const possibleCommands = preparedChunk.split(COMMANDS_SPLITER);
        this.leftOverChunkData = '';
        return possibleCommands;
    }

    async decode() {
        while(true) {
            const chunk = await this.getChunk();
            if (!chunk) {
                break;
            }

            const possibleCommands = this.getCommandsFromChunk(chunk);
            for (const command of possibleCommands) {
                this.addCommand(command);
            }
        }

        this.addCommand(this.getLastCommand());
        return this.commands;
    }
}

export default ToolpathDecoder;