import {
    BufferAttribute,
    BufferGeometry,
    DoubleSide,
    ExtrudeGeometry,
    Line,
    LineBasicMaterial,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Plane,
    Quaternion,
    Shape,
    Vector3,
} from 'three';
import * as ConvexHull from 'convex-hull';

import ThreeInstance from '../ThreeInstance';
import {
    isPurgeTower,
    filterOutPurgeTower,
    getAllSubmodelsRecursive,
    getChildrenOrAssembledModels,
    getParentModel,
    filterOutEmptyFolder,
} from './viewer';
import { DEFAULT_CONVEX_HULL_Z, WARNING_CONVEX_HULL_EXTRUDE_SETTINGS } from '../../consts';
import { getPositionFromSettings } from './model-settings';

const SKIP_POINTS_COEFFICIENT = 1000000;

const getMatrixToProjectPoints = modelMesh => {
    const quaternion = new Quaternion();
    const scale = new Vector3();
    modelMesh.updateMatrixWorld();
    const matrix4 = modelMesh.matrixWorld.clone();
    matrix4.decompose(new Vector3(), quaternion, scale);
    const threeTransform = new Matrix4().compose(new Vector3(), quaternion, scale);

    return threeTransform;
};

export const calculateProjectionPointsForGeometry = (geometry, transformMatrix = new Matrix4()) => {
    const pointsArray = geometry.attributes.position.array;
    // We step through pointsArray by threes because the array is structured
    // such that every group of three array elements represents a point.
    // It's like: [x1, y1, z1, x2, y2, z2...]
    const points = [];
    const point = new Vector3();
    // The coefficient allows skipping some points to calculate the convex hull of large models.
    // We do not need to take all the points to calculate the convex hull,
    // especially when the concentration of points is large
    const skipIndex = Math.round(pointsArray.length / SKIP_POINTS_COEFFICIENT) || 1;

    for (let i = 0; i < pointsArray.length; i += 3 * skipIndex) {
        point.set(pointsArray[i], pointsArray[i + 1], pointsArray[i + 2]);
        point.applyMatrix4(transformMatrix);
        points.push([point.x, point.y]);
    }

    return points;
};

// Projection points are the points of the model, projected downward onto the build plate.
// Put another way, they're a representation of the model as if it were "squashed flat".
const calculateProjectionPointsForMesh = (modelMesh, parentMatrix = null) => {
    if (isPurgeTower(modelMesh) || !modelMesh.geometry) return [];

    const threeTransform = parentMatrix ? parentMatrix.clone() : getMatrixToProjectPoints(modelMesh);
    return calculateProjectionPointsForGeometry(modelMesh.geometry, threeTransform);
};

export const getConvexHullPointsFromProjectionPoints = projectionPoints => {
    const convexHull = ConvexHull(projectionPoints);
    const pointsArray = [];
    /* The ConvexHull algorithm returns a series of lines, or edges. The edges are defined
     * by two endpoints. Each of these endpoints is expressed, not as points, but as indices
     * that refer to the location of the point in the original points array.
     * Why does the algorithm not just do that one extra step and give us the points straight-up?
     * Because life isn't fair. :(
     */
    convexHull.forEach(edge => {
        // We're only looking at one point at a time, so we only need one endpoint of the edge.
        const pointIndex = edge[0];
        // Remember, the algorithm returns an index. To get the actual point, we have to look it up
        // in the array that we originally handed to the algorithm.
        const point = projectionPoints[pointIndex];
        pointsArray.push(point[0], point[1], 0);
    });
    // To close the loop
    pointsArray.push(pointsArray[0], pointsArray[1], 0);

    return pointsArray;
};

const getConvexHullPointsArrayFromMesh = mesh => {
    const getProjectionPoints = meshOrMeshes => {
        if (meshOrMeshes.isGroup && meshOrMeshes.children.length > 0) {
            const filteredChildren = meshOrMeshes.children.filter(child => {
                if (child.isGroup) {
                    return child.children.some(({ visible }) => visible);
                } else {
                    return child.visible;
                }
            });

            const allProjectionPoints = [];
            filteredChildren.forEach(m => {
                if (m.userData.convexHull) {
                    const position = new Vector3();
                    if (!m.isGroup) {
                        m.getWorldPosition(position);
                    }
                    const matrix = new Matrix4();
                    matrix.makeTranslation(position.x, position.y, 0);
                    allProjectionPoints.push(calculateProjectionPointsForMesh(m.userData.convexHull, matrix));
                } else {
                    allProjectionPoints.push(calculateProjectionPointsForMesh(m));
                }
            });
            return allProjectionPoints.flat();
        } else {
            return calculateProjectionPointsForMesh(meshOrMeshes);
        }
    };

    const projectionPoints = getProjectionPoints(mesh);
    if (!projectionPoints) return;

    const convexHullPoints = getConvexHullPointsFromProjectionPoints(projectionPoints);
    const simplifiedConvexHullPoints = simplifyConvexHull(convexHullPoints);

    return simplifiedConvexHullPoints;
};

const makeConvexHullForModel = (convexHullPoints, mesh) => {
    const typedArray = Float32Array.from(convexHullPoints);
    const hullGeometry = new BufferGeometry();
    hullGeometry.setAttribute('position', new BufferAttribute(typedArray, 3));
    const material = new LineBasicMaterial({ color: 0x33aadd });
    const convexHullMesh = new Line(hullGeometry, material);
    convexHullMesh.translateZ(DEFAULT_CONVEX_HULL_Z);
    convexHullMesh.name = `ConvexHull-${mesh.name}`;

    return convexHullMesh;
};

export const createShapeGeometryFromConvexPoints = convexPoints => {
    const shape = new Shape();
    shape.moveTo(convexPoints[0], convexPoints[1]);

    for (let i = 3; i < convexPoints.length; i += 3) {
        shape.lineTo(convexPoints[i], convexPoints[i + 1]);
    }

    const geometry = new ExtrudeGeometry(shape, WARNING_CONVEX_HULL_EXTRUDE_SETTINGS);
    return geometry;
};

// convexPoints: [x1, y1, z1, x2, y2, z2, ...]
const makeOutsideVolumeConvexHullForModel = (convexHullPoints, mesh, buildVolumeClippingPlanes) => {
    const geometry = createShapeGeometryFromConvexPoints(convexHullPoints);

    const material = new MeshBasicMaterial({
        color: 0xfca833,
        side: DoubleSide,
        clippingPlanes: buildVolumeClippingPlanes,
        clipIntersection: true,
    });

    const outsideVolumeConvexHullMesh = new Mesh(geometry, material);
    outsideVolumeConvexHullMesh.translateZ(DEFAULT_CONVEX_HULL_Z);
    outsideVolumeConvexHullMesh.name = `OutsideVolumeConvexHullMesh-${mesh.name}`;
    return outsideVolumeConvexHullMesh;
};

const getModelPositionForConvex = model => {
    const mesh = model.instance();
    const position = new Vector3();
    mesh.getWorldPosition(position);

    if (mesh.isGroup) {
        const settingPos = getPositionFromSettings(model.settings);
        settingPos.applyMatrix4(mesh.parent.matrixWorld);

        position.sub(settingPos);
    }

    return position;
};

export const createClippingPlanesFromBuildPlate = (buildVolume, expandAxisX = 0) => {
    const clippingPlanes = [
        new Plane(new Vector3(1, 0, 0), -(buildVolume.x / 2)),
        new Plane(new Vector3(0, 1, 0), -(buildVolume.y / 2)),
        new Plane(new Vector3(-1, 0, 0), -(buildVolume.x / 2 - expandAxisX)),
        new Plane(new Vector3(0, -1, 0), -(buildVolume.y / 2)),
    ];

    return clippingPlanes;
};

const createPlanesFromConvexPoints = (convexPoints, position) => {
    const planes = [];

    for (let i = 0; i < convexPoints.length; i += 3) {
        const isLast = i === convexPoints.length - 3;

        const x1 = convexPoints[i];
        const y1 = convexPoints[i + 1];
        const x2 = isLast ? convexPoints[0] : convexPoints[i + 3];
        const y2 = isLast ? convexPoints[1] : convexPoints[i + 4];

        const point1 = new Vector3(x1 + position.x, y1 + position.y, 0);
        const point2 = new Vector3(x2 + position.x, y2 + position.y, 0);
        const point3 = new Vector3(x1 + position.x, y1 + position.y, 10);

        const plane = new Plane().setFromCoplanarPoints(point1, point2, point3);
        planes.push(plane);
    }

    return planes;
};

const makeCollisionConvexHullForModel = (geometry, planes, model) => {
    const position = getModelPositionForConvex(model);
    const material = new MeshBasicMaterial({
        color: 0xfca833,
        side: DoubleSide,
        clippingPlanes: planes,
    });

    const collisionConvexHullMesh = new Mesh(geometry, material);
    collisionConvexHullMesh.position.set(position.x, position.y, DEFAULT_CONVEX_HULL_Z);
    collisionConvexHullMesh.updateMatrixWorld(true);
    collisionConvexHullMesh.name = `CollisionConvexHull-${model.name}`;
    return collisionConvexHullMesh;
};

export const addConvexHullsToModel = (model, buildVolumeClippingPlanes, calculatedHullPoints = null) => {
    const mesh = model.instance();
    if (model.isEmptyFolder) return;
    if (model.isNewFolder) {
        buildVolumeClippingPlanes = getFirstClippingPlanes(model);
    }
    const convexHullPoints = calculatedHullPoints ?? getConvexHullPointsArrayFromMesh(mesh);

    const convexHull = makeConvexHullForModel(convexHullPoints, mesh);
    const outsideVolumeConvexHull = makeOutsideVolumeConvexHullForModel(
        convexHullPoints,
        mesh,
        buildVolumeClippingPlanes
    );

    mesh.userData.convexHull = convexHull;
    mesh.userData.outsideVolumeConvexHull = outsideVolumeConvexHull;
    mesh.userData.convexHullPoints = convexHullPoints;
    ThreeInstance.scene.add(convexHull);
    ThreeInstance.scene.add(outsideVolumeConvexHull);
};

const getFirstClippingPlanes = model => {
    const getClippingPlane = ({ instance }) => instance().userData?.outsideVolumeConvexHull?.material?.clippingPlanes;
    const modelWithClippingPlane = getAllSubmodelsRecursive(model).find(getClippingPlane);
    if (!modelWithClippingPlane) return null;
    return getClippingPlane(modelWithClippingPlane);
};

const addCollisionConvexHullsToModel = (model, allModels) => {
    const mesh = model.instance();
    mesh.userData.collisionConvexHulls = [];

    allModels.forEach(m => {
        const position = getModelPositionForConvex(m);
        const planes = createPlanesFromConvexPoints(m.instance().userData.convexHullPoints, position);
        const collisionConvexHull = makeCollisionConvexHullForModel(
            mesh.userData.outsideVolumeConvexHull.geometry.clone(),
            planes,
            model
        );
        mesh.userData.collisionConvexHulls.push(collisionConvexHull);
        ThreeInstance.scene.add(collisionConvexHull);
    });
};

const removeCollisionConvexHulls = model => {
    const mesh = model.instance();

    if (mesh.userData.collisionConvexHulls) {
        mesh.userData.collisionConvexHulls.forEach(convexHull => {
            convexHull.geometry.dispose();
            convexHull.material.dispose();
            ThreeInstance.scene.remove(convexHull);
        });
        mesh.userData.collisionConvexHulls = null;
    }
};

const updateConvexHullForModelAndChildren = model => {
    if (model.isEmptyFolder) return;
    const mesh = model.instance();
    if (mesh.isGroup && mesh.children.length > 0) {
        model.submodels.forEach(submodel => {
            updateConvexHullForModelAndChildren(submodel);
        });
    }
    updateConvexHullForModel(model);
};

const updateConvexHullForParents = model => {
    if (model.isEmptyFolder) return;

    if (!model.parent?.instance().isGroup) return;
    updateConvexHullForModel(model.parent);

    if (model.parent?.parent?.instance().isGroup) {
        updateConvexHullForParents(model.parent);
    }
};

const updateConvexHullForModel = model => {
    const mesh = model.instance ? model.instance() : model;
    const prevVisibility = mesh.userData.convexHullVisible ?? true;
    let clippingPlanes = null;

    if (mesh.userData?.convexHull) {
        ThreeInstance.scene.remove(mesh.userData.convexHull);
        mesh.userData.convexHull = null;
    }
    if (mesh.userData?.outsideVolumeConvexHull) {
        clippingPlanes = mesh.userData.outsideVolumeConvexHull.material.clippingPlanes;
        ThreeInstance.scene.remove(mesh.userData.outsideVolumeConvexHull);
        mesh.userData.outsideVolumeConvexHull = null;
    }

    addConvexHullsToModel(model, clippingPlanes);
    mesh.userData.convexHull.visible = prevVisibility;
    mesh.userData.outsideVolumeConvexHull.visible = prevVisibility;
};

export const updateConvexHullBuildPlateBorders = (model, buildVolume, expandAxisX = 0) => {
    const mesh = model.instance();
    if (mesh.isGroup && mesh.children.length > 0) {
        model.submodels.forEach(submodel => {
            updateConvexHullBuildPlateBorders(submodel, buildVolume, expandAxisX);
        });
    }

    if (!mesh.userData?.outsideVolumeConvexHull) {
        return;
    }

    const clippingPlanes = createClippingPlanesFromBuildPlate(buildVolume, expandAxisX);
    mesh.userData.outsideVolumeConvexHull.material.clippingPlanes = clippingPlanes;
};

export const deleteConvexHullsForModelAndChildren = model => {
    const instance = model.instance();
    if (instance.isGroup && instance.children.length > 0) {
        model.submodels.forEach(submodel => {
            deleteConvexHullsForModelAndChildren(submodel);
        });
    }
    if (instance.userData?.convexHull) {
        ThreeInstance.scene.remove(instance.userData.convexHull);
        instance.userData.convexHull = null;
    }
    if (instance.userData?.outsideVolumeConvexHull) {
        ThreeInstance.scene.remove(instance.userData.outsideVolumeConvexHull);
        instance.userData.outsideVolumeConvexHull = null;
    }
};

export const toggleConvexHullVisibility = (model, isVisible) => {
    const mesh = model.instance();
    if (!mesh.userData.convexHull) return;

    mesh.userData.convexHull.visible = isVisible;
    mesh.userData.outsideVolumeConvexHull.visible = isVisible;
    mesh.userData.convexHullVisible = isVisible;

    if (mesh.userData.collisionConvexHulls) {
        mesh.userData.collisionConvexHulls.forEach(convexHull => {
            convexHull.visible = isVisible;
        });
    }
};

export const setConvexHullVisibility = (models, activeModels, isVisible) => {
    filterOutPurgeTower(models)
        .map(getAllSubmodelsRecursive)
        .flat()
        .forEach(model => {
            toggleConvexHullVisibility(model, false);
        });

    if (!isVisible) return;

    filterOutPurgeTower(activeModels).forEach(model => {
        toggleConvexHullVisibility(model, isVisible);
    });
};

const isOnlyPurgeTowerActive = activeModels => {
    return activeModels.length === 1 && isPurgeTower(activeModels[0]);
};

const makeNewHullsForModelParentsAndChildren = model => {
    updateConvexHullForModelAndChildren(model);
    updateConvexHullForParents(model);
};

export const updateConvexHullPosition = (model, shouldUpdateParent = true) => {
    if (model.isEmptyFolder) return;

    const position = getModelPositionForConvex(model);
    const convexHullPos = new Vector3(position.x, position.y, DEFAULT_CONVEX_HULL_Z);
    const mesh = model.instance();

    mesh.userData.convexHull.position.copy(convexHullPos);
    mesh.userData.convexHull.updateMatrixWorld(true);
    mesh.userData.outsideVolumeConvexHull.position.copy(convexHullPos);
    mesh.userData.outsideVolumeConvexHull.updateMatrixWorld(true);

    if (mesh.userData.collisionConvexHulls) {
        mesh.userData.collisionConvexHulls.forEach(convexHull => {
            convexHull.position.copy(convexHullPos);
            convexHull.updateMatrixWorld(true);
        });
    }

    // Once, a submodel position changes, the convex hull shape of an assembly (group) also changes
    if (shouldUpdateParent) updateConvexHullForParents(model);
};

/**
 * Recalculates convex hull for models.
 * Use it only when a projection of the model into the build plate changes (scale, rotation, etc.).
 * Also, ensure that the convex hull recalculates only once per model to save app performance.
 * Use updateConvexHullPosition when you need just to change the position of the convex hull.
 */
export const updateConvexHull = activeModels => {
    if (!Array.isArray(activeModels)) return;
    if (!activeModels.length || isOnlyPurgeTowerActive(activeModels)) return;

    const filteredModels = filterOutPurgeTower(activeModels).filter(filterOutEmptyFolder);
    filteredModels.forEach(model => {
        makeNewHullsForModelParentsAndChildren(model);
        const activeInstances = getAllSubmodelsRecursive(model);
        activeInstances.forEach(updateConvexHullPosition);
        if (activeInstances.length === 0) return;
        updateConvexHullForParents(activeInstances[activeInstances.length - 1]);
    });

    ThreeInstance.renderScene();
};

export const updateCollisionConvexHulls = (activeModels, models) => {
    const filteredModels = filterOutPurgeTower(models);
    filteredModels
        .map(getAllSubmodelsRecursive)
        .flat()
        .forEach(model => {
            removeCollisionConvexHulls(model);
        });
    const allModels = filteredModels.map(getChildrenOrAssembledModels).flat();

    if (allModels.length < 1) {
        return;
    }

    const filterChildrenModels = (child, model) => {
        if (child.name === model.name || !child.shown || child.isEmptyFolder) {
            return false;
        }

        if (child.getParentName()) {
            if (child.assembleCheckbox === true) return false;
            const parent = getParentModel(child, filteredModels);
            return filterChildrenModels(parent, model);
        }

        return true;
    };

    filterOutPurgeTower(activeModels)
        .filter(filterOutEmptyFolder)
        .forEach(model => {
            const filteredChildrenModels = allModels.filter(child => filterChildrenModels(child, model));
            addCollisionConvexHullsToModel(model, filteredChildrenModels);
        });
};

export const updateCollisionConvexHullGeometry = model => {
    const mesh = model.instance();
    if (mesh.userData.collisionConvexHulls) {
        mesh.userData.collisionConvexHulls.forEach(convexHull => {
            convexHull.geometry.copy(mesh.userData.outsideVolumeConvexHull.geometry.clone());
        });
    }
};

// Returns squared distance from a vector to a line
const getSquaredDistanceToLine = (vector, lineVectorA, lineVectorB) => {
    const dx = lineVectorB.x - lineVectorA.x;
    const dy = lineVectorB.y - lineVectorA.y;
    let x = lineVectorA.x;
    let y = lineVectorA.y;

    if (dx !== 0 || dy !== 0) {
        const t = ((vector.x - lineVectorA.x) * dx + (vector.y - lineVectorA.y) * dy) / (dx * dx + dy * dy);

        if (t > 1) {
            x = lineVectorB.x;
            y = lineVectorB.y;
        } else if (t > 0) {
            x += dx * t;
            y += dy * t;
        }
    }

    const finalDx = vector.x - x;
    const finalDy = vector.y - y;

    return finalDx * finalDx + finalDy * finalDy;
};

const douglasPeuckerAlgorithmStep = (vectors, firstIndex, lastIndex, tolerance) => {
    const simplifiedVectors = [];
    let maxDistance = tolerance;
    let index = 0;

    for (let i = firstIndex + 1; i < lastIndex; i++) {
        const distance = getSquaredDistanceToLine(vectors[i], vectors[firstIndex], vectors[lastIndex]);

        if (distance > maxDistance) {
            index = i;
            maxDistance = distance;
        }
    }

    if (maxDistance > tolerance) {
        if (index - firstIndex > 1) {
            simplifiedVectors.push(...douglasPeuckerAlgorithmStep(vectors, firstIndex, index, tolerance));
        }

        simplifiedVectors.push(vectors[index]);

        if (lastIndex - index > 1) {
            simplifiedVectors.push(...douglasPeuckerAlgorithmStep(vectors, index, lastIndex, tolerance));
        }
    }

    return simplifiedVectors;
};

const douglasPeuckerAlgorithm = (vectors, tolerance) => {
    const simplifiedVectors = [];
    const lastIndex = vectors.length - 1;
    const firstIndex = 0;

    simplifiedVectors.push(vectors[firstIndex]);
    simplifiedVectors.push(...douglasPeuckerAlgorithmStep(vectors, firstIndex, lastIndex, tolerance));
    simplifiedVectors.push(vectors[lastIndex]);

    return simplifiedVectors;
};

// Convex hull simplification is done by Ramer–Douglas–Peucker algorithm
export const simplifyConvexHull = (points, tolerance = 0.36) => {
    const vectors = [];
    for (let i = 0; i < points.length; i += 3) {
        vectors.push(new Vector3(points[i], points[i + 1], points[i + 2]));
    }

    const simplifiedVectors = douglasPeuckerAlgorithm(vectors, tolerance);

    const simplifiedPoints = [];
    for (const vector of simplifiedVectors) {
        simplifiedPoints.push(vector.x, vector.y, vector.z);
    }
    return simplifiedPoints;
};
