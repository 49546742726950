import {
    MODELS_SET_MODEL_LOADING_PROGRESS,
    MODELS_SET_MODEL_LOADING,
    MODELS_SET_WRONG_FORMAT,
    MODELS_SET_UNABLE_TO_CONVERT_FILETYPE,
    MODELS_SET_EXCEEDED_FILE_SIZE,
    MODELS_CANCEL_IMPORT,
    MODELS_SET_SHOW_UPLOAD_MODEL_ERROR,
    MODELS_SET_SHOULD_RESET_MODEL_SETTINGS,
    MODELS_SET_SETTINGS_ON_ONE_ACTIVE_MODEL,
    MODELS_SET_ACTIVE_MODELS,
    MODELS_SET_COPIED_MODELS,
    MODELS_SET_MODELS,
    MODELS_DELETE_MODELS,
    MODELS_ADD_PURGE_TOWER,
    MODELS_REMOVE_PURGE_TOWER,
    MODELS_SET_MAKERBOT_DOWNLOAD_IMPOSSIBLE,
    CONVERT_FILE_FAIL,
    CONVERT_FILE_SUCCESS,
    CONVERT_FILE_START,
    SET_CONVERSION_STATUS,
    SET_CONVERTED_MODELS_BLOBS,
    FETCH_CONVERTED_MODELS_START,
    MODELS_ADD_MODELS,
    SET_CONVERT_FILE_FAIL,
    TRACK_CONVERT_FILE_START,
    UPDATE_CONVERT_FILE_PROGRESS,
    STOP_UPDATE_CONVERT_FILE_PROGRESS,
    STOP_CONVERT_FILE,
    STOP_CONVERT_FILE_SUCSESS,
    STOP_CONVERT_FILE_FAIL,
    MODELS_CLEAR_CONVERTER_META,
    MODELS_SET_WRONG_FILES,
    MODELS_START_HANDLING_UPLOAD_JOBS,
    MODELS_STOP_HANDLING_UPLOAD_JOBS,
    MODELS_DO_BROADCAST_UPLOAD_JOB,
    MODELS_DO_BROADCAST_UPLOAD_JOB_ERROR,
    MODELS_DO_RESET_UPLOAD_JOB,
    MODELS_DO_ADD_LOADER_DESCRIPTORS,
    MODELS_DO_RESET_LOADER_DESCRIPTORS,
    MODELS_DO_SET_UPLOAD_JOB_COUNT,
    MODELS_DO_SET_JOB_NAME,
    MODELS_DO_SET_JOB_NAME_FROM_DEFAULT,
    MODELS_FETCH_CAD_FROM_URL,
    MODELS_SET_MODEL_ORIGINAL_NAME,
    MODELS_DO_SET_CONVERT_JOB_COUNT,
    MODELS_DO_UPDATE_CONVERT_JOB_COUNT,
    MODELS_DO_ADD_COMPLETED_UPLOAD_JOB_COUNT,
    MODELS_SET_FILE_TOO_OLD_ERROR,
    MODELS_SET_FILE_TOO_RECENT_ERROR,
    MODELS_DO_UPDATE_DEFAULT_JOB_NAME,
    MODELS_PERMODEL_SET_INSTANCE,
    MODELS_DOWNLOAD_DEMO_FILE_START,
    MODELS_STOP_DOWNLOAD_DEMO_FILE,
    MODELS_DOWNLOAD_DEMO_FILE_FULFILLED,
    MODELS_DOWNLOAD_DEMO_FILE_FAILED,
    SET_HOVERED_MODEL_ID,
    SET_OVERLAP_MODELS_IDS,
    SET_IS_SMART_ARRANGE_HOVERED,
    MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES,
    MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES,
    MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FULFILLED,
    MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FAILED,
    SET_IS_DRAGGING,
    SET_IS_MODEL_HOVERED_FROM_TREE,
    MODELS_UPDATE_SETTINGS,
    MODELS_UPDATE_MODELS,
    SET_IS_DRAG_ROTATION,
    MODELS_SET_IMPORT_FAILED,
    MODELS_SET_UPLOADED_BIN_FILES,
    MODELS_SET_LARGE_FILES,
    MODELS_CLEAR_LARGE_FILES,
    MODELS_SET_IS_MODEL_SIMPLIFYING,
    MODELS_SET_IS_MODEL_SIMPLIFYING_FAILED,
} from './types';

export const doSetConversionStatus = payload => {
    return {
        type: SET_CONVERSION_STATUS,
        payload,
    };
};

export const doRunConversionJob = payload => {
    return {
        type: CONVERT_FILE_START,
        payload,
    };
};

export const doTrackConvertJobProgress = payload => {
    return {
        type: TRACK_CONVERT_FILE_START,
        payload,
    };
};

export const doUpdateConvertProgressJob = payload => {
    return {
        type: UPDATE_CONVERT_FILE_PROGRESS,
        payload,
    };
};

export const doStopUpdateConvertProgress = payload => {
    return {
        type: STOP_UPDATE_CONVERT_FILE_PROGRESS,
        payload,
    };
};

export const doStopConversionJob = payload => {
    return {
        type: STOP_CONVERT_FILE,
        payload,
    };
};

export const doStopConversionJobFulfilled = payload => {
    return {
        type: STOP_CONVERT_FILE_SUCSESS,
        payload,
    };
};

export const doStopConversionJobFailed = payload => {
    return {
        type: STOP_CONVERT_FILE_FAIL,
        payload,
    };
};

export const doRunConversionJobFulfilled = payload => {
    return {
        type: CONVERT_FILE_SUCCESS,
        payload,
    };
};

export const doRunConversionJobFail = payload => {
    return {
        type: CONVERT_FILE_FAIL,
        payload,
    };
};

export const doSetConversionFailed = payload => {
    return {
        type: SET_CONVERT_FILE_FAIL,
        payload,
    };
};

export const doFetchConvertedModels = payload => {
    return {
        type: FETCH_CONVERTED_MODELS_START,
        payload,
    };
};

export const doSetConvertedBlobs = payload => {
    return {
        type: SET_CONVERTED_MODELS_BLOBS,
        payload,
    };
};

export const doSetModelLoadingProgress = modelLoadingPercent => {
    return {
        type: MODELS_SET_MODEL_LOADING_PROGRESS,
        payload: { modelLoadingPercent },
    };
};

export const doSetModelLoading = ({ modelLoading, uploadedModelName }) => {
    return {
        type: MODELS_SET_MODEL_LOADING,
        payload: { modelLoading, uploadedModelName },
    };
};

export const doSetWrongFormat = isWrongFormat => {
    return {
        type: MODELS_SET_WRONG_FORMAT,
        payload: { isWrongFormat },
    };
};

export const doSetUnableToConvertFileType = unableToConvertFileType => {
    return {
        type: MODELS_SET_UNABLE_TO_CONVERT_FILETYPE,
        payload: { unableToConvertFileType },
    };
};

export const doSetWrongFiles = wrongFilesInfo => {
    return {
        type: MODELS_SET_WRONG_FILES,
        payload: { wrongFilesInfo },
    };
};

export const doSetMakerbotDownloadImpossible = value => {
    return {
        type: MODELS_SET_MAKERBOT_DOWNLOAD_IMPOSSIBLE,
        payload: value,
    };
};

export const doSetExceededFileSize = isWrongExportFileSize => {
    return {
        type: MODELS_SET_EXCEEDED_FILE_SIZE,
        payload: { isWrongExportFileSize },
    };
};

export const doCancelImport = newModels => {
    return {
        type: MODELS_CANCEL_IMPORT,
        payload: { newModels },
    };
};

export const doSetShowUploadModelError = showUploadModelError => {
    return {
        type: MODELS_SET_SHOW_UPLOAD_MODEL_ERROR,
        payload: { showUploadModelError },
    };
};

export const doSetFileTooOld = isFileTooOld => {
    return {
        type: MODELS_SET_FILE_TOO_OLD_ERROR,
        payload: { isFileTooOld },
    };
};

export const doSetFileTooRecent = isFileTooRecent => {
    return {
        type: MODELS_SET_FILE_TOO_RECENT_ERROR,
        payload: { isFileTooRecent },
    };
};

export const doSetShouldResetModelSettings = resetObject => {
    return {
        type: MODELS_SET_SHOULD_RESET_MODEL_SETTINGS,
        payload: { resetObject },
    };
};

export const doSetSettingsOnOneActiveModel = settings => {
    return {
        type: MODELS_SET_SETTINGS_ON_ONE_ACTIVE_MODEL,
        payload: { settings },
    };
};

export const doUpdateSettings = payload => {
    return {
        type: MODELS_UPDATE_SETTINGS,
        payload,
    };
};

export const doSetActiveModels = activeModels => {
    return {
        type: MODELS_SET_ACTIVE_MODELS,
        payload: { activeModels },
    };
};

export const doSetCopiedModels = payload => {
    return {
        type: MODELS_SET_COPIED_MODELS,
        payload,
    };
};

export const doSetModels = newModels => {
    return {
        type: MODELS_SET_MODELS,
        payload: { newModels },
    };
};

export const doAddModels = newModels => {
    return {
        type: MODELS_ADD_MODELS,
        payload: { newModels },
    };
};

export const doDeleteModels = deletedModels => {
    return {
        type: MODELS_DELETE_MODELS,
        payload: { deletedModels },
    };
};

export const doUpdateModels = () => {
    return {
        type: MODELS_UPDATE_MODELS,
    };
};

export const doAddPurgeTower = purgeTower => {
    return {
        type: MODELS_ADD_PURGE_TOWER,
        payload: { purgeTower },
    };
};

export const doRemovePurgeTower = ptName => {
    return {
        type: MODELS_REMOVE_PURGE_TOWER,
        payload: { ptName },
    };
};

export const doSetHoveredModelsIds = hoveredModelsIds => {
    return {
        type: SET_HOVERED_MODEL_ID,
        payload: { hoveredModelsIds },
    };
};

export const doSetOverlapModelsIds = overlapModelsIds => {
    return {
        type: SET_OVERLAP_MODELS_IDS,
        payload: { overlapModelsIds },
    };
};

export const doSetIsSmartArrangeHovered = isSmartArrangeHovered => {
    return {
        type: SET_IS_SMART_ARRANGE_HOVERED,
        payload: { isSmartArrangeHovered },
    };
};

export const doSetModelOriginalName = payload => {
    return {
        type: MODELS_SET_MODEL_ORIGINAL_NAME,
        payload,
    };
};

export const doStartHandlingUploadJobs = payload => {
    return {
        type: MODELS_START_HANDLING_UPLOAD_JOBS,
        payload,
    };
};

export const doStopHandlingUploadJobs = payload => {
    return {
        type: MODELS_STOP_HANDLING_UPLOAD_JOBS,
    };
};

export const doBroadcastUploadJob = payload => {
    return {
        type: MODELS_DO_BROADCAST_UPLOAD_JOB,
        payload,
    };
};

export const doBroadcastUploadJobError = payload => {
    return {
        type: MODELS_DO_BROADCAST_UPLOAD_JOB_ERROR,
        payload,
    };
};

export const doResetUploadJob = () => {
    return {
        type: MODELS_DO_RESET_UPLOAD_JOB,
    };
};

export const doSetUploadedBinFiles = payload => {
    return {
        type: MODELS_SET_UPLOADED_BIN_FILES,
        payload,
    };
};

export const doSetLargeFiles = payload => {
    return {
        type: MODELS_SET_LARGE_FILES,
        payload,
    };
};

export const doClearLargeFiles = () => {
    return {
        type: MODELS_CLEAR_LARGE_FILES,
    };
};

export const doAddLoaderDescriptors = loaders => {
    return {
        type: MODELS_DO_ADD_LOADER_DESCRIPTORS,
        payload: { loaders },
    };
};

export const doResetLoaderDescriptors = () => {
    return {
        type: MODELS_DO_RESET_LOADER_DESCRIPTORS,
    };
};

export const doClearConverterMetaInformaton = () => {
    return {
        type: MODELS_CLEAR_CONVERTER_META,
    };
};

export const doSetUploadJobsCount = payload => {
    return {
        type: MODELS_DO_SET_UPLOAD_JOB_COUNT,
        payload,
    };
};

export const doAddCompletedUploadJobsCount = payload => {
    return {
        type: MODELS_DO_ADD_COMPLETED_UPLOAD_JOB_COUNT,
        payload,
    };
};

export const doSetConvertJobsCount = payload => {
    return {
        type: MODELS_DO_SET_CONVERT_JOB_COUNT,
        payload,
    };
};

export const doUpdateConvertJobsCount = payload => {
    return {
        type: MODELS_DO_UPDATE_CONVERT_JOB_COUNT,
        payload,
    };
};

export const doSetJobName = payload => {
    return {
        type: MODELS_DO_SET_JOB_NAME,
        payload,
    };
};

export const doUpdateDefaultJobName = payload => {
    return {
        type: MODELS_DO_UPDATE_DEFAULT_JOB_NAME,
        payload,
    };
};

export const doSetJobNameFromDefault = payload => {
    return {
        type: MODELS_DO_SET_JOB_NAME_FROM_DEFAULT,
        payload,
    };
};

export const doFetchCadsFromUrls = payload => {
    return {
        type: MODELS_FETCH_CAD_FROM_URL,
        payload,
    };
};

export const doSetPerModelInstance = payload => {
    return {
        type: MODELS_PERMODEL_SET_INSTANCE,
        payload,
    };
};

export const doDownloadDemoFile = payload => {
    return {
        type: MODELS_DOWNLOAD_DEMO_FILE_START,
        payload,
    };
};

export const doStopDownloadDemoFile = payload => {
    return {
        type: MODELS_STOP_DOWNLOAD_DEMO_FILE,
        payload,
    };
};

export const doDownloadDemoFileFulfilled = payload => {
    return {
        type: MODELS_DOWNLOAD_DEMO_FILE_FULFILLED,
        payload,
    };
};

export const doDownloadDemoFileFailed = payload => {
    return {
        type: MODELS_DOWNLOAD_DEMO_FILE_FAILED,
        payload,
    };
};

export const doDownloadDemoAssemblyFiles = payload => {
    return {
        type: MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES,
        payload,
    };
};

export const doStopDownloadDemoAssemblyFiles = payload => {
    return {
        type: MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES,
        payload,
    };
};

export const doDownloadDemoAssemblyFilesFulfilled = payload => {
    return {
        type: MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FULFILLED,
        payload,
    };
};

export const doDownloadDemoAssemblyFilesFailed = payload => {
    return {
        type: MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FAILED,
        payload,
    };
};

export const doSetIsDragging = payload => {
    return {
        type: SET_IS_DRAGGING,
        payload: { isDragging: payload },
    };
};

export const doSetIsModelHoveredFromTree = payload => {
    return {
        type: SET_IS_MODEL_HOVERED_FROM_TREE,
        payload: { isModelHoveredFromTree: payload },
    };
};

export const doSetIsDragRotation = payload => {
    return {
        type: SET_IS_DRAG_ROTATION,
        payload: { isDragRotation: payload },
    };
};

export const doSetIsModelImportFailed = payload => {
    return {
        type: MODELS_SET_IMPORT_FAILED,
        payload: { isModelImportFailed: payload },
    };
};

export const doSetIsModelSimplifying = payload => {
    return {
        type: MODELS_SET_IS_MODEL_SIMPLIFYING,
        payload: { isModelSimplifying: payload },
    };
};

export const doSetIsModelSimplifyingFailed = payload => {
    return {
        type: MODELS_SET_IS_MODEL_SIMPLIFYING_FAILED,
        payload: { isModelSimplifyingFailed: payload },
    };
};
