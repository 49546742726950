import { CLOSE_BUILD_PLATE_CHECK_MODAL, SHOW_BUILD_PLATE_CHECK_MODAL, SETTINGS_INPUT_ERROR, SET_SHOW_MAKERBOT_DETECTED_MODAL, SET_DETECTED_MAKERBOT_FILES, SET_SHOW_BLOCKER_ALERT } from './types';

const initialState = {
    showBuildPlateCheck: false,
    title: null,
    body: null,
    settingsInputError: '',
    showFolderContainsMakerbot: false,
    detectedMakerbotFiles: [],
    showBlockedRedirectAlert: false,
};

const applyModalOptions = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_BUILD_PLATE_CHECK_MODAL:
            return Object.assign({}, state, {
                showBuildPlateCheck: true,
            });
        case CLOSE_BUILD_PLATE_CHECK_MODAL:
            return Object.assign({}, state, {
                showBuildPlateCheck: false,
            });
        case SET_SHOW_MAKERBOT_DETECTED_MODAL:
            return Object.assign({}, state, {
                showFolderContainsMakerbot: action.payload,
            });
        case SET_DETECTED_MAKERBOT_FILES:
            return Object.assign({}, state, {
                detectedMakerbotFiles: action.payload,
            });
        case SETTINGS_INPUT_ERROR:
            return {
                ...state,
                settingsInputError: action.payload.settingsInputError,
            };
        case SET_SHOW_BLOCKER_ALERT: 
        return{ 
            ...state,
            showBlockedRedirectAlert: action.payload

        }
        default:
            return state;
    }
};

export default applyModalOptions;
