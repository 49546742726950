import { IMPORT_INPUT_ID } from '../../consts';

export const openUploadDialog = () => {
    const input = document.getElementById(IMPORT_INPUT_ID);
    input && input.click();
};

export const selectAllValues = el => {
    setTimeout(() => {
        el.setSelectionRange(0, el.value.length);
    });
};

export const handleBeforeUnloadFn = event => {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (typeof event === 'undefined') {
        event = window.event;
    }
    if (event) {
        event.returnValue = '';
    }
    return;
};

const getStrLenInPx = (str, fontSize = '12px') => {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    document.body.append(span);
    span.style.fontSize = fontSize;
    span.innerText = str;
    const withInPx = span.offsetWidth;
    span.remove();
    return withInPx;
};

const getElemInnerWith = element => {
    const cs = getComputedStyle(element);
    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    const borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
    const innerWidth = element.offsetWidth - paddingX - borderX;

    return innerWidth;
};

export const isTextFitsInInput = (str, input) => {
    if (!input) return true;
    const inputWith = getElemInnerWith(input);
    const strWith = getStrLenInPx(str);

    return inputWith >= strWith;
};

export const isEllipsisActive = elem => {
    if (!elem) return false;
    return elem.offsetWidth < elem.scrollWidth;
};
