import { v4 as uuidv4 } from 'uuid';

import {
    PRINT_PREVIEW_MODES,
    EXPORT_STATUSES,
    DISPLAY_PATH_PARAMETERS,
    JOB_TYPE,
    TOOOLPATH_FETCH_STATUSES,
} from '../../../consts';

import {
    TRACK_PROGRESS_FROM_SLICER_START,
    TRACK_PROGRESS_FROM_SLICER_SUCCESS,
    TRACK_PROGRESS_FROM_SLICER_FAIL,
    UPDATE_PROGRESS_FROM_SLICER,
    CANCEL_SLICE_JOB,
    SEND_JOB_TO_SLICER_START,
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS,
    FETCH_RECONSTRUCTED_MODEL_SUCCESS,
    RESET_RECONSTRUCTED_MODEL,
    UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR,
    HANDLE_MODEL_PREVIEW_START,
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START,
    UPDATE_MAKERBOT_FILE_LAST_PREVIEW_LINK,
    HANDLE_MODEL_PREVIEW_SUCCESS,
    PREVIEW_SET_MAKERBOT_AVAILABLE,
    PREVIEW_SET_PREVIEW_MODE,
    PREVIEW_SET_PRINT_PREVIEW_ENABLED,
    PREVIEW_SET_MAX_LAYER,
    PREVIEW_SET_MAX_MOVE,
    PREVIEW_SET_CURRENT_MOVE,
    PREVIEW_SET_CURRENT_LAYER,
    PREVIEW_SET_MODEL_VISIBILITY,
    PREVIEW_SET_TRAVEL_MOVES_VISIBILITY,
    PREVIEW_SET_RETRACT_VISIBILTY,
    PREVIEW_SET_RESTART_VISIBILTY,
    PREVIEW_SET_PT_VISIBILITY,
    PRINTER_SET_JOB_FILENAME,
    SEND_JOB_TO_SLICER_FAIL,
    FETCH_PROFILE_FAIL,
    FETCH_BASIC_SLICE_SUCCESS,
    MODELS_SET_CHUNKS_READY,
    HANDLE_MODEL_PREVIEW_FAIL,
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_FAIL,
    PREVIEW_SET_RAFT_VISIBILITY,
    PREVIEW_SET_BRIM_VISIBILITY,
    PREVIEW_SET_CURRENT_LAYER_INFO,
    PREVIEW_SET_MOVES,
    PREVIEW_SET_TOOLPATH_VISIBILITY,
    SET_SORTED_MODEL,
    UPLOAD_FILE_TO_STORAGE_SUCCESS,
    FETCH_UPLOAD_URLS_START,
    RUN_ZIP_JOB_FAIL,
    FETCH_UPLOAD_URLS_FAIL,
    UPLOAD_TO_BUCKET_FAIL,
    UPLOAD_TO_BUCKET_SUCCESS,
    FETCH_DOWNLOAD_URLS_SUCCESS,
    SET_SORTED_CURRENT_TOOLPATH,
    DOWNLOAD_FILE_FROM_SIGNED_START,
    DOWNLOAD_FILE_FROM_SIGNED_SUCCESS,
    DOWNLOAD_FILE_FROM_SIGNED_FAIL,
    PREVIEW_SET_THING_AVAILABLE,
    PREVIEW_SET_SHOW_LAYER_DETAILS,
    PREVIEW_SET_COMPARISON_MODE_ENABLED,
    PREVIEW_SET_COMPARISON_MODELS_LOADED,
    RESET_ALL_CHUNKS,
    RESET_ALL_TOOLPATH_VISIBILITY,
    PREVIEW_SET_COMPARISON_MODELS,
    UPLOAD_FILE_TO_STORAGE_FAIL,
    SET_THUMBNAILS_LINK,
    INIT_SLICE_JOB,
    UPDATE_SLICE_JOB,
    SET_PREVIEW_ID_FOR_PREVIEW_MODEL,
    TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_FAIL,
    PREVIEW_SET_IS_VOLUMETRIC_ANIMATION_PLAYING,
    FETCH_RECONSTRUCTED_MODEL_FAIL,
    RESET_CURRENT_SLICE_JOB_ID,
    ADD_OPERATING_JOB,
    UPDATE_OPERATING_JOB,
    DELETE_OPERATING_JOB,
    RESET_CURRENT_FAILED_SLICE_JOB,
    RESET_JOB_TO_RESTART,
    SET_JOB_TO_RESTART,
    FETCH_TOOLPATH_START,
    FETCH_TOOLPATH_SUCCESS,
    SET_DEFAULT_MODELS_PREVIEW,
    UPDATE_JOB_FILE_NAME,
    SET_PREVIEW_MODELS,
    SET_HELD_SLICE_PAYLOAD,
} from './types';
import {
    filterJobsSlicedWithin30Days,
    getCachedSliceJobs,
    setSliceJobsToLocalStorage,
} from '../../../helpers/sliceJobsUtils';

const cachedSliceJobs = getCachedSliceJobs();
const mainPreviewId = uuidv4();
const modelPreviewInitialState = {
    previewId: '',
    sortedModel: null,
    estimate: 0,
    extrusionMasses: [],
    materialsType: [],
    materialVisible: true,
    supportVisible: true,
    travelMoveVisible: true,
    retractVisible: true,
    restartVisible: true,
    raftVisible: true,
    brimVisible: true,
    ptVisible: true,
    toolpathVisible: false,
    currentLayer: 0,
    currentLayerInfo: {
        type: DISPLAY_PATH_PARAMETERS.none,
        colors: [],
    },
    currentMove: 1,
    maxLayer: 1,
    maxMove: 1,
    moves: null,
    currentToolpath: null,
    showLayerDetails: false,
    cancelDisabled: true,
    isVolumetricAnimationPlaying: false,
};

const initialState = {
    jobFileName: '',
    sliceJobs: cachedSliceJobs,
    currentFailedSliceJobId: null,
    jobToRestart: null,
    operatingJobs: {},
    currentSliceJobId: null,
    modelsPreview: [
        { ...modelPreviewInitialState, previewId: mainPreviewId }, //preview model
    ],
    printPreviewMode: PRINT_PREVIEW_MODES.threeD,
    printPreviewEnabled: false,
    comparisonModeEnabled: false,
    comparisonModelsLoaded: false,
    comparisonModels: [],
    makerbotAvailable: false,
    thingAvailable: false,
    thingExporting: null,
    makerbotUrl: null,
    thingStorageURL: null,
    thingLoadURL: null,
    thumbnailsStorageURL: null,
    toolpathStatus: TOOOLPATH_FETCH_STATUSES.initial,
    heldSlicePayload: null,
};

const previewReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEFAULT_MODELS_PREVIEW:
        case FETCH_UPLOAD_URLS_START:
            if (action.payload?.openInNewWindow) {
                return Object.assign({}, state);
            }

            //if action triggered to build comparison preview model
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state, {
                    modelsPreview: [
                        ...state.modelsPreview,
                        {
                            ...modelPreviewInitialState,
                            progress: 0,
                            completed: false,
                            cancelDisabled: true,
                            fileName: null,
                            botTypeForPreview: null,
                            previewId: uuidv4(),
                        },
                    ],
                });
            }

            return Object.assign({}, state, {
                modelsPreview: [
                    {
                        ...state.modelsPreview[0],
                        progress: 0,
                        completed: false,
                        cancelDisabled: true,
                        fileName: state.modelsPreview[0].fileName || null,
                        botTypeForPreview: null,
                    },
                ],
            });

        case TRACK_PROGRESS_FROM_SLICER_START: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return { ...item, cancelDisabled: false };
                }),
            });
        }
        case SEND_JOB_TO_SLICER_START:
            return state;
        case CANCEL_SLICE_JOB:
            const { sliceJobs } = state;
            const sliceJobId = action.payload.id;
            const newSliceJobs = sliceJobs.filter(job => job.id !== sliceJobId);

            setSliceJobsToLocalStorage(newSliceJobs);
            return Object.assign({}, state, {
                sliceJobs: newSliceJobs,
                currentSliceJobId: null,
            });
        case UPDATE_PROGRESS_FROM_SLICER:
        case TRACK_PROGRESS_FROM_SLICER_SUCCESS:
            const currentJob = action.payload;
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            if (currentJob && currentJob.progress != null) {
                const newState = {};
                if (currentJob.jobType === JOB_TYPE.preview) {
                    newState.modelsPreview = state.modelsPreview.map((item, index) => {
                        if (index !== currentIndex) {
                            return item;
                        }

                        return { ...item, cancelDisabled: false };
                    });
                    if (currentJob.progress > 0) {
                        newState.modelsPreview[currentIndex].progress = currentJob.progress / 2;
                    }
                    if (currentJob.isSliced) {
                        newState.modelsPreview[currentIndex].isSliced = true;
                    }
                } else {
                    newState.sliceJobs = [
                        ...state.sliceJobs.map(job => {
                            if (job.localId !== currentJob.localId) {
                                return job;
                            }

                            return currentJob;
                        }),
                    ];
                }

                return Object.assign({}, state, newState);
            }
            return Object.assign({}, state);
        case TRACK_PROGRESS_FROM_SLICER_FAIL:
            const failedJob = action.payload.failedSliceJob;
            const modelToUpdate = state.comparisonModeEnabled ? 1 : 0;
            return Object.assign({}, state, {
                currentFailedSliceJobId: failedJob.localId,
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (job.localId !== failedJob.localId) {
                            return job;
                        }

                        return { ...job, ...failedJob };
                    }),
                ],
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== modelToUpdate) {
                        return item;
                    }

                    return { ...item, cancelDisabled: false, failed: true };
                }),
            });
        case TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_FAIL:
            const { currentSliceJobId } = state;
            return Object.assign({}, state, {
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (job.localId === currentSliceJobId && job.status === EXPORT_STATUSES.basic) {
                            job.status = EXPORT_STATUSES.slicing;
                        }
                        return job;
                    }),
                ],
            });
        case HANDLE_MODEL_PREVIEW_START: {
            let newModelsPreview;
            if (!state.comparisonModeEnabled || state.modelsPreview[1]) {
                const currentIndex = state.comparisonModeEnabled ? 1 : 0;
                newModelsPreview = state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return {
                        ...item,
                        progress: 50,
                        cancelDisabled: true,
                        fileName: action.payload.fileName,
                        botTypeForPreview: action.payload.botTypeForPreview,
                    };
                });
                return Object.assign({}, state, { modelsPreview: newModelsPreview });
            }
            newModelsPreview = [
                ...state.modelsPreview,
                {
                    ...modelPreviewInitialState,
                    progress: 50,
                    cancelDisabled: true,
                    fileName: action.payload.fileName,
                    botTypeForPreview: action.payload.botTypeForPreview,
                    previewId: uuidv4(),
                },
            ];

            return Object.assign({}, state, { modelsPreview: newModelsPreview });
        }
        case HANDLE_MODEL_PREVIEW_SUCCESS: {
            let newModelsPreview;
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;

            if (!state.comparisonModeEnabled || state.modelsPreview[1]) {
                newModelsPreview = state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return { ...item, toolpathId: action.payload.url };
                });
                return Object.assign({}, state, { modelsPreview: newModelsPreview });
            }

            newModelsPreview = [
                ...state.modelsPreview,
                {
                    ...modelPreviewInitialState,
                    toolpathId: action.payload.url,
                    previewId: uuidv4(),
                },
            ];
            return Object.assign({}, state, { modelsPreview: newModelsPreview });
        }
        case TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return {
                        ...item,
                        progress: 55,
                        cancelDisabled: false,
                        fileName: action.payload.fileName,
                        botTypeForPreview: action.payload.botTypeForPreview,
                    };
                }),
            });
        }
        case UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return {
                        ...item,
                        progress: 55 + action.payload.progress / 2.5,
                        cancelDisabled: false,
                        fileName: action.payload.fileName,
                        botTypeForPreview: action.payload.botTypeForPreview,
                    };
                }),
            });
        }
        case TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;

            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return {
                        ...item,
                        progress: 95,
                        cancelDisabled: true,
                        fileName: action.payload.fileName,
                        botTypeForPreview: action.payload.botTypeForPreview,
                    };
                }),
            });
        }
        case UPDATE_MAKERBOT_FILE_LAST_PREVIEW_LINK: {
            let newModelsPreview;
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            if (!state.comparisonModeEnabled || state.modelsPreview[1]) {
                newModelsPreview = state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }
                    return {
                        ...item,
                        makerBotFilePreviewLink: action.payload.makerBotFilePreviewLink,
                        fileName: action.payload.fileName,
                        botTypeForPreview: action.payload.botTypeForPreview,
                    };
                });
                return Object.assign({}, state, { modelsPreview: newModelsPreview });
            }

            newModelsPreview = [
                ...state.modelsPreview,
                {
                    ...modelPreviewInitialState,
                    makerBotFilePreviewLink: action.payload.makerBotFilePreviewLink,
                    fileName: action.payload.fileName,
                    botTypeForPreview: action.payload.botTypeForPreview,
                    previewId: uuidv4(),
                },
            ];
            return Object.assign({}, state, { modelsPreview: newModelsPreview });
        }
        case FETCH_RECONSTRUCTED_MODEL_SUCCESS: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;

            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return { ...item, ...action.payload.result };
                }),
            });
        }
        case FETCH_RECONSTRUCTED_MODEL_FAIL:
            const failedJobId = action.payload.failedJobId;
            const ind = state.comparisonModeEnabled ? 1 : 0;
            return Object.assign({}, state, {
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (job.localId !== failedJobId) {
                            return job;
                        }

                        return {
                            ...job,
                            status: EXPORT_STATUSES.failed,
                            progress: 0,
                            timestamp: new Date(),
                        };
                    }),
                ],
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== ind) {
                        return item;
                    }

                    return { ...item, cancelDisabled: false, failed: true };
                }),
            });
        case UPLOAD_TO_BUCKET_SUCCESS: {
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state);
            }

            return Object.assign({}, state, {
                modelsPreview: [
                    {
                        ...state.modelsPreview[0],
                    },
                ],
                thingLoadURL: action.payload.thingLoadURL,
                thingStorageURL: action.payload.thingStorageURL,
            });
        }
        case FETCH_DOWNLOAD_URLS_SUCCESS: {
            return Object.assign({}, state, {
                thingLoadURL: action.payload.thingLoadURL,
                thingStorageURL: action.payload.thingStorageURL,
            });
        }
        case DOWNLOAD_FILE_FROM_SIGNED_START: {
            return Object.assign({}, state, {
                thingExporting: true,
            });
        }
        case DOWNLOAD_FILE_FROM_SIGNED_SUCCESS: {
            return Object.assign({}, state, {
                thingExporting: false,
            });
        }
        case DOWNLOAD_FILE_FROM_SIGNED_FAIL: {
            return Object.assign({}, state, {
                thingExporting: false,
            });
        }
        case SET_THUMBNAILS_LINK: {
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state);
            }
            return Object.assign({}, state, {
                thumbnailsStorageURL: action.payload,
            });
        }
        case PREVIEW_SET_THING_AVAILABLE: {
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state);
            }
            const { available } = action.payload;
            return Object.assign({}, state, {
                thingAvailable: available,
                thumbnailsStorageURL: available ? state.thumbnailsStorageURL : null,
                thingLoadURL: available ? state.thingLoadURL : null,
                thingStorageURL: available ? state.thingStorageURL : null,
                modelsPreview: [
                    {
                        ...state.modelsPreview[0],
                    },
                ],
            });
        }
        case RESET_RECONSTRUCTED_MODEL: {
            if (state.thingAvailable && state.thingStorageURL && state.thingLoadURL) {
                return Object.assign({}, state, {
                    modelsPreview: [
                        {
                            ...modelPreviewInitialState,
                            previewId: mainPreviewId,
                            progress: 0,
                            completed: false,
                            cancelDisabled: true,
                            fileName: state.modelsPreview[0].fileName,
                            botTypeForPreview: null,
                        },
                    ],
                    makerbotUrl: null,
                    makerbotAvailable: false,
                });
            }

            return Object.assign({}, state, {
                modelsPreview: [{ ...modelPreviewInitialState, previewId: mainPreviewId }],
                makerbotUrl: null,
                makerbotAvailable: false,
                thingAvailable: false,
                thingExporting: false,
                thingStorageURL: null,
                thingLoadURL: null,
            });
        }
        case PREVIEW_SET_CURRENT_LAYER_INFO: {
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map(item => {
                    if (item.previewId !== action.payload.previewId) {
                        return item;
                    }

                    return {
                        ...item,
                        currentLayerInfo: {
                            ...item.currentLayerInfo,
                            ...action.payload.info,
                        },
                    };
                }),
            });
        }
        case FETCH_PROFILE_FAIL: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;

            return Object.assign({}, state, {
                sliceJobs: [
                    {
                        ...action.payload.failedSliceJob,
                    },
                    ...state.sliceJobs,
                ],
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return { ...item, cancelDisabled: false };
                }),
            });
        }
        case UPLOAD_FILE_TO_STORAGE_FAIL: {
            if (state.comparisonModeEnabled) {
                return Object.assign(state, {}, { modelsPreview: [{ ...state.modelsPreview[0] }] });
            }
            return Object.assign({}, state, {
                modelsPreview: [{ ...modelPreviewInitialState, previewId: mainPreviewId }],
                makerbotUrl: null,
                makerbotAvailable: false,
                thingAvailable: false,
                thingExporting: false,
                thingStorageURL: null,
                thingLoadURL: null,
            });
        }
        case FETCH_UPLOAD_URLS_FAIL:
        case RUN_ZIP_JOB_FAIL:
        case SEND_JOB_TO_SLICER_FAIL:
        case UPLOAD_TO_BUCKET_FAIL: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            const failedJob = action.payload.failedSliceJob;

            const newOperatingJobs = { ...state.operatingJobs };
            delete newOperatingJobs[failedJob.id];

            return Object.assign({}, state, {
                currentFailedSliceJobId: failedJob.localId,
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (job.localId !== failedJob.localId) {
                            return job;
                        }

                        return failedJob;
                    }),
                ],
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return { ...item, cancelDisabled: false };
                }),
                operatingJobs: newOperatingJobs,
            });
        }
        case INIT_SLICE_JOB: {
            const newSliceJobs = [
                {
                    ...action.payload,
                    id: null,
                    url: null,
                    progress: null,
                    chunksUrl: null,
                    fileUrl: null,
                    storageUrl: null,
                    thingStorageURL: null,
                    status: EXPORT_STATUSES.preparing,
                    unformattedDate: new Date(),
                },
                ...state.sliceJobs,
            ];
            return Object.assign({}, state, {
                jobFileName: action.payload.fileName || '',
                currentSliceJobId: action.payload.localId,
                sliceJobs: newSliceJobs,
            });
        }

        case UPDATE_JOB_FILE_NAME: {
            return Object.assign({}, state, {
                jobFileName: action.payload.fileName,
            });
        }
        case UPDATE_SLICE_JOB: {
            if (!action.payload.sliceJob.localId) {
                return state;
            }

            // Do not update failed jobs
            const job = state.sliceJobs.find(job => job.localId === action.payload.sliceJob.localId);
            if (job?.status === EXPORT_STATUSES.failed) {
                return state;
            }

            let newSliceJobs = state.sliceJobs.map(job => {
                if (job.localId !== action.payload.sliceJob.localId) {
                    return job;
                }

                return { ...job, ...action.payload.sliceJob };
            });

            if (action.payload.updateLocalStorage) {
                // Remove old slices & update the localStorage cache
                newSliceJobs = filterJobsSlicedWithin30Days(newSliceJobs);
                setSliceJobsToLocalStorage(newSliceJobs);
            }

            return Object.assign({}, state, {
                sliceJobs: [...newSliceJobs],
            });
        }
        case ADD_OPERATING_JOB: {
            const newOperatingJobs = { ...state.operatingJobs };
            newOperatingJobs[action.payload.sliceJobId] = { ...action.payload };

            return Object.assign({}, state, {
                operatingJobs: newOperatingJobs,
            });
        }
        case UPDATE_OPERATING_JOB: {
            const jobId = action.payload.sliceJobId;
            if (!state.operatingJobs[jobId]) {
                return state;
            }
            const newOperatingJobs = { ...state.operatingJobs };
            newOperatingJobs[jobId].ready = action.payload.ready;

            return Object.assign({}, state, {
                operatingJobs: newOperatingJobs,
            });
        }
        case DELETE_OPERATING_JOB: {
            const { sliceJobId, localId } = action.payload;

            const newOperatingJobs = { ...state.operatingJobs };
            const sliceJobIdLocal = Object.keys(newOperatingJobs).find(id => newOperatingJobs[id].localId === localId);
            delete newOperatingJobs[sliceJobId];
            delete newOperatingJobs[sliceJobIdLocal];

            return Object.assign({}, state, {
                operatingJobs: newOperatingJobs,
            });
        }
        case RESET_CURRENT_SLICE_JOB_ID: {
            return {
                ...state,
                currentSliceJobId: null,
            };
        }
        case UPLOAD_FILE_TO_STORAGE_SUCCESS: {
            const currentJob = action.payload.currentJob;
            if (!currentJob || !currentJob.localId) {
                return state;
            }

            return Object.assign({}, state, {
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (currentJob.localId !== job.localId) {
                            return job;
                        }

                        return currentJob;
                    }),
                ],
            });
        }
        case PREVIEW_SET_MAKERBOT_AVAILABLE: {
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state);
            }
            const available = action.payload;
            return Object.assign({}, state, {
                makerbotAvailable: available,
                modelsPreview: [
                    {
                        ...state.modelsPreview[0],
                        sortedModel: available ? state.modelsPreview[0].sortedModel : null,
                    },
                ],
                makerbotUrl: available ? state.modelsPreview[0].makerbotUrl : null,
            });
        }
        case PREVIEW_SET_MAX_LAYER:
        case PREVIEW_SET_MAX_MOVE:
        case PREVIEW_SET_CURRENT_MOVE:
        case PREVIEW_SET_CURRENT_LAYER:
        case PREVIEW_SET_MOVES:
        case PREVIEW_SET_MODEL_VISIBILITY:
        case PREVIEW_SET_TRAVEL_MOVES_VISIBILITY:
        case PREVIEW_SET_RETRACT_VISIBILTY:
        case PREVIEW_SET_RESTART_VISIBILTY:
        case PREVIEW_SET_PT_VISIBILITY:
        case PREVIEW_SET_RAFT_VISIBILITY:
        case PREVIEW_SET_BRIM_VISIBILITY:
        case PREVIEW_SET_TOOLPATH_VISIBILITY:
        case SET_SORTED_MODEL:
        case SET_SORTED_CURRENT_TOOLPATH:
        case PREVIEW_SET_SHOW_LAYER_DETAILS:
        case PREVIEW_SET_IS_VOLUMETRIC_ANIMATION_PLAYING:
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map(item => {
                    if (item.previewId !== action.payload.previewId) {
                        return item;
                    }

                    return { ...item, ...action.payload };
                }),
            });
        case PRINTER_SET_JOB_FILENAME:
        case PREVIEW_SET_PREVIEW_MODE:
        case PREVIEW_SET_PRINT_PREVIEW_ENABLED:
        case PREVIEW_SET_COMPARISON_MODELS_LOADED: {
            return Object.assign({}, state, {
                ...action.payload,
            });
        }
        case SET_PREVIEW_MODELS: {
            const modelsPreview = action.payload.previewModels.map(item => ({
                ...item,
                isVolumetricAnimationPlaying: false,
            }));

            return Object.assign({}, state, {
                modelsPreview,
            });
        }
        case PREVIEW_SET_COMPARISON_MODELS:
            if (!action.payload?.models) {
                return Object.assign({}, state, {
                    ...state,
                    comparisonModels: [],
                });
            }

            action.payload.models.forEach((model, i) => {
                const meshes = model.mesh();
                meshes.forEach(mesh => {
                    mesh.instancePosition = action.payload.perModelSettingsFlag ? `instance${i}` : `instance0`;
                });
            });

            return Object.assign({}, state, {
                ...state,
                comparisonModels: [...state.comparisonModels, ...action.payload.models],
            });
        case PREVIEW_SET_COMPARISON_MODE_ENABLED: {
            const previewModel = state.modelsPreview[0];
            return Object.assign({}, state, {
                modelsPreview: [{ ...previewModel }],
                comparisonModelsLoaded: false,
                comparisonModels: [],
                comparisonModeEnabled: action.payload.comparisonModeEnabled,
            });
        }
        case FETCH_BASIC_SLICE_SUCCESS: {
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state);
            }
            return Object.assign({}, state, {
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (job.localId !== state.currentSliceJobId) {
                            return job;
                        }

                        return { ...job, status: EXPORT_STATUSES.slicing };
                    }),
                ],
                modelsPreview: [
                    {
                        ...state.modelsPreview[0],
                        chunks: action.payload.chunks,
                    },
                ],
            });
        }
        case RESET_ALL_CHUNKS:
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map(item => {
                    return { ...item, chunks: null, chunksReady: false };
                }),
            });
        case MODELS_SET_CHUNKS_READY:
            if (state.comparisonModeEnabled) {
                return Object.assign({}, state);
            }
            return Object.assign({}, state, {
                modelsPreview: [
                    {
                        ...state.modelsPreview[0],
                        chunks: null,
                        chunksReady: action.payload.ready,
                    },
                ],
            });

        case HANDLE_MODEL_PREVIEW_FAIL:
        case TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_FAIL: {
            const currentIndex = state.comparisonModeEnabled ? 1 : 0;
            const currentFailedSliceJobId = state.currentSliceJobId;

            return Object.assign({}, state, {
                sliceJobs: [
                    ...state.sliceJobs.map(job => {
                        if (job.localId !== state.currentSliceJobId) {
                            return job;
                        }
                        return { ...job, progress: 0, status: EXPORT_STATUSES.failed, error: action.payload };
                    }),
                ],
                modelsPreview: state.modelsPreview.map((item, index) => {
                    if (index !== currentIndex) {
                        return item;
                    }

                    return { ...item, cancelDisabled: false, failed: true };
                }),
                currentFailedSliceJobId,
            });
        }
        case RESET_ALL_TOOLPATH_VISIBILITY: {
            return Object.assign({}, state, {
                modelsPreview: state.modelsPreview.map(item => {
                    return { ...item, toolpathVisible: false };
                }),
            });
        }
        case SET_PREVIEW_ID_FOR_PREVIEW_MODEL: {
            const newModelsPreview = state.modelsPreview.map((item, index) => {
                if (index !== 0) {
                    return item;
                }

                return { ...item, previewId: action.payload.previewId };
            });

            return Object.assign({}, state, { modelsPreview: newModelsPreview });
        }
        case RESET_CURRENT_FAILED_SLICE_JOB: {
            return Object.assign({}, state, { currentFailedSliceJobId: null });
        }
        case SET_JOB_TO_RESTART: {
            return Object.assign({}, state, { jobToRestart: action.payload.jobToRestart });
        }
        case RESET_JOB_TO_RESTART: {
            return Object.assign({}, state, { jobToRestart: null });
        }
        case FETCH_TOOLPATH_START: {
            return Object.assign({}, state, { toolpathStatus: TOOOLPATH_FETCH_STATUSES.loading });
        }
        case FETCH_TOOLPATH_SUCCESS: {
            return Object.assign({}, state, { toolpathStatus: TOOOLPATH_FETCH_STATUSES.success });
        }
        case SET_HELD_SLICE_PAYLOAD: {
            return Object.assign({}, state, { heldSlicePayload: action.payload });
        }
        default:
            return state;
    }
};

export default previewReducer;
