import { DISPLAY_PATH_PARAMETERS, EXTRUDER_MOVE_TYPE, TRAVEL_MOVE_TYPE } from '../consts';
import { getGridLines } from './utils';

const SCALE_MUL = 1.5;
const CARET_SIZE = 15;
const RIGHT_MENU_WIDTH = 280;
const TOP_MENU_HEIGHT = 72;

export default class Renderer2D {
    constructor(canvas) {
        this.domElement = canvas;
        this.actionKeys = {};
        this.model = null;
        this.currentLayer = 0;
        this.currentMove = 0;
        this.pathType = DISPLAY_PATH_PARAMETERS.none;
        this.toolPathPolys = [];

        this.materialVisible = true;
        this.supportVisible = true;
        this.raftVisible = true;
        this.brimVisible = true;
        this.travelMoveVisible = true;
        this.retractVisible = true;
        this.restartVisible = true;

        this.scaleFactor = 1;
        this.translateX = 0;
        this.translateY = 0;

        this.prevScale = 1;
        this.zoomFactor = 4;
        this.zoomFactorDelta = 0.4;
        this.gridSizeX = 10;
        this.gridSizeY = 10;
        this.gridStep = 10;
        this.gridSizeExtraX = 0;
        this.gridLines = {
            lines: [],
            lines10: [],
            linesCross: [],
        };

        this.createCanvas();
        this.makeGrid();
    }

    setActionsKeys(actions) {
        this.actionKeys = actions;
    }

    setToolpathSelectedCallback(callback) {
        this.callback = callback;
    }

    createCanvas() {
        this.ctx = this.domElement.getContext('2d');
        this.ctx.lineWidth = 2;
        this.ctx.lineCap = 'round';

        this.addEventListeners();
        this.updateTranslation();
    }

    setModel(model) {
        this.model = model;

        this.updateTranslation();
    }

    setVisility(
        materialVisible,
        supportVisible,
        travelMoveVisible,
        retractVisible,
        restartVisible,
        raftVisible,
        brimVisible
    ) {
        this.materialVisible = materialVisible;
        this.supportVisible = supportVisible;
        this.travelMoveVisible = travelMoveVisible;
        this.retractVisible = retractVisible;
        this.restartVisible = restartVisible;
        this.raftVisible = raftVisible;
        this.brimVisible = brimVisible;
    }

    setSize(width, height) {
        this.domElement.width = width;
        this.domElement.height = height;

        this.updateTranslation();
    }

    setGridSize(volume, expandAxisX = 0) {
        this.gridSizeX = volume.x;
        this.gridSizeY = volume.y;
        this.gridSizeExtraX = Math.abs(expandAxisX);
        this.gridLines = getGridLines(this.gridSizeX + this.gridSizeExtraX, this.gridSizeY);

        this.updateTranslation();
    }

    reset() {
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx.clearRect(0, 0, this.domElement.width, this.domElement.height);
        this.toolPathPolys = [];
    }

    makeGrid() {
        // Plate
        this.ctx.fillStyle = '#323030';
        this.ctx.fillRect(
            -(this.gridSizeX / 2 + this.gridSizeExtraX) * this.zoomFactor,
            -(this.gridSizeY / 2) * this.zoomFactor,
            (this.gridSizeX + this.gridSizeExtraX) * this.zoomFactor,
            this.gridSizeY * this.zoomFactor
        );
        this.ctx.beginPath();
        this.ctx.moveTo(
            (-CARET_SIZE - this.gridSizeExtraX / 2) * this.zoomFactor,
            (this.gridSizeY / 2) * this.zoomFactor
        );
        this.ctx.lineTo(
            (CARET_SIZE - this.gridSizeExtraX / 2) * this.zoomFactor,
            (this.gridSizeY / 2) * this.zoomFactor
        );
        this.ctx.lineTo(
            (-this.gridSizeExtraX / 2) * this.zoomFactor,
            (this.gridSizeY / 2 + CARET_SIZE) * this.zoomFactor
        );
        this.ctx.fill();

        // Grid
        const { lines, lines10, linesCross } = this.gridLines;

        // lines 1
        this.ctx.strokeStyle = '#dedede';
        this.ctx.lineWidth = 0.05;
        this.ctx.beginPath();
        for (let i = 0; i < lines.length; i += 2) {
            this.ctx.moveTo((lines[i].x - this.gridSizeExtraX / 2) * this.zoomFactor, lines[i].y * this.zoomFactor);
            this.ctx.lineTo(
                (lines[i + 1].x - this.gridSizeExtraX / 2) * this.zoomFactor,
                lines[i + 1].y * this.zoomFactor
            );
        }
        this.ctx.stroke();

        // lines 10
        this.ctx.strokeStyle = 'dedede';
        this.ctx.lineWidth = 0.15;
        this.ctx.beginPath();
        for (let i = 0; i < lines10.length; i += 2) {
            this.ctx.moveTo((lines10[i].x - this.gridSizeExtraX / 2) * this.zoomFactor, lines10[i].y * this.zoomFactor);
            this.ctx.lineTo(
                (lines10[i + 1].x - this.gridSizeExtraX / 2) * this.zoomFactor,
                lines10[i + 1].y * this.zoomFactor
            );
        }
        this.ctx.stroke();

        // cross
        this.ctx.strokeStyle = 'eaeaea';
        this.ctx.lineWidth = 0.3;
        this.ctx.beginPath();
        for (let i = 0; i < linesCross.length; i += 2) {
            this.ctx.moveTo(
                (linesCross[i].x - this.gridSizeExtraX / 2) * this.zoomFactor,
                linesCross[i].y * this.zoomFactor
            );
            this.ctx.lineTo(
                (linesCross[i + 1].x - this.gridSizeExtraX / 2) * this.zoomFactor,
                linesCross[i + 1].y * this.zoomFactor
            );
        }
        this.ctx.stroke();
    }

    addEventListeners() {
        this.domElement.addEventListener(
            'mousedown',
            event => {
                this.lastX = event.offsetX || event.pageX - this.domElement.offsetLeft;
                this.lastY = event.offsetY || event.pageY - this.domElement.offsetTop;

                if (this.actionKeys.pan.isActive(event)) {
                    this.dragStart = { x: this.lastX, y: this.lastY };
                } else if (this.actionKeys.zoom.isActive(event)) {
                    this.zoomStart = { x: this.lastX, y: this.lastY };
                }
                return false;
            },
            { passive: true }
        );

        this.domElement.addEventListener(
            'mousemove',
            event => {
                const x = event.offsetX || event.pageX - this.domElement.offsetLeft;
                const y = event.offsetY || event.pageY - this.domElement.offsetTop;
                if (this.zoomStart) {
                    const deltaY = this.zoomStart.y - y;

                    this.zoomStart.x = x;
                    this.zoomStart.y = y;

                    const delta = deltaY / 100;
                    this.makeZoom(delta);
                    return false;
                }

                this.lastX = x;
                this.lastY = y;

                if (this.dragStart) {
                    if (this.dragTimeout) {
                        window.cancelAnimationFrame(this.dragTimeout);
                    }

                    this.dragTimeout = window.requestAnimationFrame(() => {
                        if (this.dragStart) {
                            const deltaX = this.lastX - this.dragStart.x;
                            const deltaY = this.lastY - this.dragStart.y;
                            this.dragStart = { x: this.lastX, y: this.lastY };

                            const newTranslateX = this.translateX + deltaX;
                            const newTranslateY = this.translateY + deltaY;

                            if (!this.canTranslate(newTranslateX, newTranslateY, this.scaleFactor)) {
                                return;
                            }

                            this.translateX = newTranslateX;
                            this.translateY = newTranslateY;

                            this.applyTransform();
                            this.makeLayer(this.currentLayer, this.currentMove, this.pathType);
                        }
                    });
                }

                if (this?.toolPathPolys?.length) {
                    let selectedPolygone = null;
                    for (let i = this.toolPathPolys.length - 1; i >= 0 && !selectedPolygone; i--) {
                        const poly = this.toolPathPolys[i];
                        const inside = this.isPointInside(x, y, poly.polygon);
                        if (inside) {
                            selectedPolygone = { ...poly };
                            selectedPolygone.mousePosition = { x, y };
                        }
                    }
                    this.callback && this.callback(selectedPolygone);
                }

                return false;
            },
            { passive: true }
        );

        this.domElement.addEventListener(
            'mouseup',
            () => {
                this.dragStart = null;
                this.zoomStart = null;
                return false;
            },
            { passive: true }
        );

        this.domElement.addEventListener(
            'wheel',
            event => {
                if (!this.actionKeys.zoom.isActive(event)) {
                    return false;
                }

                let delta;
                if (event.detail < 0 || event.deltaY < 0 || event.wheelDelta > 0) {
                    delta = this.zoomFactorDelta;
                } else {
                    delta = -1 * this.zoomFactorDelta;
                }

                if (delta) {
                    this.makeZoom(delta);
                }

                return false;
            },
            { passive: true }
        );

        this.domElement.addEventListener(
            'contextmenu',
            () => {
                return false;
            },
            { passive: true }
        );
    }

    makeZoom(delta) {
        if (this.actionKeys && this.actionKeys.reverseMouseScrolling) {
            delta *= -1;
        }

        const newScale = this.scaleFactor * Math.pow(SCALE_MUL, delta);
        if (newScale < 0.25) {
            return;
        }

        const deltaX = this.lastX - this.translateX;
        const deltaY = this.lastY - this.translateY;
        const offsetX = deltaX - deltaX * (newScale / this.scaleFactor);
        const offsetY = deltaY - deltaY * (newScale / this.scaleFactor);

        const newTranslateX = this.translateX + offsetX;
        const newTranslateY = this.translateY + offsetY;
        if (!this.canTranslate(newTranslateX, newTranslateY, newScale)) {
            return;
        }

        this.scaleFactor = newScale;
        this.translateX = newTranslateX;
        this.translateY = newTranslateY;
        this.applyTransform();

        this.makeLayer(this.currentLayer, this.currentMove, this.pathType);
    }

    updateTranslation() {
        this.translateX = (this.domElement.width - RIGHT_MENU_WIDTH) / 2;
        this.translateY = (this.domElement.height - TOP_MENU_HEIGHT + CARET_SIZE * this.zoomFactor) / 2;

        this.applyTransform();
    }

    applyTransform() {
        this.reset();
        this.ctx.translate(this.translateX, this.translateY);
        this.ctx.scale(this.scaleFactor, this.scaleFactor);
        this.makeGrid();
    }

    makeLayer(layerIndex = 0, moveIndex = 0, pathType = DISPLAY_PATH_PARAMETERS.none) {
        if (!this.model) {
            return;
        }

        this.currentLayer = layerIndex;
        this.currentMove = moveIndex;
        this.pathType = pathType;
        const offsetModelX = 0;
        const offsetModelY = 0;
        const keys = Object.keys(this.model);
        keys.sort((a, b) => a - b);

        const index = keys[this.currentLayer];
        const layer = this.model[index];
        if (!layer) {
            console.warn(`layer with index ${layerIndex} not found`);
            return;
        }

        let colorsArr = [];
        switch (pathType) {
            case DISPLAY_PATH_PARAMETERS.extrusionTemperature:
                colorsArr = layer.newTemperatures;
                break;
            case DISPLAY_PATH_PARAMETERS.extrusionSpeed:
                colorsArr = layer.newExtrusionSpeeds;
                break;
            case DISPLAY_PATH_PARAMETERS.coolingFanSpeed:
                colorsArr = layer.newFanSpeeds;
                break;
            default:
                break;
        }

        const retractRestart = [];
        let prev = null;
        for (let i = 0; i < moveIndex; i++) {
            const line = layer[i];
            if (!line || !line.type) continue;
            const type = line.type;
            const xStart = line.s[0];
            const yStart = -line.s[1];
            const xEnd = line.e[0];
            const yEnd = -line.e[1];
            const travelType = line.tt;
            let width = line.layerWidth;
            let height = line.layerHeight;

            if (type === EXTRUDER_MOVE_TYPE.MATERIAL) {
                if (!this.materialVisible) {
                    continue;
                }
                this.ctx.lineWidth = width * this.zoomFactor || 1.5;
                if (colorsArr.length) {
                    this.ctx.strokeStyle = colorsArr[i] || '#569BC1';
                    this.ctx.fillStyle = colorsArr[i] || '#569BC1';
                } else {
                    this.ctx.strokeStyle = '#569BC1';
                    this.ctx.fillStyle = '#569BC1';
                }
            } else if (type === EXTRUDER_MOVE_TYPE.SUPPORT) {
                if (!this.supportVisible) {
                    continue;
                }
                this.ctx.lineWidth = width * this.zoomFactor || 1.5;
                if (colorsArr.length) {
                    this.ctx.strokeStyle = colorsArr[i] || '#777777';
                    this.ctx.fillStyle = colorsArr[i] || '#777777';
                } else {
                    this.ctx.strokeStyle = '#777777';
                    this.ctx.fillStyle = '#777777';
                }
            } else if (type === EXTRUDER_MOVE_TYPE.RAFT) {
                if (!this.raftVisible) {
                    continue;
                }
                this.ctx.lineWidth = width * this.zoomFactor || 1.5;
                if (colorsArr.length) {
                    this.ctx.strokeStyle = colorsArr[i] || '#9c9c9c';
                    this.ctx.fillStyle = colorsArr[i] || '#9c9c9c';
                } else {
                    this.ctx.strokeStyle = '#9c9c9c';
                    this.ctx.fillStyle = '#9c9c9c';
                }
            } else if (type === EXTRUDER_MOVE_TYPE.BRIM) {
                if (!this.brimVisible) {
                    continue;
                }
                this.ctx.lineWidth = width * this.zoomFactor || 1.5;
                if (colorsArr.length) {
                    this.ctx.strokeStyle = colorsArr[i] || '#bbbbbb';
                    this.ctx.fillStyle = colorsArr[i] || '#bbbbbb';
                } else {
                    this.ctx.strokeStyle = '#bbbbbb';
                    this.ctx.fillStyle = '#bbbbbb';
                }
            } else if (type === EXTRUDER_MOVE_TYPE.TRAVEL) {
                if (!this.travelMoveVisible && !this.retractVisible && !this.restartVisible) {
                    continue;
                }

                width = 0;
                height = 0;

                this.ctx.lineWidth = 0.45;
                this.ctx.strokeStyle = '#56C182';
            } else {
                console.warn('unknown type:', type);
            }

            if (travelType === TRAVEL_MOVE_TYPE.RETRACT && this.retractVisible) {
                retractRestart.push({
                    x: xStart * this.zoomFactor + offsetModelX,
                    y: yStart * this.zoomFactor + offsetModelY,
                    type: 'RETRACT',
                });
            }

            if (travelType === TRAVEL_MOVE_TYPE.RESTART && this.restartVisible) {
                retractRestart.push({
                    x: xStart * this.zoomFactor + offsetModelX,
                    y: yStart * this.zoomFactor + offsetModelY,
                    type: 'RESTART',
                });
            }

            if (type !== EXTRUDER_MOVE_TYPE.TRAVEL || (type === EXTRUDER_MOVE_TYPE.TRAVEL && this.travelMoveVisible)) {
                this.ctx.beginPath();
                this.ctx.moveTo(xStart * this.zoomFactor + offsetModelX, yStart * this.zoomFactor + offsetModelY);
                this.ctx.lineTo(xEnd * this.zoomFactor + offsetModelX, yEnd * this.zoomFactor + offsetModelY);
                this.ctx.stroke();

                if (width > 0) {
                    const p1 = this.getPolyPoint(xStart, yStart, xEnd, yEnd, offsetModelX, offsetModelY, width, 1);
                    const p2 = this.getPolyPoint(xStart, yStart, xEnd, yEnd, offsetModelX, offsetModelY, width, -1);
                    const p3 = this.getPolyPoint(xEnd, yEnd, xStart, yStart, offsetModelX, offsetModelY, width, 1);
                    const p4 = this.getPolyPoint(xEnd, yEnd, xStart, yStart, offsetModelX, offsetModelY, width, -1);
                    this.toolPathPolys.push({
                        polygon: [p1, p2, p3, p4],
                        id: i,
                        width: width.toFixed(2),
                        height: height.toFixed(2),
                        type,
                        temp: line.t,
                        ext: line.es,
                        cool: line.cfs,
                    });
                }
            }

            if (
                prev &&
                prev.type === type &&
                (type === EXTRUDER_MOVE_TYPE.MATERIAL ||
                    type === EXTRUDER_MOVE_TYPE.SUPPORT ||
                    type === EXTRUDER_MOVE_TYPE.RAFT ||
                    type === EXTRUDER_MOVE_TYPE.BRIM)
            ) {
                const xStartPrev = prev.s[0];
                const yStartPrev = -prev.s[1];
                const xEndPrev = prev.e[0];
                const yEndPrev = -prev.e[1];

                const v1 = { x: xEndPrev - xStartPrev, y: yEndPrev - yStartPrev };
                const v2 = { x: xEnd - xStart, y: yEnd - yStart };

                let angle = Math.atan2(v2.y, v2.x) - Math.atan2(v1.y, v1.x);
                // [-Pi; +Pi]
                if (angle > Math.PI) {
                    angle -= Math.PI * 2;
                } else if (angle < -Math.PI) {
                    angle += Math.PI * 2;
                }

                if (Math.abs(angle) - 0.01 <= Math.PI && angle !== 0) {
                    const firstX = xEndPrev;
                    const firstY = yEndPrev;

                    const ang = angle > 0 ? -Math.PI / 2 : Math.PI / 2;
                    const rx = v1.x * Math.cos(ang) - v1.y * Math.sin(ang);
                    const ry = v1.x * Math.sin(ang) + v1.y * Math.cos(ang);
                    const secondXtmp = firstX + rx;
                    const secondYtmp = firstY + ry;
                    const dist = Math.sqrt(Math.pow(secondXtmp - firstX, 2) + Math.pow(secondYtmp - firstY, 2));
                    const rate = width / 2 / dist;
                    const secondX = firstX + (secondXtmp - firstX) * rate;
                    const secondY = firstY + (secondYtmp - firstY) * rate;

                    const thirdX = Math.cos(angle) * (secondX - firstX) - Math.sin(angle) * (secondY - firstY) + firstX;
                    const thirdY = Math.sin(angle) * (secondX - firstX) + Math.cos(angle) * (secondY - firstY) + firstY;

                    this.ctx.beginPath();
                    this.ctx.moveTo(firstX * this.zoomFactor + offsetModelX, firstY * this.zoomFactor + offsetModelY);
                    this.ctx.lineTo(secondX * this.zoomFactor + offsetModelX, secondY * this.zoomFactor + offsetModelY);
                    this.ctx.lineTo(thirdX * this.zoomFactor + offsetModelX, thirdY * this.zoomFactor + offsetModelY);
                    this.ctx.closePath();
                    this.ctx.fill();
                }
            }

            prev = line;
        }

        retractRestart.forEach(pos => {
            if (pos.type === 'RETRACT') {
                this.ctx.fillStyle = '#ff1e0d';
            } else {
                this.ctx.fillStyle = '#ffa500';
            }

            this.ctx.beginPath();
            this.ctx.arc(pos.x, pos.y, 2, 0, 2 * Math.PI);
            this.ctx.fill();
        });
    }

    getPolyPoint(x1, y1, x2, y2, offsetModelX, offsetModelY, width, sign) {
        const halfWidth = width / 2;
        const angle = (sign * Math.PI) / 2;
        const vector = { x: x2 - x1, y: y2 - y1 };

        const rx = vector.x * Math.cos(angle) - vector.y * Math.sin(angle);
        const ry = vector.x * Math.sin(angle) + vector.y * Math.cos(angle);

        const xTmp = x1 + rx;
        const yTmp = y1 + ry;
        const dist = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        const rate = halfWidth / dist;
        const newX = x1 + (xTmp - x1) * rate;
        const newY = y1 + (yTmp - y1) * rate;

        return {
            x: (newX * this.zoomFactor + offsetModelX) * this.scaleFactor + this.translateX,
            y: (newY * this.zoomFactor + offsetModelY) * this.scaleFactor + this.translateY,
        };
    }

    isPointInside = (x, y, poly) => {
        let inside = false;
        for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            const xi = poly[i].x,
                yi = poly[i].y;
            const xj = poly[j].x,
                yj = poly[j].y;

            // eslint-disable-next-line no-mixed-operators
            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
                inside = !inside;
            }
        }

        return inside;
    };

    canTranslate(newTranslateX, newTranslateY, newScaleFactor) {
        const plateWidth = this.gridSizeX * this.zoomFactor * newScaleFactor;
        const plateHeight = this.gridSizeY * this.zoomFactor * newScaleFactor;
        const gridSizeExtraX = this.gridSizeExtraX * this.zoomFactor * newScaleFactor;

        const minTranslateX = -(plateWidth / 2 - 100);
        const maxTranslateX = this.domElement.width + (plateWidth / 2 - 100) + gridSizeExtraX - RIGHT_MENU_WIDTH;
        const minTranslateY = -plateHeight / 2 + 100 + TOP_MENU_HEIGHT;
        const maxTranslateY = this.domElement.height + (plateHeight / 2 - 100);

        if (
            newTranslateX < minTranslateX ||
            newTranslateX > maxTranslateX ||
            newTranslateY < minTranslateY ||
            newTranslateY > maxTranslateY
        ) {
            return false;
        }

        return true;
    }
}
