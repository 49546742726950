import { JOB_TYPE } from '../../consts';

export const getPrettyJobType = activeJobType => {
    switch (activeJobType) {
        case JOB_TYPE.export:
            return 'Exporting';
        case JOB_TYPE.exportThing:
            return 'Exporting Thing';
        case JOB_TYPE.print:
            return 'Printing';
        case JOB_TYPE.printMakerbot:
            return 'Printing MakerBot';
        case JOB_TYPE.queue:
            return 'Queueing';
        case JOB_TYPE.immediatePrint:
            return 'Immediate Printing';
        case JOB_TYPE.preview:
            return 'Previewing';
        case JOB_TYPE.previewMakerbot:
            return 'Previewing Makerbot';
        case JOB_TYPE.upload:
            return 'Uploading';
        case JOB_TYPE.convert:
            return 'Converting';
        case JOB_TYPE.downloadDemo:
            return 'Downloading Demo File';
        case JOB_TYPE.simplify:
            return 'Simplifying Model';
        default:
            return 'Slicing';
    }
};

export const cleanErrorMessage = message => {
    return message?.replace('Error: ', '');
};
