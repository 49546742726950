import { ajax } from 'rxjs/ajax';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { compareEpicPayload, protoBufPromise } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { FETCH_BASIC_SLICE_START } from '../state/redux/types';
import { doFetchBasicSliceFailed, doFetchBasicSliceFulfilled } from '../state/redux/actions';

const parseResToActions = ({ response }, action) => {
    return from(protoBufPromise).pipe(
        mergeMap(root => {
            const ChunksMessage = root.lookupType('makerbot.Chunks');
            const message = ChunksMessage.decode(new Uint8Array(response));
            const object = ChunksMessage.toObject(message);
            return of(
                doFetchBasicSliceFulfilled({
                    chunks: object.chunks,
                })
            );
        })
    );
};

const parseErrorToActions = (error, action) => {
    console.log(`Error:`, error.message);
    return of(doFetchBasicSliceFailed()); //do we care to stop slicing? we can just continue and show result from reconstructor.
};

const fetchBasicSliceProto = action$ =>
    action$.pipe(
        ofType(FETCH_BASIC_SLICE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_BASIC_SLICE),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            return ajax({
                url: action.payload.chunksUrl,
                method: 'GET',
                responseType: 'arraybuffer',
            }).pipe(
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default fetchBasicSliceProto;
