import { combineReducers } from 'redux';

import previewState from './preview/reducer';
import modalsState from './modals/reducer';
import userState from './user/reducer';
import errorState from './error/reducer';
import printerState from './printer/reducer';
import modelsState from './models/reducer';
import viewerState from './viewer/reducer';
import notificationState from './notifications/reducer';

const appState = combineReducers({
    previewState,
    modalsState,
    userState,
    errorState,
    printerState,
    modelsState,
    viewerState,
    notificationState,
});

export default appState;
