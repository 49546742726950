import urls from '@makerbot/urls';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';

import { EPICS_DEBOUNCE_TIME, PRINT_PREVIEW_FAILED_MODEL_TOO_LARGE, MAX_PREVIEW_TRAVEL_MOVES } from '../consts';
import { compareEpicPayload, getFailedSliceJob, getTravelMovesFromLayers } from '../helpers/utils';
import { removeBasicSliceFromScene } from '../helpers/utils/print-preview';
import {
    doFetchReconstructedModelFailed,
    doFetchReconstructedModelFulfilled,
    doGenerateModelPreviewFailed,
    doStopUpdateProgressCheckFromPreviewGenerator,
    doTrackProgressFromSlicerFailed,
} from '../state/redux/actions';
import { TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS } from '../state/redux/types';

const parseResToActions = ({ response }, action) => {
    const result = {
        progress: 100,
        completed: true,
        makerbotUrl: action.payload.makerbotUrl,
        ...response,
    };

    // If layers are missing then the response was too large for the server to handle
    // If the total travel moves is too high then the model may crash the browser
    if (
        !result.layers ||
        result.layers.length === 0 ||
        getTravelMovesFromLayers(response.layers) > MAX_PREVIEW_TRAVEL_MOVES
    ) {
        removeBasicSliceFromScene();
        const failedSliceJob = getFailedSliceJob(
            { ...action.payload },
            new Error(PRINT_PREVIEW_FAILED_MODEL_TOO_LARGE)
        );
        failedSliceJob.localId = failedSliceJob.jobId;
        return of(
            doTrackProgressFromSlicerFailed({ failedSliceJob }),
            doGenerateModelPreviewFailed(PRINT_PREVIEW_FAILED_MODEL_TOO_LARGE),
            doStopUpdateProgressCheckFromPreviewGenerator(),
            doFetchReconstructedModelFailed()
        );
    }

    return of(doFetchReconstructedModelFulfilled(result));
};

const parseErrorToActions = (action, error) => {
    console.log(`Error:`, error.message);
    const faildJobId = action.payload.jobId;
    return of(doFetchReconstructedModelFailed(faildJobId));
};

const fetchReconstructedModelStart = action$ =>
    action$.pipe(
        ofType(TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_RECONSTRUCTED_MODEL),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const id = action.payload.id;
            const url = `https://${urls.reconstructor}/toolpathResult/${id}`;
            return ajax.get(url).pipe(
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(action, error))
            );
        })
    );

export default fetchReconstructedModelStart;
