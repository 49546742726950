import { ajax } from 'rxjs/ajax';
import { concat, of, throwError, timer } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, concatMap, retryWhen, delay, take, takeUntil } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { CONVERTER_ERROR_IDS, EPICS_DEBOUNCE_TIME } from '../consts';
import { STOP_CONVERT_FILE, STOP_UPDATE_CONVERT_FILE_PROGRESS, TRACK_CONVERT_FILE_START } from '../state/redux/types';
import {
    doRunConversionJobFulfilled,
    doRunConversionJobFail,
    doStopUpdateConvertProgress,
    doUpdateConvertProgressJob,
    doSetModelLoading,
    doShowMissingPartsModal,
    doUpdateConvertJobsCount,
    doSetFileTooRecent,
    doSetFileTooOld,
    doSetFilesUploadedFromFolder,
    doSetNotification,
    doSetComparisonModeEnabled,
} from '../state/redux/actions';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResToActions = ({ response }, action, filesUploadedFromFolder) => {
    if (response.failed && !response.errorIds?.length) {
        return parseErrorToActions('Conversion failed');
    }

    if (response.stopped) {
        console.log('Conversion canceled');
        return of(doSetFilesUploadedFromFolder(false), doStopUpdateConvertProgress());
    }

    if (response.completed) {
        console.log('File converted successfully');
        const links = [
            ...response.assemblyCads.map(obj => {
                return { url: obj.url, name: obj.name };
            }),
            ...response.cads.map(obj => {
                return { url: obj.url, name: obj.name };
            }),
        ];
        const args = [doSetFilesUploadedFromFolder(false)];
        if (links.length === 0) {
            console.warn('Links list is empty');
            args.push(doSetModelLoading({ modelLoading: false }));
            if (filesUploadedFromFolder) {
                args.push(doSetNotification(NOTIFICATION_TYPE.FOLDER_DOESNT_CONTAIN_SUPPORTED_MODELS));
            } else {
                args.push(doSetNotification(NOTIFICATION_TYPE.FILES_DOESNT_CONTAIN_SUPPORTED_MODELS));
            }
            if (response.errorIds?.length) {
                response.errorIds.forEach(errorId => {
                    if (errorId === CONVERTER_ERROR_IDS.A3D_LOAD_FILE_TOO_RECENT) {
                        args.push(doSetFileTooRecent(true));
                    } else if (errorId === CONVERTER_ERROR_IDS.A3D_LOAD_FILE_TOO_OLD) {
                        args.push(doSetFileTooOld(true));
                    }
                });
            }
            args.push(doSetComparisonModeEnabled(false));
            args.push(doStopUpdateConvertProgress());
            return of(...args);
        }
        args.push(
            doRunConversionJobFulfilled({
                ...action.payload,
                links,
                metaInfo: {
                    cads: response.cads,
                    assemblyCads: response.assemblyCads,
                    trees: response.trees,
                    skipped: response.skipped,
                },
            })
        );
        if (response.trees?.length) {
            const hasMissingParts = response.trees.some(el => !!el.missingParts?.length);
            if (hasMissingParts) {
                args.push(doShowMissingPartsModal(true));
            }
        }
        args.push(doUpdateConvertJobsCount(response.assemblyCads.length + response.cads.length));
        if (response.errorIds?.length) {
            response.errorIds.forEach(errorId => {
                if (errorId === CONVERTER_ERROR_IDS.A3D_LOAD_FILE_TOO_RECENT) {
                    args.push(doSetFileTooRecent(true));
                } else if (errorId === CONVERTER_ERROR_IDS.A3D_LOAD_FILE_TOO_OLD) {
                    args.push(doSetFileTooOld(true));
                }
            });
        }
        args.push(doStopUpdateConvertProgress());
        return of(...args);
    }

    return of(doUpdateConvertProgressJob());
};

const parseErrorToActions = error => {
    console.log('Unable to convert file:', error);
    return of(
        doSetComparisonModeEnabled(false),
        doSetFilesUploadedFromFolder(false),
        doSetModelLoading({ modelLoading: false }),
        doSetNotification(NOTIFICATION_TYPE.CONVERSION_FAILED),
        doRunConversionJobFail(),
        doStopUpdateConvertProgress(),
    );
};

const trackUpdateConvertJobProgress = (action$, state$) =>
    action$.pipe(
        ofType(TRACK_CONVERT_FILE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.TRACK_CONVERSION_JOB),
        mergeMap(action => {
            const url = `https://${urls.converter}/convert/${action.payload.convertId}`;
            const fetchProgress = ajax.get(url);
            const filesUploadedFromFolder = state$?.value?.appState?.viewerState?.filesUploadedFromFolder;

            return timer(0, 1000).pipe(
                concatMap(() => fetchProgress),
                retryWhen(error => error.pipe(delay(2000), take(3), concat(throwError(error)))),
                mergeMap(response => parseResToActions(response, action, filesUploadedFromFolder)),
                catchError(error => parseErrorToActions(error)),
                takeUntil(action$.pipe(ofType(STOP_UPDATE_CONVERT_FILE_PROGRESS, STOP_CONVERT_FILE)))
            );
        })
    );

export default trackUpdateConvertJobProgress;
