import { LOCAL_STORAGE_KEYS } from '../consts';

/**
 * CrossFrameStorage is a wrapper for saving data to localStorage when working
 * with cross-domain iframes. Top / parent window gets an instance of
 * CrossFrameStorageServer while any non-matching window object will get an
 * instance of CrossFrameStorageClient. Communication is done over
 * window.postMessage(). Due to the nature of postMessage communication
 * a client will setup an event listener and then send a request to the
 * parent window for data. The message sent in the event includes a
 * unique id, which is also included in the response from the server
 * instance. Using this id, the client is able to filter the events,
 * capture the response to the initial request, and then remove the
 * corresponding event listener to keep resources lean.
 */

/**
 * Handles requesting data from parent / top window's localStorage.
 */
export class CrossFrameStorageClient {
    static counter = 0;

    constructor(options = {}) {
        this.options = {
            keys: [],
            ...options,
        };
        this.cachedData = {};
        this.dirty = {};
        this.installed = false;
    }

    async install() {
        if (!this.installed) {
            for (const key of this.options.keys) {
                // console.log('fetching ', key);
                this.cachedData[key] = await this.fetchItem(key);
                // console.log(key, ' = ', this.cachedData[key]);
                this.dirty[key] = false;
            }
            // console.log('done loading keys', vals);
            window.addEventListener('beforeunload', this.setAll.bind(this));
            this.installed = true;
        }
    }

    setAll(force = false) {
        const keys = this.options.keys.filter(k => this.dirty[k] || force);
        for (const key of keys) {
            this.setItem(key, this.cachedData[key]);
        }
    }

    getItem(key) {
        if (this.dirty[key] === undefined) {
            console.warn(`CrossFrameStorageClient var ${key} has not been retrieved and cached yet.`);
            this.fetchItem(key);
            return undefined;
        }
        return this.cachedData[key];
    }

    fetchItem(key) {
        return new Promise((resolve, reject) => {
            const msg = {
                type: 'CrossFrameStorage',
                id: `getItem_${key}_${CrossFrameStorageClient.counter++}`,
                cmd: 'getItem',
                args: [key],
            };
            const listener = evt => {
                if (evt.data?.type === msg.type && evt.data?.id === msg.id) {
                    window.removeEventListener('message', listener);
                    this.cachedData[key] = evt.data.value;
                    this.dirty[key] = false;
                    resolve(evt.data.value);
                }
            };
            window.addEventListener('message', listener);
            window.top.postMessage(msg, '*');
        });
    }

    setItem(varName, value) {
        this.cachedData[varName] = value;
        this.dirty[varName] = true;
        const msg = {
            type: 'CrossFrameStorage',
            id: `setItem_${varName}_${CrossFrameStorageClient.counter++}`,
            cmd: 'setItem',
            args: [varName, value],
        };
        const listener = evt => {
            if (evt.data?.type === msg.type && evt.data.id === msg.id) {
                window.removeEventListener('message', listener);
                this.dirty[varName] = !evt.data.success;
            }
        };
        window.addEventListener('message', listener);
        window.top.postMessage(msg, '*');
    }
}

/**
 * Handles access to top window's localStorage from either top / parent
 * window or from embedded iframes.
 */
export class CrossFrameStorageServer {
    install() {
        window.addEventListener('message', this.handleMessage.bind(this));
    }

    handleMessage(evt) {
        if (evt.data?.type === 'CrossFrameStorage') {
            const msg = evt.data;
            const {
                args: [key, value],
                cmd,
            } = msg;
            const scopedKey = `${evt.origin}_${key}`;
            if (cmd === 'getItem') {
                msg.value = this.getItem(scopedKey);
                evt.source.postMessage(msg, evt.origin);
            } else if (cmd === 'setItem') {
                this.setItem(scopedKey, value);
                msg.success = true;
                evt.source.postMessage(msg, evt.origin);
            }
        }
    }

    getItem(key) {
        const val = window.localStorage.getItem(key);
        return JSON.parse(val);
    }

    // Needs to match signature of client in case server is used instead
    async fetchItem(key) {
        return this.getItem(key);
    }

    setItem(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
}

export class CrossFrameStorage {
    static inst = null;

    static getInstance(options = {}) {
        if (!CrossFrameStorage.inst) {
            if (window === window.top) {
                CrossFrameStorage.inst = new CrossFrameStorageServer();
            } else {
                CrossFrameStorage.inst = new CrossFrameStorageClient(options);
            }
        }
        return CrossFrameStorage.inst;
    }
}

export const crossFrameStorage = CrossFrameStorage.getInstance({ keys: Object.keys(LOCAL_STORAGE_KEYS) });
