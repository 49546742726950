import { ofType } from 'redux-observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { HANDLE_CHANGE_PRINTER_START } from '../state/redux/types';
import { updateTargetDeviceChange } from '../state/redux/actions';

import { compareEpicPayload } from '../helpers/utils';
import { BOT_TYPE_ENUM, EPICS_DEBOUNCE_TIME } from '../consts';
import { getPrinterBuildVolume, getPrinterName } from '../helpers/printerUtils';

const handleTargetDeviceChange = action$ =>
    action$.pipe(
        ofType(HANDLE_CHANGE_PRINTER_START),
        debounceTime(EPICS_DEBOUNCE_TIME.HANDLE_TARGET_DEVICE_CHANGE),
        distinctUntilChanged(compareEpicPayload),
        map(action => {
            let device;
            const { printer_id } = action.payload;
            const getDeviceByPrinterId = printerId => {
                const newDevice = {
                    name: getPrinterName(printerId),
                    type: printerId,
                    printer_id: printerId,
                    prettyPrinterType: getPrinterName(printerId),
                };
                newDevice.volume = getPrinterBuildVolume(printerId);
                newDevice.expandAxisX = 0;
                return newDevice;
            };

            // ARCHETYPE PRINTERS
            // bot_type is used as printer_id
            if (Object.keys(BOT_TYPE_ENUM).includes(printer_id)) {
                device = getDeviceByPrinterId(printer_id);
            } else {
                // REFLECTOR PRINTERS
                const { devices } = action.payload;
                const existingDevice = devices.find(device => device.printer_id === printer_id);
                if (existingDevice) {
                    device = existingDevice;
                    Object.assign(device, { prettyPrinterType: getPrinterName(device.status.bot_type) });
                } else {
                    device = getDeviceByPrinterId('lava_f');
                    device.isPrinterMissing = true;
                }
            }
            return updateTargetDeviceChange(device);
        })
    );

export default handleTargetDeviceChange;
