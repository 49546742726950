import { ofType } from 'redux-observable';
import { fromEvent, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { TRACK_FAIL_JOB_STATUS_FROM_TEAMS } from '../state/redux/types';
import {
    doRemoveOperatingJob,
    doStopUpdateProgressCheckFromSlicer,
    doStopUpdateProgressCheckFromSlicerBasicSlice,
    doTrackProgressForBasicSliceFailed,
    doTrackProgressFromSlicerFailed,
} from '../state/redux/actions';
import { getFailedSliceJob } from '../helpers/utils';

const trackFailJobStatusFromTeams = (action$, state$) =>
    action$.pipe(
        ofType(TRACK_FAIL_JOB_STATUS_FROM_TEAMS),
        mergeMap(() => {
            const teamsUrl = urls.teams;

            return fromEvent(window, 'message').pipe(
                filter(event => {
                    const url = `${teamsUrl.includes('local') ? 'http' : 'https'}://${teamsUrl}`;
                    return event.origin === url && !!event.data?.fail_slice;
                }),
                mergeMap(event => {
                    const { fail_slice } = event.data;
                    const sliceJobs = state$.value?.appState?.previewState?.sliceJobs;
                    const foundJob = sliceJobs.find(job => job.id === fail_slice.id);

                    if (foundJob) {
                        const payload = { failedSliceJob: getFailedSliceJob(foundJob, fail_slice?.message) };

                        if (fail_slice.job_id) {
                            payload.failedSliceJob.localTeamsId = fail_slice.job_id;
                        }

                        return of(
                            doRemoveOperatingJob(fail_slice.id),
                            doTrackProgressFromSlicerFailed(payload),
                            doStopUpdateProgressCheckFromSlicerBasicSlice(),
                            doStopUpdateProgressCheckFromSlicer(),
                            doTrackProgressForBasicSliceFailed()
                        );
                    }

                    const heldSliceJob = state$.value?.appState?.previewState?.heldSlicePayload?.currentJob;
                    if (heldSliceJob && heldSliceJob.localId === fail_slice.job_id) {
                        const payload = { failedSliceJob: getFailedSliceJob(heldSliceJob, fail_slice?.message) };

                        return of(doRemoveOperatingJob(fail_slice.id), doTrackProgressFromSlicerFailed(payload));
                    }

                    return of();
                }),
                catchError(error => {
                    console.error(error);
                    return of();
                })
            );
        })
    );

export default trackFailJobStatusFromTeams;
