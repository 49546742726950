import {
    GET_SPOOLS_INFO_START,
    GET_SPOOLS_INFO_SUCCESS,
    GET_SPOOLS_INFO_FAILED,
    PREVIEW_SET_MAKERBOT_AVAILABLE,
    MODELS_SET_CHUNKS_READY,
    PREVIEW_SET_PRINT_PREVIEW_ENABLED,
    PREVIEW_SET_PREVIEW_MODE,
    PREVIEW_SET_MAX_LAYER,
    PREVIEW_SET_MAX_MOVE,
    PREVIEW_SET_CURRENT_MOVE,
    PREVIEW_SET_MODEL_VISIBILITY,
    PREVIEW_SET_TRAVEL_MOVES_VISIBILITY,
    PREVIEW_SET_RETRACT_VISIBILTY,
    PREVIEW_SET_RESTART_VISIBILTY,
    PREVIEW_SET_PT_VISIBILITY,
    PREVIEW_SET_CURRENT_LAYER,
    PRINTER_SET_JOB_FILENAME,
    FETCH_PROFILE_START,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAIL,
    SEND_JOB_TO_SLICER_START,
    SEND_JOB_TO_SLICER_SUCCESS,
    SEND_JOB_TO_SLICER_FAIL,
    TRACK_PROGRESS_FROM_SLICER_START,
    TRACK_PROGRESS_FROM_SLICER_SUCCESS,
    TRACK_PROGRESS_FROM_SLICER_FAIL,
    UPDATE_PROGRESS_FROM_SLICER,
    STOP_UPDATE_POLLING_FROM_SLICER,
    STOP_UPDATE_POLLING_FROM_SLICER_BASIC_SLICE,
    UPLOAD_FILE_TO_STORAGE_START,
    UPLOAD_FILE_TO_STORAGE_SUCCESS,
    UPLOAD_FILE_TO_STORAGE_FAIL,
    FETCH_RECONSTRUCTED_MODEL_SUCCESS,
    FETCH_RECONSTRUCTED_MODEL_FAIL,
    RESET_RECONSTRUCTED_MODEL,
    FETCH_TOOLPATH_START,
    FETCH_TOOLPATH_FAIL,
    HANDLE_MODEL_PREVIEW_START,
    HANDLE_MODEL_PREVIEW_SUCCESS,
    HANDLE_MODEL_PREVIEW_FAIL,
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START,
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS,
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_FAIL,
    UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR,
    STOP_UPDATE_POLLING_FROM_PREVIEW_GENERATOR,
    UPDATE_MAKERBOT_FILE_LAST_PREVIEW_LINK,
    PREVIEW_SET_RAFT_VISIBILITY,
    PREVIEW_SET_BRIM_VISIBILITY,
    PREVIEW_SET_CURRENT_LAYER_INFO,
    PREVIEW_SET_MOVES,
    FETCH_BASIC_SLICE_START,
    FETCH_BASIC_SLICE_SUCCESS,
    FETCH_BASIC_SLICE_FAIL,
    PREVIEW_SET_TOOLPATH_VISIBILITY,
    SET_SORTED_MODEL,
    PREVIEW_SET_COMPARISON_MODE_ENABLED,
    PREVIEW_SET_COMPARISON_MODELS_LOADED,
    PREVIEW_SET_SHOW_LAYER_DETAILS,
    RESET_ALL_TOOLPATH_VISIBILITY,
    RESET_ALL_CHUNKS,
    TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_START,
    TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_SUCCESS,
    TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_FAIL,
    FETCH_UPLOAD_URLS_START,
    FETCH_UPLOAD_URLS_SUCCESS,
    FETCH_UPLOAD_URLS_FAIL,
    UPLOAD_TO_BUCKET_START,
    UPLOAD_TO_BUCKET_SUCCESS,
    UPLOAD_TO_BUCKET_FAIL,
    RUN_ZIP_JOB_START,
    RUN_ZIP_JOB_SUCCESS,
    RUN_ZIP_JOB_FAIL,
    UPDATE_ZIP_PROGRESS_JOB,
    STOP_UPDATE_ZIP_PROGRESS_JOB,
    FETCH_DOWNLOAD_URLS_FAIL,
    FETCH_DOWNLOAD_URLS_SUCCESS,
    FETCH_DOWNLOAD_URLS_START,
    DOWNLOAD_FILE_FROM_SIGNED_START,
    DOWNLOAD_FILE_FROM_SIGNED_SUCCESS,
    DOWNLOAD_FILE_FROM_SIGNED_FAIL,
    SET_SORTED_CURRENT_TOOLPATH,
    PREVIEW_SET_THING_AVAILABLE,
    PREVIEW_SET_COMPARISON_MODELS,
    SET_THUMBNAILS_LINK,
    INIT_SLICE_JOB,
    UPDATE_SLICE_JOB,
    CANCEL_SLICE_JOB,
    SET_PREVIEW_ID_FOR_PREVIEW_MODEL,
    PREVIEW_SET_IS_VOLUMETRIC_ANIMATION_PLAYING,
    TRACK_JOB_STATUS_FROM_TEAMS,
    TRACK_FAIL_JOB_STATUS_FROM_TEAMS,
    RESET_CURRENT_SLICE_JOB_ID,
    ADD_OPERATING_JOB,
    UPDATE_OPERATING_JOB,
    DELETE_OPERATING_JOB,
    RESET_CURRENT_FAILED_SLICE_JOB,
    TRACK_RESTART_SLICE_JOB_FROM_TEAMS,
    SET_JOB_TO_RESTART,
    RESET_JOB_TO_RESTART,
    CANCEL_SLICE_JOB_SUCCESS,
    CANCEL_SLICE_JOB_FAIL,
    FETCH_TOOLPATH_SUCCESS,
    SET_DEFAULT_MODELS_PREVIEW,
    UPDATE_JOB_FILE_NAME,
    SET_PREVIEW_MODELS,
    SET_HELD_SLICE_PAYLOAD,
} from './types';

export const doGetSpoolsInfoStart = payload => {
    return {
        type: GET_SPOOLS_INFO_START,
        payload,
    };
};

export const doGetSpoolsInfoFulfilled = payload => {
    return {
        type: GET_SPOOLS_INFO_SUCCESS,
        payload,
    };
};

export const doGetSpoolsInfoFailed = payload => {
    return {
        type: GET_SPOOLS_INFO_FAILED,
        payload,
    };
};

export const doSetJobFileName = jobFileName => {
    return {
        type: PRINTER_SET_JOB_FILENAME,
        payload: { jobFileName },
    };
};

export const doSetMakerbotAvailable = available => {
    return {
        type: PREVIEW_SET_MAKERBOT_AVAILABLE,
        payload: available,
    };
};

export const doSetThingLinkAvailable = available => {
    return {
        type: PREVIEW_SET_THING_AVAILABLE,
        payload: { available },
    };
};

export const doSetChunksReady = ({ ready, previewId }) => {
    return {
        type: MODELS_SET_CHUNKS_READY,
        payload: { ready, previewId },
    };
};

export const doSetPrintPreviewEnabled = printPreviewEnabled => {
    return {
        type: PREVIEW_SET_PRINT_PREVIEW_ENABLED,
        payload: { printPreviewEnabled },
    };
};

export const doSetPrintPreviewMode = printPreviewMode => {
    return {
        type: PREVIEW_SET_PREVIEW_MODE,
        payload: { printPreviewMode },
    };
};

export const doSetMaxLayer = ({ maxLayer, previewId }) => {
    return {
        type: PREVIEW_SET_MAX_LAYER,
        payload: { maxLayer, previewId },
    };
};

export const doSetMaxMove = ({ maxMove, previewId }) => {
    return {
        type: PREVIEW_SET_MAX_MOVE,
        payload: { maxMove, previewId },
    };
};

export const doSetCurrentMove = ({ currentMove, previewId }) => {
    return {
        type: PREVIEW_SET_CURRENT_MOVE,
        payload: { currentMove, previewId },
    };
};

export const doSetCurrentLayer = ({ currentLayer, previewId }) => {
    return {
        type: PREVIEW_SET_CURRENT_LAYER,
        payload: { currentLayer, previewId },
    };
};

export const doSetCurrentLayerInfo = ({ info, previewId }) => {
    return {
        type: PREVIEW_SET_CURRENT_LAYER_INFO,
        payload: { info, previewId },
    };
};

export const doSetMoves = ({ moves, previewId }) => {
    return {
        type: PREVIEW_SET_MOVES,
        payload: { moves, previewId },
    };
};

export const doSetModelVisibility = ({ materialVisible, supportVisible, previewId }) => {
    return {
        type: PREVIEW_SET_MODEL_VISIBILITY,
        payload: { materialVisible, supportVisible, previewId },
    };
};

export const doSetTravelMoveVisibility = ({ travelMoveVisible, previewId }) => {
    return {
        type: PREVIEW_SET_TRAVEL_MOVES_VISIBILITY,
        payload: { travelMoveVisible, previewId },
    };
};

export const doSetRetractVisiblity = ({ retractVisible, previewId }) => {
    return {
        type: PREVIEW_SET_RETRACT_VISIBILTY,
        payload: { retractVisible, previewId },
    };
};

export const doSetRestartVisible = ({ restartVisible, previewId }) => {
    return {
        type: PREVIEW_SET_RESTART_VISIBILTY,
        payload: { restartVisible, previewId },
    };
};

export const doSetPTVisibility = ({ ptVisible, previewId }) => {
    return {
        type: PREVIEW_SET_PT_VISIBILITY,
        payload: { ptVisible, previewId },
    };
};

export const doSetRaftVisibility = ({ raftVisible, previewId }) => {
    return {
        type: PREVIEW_SET_RAFT_VISIBILITY,
        payload: { raftVisible, previewId },
    };
};

export const doSetBrimVisibility = ({ brimVisible, previewId }) => {
    return {
        type: PREVIEW_SET_BRIM_VISIBILITY,
        payload: { brimVisible, previewId },
    };
};

export const doSetToolpathVisibility = ({ toolpathVisible, previewId }) => {
    return {
        type: PREVIEW_SET_TOOLPATH_VISIBILITY,
        payload: { toolpathVisible, previewId },
    };
};

export const doSetLayerDetailsVisibility = ({ showLayerDetails, previewId }) => {
    return {
        type: PREVIEW_SET_SHOW_LAYER_DETAILS,
        payload: { showLayerDetails, previewId },
    };
};

export const doSetIsVolumetricAnimationPlaying = ({ isVolumetricAnimationPlaying, previewId }) => {
    return {
        type: PREVIEW_SET_IS_VOLUMETRIC_ANIMATION_PLAYING,
        payload: { isVolumetricAnimationPlaying, previewId },
    };
};

export const doFetchProfile = payload => {
    return {
        type: FETCH_PROFILE_START,
        payload,
    };
};

export const doFetchProfileFulfilled = payload => {
    return {
        type: FETCH_PROFILE_SUCCESS,
        payload,
    };
};

export const doFetchProfileFailed = payload => {
    return {
        type: FETCH_PROFILE_FAIL,
        payload,
    };
};

export const doUploadFileToStorage = payload => {
    return {
        type: UPLOAD_FILE_TO_STORAGE_START,
        payload,
    };
};

export const doUploadFileToStorageFulfilled = payload => {
    return {
        type: UPLOAD_FILE_TO_STORAGE_SUCCESS,
        payload,
    };
};

export const doUploadFileToStorageFailed = payload => {
    return {
        type: UPLOAD_FILE_TO_STORAGE_FAIL,
        payload,
    };
};
export const doFetchPresignedDownloadURLs = payload => {
    return {
        type: FETCH_DOWNLOAD_URLS_START,
        payload,
    };
};

export const doFetchPresignedDownloadURLsFulfilled = payload => {
    return {
        type: FETCH_DOWNLOAD_URLS_SUCCESS,
        payload,
    };
};

export const doFetchPresignedDownloadURLsFailed = payload => {
    return {
        type: FETCH_DOWNLOAD_URLS_FAIL,
        payload,
    };
};

export const doFetchPresignedUploadURLs = payload => {
    return {
        type: FETCH_UPLOAD_URLS_START,
        payload,
    };
};

export const doFetchPresignedUploadURLsFulfilled = payload => {
    return {
        type: FETCH_UPLOAD_URLS_SUCCESS,
        payload,
    };
};

export const doFetchPresignedUploadURLsFailed = payload => {
    return {
        type: FETCH_UPLOAD_URLS_FAIL,
        payload,
    };
};

export const doUploadFilesToBucket = payload => {
    return {
        type: UPLOAD_TO_BUCKET_START,
        payload,
    };
};

export const doUploadFilesToBucketFulfilled = payload => {
    return {
        type: UPLOAD_TO_BUCKET_SUCCESS,
        payload,
    };
};

export const doUploadFilesToBucketFailed = payload => {
    return {
        type: UPLOAD_TO_BUCKET_FAIL,
        payload,
    };
};

export const doRunZipJob = payload => {
    return {
        type: RUN_ZIP_JOB_START,
        payload,
    };
};

export const doRunZipJobFulfilled = payload => {
    return {
        type: RUN_ZIP_JOB_SUCCESS,
        payload,
    };
};

export const doRunZipJobFailed = payload => {
    return {
        type: RUN_ZIP_JOB_FAIL,
        payload,
    };
};

export const doUpdateZipProgressJob = payload => {
    return {
        type: UPDATE_ZIP_PROGRESS_JOB,
        payload,
    };
};

export const doStopUpdateZipProgressCheck = () => {
    return { type: STOP_UPDATE_ZIP_PROGRESS_JOB };
};

export const doTrackProgressFromSlicer = payload => {
    return {
        type: TRACK_PROGRESS_FROM_SLICER_START,
        payload,
    };
};

export const doTrackProgressFromSlicerFulfilled = payload => {
    return {
        type: TRACK_PROGRESS_FROM_SLICER_SUCCESS,
        payload,
    };
};

export const doTrackProgressFromSlicerFailed = payload => {
    return {
        type: TRACK_PROGRESS_FROM_SLICER_FAIL,
        payload,
    };
};

export const doTrackProgressForBasicSlice = payload => {
    return {
        type: TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_START,
        payload,
    };
};

export const doTrackProgressForBasicSliceFulfilled = payload => {
    return {
        type: TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_SUCCESS,
        payload,
    };
};

export const doTrackProgressForBasicSliceFailed = payload => {
    return {
        type: TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_FAIL,
        payload,
    };
};

export const doUpdateSliceProgressJob = payload => {
    return { type: UPDATE_PROGRESS_FROM_SLICER, payload };
};

export const doCancelSliceJob = payload => {
    return { type: CANCEL_SLICE_JOB, payload };
};

export const doCancelSliceJobSuccess = payload => {
    return { type: CANCEL_SLICE_JOB_SUCCESS, payload };
};

export const doCancelSliceJobFail = payload => {
    return { type: CANCEL_SLICE_JOB_FAIL, payload };
};

export const doTrackJobStatusFromTeams = payload => {
    return { type: TRACK_JOB_STATUS_FROM_TEAMS, payload };
};

export const doTrackFailJobStatusFromTeams = payload => {
    return { type: TRACK_FAIL_JOB_STATUS_FROM_TEAMS, payload };
};

export const doTrackRestartSliceJobFromTeams = payload => {
    return { type: TRACK_RESTART_SLICE_JOB_FROM_TEAMS, payload };
};

export const doStopUpdateProgressCheckFromSlicer = () => {
    return { type: STOP_UPDATE_POLLING_FROM_SLICER };
};

export const doStopUpdateProgressCheckFromSlicerBasicSlice = () => {
    return { type: STOP_UPDATE_POLLING_FROM_SLICER_BASIC_SLICE };
};

export const doSendJobToSlicer = payload => {
    return {
        type: SEND_JOB_TO_SLICER_START,
        payload,
    };
};

export const doSendJobToSlicerFulfilled = payload => {
    return {
        type: SEND_JOB_TO_SLICER_SUCCESS,
        payload,
    };
};

export const doSendJobToSlicerFailed = payload => {
    return {
        type: SEND_JOB_TO_SLICER_FAIL,
        payload,
    };
};

export const doDownloadFileFromSignedUrl = payload => {
    return {
        type: DOWNLOAD_FILE_FROM_SIGNED_START,
        payload,
    };
};

export const doDownloadFileFromSignedUrlFulfilled = payload => {
    return {
        type: DOWNLOAD_FILE_FROM_SIGNED_SUCCESS,
        payload,
    };
};

export const doDownloadFileFromSignedUrlFailed = payload => {
    return {
        type: DOWNLOAD_FILE_FROM_SIGNED_FAIL,
        payload,
    };
};

export const doTrackProgressFromPreviewGenerator = payload => {
    return {
        type: TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START,
        payload,
    };
};

export const doTrackProgressFromPreviewGeneratorFulfilled = payload => {
    return {
        type: TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_SUCCESS,
        payload,
    };
};

export const doTrackProgressFromPreviewGeneratorFailed = payload => {
    return {
        type: TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_FAIL,
        payload,
    };
};

export const doUpdatePreviewProgressJob = payload => {
    return { type: UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR, payload };
};

export const doStopUpdateProgressCheckFromPreviewGenerator = () => {
    return { type: STOP_UPDATE_POLLING_FROM_PREVIEW_GENERATOR };
};

export const doUpdateMakerbotFileLastPreviewLink = payload => {
    return { type: UPDATE_MAKERBOT_FILE_LAST_PREVIEW_LINK, payload };
};

export const doGenerateModelPreview = payload => {
    return {
        type: HANDLE_MODEL_PREVIEW_START,
        payload,
    };
};

export const doGenerateModelPreviewFulfilled = payload => {
    return {
        type: HANDLE_MODEL_PREVIEW_SUCCESS,
        payload,
    };
};

export const doGenerateModelPreviewFailed = payload => {
    return {
        type: HANDLE_MODEL_PREVIEW_FAIL,
        payload,
    };
};

export const doFetchToolpath = payload => {
    return {
        type: FETCH_TOOLPATH_START,
        payload,
    };
};

export const doFetchToolpathFailed = payload => {
    return {
        type: FETCH_TOOLPATH_FAIL,
        payload,
    };
};

export const doFetchToolpathFulfilled = payload => {
    return {
        type: FETCH_TOOLPATH_SUCCESS,
        payload,
    };
};

export const doFetchReconstructedModelFulfilled = result => {
    return {
        type: FETCH_RECONSTRUCTED_MODEL_SUCCESS,
        payload: { result },
    };
};

export const doFetchReconstructedModelFailed = failedJobId => {
    return {
        type: FETCH_RECONSTRUCTED_MODEL_FAIL,
        payload: { failedJobId },
    };
};

export const doResetReconstructedModel = () => {
    return {
        type: RESET_RECONSTRUCTED_MODEL,
    };
};

export const doInitSliceJob = payload => {
    return {
        type: INIT_SLICE_JOB,
        payload,
    };
};
export const doUpdateJobFileName = payload => {
    return {
        type: UPDATE_JOB_FILE_NAME,
        payload,
    };
};

export const doResetCurrentSliceJobId = payload => {
    return {
        type: RESET_CURRENT_SLICE_JOB_ID,
        payload,
    };
};

export const doUpdateSliceJob = (sliceJob, updateLocalStorage = false) => {
    return {
        type: UPDATE_SLICE_JOB,
        payload: { sliceJob, updateLocalStorage },
    };
};

export const doAddOperatingJob = payload => {
    return {
        type: ADD_OPERATING_JOB,
        payload,
    };
};

export const doUpdateOperatingJob = (sliceJobId, ready) => {
    return {
        type: UPDATE_OPERATING_JOB,
        payload: { sliceJobId, ready },
    };
};

export const doRemoveOperatingJob = (sliceJobId, localId) => {
    return {
        type: DELETE_OPERATING_JOB,
        payload: { sliceJobId, localId },
    };
};

export const doFetchBasicSlice = payload => {
    return {
        type: FETCH_BASIC_SLICE_START,
        payload,
    };
};

export const doFetchBasicSliceFulfilled = payload => {
    return {
        type: FETCH_BASIC_SLICE_SUCCESS,
        payload,
    };
};

export const doFetchBasicSliceFailed = payload => {
    return {
        type: FETCH_BASIC_SLICE_FAIL,
        payload,
    };
};

export const doSetSortedModel = ({ sortedModel, previewId }) => {
    return {
        type: SET_SORTED_MODEL,
        payload: { sortedModel, previewId },
    };
};

export const doSetComparisonModeEnabled = comparisonModeEnabled => {
    return {
        type: PREVIEW_SET_COMPARISON_MODE_ENABLED,
        payload: { comparisonModeEnabled },
    };
};

export const doSetComparisonModelsLoaded = comparisonModelsLoaded => {
    return {
        type: PREVIEW_SET_COMPARISON_MODELS_LOADED,
        payload: { comparisonModelsLoaded },
    };
};

export const doSetComparisonModels = payload => {
    return {
        type: PREVIEW_SET_COMPARISON_MODELS,
        payload,
    };
};
export const doSetPreviewModels = payload => {
    return {
        type: SET_PREVIEW_MODELS,
        payload,
    };
};
export const doResetAllToolpathVisibility = () => {
    return {
        type: RESET_ALL_TOOLPATH_VISIBILITY,
    };
};

export const doResetAllChunks = () => {
    return {
        type: RESET_ALL_CHUNKS,
    };
};

export const doSetCurrentToolpath = ({ currentToolpath, previewId }) => {
    return {
        type: SET_SORTED_CURRENT_TOOLPATH,
        payload: { currentToolpath, previewId },
    };
};

export const doSetThumbnailsLink = link => {
    return {
        type: SET_THUMBNAILS_LINK,
        payload: link,
    };
};

export const doSetPreviewIdForPreviewModel = previewId => {
    return {
        type: SET_PREVIEW_ID_FOR_PREVIEW_MODEL,
        payload: { previewId },
    };
};

export const doResetCurrentFailedSliceJobId = () => {
    return {
        type: RESET_CURRENT_FAILED_SLICE_JOB,
    };
};

export const doSetJobToRestart = jobToRestart => {
    return {
        type: SET_JOB_TO_RESTART,
        payload: { jobToRestart },
    };
};

export const doResetJobToRestart = () => {
    return {
        type: RESET_JOB_TO_RESTART,
    };
};

export const doSetDefaultModelsPreview = payload => {
    return {
        type: SET_DEFAULT_MODELS_PREVIEW,
        payload,
    };
};

export const doSetHeldSlicePayload = payload => {
    return {
        type: SET_HELD_SLICE_PAYLOAD,
        payload,
    };
};
