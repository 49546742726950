import {
    SEND_PRINTER_COMMAND_START,
    SEND_PRINTER_COMMAND_SUCCESS,
    SEND_PRINTER_COMMAND_FAIL,
    FETCH_REFLECTOR_AUTH_PRINTERS_START,
    FETCH_REFLECTOR_AUTH_PRINTERS_SUCCESS,
    FETCH_REFLECTOR_AUTH_PRINTERS_FAIL,
    HANDLE_PARENT_POST_MESSAGE_START,
    FETCH_WORKSPACE_PRINTERS_SUCCESS,
    FETCH_WORKSPACE_PRINTERS_FAIL,
    FETCH_PRINTER_SCHEMA_START,
    FETCH_PRINTER_SCHEMA_SUCCESS,
    FETCH_PRINTER_SCHEMA_FAILED,
    FETCH_PRINTER_SCHEMA_COMPLETED,
    FETCH_PRINTERS_CONFIGS_START,
    FETCH_PRINTERS_CONFIGS_SUCCESS,
    FETCH_PRINTERS_CONFIGS_FAILED,
    PRINTER_SET_PRINTED_MAKERBOT_FILENAME,
    PRINTER_SET_PRINTER_CONFIG,
    PRINTER_UPDATE_SETTINGS,
    PRINTER_UPDATE_SETTINGS_FOR_6th_GEN,
    PRINTER_UPDATE_PRINT_MODE,
    PRINTER_SET_MODELS_OUTSIDE,
    PRINTER_SET_MODELS_FITS,
    PRINTER_SET_STATUS,
    PRINTER_CLEAR_PRINTER_OVERRIDES,
    PRINTER_SET_OVERRIDES,
    PRINTER_SET_UNKNOW_MATERIAL,
    HANDLE_CHANGE_PRINTER_START,
    HANDLE_CHANGE_PRINTER_SUCCESS,
    PRINTER_SET_PRINT_PREP_PARAMS,
    SET_PRINT_FILES_BLOB,
    SET_THUMBNAILS,
    SET_PRINT_FILE_DATA,
    SET_FILE_NAME,
    UPDATE_PRINTER_SCHEMA,
    PRINTER_SET_PRINTER_MISSING,
    FETCH_CUSTOM_PRINT_MODES_START,
    FETCH_CUSTOM_PRINT_MODES_SUCCESS,
    FETCH_CUSTOM_PRINT_MODES_FAIL,
    SET_SHOW_IMPORT_PRINT_MODE_MODAL,
    SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT,
    IMPORT_CUSTOM_PRINT_MODE_START,
    IMPORT_CUSTOM_PRINT_MODE_SUCCESS,
    IMPORT_CUSTOM_PRINT_MODE_FAIL,
    SET_SHOW_SAVE_PRINT_MODE_MODAL,
    SET_CUSTOM_PRINT_MODE_OVERRIDES,
    SET_PRESELECTED_CUSTOM_PRINT_SETTINGS,
    CREATE_CUSTOM_PRINT_MODE_START,
    CREATE_CUSTOM_PRINT_MODE_SUCCESS,
    CREATE_CUSTOM_PRINT_MODE_FAIL,
    FETCH_CUSTOM_PRINT_SETTINGS_START,
    FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS,
    FETCH_CUSTOM_PRINT_SETTINGS_FAIL,
    CLEAR_CUSTOM_PRINT_SETTINGS_OVERRIDES,
    DELETE_CUSTOM_PRINT_MODE_START,
    DELETE_CUSTOM_PRINT_MODE_SUCCESS,
    DELETE_CUSTOM_PRINT_MODE_FAIL,
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_START,
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_SUCCESS,
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_FAIL,
    SET_EXPORT_PRINT_MODE_ID_FOR_MODAL,
    EXPORT_CUSTOM_PRINT_MODE_START,
    EXPORT_CUSTOM_PRINT_MODE_SUCCESS,
    EXPORT_CUSTOM_PRINT_MODE_FAIL,
    DOWNLOAD_CUSTOM_PRINT_MODE_START,
    DOWNLOAD_CUSTOM_PRINT_MODE_SUCCESS,
    DOWNLOAD_CUSTOM_PRINT_MODE_FAIL,
    SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OVERRIDES_OBJECT,
    SET_VALID_CUSTOM_PRINT_MODE_OVERRIDES,
    UPDATE_SETTINGS_IN_CUSTOM_PRINT_MODE,
    SET_SHOW_PRINT_MODE_WRONG_FORMAT_MODAL,
    PRINTER_SET_PRETTY_STATUS,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_START,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS,
    TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_FAILED,
    UPDATE_PROGRES_FROM_KAITEN_METHOD_EXEC,
    SET_SPOOL_INFO,
    PRINTER_SET_NULL_MATERIAL,
    PRINTER_SET_QUEUE_LENGTH,
    CREATE_CUSTOM_PRINT_MODE_STOP_TRACKING,
    FETCH_LOCAL_PRINTERS_START,
    FETCH_LOCAL_PRINTERS_SUCCESS,
    FETCH_LOCAL_PRINTERS_FAIL,
    REMOVE_LOCAL_PRINTER,
    AUTHORIZE_LOCAL_PRINTER,
    AUTHORIZE_LOCAL_PRINTER_FAIL,
    FETCH_LOCAL_PRINTER_INFO_SUCCESS,
    REAUTHORIZE_LOCAL_PRINTER,
    REAUTHORIZE_LOCAL_PRINTER_FAIL,
    AUTHORIZE_LOCAL_PRINTER_SUCCESS,
    SET_INITIAL_EXTRUDER_MATERIAL_STATE,
    PRINTER_SET_INVALID_MATERIALS_COMBINATION,
    PRINTER_PERMODEL_SET_OVERRIDES,
    FETCH_INITIAL_PRINTER_SCHEMAS_FAILED,
    FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS,
    FETCH_INITIAL_PRINTER_SCHEMAS_START,
    SELECT_PRINT_MODE,
    PRINTER_PERMODEL_CLEAR_OVERRIDES,
    PRINTER_CLEAR_PRINTER_OVERRIDE,
    PRINTER_PERMODEL_CLEAR_OVERRIDE,
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
    SET_NOTIFICATIONS,
    SHAKE_BLOCKING_CTA_SNACKBARS,
    PRINTER_SET_MODELS_BREAKS_VOLUME,
    PRINTER_SET_IS_MAIN_EXTRUDER_MISSING,
    PRINTER_SET_HIGHLIGHTED_WALLS,
    RESET_SECOND_EXTRUDER_MATERIAL,
    SET_IS_SELECTED_CONFIG_EQUAl_LOADED,
} from './types';

export const doSendPrinterCommand = payload => {
    return {
        type: SEND_PRINTER_COMMAND_START,
        payload,
    };
};

export const doSendPrinterCommandFulfilled = payload => {
    return {
        type: SEND_PRINTER_COMMAND_SUCCESS,
        payload,
    };
};

export const doSendPrinterCommandFail = payload => {
    return {
        type: SEND_PRINTER_COMMAND_FAIL,
        payload,
    };
};

export const doFetchReflectorPrinters = userToken => {
    return {
        type: FETCH_REFLECTOR_AUTH_PRINTERS_START,
        payload: { userToken },
    };
};

export const doFetchReflectorPrintersFulfilled = printers => {
    return {
        type: FETCH_REFLECTOR_AUTH_PRINTERS_SUCCESS,
        payload: { printers },
    };
};

export const doFetchReflectorPrintersFailed = error => {
    return {
        type: FETCH_REFLECTOR_AUTH_PRINTERS_FAIL,
        payload: { error },
    };
};

export const doFetchLocalPrinters = () => {
    return {
        type: FETCH_LOCAL_PRINTERS_START,
    };
};

export const doFetchLocalPrintersFulfilled = local_printer => {
    return {
        type: FETCH_LOCAL_PRINTERS_SUCCESS,
        payload: { local_printer },
    };
};

export const doFetchLocalPrintersFailed = error => {
    return {
        type: FETCH_LOCAL_PRINTERS_FAIL,
        payload: { error },
    };
};

export const doAuthorizeLocalPrinter = selected_local_printer => {
    return {
        type: AUTHORIZE_LOCAL_PRINTER,
        payload: { selected_local_printer },
    };
};

export const doAuthorizeLocalPrinterSuccess = ip => {
    return {
        type: AUTHORIZE_LOCAL_PRINTER_SUCCESS,
        payload: { ip },
    };
};

export const doAuthorizeLocalPrinterFail = error => {
    return {
        type: AUTHORIZE_LOCAL_PRINTER_FAIL,
        payload: { error },
    };
};

export const doReauthorizeLocalPrinter = selected_local_printer => {
    return {
        type: REAUTHORIZE_LOCAL_PRINTER,
        payload: { selected_local_printer },
    };
};

export const doReauthorizeLocalPrinterFail = error => {
    return {
        type: REAUTHORIZE_LOCAL_PRINTER_FAIL,
        payload: { error },
    };
};

export const doFetchLocalPrinterInfoSuccess = printer_information => {
    return {
        type: FETCH_LOCAL_PRINTER_INFO_SUCCESS,
        payload: { printer_information },
    };
};

export const doRemoveLocalPrinter = local_printer => {
    return {
        payload: { local_printer },
        type: REMOVE_LOCAL_PRINTER,
    };
};

export const doHandleParentPostMessage = target => {
    return {
        type: HANDLE_PARENT_POST_MESSAGE_START,
        payload: { target },
    };
};

export const doHandleParentPostMessageFailed = error => {
    return {
        type: FETCH_WORKSPACE_PRINTERS_FAIL,
        payload: { error },
    };
};

export const doFetchWorkspacePrintersFulfilled = ({ workspace_archetypes, workspace_printers }) => {
    return {
        type: FETCH_WORKSPACE_PRINTERS_SUCCESS,
        payload: { workspace_archetypes, workspace_printers },
    };
};

export const doFetchPrinterSchema = payload => {
    return {
        type: FETCH_PRINTER_SCHEMA_START,
        payload,
    };
};

export const doFetchPrinterSchemaFulfilled = payload => {
    return {
        type: FETCH_PRINTER_SCHEMA_SUCCESS,
        payload,
    };
};

export const doFetchPrinterSchemaFailed = payload => {
    return {
        type: FETCH_PRINTER_SCHEMA_FAILED,
        payload,
    };
};

export const doFetchPrinterSchemaCompleted = payload => {
    return {
        type: FETCH_PRINTER_SCHEMA_COMPLETED,
        payload,
    };
};

export const doFetchInitialPrinterSchemas = payload => {
    return {
        type: FETCH_INITIAL_PRINTER_SCHEMAS_START,
        payload,
    };
};

export const doFetchInitialPrinterSchemasFulfilled = payload => {
    return {
        type: FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS,
        payload,
    };
};

export const doFetchInitialPrinterSchemasFailed = payload => {
    return {
        type: FETCH_INITIAL_PRINTER_SCHEMAS_FAILED,
        payload,
    };
};

export const doSelectPrintMode = payload => {
    return {
        type: SELECT_PRINT_MODE,
        payload,
    };
};

export const doFetchPrintersConfigs = payload => {
    return {
        type: FETCH_PRINTERS_CONFIGS_START,
        payload,
    };
};

export const doFetchPrintersConfigsFulfilled = payload => {
    return {
        type: FETCH_PRINTERS_CONFIGS_SUCCESS,
        payload,
    };
};

export const doFetchPrintersConfigsFailed = payload => {
    return {
        type: FETCH_PRINTERS_CONFIGS_FAILED,
        payload,
    };
};

export const doSetPrintedMakerbotFilename = printedMakerbotFileName => {
    return {
        type: PRINTER_SET_PRINTED_MAKERBOT_FILENAME,
        payload: { printedMakerbotFileName },
    };
};

export const doSetPrinterConfig = printerConfig => {
    return {
        type: PRINTER_SET_PRINTER_CONFIG,
        payload: { printerConfig },
    };
};

export const doUpdatePrinterSettings = ({ extruders, materials }) => {
    return {
        type: PRINTER_UPDATE_SETTINGS,
        payload: { extruders, materials },
    };
};

export const doUpdate6thGenPrinterSettings = ({ extruders, materials }) => {
    return {
        type: PRINTER_UPDATE_SETTINGS_FOR_6th_GEN,
        payload: { extruders, materials },
    };
};

export const doUpdatePrintMode = printMode => {
    return {
        type: PRINTER_UPDATE_PRINT_MODE,
        payload: { printMode },
    };
};

export const doSetHighlightedWalls = highlightedWalls => {
    return {
        type: PRINTER_SET_HIGHLIGHTED_WALLS,
        payload: { highlightedWalls },
    };
};

export const doSetModelsOutside = ({ isOutside, modelsOutsideArr }) => {
    return {
        type: PRINTER_SET_MODELS_OUTSIDE,
        payload: { isOutside, modelsOutsideArr },
    };
};

export const doSetIsModelsBreakVolume = isModelsBreakVolume => {
    return {
        type: PRINTER_SET_MODELS_BREAKS_VOLUME,
        payload: { isModelsBreakVolume },
    };
};

export const doSetModelsFits = isFits => {
    return {
        type: PRINTER_SET_MODELS_FITS,
        payload: { isFits },
    };
};

export const doSetPrinterStatus = status => {
    return {
        type: PRINTER_SET_STATUS,
        payload: { status },
    };
};

export const doSetPrinterQueueLength = queue_length => {
    return {
        type: PRINTER_SET_QUEUE_LENGTH,
        payload: { queue_length },
    };
};

export const doSetPrinterPrettyStatus = status => {
    return {
        type: PRINTER_SET_PRETTY_STATUS,
        payload: { status },
    };
};

export const doClearPrinterOverrides = keepPrintMode => {
    return {
        type: PRINTER_CLEAR_PRINTER_OVERRIDES,
        payload: { keepPrintMode },
    };
};

export const doClearPerModelOverrides = models => {
    return {
        type: PRINTER_PERMODEL_CLEAR_OVERRIDES,
        payload: { models },
    };
};

export const doClearPrinterOverride = name => {
    return {
        type: PRINTER_CLEAR_PRINTER_OVERRIDE,
        payload: { name },
    };
};

export const doClearPerModelOverride = override => {
    return {
        type: PRINTER_PERMODEL_CLEAR_OVERRIDE,
        payload: { override },
    };
};

export const doSetPrinterOverrides = overrides => {
    return {
        type: PRINTER_SET_OVERRIDES,
        payload: { overrides },
    };
};

export const doSetPerModelOverrides = overrides => {
    return {
        type: PRINTER_PERMODEL_SET_OVERRIDES,
        payload: { overrides },
    };
};

export const doSetUnknowMaterial = isUnknownMaterial => {
    return {
        type: PRINTER_SET_UNKNOW_MATERIAL,
        payload: { isUnknownMaterial },
    };
};

export const doSetNullMaterial = isNullMaterial => {
    return {
        type: PRINTER_SET_NULL_MATERIAL,
        payload: { isNullMaterial },
    };
};

export const doSetInvalidMaterialsCombination = isInvalidMaterialsCombination => {
    return {
        type: PRINTER_SET_INVALID_MATERIALS_COMBINATION,
        payload: { isInvalidMaterialsCombination },
    };
};

export const doSetPrinterMissing = isPrinterMissing => {
    return {
        type: PRINTER_SET_PRINTER_MISSING,
        payload: { isPrinterMissing },
    };
};

export const handleTargetDeviceChange = (printer_id, devices) => {
    return {
        type: HANDLE_CHANGE_PRINTER_START,
        payload: {
            printer_id,
            devices,
        },
    };
};

export const updateTargetDeviceChange = device => {
    return {
        type: HANDLE_CHANGE_PRINTER_SUCCESS,
        payload: { device },
    };
};

export const doSetPrintPrepParams = printPrepParams => {
    return {
        type: PRINTER_SET_PRINT_PREP_PARAMS,
        payload: { printPrepParams },
    };
};

export const doSetPrintFileBlob = files => {
    return {
        type: SET_PRINT_FILES_BLOB,
        payload: { files },
    };
};

export const doSetThumbnails = thumbFiles => {
    return {
        type: SET_THUMBNAILS,
        payload: { thumbFiles },
    };
};

export const doSetPrintFileData = fileUrl => {
    return {
        type: SET_PRINT_FILE_DATA,
        payload: { fileUrl },
    };
};

export const doSetFileName = fileName => {
    return {
        type: SET_FILE_NAME,
        payload: { fileName },
    };
};

export const doUpdatePrinterSchema = payload => {
    return {
        type: UPDATE_PRINTER_SCHEMA,
        payload,
    };
};

export const doFetchCustomPrintModes = payload => {
    return {
        type: FETCH_CUSTOM_PRINT_MODES_START,
        payload,
    };
};

export const doFetchCustomPrintModesSuccess = payload => {
    return {
        type: FETCH_CUSTOM_PRINT_MODES_SUCCESS,
        payload,
    };
};

export const doFetchCustomPrintModesFail = payload => {
    return {
        type: FETCH_CUSTOM_PRINT_MODES_FAIL,
        payload,
    };
};

export const doSetShowImportPrintModeModal = showImportPrintModeModal => {
    return {
        type: SET_SHOW_IMPORT_PRINT_MODE_MODAL,
        payload: { showImportPrintModeModal },
    };
};

export const doSetShowSavePrintModeModal = showSavePrintModeModal => {
    return {
        type: SET_SHOW_SAVE_PRINT_MODE_MODAL,
        payload: { showSavePrintModeModal },
    };
};

export const doSetExportPrintModeIdForModal = exportPrintModeIdForModal => {
    return {
        type: SET_EXPORT_PRINT_MODE_ID_FOR_MODAL,
        payload: { exportPrintModeIdForModal },
    };
};

export const doSetShowPrintModeWrongFormatModal = showPrintModeWrongFormatModal => {
    return {
        type: SET_SHOW_PRINT_MODE_WRONG_FORMAT_MODAL,
        payload: { showPrintModeWrongFormatModal },
    };
};

export const doSetShakeBlockingCTASnackbars = shakeBlockingCTASnackbars => {
    return {
        type: SHAKE_BLOCKING_CTA_SNACKBARS,
        payload: { shakeBlockingCTASnackbars },
    };
};

export const doSetCustomPrintModeIncompatibleObject = customPrintModeIncompatibleObject => {
    return {
        type: SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT,
        payload: { customPrintModeIncompatibleObject },
    };
};

export const doSetCustomPrintModeIncompatibleOverridesObject = customPrintModeIncompatibleOverridesObject => {
    return {
        type: SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OVERRIDES_OBJECT,
        payload: { customPrintModeIncompatibleOverridesObject },
    };
};

export const doSetCustomPrintModeOverrides = customPrintModeOverrides => {
    return {
        type: SET_CUSTOM_PRINT_MODE_OVERRIDES,
        payload: { customPrintModeOverrides },
    };
};

export const doSetValidCustomPrintModeOverrides = validCustomPrintModeOverrides => {
    return {
        type: SET_VALID_CUSTOM_PRINT_MODE_OVERRIDES,
        payload: { validCustomPrintModeOverrides },
    };
};

export const doSetPreselectedCustomPrintSettings = preselectedCustomPrintSettings => {
    return {
        type: SET_PRESELECTED_CUSTOM_PRINT_SETTINGS,
        payload: { preselectedCustomPrintSettings },
    };
};

export const doClearCustomPrintSettingsOverrides = () => {
    return {
        type: CLEAR_CUSTOM_PRINT_SETTINGS_OVERRIDES,
    };
};

export const doImportCustomPrintMode = payload => {
    return {
        type: IMPORT_CUSTOM_PRINT_MODE_START,
        payload,
    };
};

export const doImportCustomPrintModeSuccess = payload => {
    return {
        type: IMPORT_CUSTOM_PRINT_MODE_SUCCESS,
        payload,
    };
};

export const doImportCustomPrintModeFail = payload => {
    return {
        type: IMPORT_CUSTOM_PRINT_MODE_FAIL,
        payload,
    };
};

export const doCreateCustomPrintMode = payload => {
    return {
        type: CREATE_CUSTOM_PRINT_MODE_START,
        payload,
    };
};

export const doCreateCustomPrintModeSuccess = payload => {
    return {
        type: CREATE_CUSTOM_PRINT_MODE_SUCCESS,
        payload,
    };
};

export const doCreateCustomPrintModeFail = payload => {
    return {
        type: CREATE_CUSTOM_PRINT_MODE_FAIL,
        payload,
    };
};

export const doCreateCustomPrintModeStopTracking = payload => {
    return {
        type: CREATE_CUSTOM_PRINT_MODE_STOP_TRACKING,
        payload,
    };
};

export const doFetchCustomPrintSettings = payload => {
    return {
        type: FETCH_CUSTOM_PRINT_SETTINGS_START,
        payload,
    };
};

export const doFetchCustomPrintSettingsSuccess = payload => {
    return {
        type: FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS,
        payload,
    };
};

export const doFetchCustomPrintSettingsFail = payload => {
    return {
        type: FETCH_CUSTOM_PRINT_SETTINGS_FAIL,
        payload,
    };
};

export const doDeleteCustomPrintMode = payload => {
    return {
        type: DELETE_CUSTOM_PRINT_MODE_START,
        payload,
    };
};

export const doDeleteCustomPrintModeSuccess = payload => {
    return {
        type: DELETE_CUSTOM_PRINT_MODE_SUCCESS,
        payload,
    };
};

export const doDeleteCustomPrintModeFail = payload => {
    return {
        type: DELETE_CUSTOM_PRINT_MODE_FAIL,
        payload,
    };
};

export const doSaveChangesOnCustomPrintMode = payload => {
    return {
        type: SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_START,
        payload,
    };
};

export const doSaveChangesOnCustomPrintModeSuccess = payload => {
    return {
        type: SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_SUCCESS,
        payload,
    };
};

export const doSaveChangesOnCustomPrintModeFail = payload => {
    return {
        type: SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_FAIL,
        payload,
    };
};

export const doExportCustomPrintMode = payload => {
    return {
        type: EXPORT_CUSTOM_PRINT_MODE_START,
        payload,
    };
};

export const doExportCustomPrintModeSuccess = payload => {
    return {
        type: EXPORT_CUSTOM_PRINT_MODE_SUCCESS,
        payload,
    };
};

export const doExportCustomPrintModeFail = payload => {
    return {
        type: EXPORT_CUSTOM_PRINT_MODE_FAIL,
        payload,
    };
};

export const doDownloadCustomPrintMode = payload => {
    return {
        type: DOWNLOAD_CUSTOM_PRINT_MODE_START,
        payload,
    };
};

export const doDownloadCustomPrintModeSuccess = payload => {
    return {
        type: DOWNLOAD_CUSTOM_PRINT_MODE_SUCCESS,
        payload,
    };
};

export const doDownloadCustomPrintModeFail = payload => {
    return {
        type: DOWNLOAD_CUSTOM_PRINT_MODE_FAIL,
        payload,
    };
};

export const doUpdateSettingsInCustomPrintMode = payload => {
    return {
        type: UPDATE_SETTINGS_IN_CUSTOM_PRINT_MODE,
        payload,
    };
};

export const doTrackProgressFromKaitenMethodExec = payload => {
    return {
        type: TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_START,
        payload,
    };
};

export const doTrackProgressFromKaitenMethodExecFulfilled = payload => {
    return {
        type: TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS,
        payload,
    };
};

export const doTrackProgressFromKaitenMethodExecFail = payload => {
    return {
        type: TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_FAILED,
        payload,
    };
};

export const doSetIsMainExtruderMissing = isMainExtruderMissing => {
    return {
        type: PRINTER_SET_IS_MAIN_EXTRUDER_MISSING,
        payload: { isMainExtruderMissing },
    };
};

export const doUpdateProgressFromKaitenMethodExec = payload => {
    return {
        type: UPDATE_PROGRES_FROM_KAITEN_METHOD_EXEC,
        payload,
    };
};

export const doSetSpoolInfo = payload => {
    return {
        type: SET_SPOOL_INFO,
        payload,
    };
};

export const doSetInitialExtruderMaterialState = initialExtruderMaterialState => {
    return {
        type: SET_INITIAL_EXTRUDER_MATERIAL_STATE,
        payload: { initialExtruderMaterialState },
    };
};

export const doAddNotification = notification => {
    return {
        type: ADD_NOTIFICATION,
        payload: { notification },
    };
};

export const doDeleteNotification = notificationId => {
    return {
        type: DELETE_NOTIFICATION,
        payload: { notificationId },
    };
};

export const doSetNotifications = notifications => {
    return {
        type: SET_NOTIFICATIONS,
        payload: { notifications },
    };
};

export const doResetSecondExtruderMaterial = () => {
    return {
        type: RESET_SECOND_EXTRUDER_MATERIAL,
        payload: {},
    };
};

export const doSetIsSelectedConfigEqualLoaded = payload => {
    return {
        type: SET_IS_SELECTED_CONFIG_EQUAl_LOADED,
        payload,
    };
};
