export const MODELS_SET_MODEL_LOADING_PROGRESS = 'MODELS_SET_MODEL_LOADING_PROGRESS';
export const MODELS_SET_MODEL_LOADING = 'MODELS_SET_MODEL_LOADING'; //modelLoading and uploadedModelName
export const MODELS_SET_WRONG_FORMAT = 'MODELS_SET_WRONG_FORMAT';
export const MODELS_SET_WRONG_FILES = 'MODELS_SET_WRONG_FILES';
export const MODELS_SET_UNABLE_TO_CONVERT_FILETYPE = 'MODELS_SET_UNABLE_TO_CONVERT_FILETYPE';

export const MODELS_SET_EXCEEDED_FILE_SIZE = 'MODELS_SET_EXCEEDED_FILE_SIZE';
export const MODELS_CANCEL_IMPORT = 'MODELS_CANCEL_IMPORT';
export const MODELS_SET_IMPORT_FAILED = 'MODELS_SET_IMPORT_FAILED';
export const MODELS_SET_SHOW_UPLOAD_MODEL_ERROR = 'MODELS_SET_SHOW_UPLOAD_MODEL_ERROR';
export const MODELS_SET_FILE_TOO_OLD_ERROR = 'MODELS_SET_FILE_TOO_OLD_ERROR';
export const MODELS_SET_FILE_TOO_RECENT_ERROR = 'MODELS_SET_FILE_TOO_RECENT_ERROR';

export const MODELS_SET_MAKERBOT_DOWNLOAD_IMPOSSIBLE = 'MODELS_SET_MAKERBOT_DOWNLOAD_IMPOSSIBLE';
export const CONVERT_FILE_START = 'CONVERT_FILE_START';
export const CONVERT_FILE_SUCCESS = 'CONVERT_FILE_SUCCESS';
export const CONVERT_FILE_FAIL = 'CONVERT_FILE_FAIL';
export const SET_CONVERSION_STATUS = 'SET_CONVERSION_STATUS';
export const FETCH_CONVERTED_MODELS_START = 'FETCH_CONVERTED_MODELS_START';
export const FETCH_CONVERTED_MODELS_SUCCESS = 'FETCH_CONVERTED_MODELS_SUCCESS';
export const FETCH_CONVERTED_MODELS_FAIL = 'FETCH_CONVERTED_MODELS_FAIL';
export const SET_CONVERTED_MODELS_BLOBS = 'SET_CONVERTED_MODELS_BLOBS';
export const SET_CONVERT_FILE_FAIL = 'SET_CONVERT_FILE_FAIL';
export const TRACK_CONVERT_FILE_START = 'TRACK_CONVERT_FILE_START';
export const STOP_UPDATE_CONVERT_FILE_PROGRESS = 'STOP_UPDATE_CONVERT_FILE_PROGRESS';
export const STOP_CONVERT_FILE = 'STOP_CONVERT_FILE';
export const STOP_CONVERT_FILE_SUCSESS = 'STOP_CONVERT_FILE_SUCSESS';
export const STOP_CONVERT_FILE_FAIL = 'STOP_CONVERT_FILE_FAIL';
export const UPDATE_CONVERT_FILE_PROGRESS = 'UPDATE_CONVERT_FILE_PROGRESS';
export const MODELS_CLEAR_CONVERTER_META = 'MODELS_CLEAR_CONVERTER_META';
export const MODELS_DO_SET_UPLOAD_JOB_COUNT = 'MODELS_DO_SET_UPLOAD_JOB_COUNT';
export const MODELS_DO_ADD_COMPLETED_UPLOAD_JOB_COUNT = 'MODELS_DO_ADD_COMPLETED_UPLOAD_JOB_COUNT';
export const MODELS_DO_SET_CONVERT_JOB_COUNT = 'MODELS_DO_SET_CONVERT_JOB_COUNT';
export const MODELS_DO_UPDATE_CONVERT_JOB_COUNT = 'MODELS_DO_UPDATE_CONVERT_JOB_COUNT';
export const MODELS_DO_SET_JOB_NAME = 'MODELS_DO_SET_JOB_NAME';
export const MODELS_DO_UPDATE_DEFAULT_JOB_NAME = 'MODELS_DO_UPDATE_DEFAULT_JOB_NAME';
export const MODELS_DO_SET_JOB_NAME_FROM_DEFAULT = 'MODELS_DO_SET_JOB_NAME_FROM_DEFAULT';
export const MODELS_SET_SHOULD_RESET_MODEL_SETTINGS = 'MODELS_SET_SHOULD_RESET_MODEL_SETTINGS'; // one action for position, scale and rotation { shouldResetRotation: bool, shouldResetScale: bool, shouldResetPosition: bool }

export const SET_HOVERED_MODEL_ID = 'SET_HOVERED_MODEL_ID';
export const SET_OVERLAP_MODELS_IDS = 'SET_OVERLAP_MODELS_IDS';
export const SET_IS_SMART_ARRANGE_HOVERED = 'SET_IS_SMART_ARRANGE_HOVERED';
export const SET_IS_DRAGGING = 'SET_IS_DRAGGING';
export const SET_IS_MODEL_HOVERED_FROM_TREE = 'SET_IS_MODEL_HOVERED_FROM_TREE';
export const SET_IS_DRAG_ROTATION = 'SET_IS_DRAG_ROTATING';

export const MODELS_SET_SETTINGS_ON_ONE_ACTIVE_MODEL = 'MODELS_SET_SETTINGS_ON_ONE_ACTIVE_MODEL';

export const MODELS_UPDATE_SETTINGS = 'MODELS_UPDATE_SETTINGS';
export const MODELS_SET_ACTIVE_MODELS = 'MODELS_SET_ACTIVE_MODELS'; //for deselect as well
export const MODELS_SET_COPIED_MODELS = 'MODELS_SET_COPIED_MODELS';

export const MODELS_SET_MODELS = 'MODELS_SET_MODELS';
export const MODELS_ADD_MODELS = 'MODELS_ADD_MODELS';
export const MODELS_DELETE_MODELS = 'MODELS_DELETE_MODELS';
export const MODELS_UPDATE_MODELS = 'MODELS_UPDATE_MODELS';

export const MODELS_ADD_PURGE_TOWER = 'MODELS_ADD_PURGE_TOWER';
export const MODELS_REMOVE_PURGE_TOWER = 'MODELS_REMOVE_PURGE_TOWER';

export const MODELS_SET_MODEL_ORIGINAL_NAME = 'MODELS_SET_MODEL_ORIGINAL_NAME';

export const MODELS_START_HANDLING_UPLOAD_JOBS = 'MODELS_START_HANDLING_UPLOAD_JOBS';
export const MODELS_STOP_HANDLING_UPLOAD_JOBS = 'MODELS_STOP_HANDLING_UPLOAD_JOBS';

export const MODELS_DO_BROADCAST_UPLOAD_JOB = 'MODELS_DO_BROADCAST_UPLOAD_JOB';
export const MODELS_DO_BROADCAST_UPLOAD_JOB_ERROR = 'MODELS_DO_BROADCAST_UPLOAD_JOB_ERROR';
export const MODELS_DO_RESET_UPLOAD_JOB = 'MODELS_DO_RESET_UPLOAD_JOB';

export const MODELS_DO_ADD_LOADER_DESCRIPTORS = 'MODELS_DO_SET_LOADER_DESCRIPTOR';
export const MODELS_DO_RESET_LOADER_DESCRIPTORS = 'MODELS_DO_RESET_LOADER_DESCRIPTORS';

export const MODELS_FETCH_CAD_FROM_URL = 'MODELS_FETCH_CAD_FROM_URL';

export const MODELS_PERMODEL_SET_INSTANCE = 'MODELS_PERMODEL_SET_INSTANCE';

export const MODELS_DOWNLOAD_DEMO_FILE_START = 'MODELS_DOWNLOAD_DEMO_FILE_START';
export const MODELS_STOP_DOWNLOAD_DEMO_FILE = 'MODELS_STOP_DOWNLOAD_DEMO_FILE';
export const MODELS_DOWNLOAD_DEMO_FILE_FULFILLED = 'MODELS_DOWNLOAD_DEMO_FILE_FULFILLED';
export const MODELS_DOWNLOAD_DEMO_FILE_FAILED = 'MODELS_DOWNLOAD_DEMO_FILE_FAILED';

export const MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES = 'MODELS_START_DOWNLOAD_DEMO_ASSEMBLY_FILES';
export const MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES = 'MODELS_STOP_DOWNLOAD_DEMO_ASSEMBLY_FILES';
export const MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FULFILLED = 'MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FULFILLED';
export const MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FAILED = 'MODELS_DOWNLOAD_DEMO_ASSEMBLY_FILES_FAILED';

export const MODELS_SET_UPLOADED_BIN_FILES = 'MODELS_SET_UPLOADED_BIN_FILES';

export const MODELS_SET_LARGE_FILES = 'MODELS_SET_LARGE_FILES';
export const MODELS_CLEAR_LARGE_FILES = 'MODELS_CLEAR_LARGE_FILES';
export const MODELS_SET_IS_MODEL_SIMPLIFYING = 'MODELS_SET_IS_MODEL_SIMPLIFYING';
export const MODELS_SET_IS_MODEL_SIMPLIFYING_FAILED = 'MODELS_SET_IS_MODEL_SIMPLIFYING_FAILED';
