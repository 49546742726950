import { AmbientLight, Scene, WebGLRenderer } from 'three';

const canvas = document.createElement('canvas');
const context = canvas.getContext('webgl');

const renderer = new WebGLRenderer({
    antialias: true,
    alpha: true,
    canvas,
    context,
});

const scene = new Scene();
const light = new AmbientLight(0xffffff);
scene.add(light);

const ThreeAxisTriad = {
    scene,
    camera: null,
    renderer,
    triad: null,
    updateRotation(quaternion) {
        if (!this.triad || !quaternion) {
            return;
        }

        this.triad.quaternion.copy(quaternion.clone().invert());
        this.triad.updateMatrix();
    },
    render() {
        if (!this.camera) {
            return;
        }

        this.renderer.render(this.scene, this.camera);
    },
};

export default ThreeAxisTriad;
