import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { compareEpicPayload } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { CONVERT_FILE_START } from '../state/redux/types';
import {
    doRunConversionJobFail,
    doSetComparisonModeEnabled,
    doSetFilesUploadedFromFolder,
    doSetModelLoading,
    doSetModelLoadingProgress,
    doSetNotification,
    doTrackConvertJobProgress,
} from '../state/redux/actions';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResToActions = (response, action) => {
    const convertId = response.response.id;
    const payload = { ...action.payload, convertId };
    return of(doTrackConvertJobProgress(payload), doSetModelLoadingProgress(1));
};

const parseErrorToActions = error => {
    console.log('Unable to convert file:', error);
    return of(
        doSetFilesUploadedFromFolder(false),
        doSetModelLoading({ modelLoading: false }),
        doSetNotification(NOTIFICATION_TYPE.CONVERSION_FAILED),
        doRunConversionJobFail(),
        doSetComparisonModeEnabled(false),
    );
};

const runConversionJob = (action$, state$) =>
    action$.pipe(
        ofType(CONVERT_FILE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.RUN_CONVERSION_JOB),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            console.log(`Starting a new conversion`);
            const payload = action.payload;
            const { cloudslicerToken } = state$.value?.appState?.userState;
            const body = JSON.stringify({
                fileUrls: Object.keys(payload.urls).map(x => {
                    return { url: payload.urls[x].gcsUrl, name: x };
                }),
            });
            return ajax({
                url: `https://${urls.converter}/convert`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cloudslicerToken}`,
                },
                body,
            }).pipe(
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(error))
            );
        })
    );

export default runConversionJob;
