import ExtruderConfig from './ExtruderConfig';
import { EXTRUDER_ID_SPLITTER } from '../consts/index';

class ExtrudersPairConfig {
    constructor(id, extrudersList, isDefault = false) {
        this.isDefault = isDefault;
        this.id = id;
        this.extrudersList = extrudersList;
    }

    static createFormConfigObject(config) {
        return new ExtrudersPairConfig(
            config['extruder_ids'].join(EXTRUDER_ID_SPLITTER),
            config['extruder_ids'].map((eId, index) => {
                return new ExtruderConfig(eId, config['material_ids'][index], config['default']);
            }),
            config['default']
        );
    }
}

export default ExtrudersPairConfig;
