export const HANDLE_PARENT_POST_MESSAGE_START = 'HANDLE_PARENT_POST_MESSAGE_START';
export const FETCH_WORKSPACE_PRINTERS_SUCCESS = 'FETCH_WORKSPACE_PRINTERS_SUCCESS';
export const FETCH_WORKSPACE_PRINTERS_FAIL = 'FETCH_WORKSPACE_PRINTERS_FAIL';

export const FETCH_REFLECTOR_AUTH_PRINTERS_START = 'FETCH_REFLECTOR_AUTH_PRINTERS_START';
export const FETCH_REFLECTOR_AUTH_PRINTERS_SUCCESS = 'FETCH_REFLECTOR_AUTH_PRINTERS_SUCCESS';
export const FETCH_REFLECTOR_AUTH_PRINTERS_FAIL = 'FETCH_REFLECTOR_AUTH_PRINTERS_FAIL';

export const FETCH_LOCAL_PRINTERS_START = 'FETCH_LOCAL_PRINTERS_START';
export const FETCH_LOCAL_PRINTERS_SUCCESS = 'FETCH_LOCAL_PRINTERS_SUCCESS';
export const FETCH_LOCAL_PRINTERS_FAIL = 'FETCH_LOCAL_PRINTERS_FAIL';
export const REMOVE_LOCAL_PRINTER = 'REMOVE_LOCAL_PRINTER';

export const AUTHORIZE_LOCAL_PRINTER = 'AUTHORIZE_LOCAL_PRINTER';
export const AUTHORIZE_LOCAL_PRINTER_FAIL = 'AUTHORIZE_LOCAL_PRINTER_FAIL';
export const AUTHORIZE_LOCAL_PRINTER_SUCCESS = 'AUTHORIZE_LOCAL_PRINTER_SUCCESS';

export const REAUTHORIZE_LOCAL_PRINTER = 'REAUTHORIZE_LOCAL_PRINTER';
export const REAUTHORIZE_LOCAL_PRINTER_FAIL = 'REAUTHORIZE_LOCAL_PRINTER_FAIL';

// The socket stream of successful authorize and reauthorize are the same. They both
// fetch printer info from the TCP socket.
export const FETCH_LOCAL_PRINTER_INFO_SUCCESS = 'FETCH_LOCAL_PRINTER_INFO_SUCCESS';

// Get printer schema
export const FETCH_PRINTER_SCHEMA_START = 'FETCH_PRINTER_SCHEMA_START';
export const FETCH_PRINTER_SCHEMA_SUCCESS = 'FETCH_PRINTER_SCHEMA_SUCCESS';
export const FETCH_PRINTER_SCHEMA_FAILED = 'FETCH_PRINTER_SCHEMA_FAILED';
export const FETCH_PRINTER_SCHEMA_COMPLETED = 'FETCH_PRINTER_SCHEMA_COMPLETED';

// Get printer schema for each print mode
export const FETCH_INITIAL_PRINTER_SCHEMAS_START = 'FETCH_INITIAL_PRINTER_SCHEMAS_START';
export const FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS = 'FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS';
export const FETCH_INITIAL_PRINTER_SCHEMAS_FAILED = 'FETCH_INITIAL_PRINTER_SCHEMAS_FAILED';

// Get printers configs
export const FETCH_PRINTERS_CONFIGS_START = 'FETCH_PRINTERS_CONFIGS_START';
export const FETCH_PRINTERS_CONFIGS_SUCCESS = 'FETCH_PRINTERS_CONFIGS_START_SUCCESS';
export const FETCH_PRINTERS_CONFIGS_FAILED = 'FETCH_PRINTERS_CONFIGS_START_FAILED';

// PRINTER SETTINGS
export const HANDLE_CHANGE_PRINTER_START = 'HANDLE_CHANGE_PRINTER_START';
export const HANDLE_CHANGE_PRINTER_SUCCESS = 'HANDLE_CHANGE_PRITNER_SUCCESS';
export const PRINTER_SET_PRINT_PREP_PARAMS = 'PRINTER_SET_PRINT_PREP_PARAMS';

export const SELECT_PRINT_MODE = 'SELECT_PRINT_MODE';

export const PRINTER_SET_PRINTER_MISSING = 'PRINTER_SET_PRINTER_MISSING';
export const PRINTER_SET_UNKNOW_MATERIAL = 'PRINTER_SET_UNKNOW_MATERIAL';
export const PRINTER_SET_NULL_MATERIAL = 'PRINTER_SET_NULL_MATERIAL';
export const PRINTER_SET_INVALID_MATERIALS_COMBINATION = 'PRINTER_SET_INVALID_MATERIALS_COMBINATION';

export const PRINTER_SET_PRINTED_MAKERBOT_FILENAME = 'PRINTER_SET_PRINTED_MAKERBOT_FILENAME';

export const PRINTER_SET_PRINTER_CONFIG = 'PRINTER_SET_PRINTER_CONFIG';
export const PRINTER_UPDATE_PRINT_MODE = 'PRINTER_UPDATE_PRINT_MODE';
export const PRINTER_UPDATE_SETTINGS = 'PRINTER_UPDATE_SETTINGS';
export const PRINTER_UPDATE_SETTINGS_FOR_6th_GEN = 'PRINTER_UPDATE_SETTINGS_FOR_6th_GEN';
export const PRINTER_SET_MODELS_OUTSIDE = 'PRINTER_SET_MODELS_OUTSIDE';
export const PRINTER_SET_MODELS_BREAKS_VOLUME = 'PRINTER_SET_MODELS_BREAKS_VOLUME';
export const PRINTER_SET_MODELS_FITS = 'PRINTER_SET_MODELS_FITS';
export const PRINTER_SET_HIGHLIGHTED_WALLS = 'PRINTER_SET_HIGHLIGHTED_WALLS';
export const PRINTER_SET_STATUS = 'PRINTER_SET_STATUS';
export const PRINTER_SET_PRETTY_STATUS = 'PRINTER_SET_PRETTY_STATUS';
export const PRINTER_SET_QUEUE_LENGTH = 'PRINTER_SET_QUEUE_LENGTH';

export const PRINTER_CLEAR_PRINTER_OVERRIDES = 'PRINTER_CLEAR_PRINTER_OVERRIDES';
export const PRINTER_SET_OVERRIDES = 'PRINTER_SET_OVERRIDES';
export const PRINTER_SET_BUILD_VOLUMES = 'PRINTER_SET_BUILD_VOLUMES';

export const PRINTER_PERMODEL_SET_OVERRIDES = 'PRINTER_PERMODEL_SET_OVERRIDES';
export const PRINTER_PERMODEL_CLEAR_OVERRIDES = 'PRINTER_PERMODEL_CLEAR_OVERRIDES';

export const PRINTER_CLEAR_PRINTER_OVERRIDE = 'PRINTER_CLEAR_PRINTER_OVERRIDE';
export const PRINTER_PERMODEL_CLEAR_OVERRIDE = 'PRINTER_PERMODEL_CLEAR_OVERRIDE';

export const UPDATE_PRINTER_SCHEMA = 'UPDATE_PRINTER_SCHEMA';

export const PRINTER_SET_IS_MAIN_EXTRUDER_MISSING = 'PRINTER_SET_IS_MAIN_EXTRUDER_MISSING';

export const SET_INITIAL_EXTRUDER_MATERIAL_STATE = 'SET_INITIAL_EXTRUDER_MATERIAL_STATE';

// Send Printer Command to Reflector
export const SEND_PRINTER_COMMAND_START = 'SEND_PRINTER_COMMAND_START';
export const SEND_PRINTER_COMMAND_SUCCESS = 'SEND_PRINTER_COMMAND_SUCCESS';
export const SEND_PRINTER_COMMAND_FAIL = 'SEND_PRINTER_COMMAND_FAIL';

//PRINT/EXPORT ACTIONS
export const SET_PRINT_FILES_BLOB = 'SET_PRINT_FILES_BLOB';
export const SET_THUMBNAILS = 'SET_THUMBNAILS';
export const SET_PRINT_FILE_DATA = 'SET_PRINT_FILE_DATA';
export const SET_FILE_NAME = 'SET_FILE_NAME';

export const FETCH_CUSTOM_PRINT_MODES_START = 'FETCH_CUSTOM_PRINT_MODES_START';
export const FETCH_CUSTOM_PRINT_MODES_SUCCESS = 'FETCH_CUSTOM_PRINT_MODES_SUCCESS';
export const FETCH_CUSTOM_PRINT_MODES_FAIL = 'FETCH_CUSTOM_PRINT_MODES_FAIL';
export const SET_SHOW_IMPORT_PRINT_MODE_MODAL = 'SET_SHOW_IMPORT_PRINT_MODE_MODAL';
export const SET_SHOW_SAVE_PRINT_MODE_MODAL = 'SET_SHOW_SAVE_PRINT_MODE_MODAL';
export const SET_EXPORT_PRINT_MODE_ID_FOR_MODAL = 'SET_EXPORT_PRINT_MODE_ID_FOR_MODAL';

export const SHAKE_BLOCKING_CTA_SNACKBARS = 'SHAKE_BLOCKING_CTA_SNACKBARS';

export const SET_SHOW_PRINT_MODE_WRONG_FORMAT_MODAL = 'SET_SHOW_PRINT_MODE_WRONG_FORMAT_MODAL';
export const SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT = 'SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT';
export const SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OVERRIDES_OBJECT = 'SET_CUSTOM_PRINT_MODE_INCOMPATIBLE_OBJECT';
export const SET_CUSTOM_PRINT_MODE_OVERRIDES = 'SET_CUSTOM_PRINT_MODE_OVERRIDES';
export const SET_VALID_CUSTOM_PRINT_MODE_OVERRIDES = 'SET_VALID_CUSTOM_PRINT_MODE_OVERRIDES';
export const SET_PRESELECTED_CUSTOM_PRINT_SETTINGS = 'SET_PRESELECTED_CUSTOM_PRINT_SETTINGS';
export const CLEAR_CUSTOM_PRINT_SETTINGS_OVERRIDES = 'CLEAR_CUSTOM_PRINT_SETTINGS_OVERRIDES';

export const IMPORT_CUSTOM_PRINT_MODE_START = 'IMPORT_CUSTOM_PRINT_MODE_START';
export const IMPORT_CUSTOM_PRINT_MODE_SUCCESS = 'IMPORT_CUSTOM_PRINT_MODE_SUCCESS';
export const IMPORT_CUSTOM_PRINT_MODE_FAIL = 'IMPORT_CUSTOM_PRINT_MODE_FAIL';

export const CREATE_CUSTOM_PRINT_MODE_START = 'CREATE_CUSTOM_PRINT_MODE_START';
export const CREATE_CUSTOM_PRINT_MODE_SUCCESS = 'CREATE_CUSTOM_PRINT_MODE_SUCCESS';
export const CREATE_CUSTOM_PRINT_MODE_FAIL = 'CREATE_CUSTOM_PRINT_MODE_FAIL';
export const CREATE_CUSTOM_PRINT_MODE_STOP_TRACKING = 'CREATE_CUSTOM_PRINT_MODE_STOP_TRACKING';

export const FETCH_CUSTOM_PRINT_SETTINGS_START = 'FETCH_CUSTOM_PRINT_SETTINGS_START';
export const FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS = 'FETCH_CUSTOM_PRINT_SETTINGS_SUCCESS';
export const FETCH_CUSTOM_PRINT_SETTINGS_FAIL = 'FETCH_CUSTOM_PRINT_SETTINGS_FAIL';

export const DELETE_CUSTOM_PRINT_MODE_START = 'DELETE_CUSTOM_PRINT_MODE_START';
export const DELETE_CUSTOM_PRINT_MODE_SUCCESS = 'DELETE_CUSTOM_PRINT_MODE_SUCCESS';
export const DELETE_CUSTOM_PRINT_MODE_FAIL = 'DELETE_CUSTOM_PRINT_MODE_FAIL';

export const SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_START = 'SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_START';
export const SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_SUCCESS = 'SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_SUCCESS';
export const SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_FAIL = 'SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_FAIL';

export const EXPORT_CUSTOM_PRINT_MODE_START = 'EXPORT_CUSTOM_PRINT_MODE_START';
export const EXPORT_CUSTOM_PRINT_MODE_SUCCESS = 'EXPORT_CUSTOM_PRINT_MODE_SUCCESS';
export const EXPORT_CUSTOM_PRINT_MODE_FAIL = 'EXPORT_CUSTOM_PRINT_MODE_FAIL';
export const DOWNLOAD_CUSTOM_PRINT_MODE_START = 'DOWNLOAD_CUSTOM_PRINT_MODE_START';
export const DOWNLOAD_CUSTOM_PRINT_MODE_SUCCESS = 'DOWNLOAD_CUSTOM_PRINT_MODE_SUCCESS';
export const DOWNLOAD_CUSTOM_PRINT_MODE_FAIL = 'DOWNLOAD_CUSTOM_PRINT_MODE_FAIL';

export const UPDATE_SETTINGS_IN_CUSTOM_PRINT_MODE = 'UPDATE_SETTINGS_IN_CUSTOM_PRINT_MODE';

export const TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_START = 'TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_START';
export const TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS = 'TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_SUCCESS';
export const TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_FAILED = 'TRACK_PROGRESS_FROM_KAITEN_METHOD_EXEC_FAIL';
export const UPDATE_PROGRES_FROM_KAITEN_METHOD_EXEC = 'UPDATE_PROGRES_FROM_KAITEN_METHOD_EXEC';
export const SET_SPOOL_INFO = 'SET_SPOOL_INFO';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const RESET_SECOND_EXTRUDER_MATERIAL = 'RESET_SECOND_EXTRUDER_MATERIAL';

export const SET_IS_SELECTED_CONFIG_EQUAl_LOADED = 'SET_IS_SELECTED_CONFIG_EQUAl_LOADED';
