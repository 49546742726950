import { ofType } from 'redux-observable';
import { fromEvent, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { TRACK_JOB_STATUS_FROM_TEAMS } from '../state/redux/types';
import {
    doCancelSliceJob,
    doRemoveOperatingJob,
    doResetCurrentFailedSliceJobId,
    doSetActiveJobType,
    doSetPrintFileData,
    doShowTopOverlayBar,
    doStopUpdateProgressCheckFromSlicer,
    doUpdateOperatingJob,
} from '../state/redux/actions';
import { JOB_TYPE } from '../consts';

const trackJobStatusFromTeams = (action$, state$) =>
    action$.pipe(
        ofType(TRACK_JOB_STATUS_FROM_TEAMS),
        mergeMap(() => {
            const teamsUrl = urls.teams;

            return fromEvent(window, 'message').pipe(
                filter(event => {
                    const url = `${teamsUrl.includes('local') ? 'http' : 'https'}://${teamsUrl}`;
                    return event.origin === url;
                }),
                mergeMap(event => {
                    const cancel_slicing = event.data?.cancel_slicing;
                    const slice_finished = event.data?.slice_finished;
                    const currentFailedSliceJobId = state$.value?.appState?.previewState?.currentFailedSliceJobId;
                    const sliceJobs = state$.value?.appState?.previewState?.sliceJobs;
                    const foundJob = sliceJobs.find(job => job.localId === currentFailedSliceJobId);

                    if (cancel_slicing?.slice_hash) {
                        const args = [
                            doSetPrintFileData(null),
                            doSetActiveJobType(JOB_TYPE.export),
                            doShowTopOverlayBar(false),
                            doRemoveOperatingJob(cancel_slicing?.slice_hash),
                            doStopUpdateProgressCheckFromSlicer(),
                        ];

                        const jobForCancelledSliceHash = sliceJobs.find(job => job.id === cancel_slicing?.slice_hash);

                        if (jobForCancelledSliceHash) {
                            args.push(doCancelSliceJob(jobForCancelledSliceHash));
                        }

                        if (foundJob?.id === cancel_slicing?.slice_hash) {
                            args.push(doResetCurrentFailedSliceJobId());
                        }

                        return of(...args);
                    }

                    if (slice_finished?.slice_hash) {
                        const args = [doUpdateOperatingJob(slice_finished?.slice_hash, true)];
                        console.log(' > finished', slice_finished?.slice_hash);
                        return of(...args);
                    }

                    return of();
                }),
                catchError(error => {
                    console.error(error);
                    return of();
                })
            );
        })
    );

export default trackJobStatusFromTeams;
