import {
    Box3,
    BufferGeometry,
    Float32BufferAttribute,
    Line,
    LineBasicMaterial,
    LineSegments,
    MathUtils,
    Vector3,
} from 'three';

import { checkIsModelFits, getBuildPlateBoundingBox, getPurgeShape, isModelBreaksVolume } from './viewer';
import ThreeInstance from '../ThreeInstance';

export const COLORS = {
    material: [0.34, 0.61, 0.76],
    support: [0.47, 0.47, 0.47],
    raft: [0.61, 0.61, 0.61],
    brims: [0.73, 0.73, 0.73],
};

export const getUpdatedLineColor = (color, line) => {
    const vector = new Vector3(line.e[0] - line.s[0], line.e[1] - line.s[1], line.e[2] - line.s[2]);
    const defaultVector = new Vector3(1, 1, 1);
    const angle = defaultVector.angleTo(vector);
    const deg = MathUtils.radToDeg(angle);
    const colorDarkness = deg / 90 + 0.5;
    const updatedColor = color.map(item => item / colorDarkness);
    return [...updatedColor, ...updatedColor];
};

export const getLineShape = (positions, colors, LineClass = LineSegments) => {
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
    const material = new LineBasicMaterial({ vertexColors: true });
    const line = new LineClass(geometry, material);

    return line;
};

export const buildBasicModelPreview = layers => {
    const geomPoints = [];
    const colors = [];

    for (let li = 0; li < layers.length; li++) {
        const { regions, extruderId, position: p } = layers[li];
        if (regions.length === 0) continue;
        const curColor = extruderId === 0 ? COLORS.material : COLORS.support;
        let r, g, b;

        for (let ri = 0; ri < regions.length; ri++) {
            const { points } = regions[ri];
            if (!points || !points.length) continue;

            points.push({ ...points[0] });

            const startIndex = 0;
            const endIndex = points.length - 1;

            for (let pi = 0; pi < points.length; pi += 2) {
                const start = points[pi];
                const end = points[pi + 1];
                if (end) {
                    const line = { s: [start.x, start.y, p.lowerZ], e: [end.x, end.y, p.lowerZ] };
                    [r, g, b] = getUpdatedLineColor(curColor, line);
                }

                geomPoints.push(start.x, start.y, p.lowerZ);
                colors.push(r, g, b);

                // add an additional vector for the segment geometry
                if (pi !== startIndex && pi !== endIndex) {
                    geomPoints.push(start.x, start.y, p.lowerZ);
                    colors.push(r, g, b);
                }

                if (end) {
                    geomPoints.push(end.x, end.y, p.lowerZ);
                    colors.push(r, g, b);
                }

                // add an additional vector for the segment geometry
                if (end && pi + 1 !== endIndex) {
                    geomPoints.push(end.x, end.y, p.lowerZ);
                    colors.push(r, g, b);
                }
            }
        }
    }

    const line = getLineShape(geomPoints, colors);

    return line;
};

export const getBasicModelHeights = layers => {
    let lastZ1 = 0;
    let lastZ2 = 0;

    for (let li = 0; li < layers.length; li++) {
        const { extruderId, position: p } = layers[li];

        if (extruderId === 0) {
            lastZ1 = p.upperZ;
        } else {
            lastZ2 = p.upperZ;
        }
    }

    return {
        lastZ1,
        lastZ2,
    };
};

export const buildBasicPTPreview = (pt, layers, settings) => {
    const doPurgeEarlyEndValue = settings.schema?.doPurgeEarlyEnd?.value;
    const { lastZ1, lastZ2 } = getBasicModelHeights(layers);
    const ptHeight = doPurgeEarlyEndValue ? lastZ2 || 1 : lastZ1;
    const stepHeight = 0.5;
    const stepsNumber = Math.ceil(ptHeight / stepHeight);
    const ptDimensions = new Vector3();
    pt.geometry.boundingBox.getSize(ptDimensions);
    const { value: ptType } = settings.schema.purgeTowerShape;
    const shape = getPurgeShape(ptType, ptDimensions);
    const points = shape.curves.map(({ v1, v2 }) => [v1, v2]).flat();
    const geomPoints = [];
    const colors = [];
    let r, g, b;

    for (let i = 0; i < stepsNumber; i++) {
        const h = stepHeight * i;

        for (let pi = 0; pi < points.length; pi += 2) {
            const start = points[pi];
            const end = points[pi + 1];
            const line = { s: [start.x, start.y, h], e: [end.x, end.y, h] };
            [r, g, b] = getUpdatedLineColor(COLORS.material, line);
            geomPoints.push(start.x, start.y, h, end.x, end.y, h);
            colors.push(r, g, b, r, g, b);
        }
    }

    const line = getLineShape(geomPoints, colors, Line);
    line.position.copy(pt.position);

    // workaround to place 'Bowtie' and 'Bowtie2' to correct position
    if (['bowtie', 'bowtie2'].includes(ptType.toLowerCase())) {
        line.position.x -= ptDimensions.x / 2;
        line.position.y -= ptDimensions.y / 2;
    }

    return line;
};

export const isLineSegmentsBreakVolume = (lineSegments, buildVolume, expandAxisX) => {
    const modelBoundingBox = new Box3().setFromObject(lineSegments);
    const buildPlateBoundingBox = getBuildPlateBoundingBox(buildVolume, expandAxisX);
    const result = checkIsModelFits(buildPlateBoundingBox, modelBoundingBox);
    return isModelBreaksVolume(result);
};

export const addBasicSliceToScene = group => {
    ThreeInstance.scene.chunks = group;
    ThreeInstance.scene.add(group);
};

export const removeBasicSliceFromScene = () => {
    if (!ThreeInstance.scene.chunks) return;
    ThreeInstance.scene.chunks.children.forEach(chunk => {
        chunk.geometry.dispose();
        chunk.material.dispose();
    });
    ThreeInstance.scene.remove(ThreeInstance.scene.chunks);
    ThreeInstance.scene.chunks = null;
};
