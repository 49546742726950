import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { v4 as uuidv4 } from 'uuid';

import { EPICS_DEBOUNCE_TIME, UPLOAD_JOB_TYPE } from '../consts';
import { MODELS_DOWNLOAD_DEMO_FILE_START, MODELS_STOP_DOWNLOAD_DEMO_FILE } from '../state/redux/types';
import {
    doSetUploadJobsCount,
    doSetModelLoading,
    doStartHandlingUploadJobs,
    doShowTopOverlayBar,
    doDownloadDemoFileFulfilled,
    doDownloadDemoFileFailed,
} from '../state/redux/actions';
import { getDemoFileUrl, blobToFile } from '../helpers/utils';

const parseResponseToActions = (res, action) => {
    const blob = new Blob([res.response]);
    const file = blobToFile(blob, `${action.payload.name}.stl`);

    const uploadJobs = [
        {
            files: () => [file],
            type: UPLOAD_JOB_TYPE.stlJob,
            id: uuidv4(),
        },
    ];

    return of(
        doDownloadDemoFileFulfilled(),
        doShowTopOverlayBar(false),
        doSetUploadJobsCount(1),
        doSetModelLoading({ modelLoading: true }),
        doStartHandlingUploadJobs({ uploadJobs })
    );
};

const parseErrorToAction = error => {
    console.log('Failed to download demo file:', error);
    return of(doDownloadDemoFileFailed());
};

const downloadDemoFile = (action$, state$) =>
    action$.pipe(
        ofType(MODELS_DOWNLOAD_DEMO_FILE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.DOWNLOAD_DEMO_FILE),
        mergeMap(action => {
            const url = getDemoFileUrl(action.payload.printerType, action.payload.modelName);

            return ajax({
                url,
                responseType: 'arraybuffer',
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(parseErrorToAction),
                takeUntil(action$.pipe(ofType(MODELS_STOP_DOWNLOAD_DEMO_FILE)))
            );
        })
    );

export default downloadDemoFile;
