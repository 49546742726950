import {
    SHOW_TOP_OVERLAY_BAR,
    SET_ACTIVE_JOB_TYPE,
    SHOW_MAKERBOT_FILE_SIZE_MODAL,
    SHOW_STL_FILE_SIZE_MODAL,
    SET_UPLOADED_MAKERBOT_FILE,
    SET_UPLOADED_FILE_FORMAT,
    SET_IS_UPLOAD_MODAL_OPEN,
    SET_FOCUSED_INPUT_NAME,
    SET_UNDO_REDO,
    SET_PLACE_FACE,
    SHOW_APP_SETTINGS,
    SET_URLS,
    SET_IS_PRINTER_DROPDOWN_OPEN,
    SET_SHORTCUTS,
    SET_BUILD_VOLUMES,
    SET_SYSTEM_PREFERENCES,
    SET_UNITS_MISMATCH,
    SET_SHOW_PLACE_FACE,
    SET_SHOULD_PLACE_FACE,
    SHOW_MISSING_PARTS_MODAL,
    SET_FILES_UPLOADED_FROM_FOLDER,
    SET_TIMESTAMP_SCROLL,
    SHOW_SAVE_APP_SETTINGS_MODAL,
    SET_ACTIVE_APP_SETTINGS_SAVE_BUTTON,
    SHOW_GRAPHICS_SETTINGS_CHANGED_MODAL,
    ADD_TO_UNDO_STACK,
    SET_UNDO_REDO_FLAG,
    SHOW_CLEAR_BUILD_PLATE_MODAL,
    SET_SMART_ORIENT,
    SEND_JOB_TO_SMART_ORIENT_START,
    SEND_JOB_TO_SMART_ORIENT_FAILED,
    SEND_JOB_TO_SMART_ORIENT_FULFILLED,
    TRACK_PROGRESS_FOR_SMART_ORIENT_START,
    TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED,
    TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED,
    SET_IS_PERSPECTIVE_CAMERA,
    SHOW_SLICING_ERROR_MODAL,
    SET_CONTEXT_MENU,
    SET_MEASURE_TOOL,
    SET_FEATURE_FLAGS,
    SET_SMART_ARRANGE_PROGRESS,
    SET_SMART_ARRANGE_STATE,
    SHOW_MAKERBOT_FILE_CORRUPTED_MODAL,
    SHOW_MAKERBOT_FILE_ERROR_FOR_NON_EMPTY_PLATE_MODAL,
    SHOW_MAKERBOT_FILE_ERROR_INCOMPATIBLE_MODAL,
    SET_FILE_UPLOAD_ACTION,
} from './types';

export const doSetUnitsMismatch = (isUnitsMismatching, validUnits) => {
    return {
        type: SET_UNITS_MISMATCH,
        payload: { isUnitsMismatching, validUnits },
    };
};

export const doSetActiveJobType = activeJobType => {
    return {
        type: SET_ACTIVE_JOB_TYPE,
        payload: { activeJobType },
    };
};

export const doSetFileUploadAction = fileUploadAction => {
    return {
        type: SET_FILE_UPLOAD_ACTION,
        payload: { fileUploadAction },
    };
};

export const doShowTopOverlayBar = showTopOverlayBar => {
    return {
        type: SHOW_TOP_OVERLAY_BAR,
        payload: { showTopOverlayBar },
    };
};

export const doShowSTLFileSizeModal = showSTLFileSizeModal => {
    return {
        type: SHOW_STL_FILE_SIZE_MODAL,
        payload: { showSTLFileSizeModal },
    };
};

export const doShowSaveAppSettingsModal = showSaveAppSettingsModal => {
    return {
        type: SHOW_SAVE_APP_SETTINGS_MODAL,
        payload: { showSaveAppSettingsModal },
    };
};

export const doShowGraphicsSettingsChangedModal = showGraphicsSettingsChangedModal => {
    return {
        type: SHOW_GRAPHICS_SETTINGS_CHANGED_MODAL,
        payload: { showGraphicsSettingsChangedModal },
    };
};

export const doShowClearBuildPlateModal = showClearBuildPlateModal => {
    return {
        type: SHOW_CLEAR_BUILD_PLATE_MODAL,
        payload: { showClearBuildPlateModal },
    };
};
export const doShowJobErrorModal = showJobErrorModal => {
    return {
        type: SHOW_SLICING_ERROR_MODAL,
        payload: { showJobErrorModal },
    };
};

export const doSetActiveAppSettingsSaveButton = activeAppSettingsSaveButton => {
    return {
        type: SET_ACTIVE_APP_SETTINGS_SAVE_BUTTON,
        payload: { activeAppSettingsSaveButton },
    };
};

export const doShowMakerbotFileSizeModal = showMakerbotFileSizeModal => {
    return {
        type: SHOW_MAKERBOT_FILE_SIZE_MODAL,
        payload: { showMakerbotFileSizeModal },
    };
};

export const doShowMakerbotFileCorruptedModal = showMakerbotFileCorruptedModal => {
    return {
        type: SHOW_MAKERBOT_FILE_CORRUPTED_MODAL,
        payload: { showMakerbotFileCorruptedModal },
    };
};

export const doShowMakerbotErrorNonEmptyPlateModal = showMakerbotErrorNonEmptyPlateModal => {
    return {
        type: SHOW_MAKERBOT_FILE_ERROR_FOR_NON_EMPTY_PLATE_MODAL,
        payload: { showMakerbotErrorNonEmptyPlateModal },
    };
}
;
export const doShowMakerbotFileErrorIncompatibleModal = showMakerbotFileErrorIncompatibleModal => {
    return {
        type: SHOW_MAKERBOT_FILE_ERROR_INCOMPATIBLE_MODAL,
        payload: { showMakerbotFileErrorIncompatibleModal },
    };
};

export const doShowMissingPartsModal = showMissingPartsModal => {
    return {
        type: SHOW_MISSING_PARTS_MODAL,
        payload: { showMissingPartsModal },
    };
};

export const doSetUploadedMakerbotFile = uploadedMakerbotFile => {
    return {
        type: SET_UPLOADED_MAKERBOT_FILE,
        payload: { uploadedMakerbotFile },
    };
};

export const doSetUploadedFileFormat = uploadedFileFormat => {
    return {
        type: SET_UPLOADED_FILE_FORMAT,
        payload: { uploadedFileFormat },
    };
};

export const doSetIsUploadModalOpen = isUploadModalOpen => {
    return {
        type: SET_IS_UPLOAD_MODAL_OPEN,
        payload: { isUploadModalOpen },
    };
};

export const doSetFocusedInputName = focusedInputName => {
    return {
        type: SET_FOCUSED_INPUT_NAME,
        payload: { focusedInputName },
    };
};

export const doSetUndoRedo = undoRedo => {
    return {
        type: SET_UNDO_REDO,
        payload: { undoRedo },
    };
};

export const doAddToUndoStack = (undoOperation, resetRedo) => {
    return {
        type: ADD_TO_UNDO_STACK,
        payload: { undoOperation, resetRedo },
    };
};

export const doSetUndoRedoFlag = flag => {
    return {
        type: SET_UNDO_REDO_FLAG,
        payload: { flag },
    };
};

export const doSetContextMenu = contextMenu => {
    return {
        type: SET_CONTEXT_MENU,
        payload: { contextMenu },
    };
};

export const doSetMeasureTool = measureTool => {
    return {
        type: SET_MEASURE_TOOL,
        payload: { measureTool },
    };
};

export const doSetPlaceFace = placeFace => {
    return {
        type: SET_PLACE_FACE,
        payload: { placeFace },
    };
};

export const doSetShowPlaceFace = showPlaceFace => {
    return {
        type: SET_SHOW_PLACE_FACE,
        payload: { showPlaceFace },
    };
};

export const doSetShouldPlaceFace = shouldPlaceFace => {
    return {
        type: SET_SHOULD_PLACE_FACE,
        payload: { shouldPlaceFace },
    };
};

export const doSetSmartOrient = smartOrient => {
    return {
        type: SET_SMART_ORIENT,
        payload: { smartOrient },
    };
};

export const doSetSmartArrangeState = state => {
    return {
        type: SET_SMART_ARRANGE_STATE,
        payload: state,
    };
};

export const doSetSmartArrangeProgress = progress => {
    return {
        type: SET_SMART_ARRANGE_PROGRESS,
        payload: progress,
    };
};

export const doSendJobToSmartOrient = payload => {
    return {
        type: SEND_JOB_TO_SMART_ORIENT_START,
        payload,
    };
};

export const doSendJobToSmartOrientFulfilled = payload => {
    return {
        type: SEND_JOB_TO_SMART_ORIENT_FULFILLED,
        payload,
    };
};

export const doSendJobToSmartOrientFailed = payload => {
    return {
        type: SEND_JOB_TO_SMART_ORIENT_FAILED,
        payload,
    };
};

export const doTrackProgressForSmartOrient = payload => {
    return {
        type: TRACK_PROGRESS_FOR_SMART_ORIENT_START,
        payload,
    };
};

export const doTrackProgressForSmartOrientFulfilled = payload => {
    return {
        type: TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED,
        payload,
    };
};

export const doTrackProgressForSmartOrientFailed = payload => {
    return {
        type: TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED,
        payload,
    };
};

export const doShowAppSettings = showAppSettings => {
    return {
        type: SHOW_APP_SETTINGS,
        payload: { showAppSettings },
    };
};

export const doSetUrls = urls => {
    return {
        type: SET_URLS,
        payload: { urls },
    };
};

export const doSetIsPrinterDropdownOpen = isPrinterDropdownOpen => {
    return {
        type: SET_IS_PRINTER_DROPDOWN_OPEN,
        payload: { isPrinterDropdownOpen },
    };
};

export const doSetShortcuts = shortcuts => {
    return {
        type: SET_SHORTCUTS,
        payload: { shortcuts },
    };
};

export const doSetBuildVolumes = buildVolumes => {
    return {
        type: SET_BUILD_VOLUMES,
        payload: { buildVolumes },
    };
};

export const doSetSystemPreferences = systemPreferences => {
    return {
        type: SET_SYSTEM_PREFERENCES,
        payload: { systemPreferences },
    };
};

export const doSetFilesUploadedFromFolder = filesUploadedFromFolder => {
    return {
        type: SET_FILES_UPLOADED_FROM_FOLDER,
        payload: { filesUploadedFromFolder },
    };
};

export const doSetIsPerspectiveCamera = isPerspectiveCamera => {
    return {
        type: SET_IS_PERSPECTIVE_CAMERA,
        payload: { isPerspectiveCamera },
    };
};

export const doSetTimestampScroll = тimestampScroll => {
    return {
        type: SET_TIMESTAMP_SCROLL,
        payload: { тimestampScroll },
    };
};

export const doSetFeatureFlags = featureFlags => {
    return {
        type: SET_FEATURE_FLAGS,
        payload: featureFlags,
    };
};
