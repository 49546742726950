import {
    SYSTEM_PREFERENCES_ACTION_KEYS,
    MEASUREMENTS_UNITS,
    LOCAL_STORAGE_KEYS,
    FILE_FORMATS,
    JOB_TYPE,
    MAKERBOT_FILE_DEFAULT_OBJECT,
    MAC_SYSTEM_PREFERENCE,
    DEFAULT_SYSTEM_PREFERENCE,
    SHORTCUTS,
    EXPORT_BUTTON_TEXT,
    QUALITY_SETTINGS,
    SMART_ORIENT_STATE,
    SMART_ARRANGE_STATE,
    FILE_UPLOAD_ACTION,
} from '../../../consts';
import { isMac, isMacPreference } from '../../../helpers/utils/environment';
import { crossFrameStorage } from '../../../helpers/CrossFrameStorage';

import { getQueryStringParameterByName } from '../../../helpers/utils/common';
import {
    SET_ACTIVE_JOB_TYPE,
    SET_BUILD_VOLUMES,
    SET_FILES_UPLOADED_FROM_FOLDER,
    SET_FOCUSED_INPUT_NAME,
    SET_UPLOADED_MAKERBOT_FILE,
    SET_IS_PRINTER_DROPDOWN_OPEN,
    SET_IS_UPLOAD_MODAL_OPEN,
    SET_PLACE_FACE,
    SET_SHORTCUTS,
    SET_SHOULD_PLACE_FACE,
    SET_SHOW_PLACE_FACE,
    SET_SYSTEM_PREFERENCES,
    SET_UNDO_REDO,
    ADD_TO_UNDO_STACK,
    SET_UNITS_MISMATCH,
    SET_UPLOADED_FILE_FORMAT,
    SET_URLS,
    SET_TIMESTAMP_SCROLL,
    SHOW_APP_SETTINGS,
    SHOW_MAKERBOT_FILE_SIZE_MODAL,
    SHOW_MISSING_PARTS_MODAL,
    SHOW_STL_FILE_SIZE_MODAL,
    SHOW_TOP_OVERLAY_BAR,
    SHOW_SAVE_APP_SETTINGS_MODAL,
    SET_ACTIVE_APP_SETTINGS_SAVE_BUTTON,
    SHOW_GRAPHICS_SETTINGS_CHANGED_MODAL,
    SET_UNDO_REDO_FLAG,
    SHOW_CLEAR_BUILD_PLATE_MODAL,
    SET_SMART_ORIENT,
    SEND_JOB_TO_SMART_ORIENT_FAILED,
    TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED,
    TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED,
    SEND_JOB_TO_SMART_ORIENT_FULFILLED,
    SET_IS_PERSPECTIVE_CAMERA,
    SHOW_SLICING_ERROR_MODAL,
    SET_CONTEXT_MENU,
    SET_MEASURE_TOOL,
    SET_FEATURE_FLAGS,
    SET_SMART_ARRANGE_STATE,
    SET_SMART_ARRANGE_PROGRESS,
    SHOW_MAKERBOT_FILE_CORRUPTED_MODAL,
    SHOW_MAKERBOT_FILE_ERROR_FOR_NON_EMPTY_PLATE_MODAL,
    SHOW_MAKERBOT_FILE_ERROR_INCOMPATIBLE_MODAL,
    SET_FILE_UPLOAD_ACTION,
} from './types';
import { getInitialFeatureFlagsForStore, updateFeatureFlags } from '../../../helpers/utils/feature-flags';
import Pendo from '../../../helpers/pendo';

// Related to ABCP-417. Adding a new option for MAC
if (isMac()) {
    SYSTEM_PREFERENCES_ACTION_KEYS.pan.unshift(...MAC_SYSTEM_PREFERENCE.pan);
    SYSTEM_PREFERENCES_ACTION_KEYS.rotate.unshift(...MAC_SYSTEM_PREFERENCE.rotate);
}

const initialState = {};

crossFrameStorage.fetchItem(LOCAL_STORAGE_KEYS.systemPreferences).then(storedPreferences => {
    const initialGraphicsPreferences = storedPreferences?.graphics
        ? { ...storedPreferences.graphics }
        : { level: QUALITY_SETTINGS.quality, showShadows: true };

    let initialSystemPreferences;
    if (storedPreferences) {
        initialSystemPreferences = {
            ...storedPreferences,
            graphics: { ...initialGraphicsPreferences },
            viewActions: {
                ...storedPreferences.viewActions,
                zoom: SYSTEM_PREFERENCES_ACTION_KEYS.zoom.find(o => {
                    return o.value === storedPreferences?.viewActions?.zoom?.value;
                }),
                pan: SYSTEM_PREFERENCES_ACTION_KEYS.pan.find(o => {
                    const selectedPan = storedPreferences?.viewActions?.pan;
                    if (isMacPreference(selectedPan, 'pan') && !isMac()) {
                        return DEFAULT_SYSTEM_PREFERENCE?.pc?.pan;
                    }
                    return o.value === selectedPan?.value;
                }),
                rotate: SYSTEM_PREFERENCES_ACTION_KEYS.rotate.find(o => {
                    const selectedRotate = storedPreferences?.viewActions?.rotate;
                    if (isMacPreference(selectedRotate, 'rotate') && !isMac()) {
                        return DEFAULT_SYSTEM_PREFERENCE?.pc?.rotate;
                    }
                    return o.value === storedPreferences?.viewActions?.rotate?.value;
                }),
            },
        };
    } else {
        const deviceKey = isMac() ? 'mac' : 'pc';
        const defaultViewActions = DEFAULT_SYSTEM_PREFERENCE[deviceKey];
        initialSystemPreferences = {
            graphics: { ...initialGraphicsPreferences },
            systemUnits: MEASUREMENTS_UNITS.mm.id,
            displayUnits: MEASUREMENTS_UNITS.mm.id,
            viewActions: {
                zoom: defaultViewActions.zoom,
                pan: defaultViewActions.pan,
                rotate: defaultViewActions.rotate,
                reverseMouseScrolling: false,
            },
        };
    }

    const isEmbedded = getQueryStringParameterByName('embedded') === 'true';
    const shortcutsKey = isMac() ? 'mac' : 'pc';
    const shortcuts = SHORTCUTS[shortcutsKey];
    shortcuts.printQueueCategory.printQueue.name = isEmbedded ? EXPORT_BUTTON_TEXT.queue : EXPORT_BUTTON_TEXT.print;

    initialState.buildVolumes = null;
    initialState.activeJobType = JOB_TYPE.export;
    initialState.fileUploadAction = FILE_UPLOAD_ACTION.upload;
    initialState.uploadedFileFormat = FILE_FORMATS.stl;
    initialState.isUnitsMismatching = false;
    initialState.validUnits = initialSystemPreferences.displayUnits;
    initialState.systemPreferences = initialSystemPreferences;
    initialState.isUploadModalOpen = false;
    initialState.showSTLFileSizeModal = false;
    initialState.showSaveAppSettingsModal = false;
    initialState.showGraphicsSettingsChangedModal = false;
    initialState.activeAppSettingsSaveButton = false;
    initialState.showMakerbotFileSizeModal = false;
    initialState.showMakerbotFileCorruptedModal = false;
    initialState.showMakerbotErrorNonEmptyPlateModal = false;
    initialState.showMakerbotFileErrorIncompatibleModal = false;
    initialState.showMissingPartsModal = false;
    initialState.uploadedMakerbotFile = Object.assign({}, MAKERBOT_FILE_DEFAULT_OBJECT);
    initialState.showMakerbotFileIncompatibleModal = false;
    initialState.showAppSettings = false;
    initialState.showTopOverlayBar = false;
    initialState.showClearBuildPlateModal = false;
    initialState.showJobErrorModal = false;
    initialState.placeFace = {
        showPlaceFace: false,
        selectedNormal: null,
        selectedModelName: '',
        shouldPlaceFace: false,
    };
    initialState.contextMenu = {
        show: false,
        x: 0,
        y: 0,
        modelIds: [],
    };
    initialState.measureTool = {
        visible: false,
        minimized: false,
        screenX: 350,
        screenY: 0,
        startPoint: {
            x: 0,
            y: 0,
            z: 0,
        },
        endPoint: {
            x: 0,
            y: 0,
            z: 0,
        },
    };
    initialState.shortcuts = shortcuts;
    initialState.undoRedo = {
        undoStack: [],
        redoStack: [],
        undoResize: false,
        redoResize: false,
        undoRotate: false,
        redoRotate: false,
        ndoMove: false,
        redoMove: false,
        purgeTowerWasMoved: false,
    };
    initialState.isPerspectiveCamera = true;
    initialState.focusedInputName = '';
    initialState.isPrinterDropdownOpen = false;
    initialState.about = {};
    initialState.smartOrient = {
        state: SMART_ORIENT_STATE.idle,
        progress: 0,
        result: null,
    };
    initialState.smartArrange = {
        state: SMART_ARRANGE_STATE.idle,
        progress: 0,
    };
    initialState.urls = {};
    initialState.featureFlags = getInitialFeatureFlagsForStore();
});

const viewerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_JOB_TYPE:
        case SET_UPLOADED_MAKERBOT_FILE:
        case SET_UPLOADED_FILE_FORMAT:
        case SET_IS_UPLOAD_MODAL_OPEN:
        case SET_FOCUSED_INPUT_NAME:
        case SET_UNDO_REDO:
        case SET_CONTEXT_MENU:
        case SET_MEASURE_TOOL:
        case SET_PLACE_FACE:
        case SET_URLS:
        case SET_IS_PRINTER_DROPDOWN_OPEN:
        case SET_SHORTCUTS:
        case SET_BUILD_VOLUMES:
        case SET_SYSTEM_PREFERENCES:
        case SET_SMART_ORIENT:
        case SHOW_STL_FILE_SIZE_MODAL:
        case SHOW_MAKERBOT_FILE_SIZE_MODAL:
        case SHOW_MAKERBOT_FILE_CORRUPTED_MODAL:
        case SHOW_MAKERBOT_FILE_ERROR_FOR_NON_EMPTY_PLATE_MODAL:
        case SHOW_MAKERBOT_FILE_ERROR_INCOMPATIBLE_MODAL:
        case SHOW_MISSING_PARTS_MODAL:
        case SHOW_TOP_OVERLAY_BAR:
        case SHOW_APP_SETTINGS:
        case SET_UNITS_MISMATCH:
        case SET_FILES_UPLOADED_FROM_FOLDER:
        case SHOW_SAVE_APP_SETTINGS_MODAL:
        case SHOW_GRAPHICS_SETTINGS_CHANGED_MODAL:
        case SET_ACTIVE_APP_SETTINGS_SAVE_BUTTON:
        case SHOW_CLEAR_BUILD_PLATE_MODAL:
        case SHOW_SLICING_ERROR_MODAL:
        case SET_IS_PERSPECTIVE_CAMERA:
        case SET_FILE_UPLOAD_ACTION:
            return Object.assign({}, state, {
                ...action.payload,
            });
        case SEND_JOB_TO_SMART_ORIENT_FULFILLED:
            return {
                ...state,
                smartOrient: {
                    state: SMART_ORIENT_STATE.active,
                },
            };
        case TRACK_PROGRESS_FOR_SMART_ORIENT_FULFILLED:
            return {
                ...state,
                smartOrient: {
                    state: SMART_ORIENT_STATE.idle,
                    progress: 100,
                    result: action.payload,
                },
            };
        case SEND_JOB_TO_SMART_ORIENT_FAILED:
        case TRACK_PROGRESS_FOR_SMART_ORIENT_FAILED:
            return {
                ...state,
                smartOrient: {
                    state: SMART_ORIENT_STATE.fail,
                    progress: 0,
                },
            };
        case SET_SHOW_PLACE_FACE:
        case SET_SHOULD_PLACE_FACE:
            return Object.assign({}, state, {
                ...action.payload,
                placeFace: {
                    ...state.placeFace,
                    ...action.payload,
                },
            });
        case ADD_TO_UNDO_STACK: {
            const { undoOperation, resetRedo } = action.payload;
            const undoRedo = {
                ...state.undoRedo,
                undoStack: [undoOperation, ...state.undoRedo.undoStack],
            };

            if (resetRedo) {
                undoRedo.redoStack = [];
            }

            return {
                ...state,
                undoRedo,
            };
        }
        case SET_UNDO_REDO_FLAG: {
            const { flag } = action.payload;
            const undoRedo = {
                ...state.undoRedo,
                ...flag,
            };
            return {
                ...state,
                undoRedo,
            };
        }
        case SET_TIMESTAMP_SCROLL:
            return {
                ...state,
                ...action.payload,
            };
        case SET_FEATURE_FLAGS:
            updateFeatureFlags(action.payload);
            Pendo.update(Pendo.getVisitorStorageData());

            return {
                ...state,
                featureFlags: { ...action.payload },
            };
        case SET_SMART_ARRANGE_STATE: {
            return {
                ...state,
                smartArrange: {
                    ...state.smartArrange,
                    state: action.payload,
                },
            };
        }
        case SET_SMART_ARRANGE_PROGRESS: {
            return {
                ...state,
                smartArrange: {
                    ...state.smartArrange,
                    progress: action.payload,
                },
            };
        }
        default:
            return state;
    }
};

export default viewerReducer;
