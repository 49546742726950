import { fromEvent, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { CREATE_CUSTOM_PRINT_MODE_START, CREATE_CUSTOM_PRINT_MODE_STOP_TRACKING } from '../state/redux/types';
import {
    doCreateCustomPrintModeSuccess,
    doCreateCustomPrintModeFail,
    doCreateCustomPrintModeStopTracking,
    doSetPrinterOverrides,
    doSetNotification,
} from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResponseToAction = response => {
    if (response.error) {
        return parseErrorToAction(response.error);
    }

    return of(
        doCreateCustomPrintModeSuccess({ printMode: response }),
        doSetNotification(NOTIFICATION_TYPE.CUSTOM_MODE_SUCCESSFULLY_CREATED),
        doSetPrinterOverrides({ printMode: response.id }),
        doCreateCustomPrintModeStopTracking()
    );
};

const parseErrorToAction = error => {
    console.log('Error creating custom print mode', error);
    return of(
        doSetNotification(NOTIFICATION_TYPE.CUSTOM_MODE_CREATION_ERROR),
        doCreateCustomPrintModeFail(error),
        doCreateCustomPrintModeStopTracking()
    );
};

const createCustomPrintMode = (action$, state$) =>
    action$.pipe(
        ofType(CREATE_CUSTOM_PRINT_MODE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.CREATE_CUSTOM_PRINT_MODE),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const { name, userToken, settings } = action.payload;
            const printModeFile = new Blob([JSON.stringify(settings)], { type: 'application/octet-stream' });
            const config = { name: `${name}.printmode`, userToken: `Bearer ${userToken}`, file: printModeFile };
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('print-mode-upload', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'print-mode-upload').pipe(
                        mergeMap(response => parseResponseToAction(response)),
                        catchError(error => parseErrorToAction(error)),
                        takeUntil(action$.pipe(ofType(CREATE_CUSTOM_PRINT_MODE_STOP_TRACKING)))
                    )
                )
            );
        })
    );

export default createCustomPrintMode;
