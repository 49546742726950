import { ofType } from 'redux-observable';

import { fromEvent, of } from 'rxjs';
import { mergeMap, catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, filter } from 'rxjs/operators';

import { FETCH_PRINTER_SCHEMA_COMPLETED, FETCH_PRINTER_SCHEMA_START } from '../state/redux/types';
import {
    doFetchPrinterSchemaFulfilled,
    doFetchPrinterSchemaFailed,
    doFetchPrinterSchemaCompleted,
    doFetchInitialPrinterSchemas,
} from '../state/redux/actions';

import { compareEpicPayload } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';

const parseResponseToActions = (response, action) => {
    if (response.error) {
        return parseErrorToActions(response.error);
    }

    Object.assign(action.payload, { schema: response });

    const args = [doFetchPrinterSchemaFulfilled(action.payload), doFetchPrinterSchemaCompleted()];
    const { fetchAllPrintModes } = action.payload;
    if (fetchAllPrintModes) {
        const printMode = response.custom_parameters.printMode.initial_value;
        const printModes = response.custom_parameters.printMode.validStrings.filter(el => el !== printMode);
        if (printModes.length) {
            args.unshift(doFetchInitialPrinterSchemas(printModes));
        }
    }

    return of(...args);
};

const parseErrorToActions = error => {
    console.log('Failed fetching printers schema - ', error);
    return of(doFetchPrinterSchemaFailed(error), doFetchPrinterSchemaCompleted());
};

const fetchPrinterSchema = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_PRINTER_SCHEMA_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_PRINTER_SCHEMA),
        distinctUntilChanged(compareEpicPayload),
        switchMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const { config } = action.payload;
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            const timestamp = state$.value?.appState?.printerState?.schemaRequestTimestamp;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('schema', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'schema').pipe(
                        filter(response => {
                            return response.timestamp === timestamp;
                        }),
                        mergeMap(response => parseResponseToActions(response, action)),
                        catchError(error => parseErrorToActions(error)),
                        takeUntil(action$.pipe(ofType(FETCH_PRINTER_SCHEMA_COMPLETED)))
                    )
                )
            );
        })
    );

export default fetchPrinterSchema;
