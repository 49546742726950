import {
    FETCH_USER_FROM_ONION_START,
    FETCH_USER_FROM_ONION_SUCCESS,
    FETCH_USER_FROM_ONION_FAILED,
    FETCH_AVAILABLE_ARTIFACTS_START,
    FETCH_AVAILABLE_ARTIFACTS_SUCCESS,
    FETCH_AVAILABLE_ARTIFACTS_FAILED,
    SET_SELECTED_ARTIFACT,
    FETCH_ABOUT_INFO_START,
    FETCH_ABOUT_INFO_SUCCESS,
    FETCH_ABOUT_INFO_FAILED,
    HANDLE_DIGITAL_FACTORY_TOKEN,
} from './types';

export const doFetchAboutInfo = payload => {
    return {
        type: FETCH_ABOUT_INFO_START,
        payload,
    };
};

export const doFetchAboutInfoFulfilled = payload => {
    return {
        type: FETCH_ABOUT_INFO_SUCCESS,
        payload,
    };
};

export const doFetchAboutInfoFailed = payload => {
    return {
        type: FETCH_ABOUT_INFO_FAILED,
        payload,
    };
};

export function doFetchUserFromOnion() {
    return {
        type: FETCH_USER_FROM_ONION_START,
    };
}

export function doFetchUserFromOnionFulfilled(authToken) {
    return {
        type: FETCH_USER_FROM_ONION_SUCCESS,
        payload: authToken,
    };
}

export function doFetchUserFromOnionFailed(error) {
    return {
        type: FETCH_USER_FROM_ONION_FAILED,
        payload: { error },
    };
}

export const doHandleDigitalFactoryToken = payload => {
    return {
        type: HANDLE_DIGITAL_FACTORY_TOKEN,
        payload,
    };
};

export const doFetchAvailableArtifacts = payload => {
    return {
        type: FETCH_AVAILABLE_ARTIFACTS_START,
        payload,
    };
};

export const doFetchAvailableArtifactsFulfilled = payload => {
    return {
        type: FETCH_AVAILABLE_ARTIFACTS_SUCCESS,
        payload,
    };
};

export const doFetchAvailableArtifactsFailed = payload => {
    return {
        type: FETCH_AVAILABLE_ARTIFACTS_FAILED,
        payload,
    };
};

export const doSetSelectedArtifact = payload => {
    return {
        type: SET_SELECTED_ARTIFACT,
        payload,
    };
};
