import { ajax } from 'rxjs/ajax';
import { timer, of, throwError } from 'rxjs';
import { ofType } from 'redux-observable';
import {
    mergeMap,
    concat,
    concatMap,
    catchError,
    take,
    takeUntil,
    retryWhen,
    delay,
    debounceTime,
    timeout,
} from 'rxjs/operators';

import urls from '@makerbot/urls';

import {
    TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START,
    STOP_UPDATE_POLLING_FROM_PREVIEW_GENERATOR,
} from '../state/redux/types';
import {
    doTrackProgressFromPreviewGeneratorFulfilled,
    doTrackProgressFromPreviewGeneratorFailed,
    doStopUpdateProgressCheckFromPreviewGenerator,
    doUpdatePreviewProgressJob,
    doSetModelLoading,
} from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME, EXPORT_STATUSES, TIMING } from '../consts';
import { cleanErrorMessage } from '../helpers/utils/error';

const parseResToActions = ({ response }, action) => {
    const { failed, completed, result } = response;
    if (completed || failed) {
        let status;

        if (failed) {
            status = EXPORT_STATUSES.failed;
            console.log(`Preview Job ${response.id} ${status}: ${result}.`);

            return of(
                doTrackProgressFromPreviewGeneratorFailed(cleanErrorMessage(result)),
                doStopUpdateProgressCheckFromPreviewGenerator()
            );
        } else {
            const result = response.result;
            result.id = response.id;
            result.fileName = action.payload.fileName;
            result.makerbotUrl = action.payload.fileUrl;
            result.botTypeForPreview = action.payload.botTypeForPreview;
            result.previewId = action.payload.previewId;
            result.jobId = action.payload.jobId;
            console.log(`Preview Job ${response.id} ${status}.`);

            return of(
                doSetModelLoading({ modelLoading: false }),
                doTrackProgressFromPreviewGeneratorFulfilled(result),
                doStopUpdateProgressCheckFromPreviewGenerator()
            );
        }
    } else {
        // Update the 'progress' of the slice from respose.data
        console.log(`Polling Preview Generator for progress...`, `${response.progress}%`);
        return of(
            doUpdatePreviewProgressJob({
                progress: response.progress,
                fileName: action.payload.fileName,
                botTypeForPreview: action.payload.botTypeForPreview,
            })
        );
    }
};

const parseErrorToActions = error => {
    console.log(`Preview Job failed...`);
    console.log(`Error:`, error);
    return of(doTrackProgressFromPreviewGeneratorFailed(error), doStopUpdateProgressCheckFromPreviewGenerator());
};

const trackUpdateProgressFromPreviewGenerator = action$ =>
    action$.pipe(
        ofType(TRACK_PROGRESS_FROM_PREVIEW_GENERATOR_START),
        debounceTime(EPICS_DEBOUNCE_TIME.TRACK_UPDATE_PROGRESS_FROM_PREVIEW_GENERATOR),
        mergeMap(action => {
            const apiUrl = `https://${urls.reconstructor}/toolpath/${action.payload.url}`;
            const fetchProgress$ = ajax.get(apiUrl);
            const trigger$ = timer(0, 1000);

            return trigger$.pipe(
                concatMap(() => fetchProgress$),
                retryWhen(error => error.pipe(delay(2000), take(3), concat(throwError(error)))),
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(error)),
                timeout(TIMING.SLICING_TIMEOUT),
                catchError(_ => parseErrorToActions('Preview was processing for too long, and has been canceled.')),
                takeUntil(action$.pipe(ofType(STOP_UPDATE_POLLING_FROM_PREVIEW_GENERATOR)))
            );
        })
    );

export default trackUpdateProgressFromPreviewGenerator;
