import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of, timer } from 'rxjs';
import { catchError, mergeMap, concatMap, debounceTime } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { FETCH_REFLECTOR_AUTH_PRINTERS_START } from '../state/redux/types';
import { doFetchReflectorPrintersFulfilled, doFetchReflectorPrintersFailed } from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';

const parseResponseToActions = ({ response }) => {
    const data = response;
    const printers = Object.keys(data.printers).map(id => data.printers[id]);
    const printersExceptOGMini = printers.filter(device => device.status.bot_type !== 'mini_4');
    const sortedPrintersByName = printersExceptOGMini.sort((a, b) => a.name.localeCompare(b.name));

    return of(doFetchReflectorPrintersFulfilled(sortedPrintersByName));
};

const parseErrorToAction = error => {
    console.log('Failed fetching printers from reflector - ', error);
    return of(doFetchReflectorPrintersFailed(error.message));
};

const fetchReflectorPrinters = action$ =>
    action$.pipe(
        ofType(FETCH_REFLECTOR_AUTH_PRINTERS_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_REFLECTOR_PRINTERS),
        mergeMap(action => {
            const apiUrl = `https://${urls.reflector}/printers`;

            const req$ = ajax({
                url: apiUrl,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${action.payload.userToken}`,
                },
            });

            return timer(0, 10000).pipe(
                concatMap(() => req$),
                mergeMap(response => parseResponseToActions(response)),
                catchError(error => parseErrorToAction(error))
            );
        })
    );

export default fetchReflectorPrinters;
