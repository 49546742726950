import { ofType } from 'redux-observable';
import { fromEvent, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { TRACK_RESTART_SLICE_JOB_FROM_TEAMS } from '../state/redux/types';
import { doSetJobToRestart } from '../state/redux/actions';

const trackRestartSliceJobFromTeams = (action$, state$) =>
    action$.pipe(
        ofType(TRACK_RESTART_SLICE_JOB_FROM_TEAMS),
        mergeMap(() => {
            const teamsUrl = urls.teams;

            return fromEvent(window, 'message').pipe(
                filter(event => {
                    const url = `${teamsUrl.includes('local') ? 'http' : 'https'}://${teamsUrl}`;
                    return event.origin === url && !!event.data?.restart_slice;
                }),
                mergeMap(event => {
                    const { restart_slice } = event.data;
                    return of(doSetJobToRestart(restart_slice));
                }),
                catchError(error => {
                    console.error(error);
                    return of();
                })
            );
        })
    );

export default trackRestartSliceJobFromTeams;
