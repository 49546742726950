import { Vector3 } from 'three';

import printerErrorMap from '@makerbot/printer-error-map';
import { getPrinterAttachedExtruders } from '@makerbot/will-it-print/lib/printer.utils';

import {
    PrinterStatusTranslationEnum,
    PrinterStatusPrettierEnum,
    sixGenBotTypes,
    fifthGenBotTypes,
    printersWithDemoFiles,
    PrinterStatusProcessNameEnum,
    BuildVolumes,
    unreleasedPrinters,
} from '../consts/printers';

import {
    BASE_LAYER_TYPES,
    BOT_TYPE_ENUM,
    DEFAULT_MODEL_NAME,
    EXPAND_AXIS_X_SUPPORT_TYPES,
    EXTRUDERS,
    EXTRUDER_ID_SPLITTER,
    MATERIAL_TYPES,
    PRINT_MODES_ENUM,
    SUPPORT_TYPES,
    PRINT_STATUSES,
} from '../consts';

import PrinterConfig from '../models/PrinterConfig';
import ExtrudersPairConfig from '../models/ExtrudersPairConfig';
import ExtruderConfig from '../models/ExtruderConfig';

const hasErrors = status => {
    // status.toolhead.extruder is an array
    // can be [1x1] or [1x2].
    // **Should be called "extruders"**
    const extrudersArr = status.toolheads.extruder;
    const hasPrinterError =
        status.current_process && status.current_process.error && status.current_process.error.length;
    const hasExtruderError = extrudersArr.reduce((flag, extruder) => {
        if (isSixthGen(status.bot_type)) {
            if (extruder.error?.length > 0) {
                flag = true;
            }
        } else if (isFifthGen(status.bot_type)) {
            if (extruder.error) {
                flag = true;
            }
        }
        return flag;
    }, false);

    return hasPrinterError ? true : hasExtruderError;
};

const prettifyCurrentProcessStep = status => {
    const currentProcessStep = status?.current_process?.step || null;
    const currentProcessName = status?.current_process?.name || null;

    if ((currentProcessStep === 'suspended' || currentProcessStep === PRINT_STATUSES.suspended) && hasErrors(status)) {
        return PrinterStatusTranslationEnum.Error;
    }

    if (currentProcessName === PrinterStatusProcessNameEnum.DryingCycle) {
        return PrinterStatusTranslationEnum.DryingCycle;
    }

    if (PrinterStatusPrettierEnum[currentProcessStep]) {
        return PrinterStatusPrettierEnum[currentProcessStep];
    }

    return currentProcessStep;
};

const isExtruderAttached = status => {
    const extrudersInfo = status?.toolheads?.extruder || [];

    return extrudersInfo.reduce((flag, extruderInfo) => {
        if (!extruderInfo.tool_present) {
            flag = false;
        }
        return flag;
    }, true);
};

const isMainExtruderAttached = status => {
    const extrudersInfo = status?.toolheads?.extruder || [];
    return extrudersInfo[0]?.tool_present;
};

const prettifyPrinterStatus = printerStatus => {
    let status = PrinterStatusTranslationEnum.Idle;

    status = prettifyCurrentProcessStep(printerStatus) || status;
    status = isExtruderAttached(printerStatus) ? status : PrinterStatusTranslationEnum.AttachedExtruder;

    return status;
};

/**
 * Get formatted error message
 */
const prettifyPrinterError = (printer, errorCode, printerExtruders, modelExtruders) => {
    let errorMsg = `Error ${errorCode}`;
    if (printerErrorMap[errorCode]) {
        const sketch = isSketchPrinter(printer.printer_id);
        if (sketch) {
            // retrieve sketch-specific error message
            errorMsg = printerErrorMap.whitesmith[errorCode].message;
        } else if (printerErrorMap[errorCode].message) {
            // use error message if present
            errorMsg = printerErrorMap[errorCode].message;
            if (errorCode === 1048) {
                if (!printerExtruders || !modelExtruders) {
                    errorMsg = 'Selected print was sliced for another extruder. Please reslice print. (Error 1048)';
                } else {
                    let printerExtrLabels = printerExtruders.map(ext => getExtruderName(ext));
                    let modelExtrLabels = modelExtruders.map(ext => getExtruderName(ext));
                    printerExtrLabels.length !== 1 && (printerExtrLabels = printerExtrLabels.join(', '));
                    modelExtrLabels.length !== 1 && (modelExtrLabels = modelExtrLabels.join(', '));
                    //IE does not support .replaceAll method
                    errorMsg = errorMsg
                        .replace('%1', printerExtrLabels)
                        .split('%2')
                        .join(modelExtrLabels);
                }
            }
        } else {
            // use error name
            errorMsg = printerErrorMap[errorCode].pretty_name;
        }
    }

    return errorMsg;
};

const sketch_idRegex = /^(28571627)/g;

const isSixthGen = botType => sixGenBotTypes.includes(botType);
const isFifthGen = botType => fifthGenBotTypes.includes(botType);
const isSketchPrinter = id => id.match(sketch_idRegex) || id === 'sketch' || id === 'sketch_large';
const isCustomPrintMode = printModeId => {
    if (!printModeId) {
        return false;
    }

    const defaultPrintModes = Object.keys(PRINT_MODES_ENUM);
    return defaultPrintModes.every(mode => PRINT_MODES_ENUM[mode].id !== printModeId);
};

const getUpdatedExpandAxisXValue = (printerType, supportType, settings = {}) => {
    if (!printerType || !settings || !Object.keys(settings).length) {
        return 0;
    }

    if (!isSixthGen(printerType)) {
        return 0;
    }

    const expandAxisX = BuildVolumes[printerType]?.expandX || 0;

    if (EXPAND_AXIS_X_SUPPORT_TYPES.includes(supportType)) {
        return expandAxisX;
    }

    if (supportType === SUPPORT_TYPES.none.id) {
        const { doPurgeWall } = settings;
        const isPurgeTowerEnabled = doPurgeWall && doPurgeWall.value;

        if (isPurgeTowerEnabled) {
            return 0;
        }

        if (
            settings.baseLayer.value === BASE_LAYER_TYPES.none.id ||
            settings.baseLayer.value === BASE_LAYER_TYPES.paddedBase.id
        ) {
            return expandAxisX;
        }

        const raftBaseExtruderId = settings['raftProfiles.base.extruderID'];
        const raftInterfaceExtruderId = settings['raftProfiles.interface.extruderID'];
        const raftSurfaceExtruderId = settings['raftProfiles.surface.extruderID'];

        if (!raftBaseExtruderId || !raftInterfaceExtruderId || !raftSurfaceExtruderId) {
            return 0;
        }

        const isOnlyLeftExtruder =
            raftBaseExtruderId.value === 0 && raftInterfaceExtruderId.value === 0 && raftSurfaceExtruderId.value === 0;

        if (isOnlyLeftExtruder) {
            return expandAxisX;
        }

        return 0;
    }

    return 0;
};

const canQueue = status => {
    /**
     * ATM queuing is possible throughout the print process.
     * However with error state, until we define which errors
     * can/cannot allow queuing will for now be disabled.
     */
    return ![PrinterStatusTranslationEnum.Error].includes(status);
};

const formatFileName = name => {
    let resultName;
    if (name.indexOf('.') >= 0) {
        resultName = name
            .split('.')
            .slice(0, -1)
            .join('.');
    } else {
        resultName = name;
    }

    // eslint-disable-next-line no-useless-escape
    resultName = resultName.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_\.]/g, '');
    if (!resultName.length) {
        resultName = DEFAULT_MODEL_NAME;
    }

    return resultName;
};

const getPrinterByLabel = (label, buildVolumes, isOffline) => {
    let printer;
    if (isOffline) {
        printer = Object.values(buildVolumes.offline).find(el => el.name === label);
    } else {
        printer = Object.values(buildVolumes.online).find(el => el.name === label);
    }
    return printer;
};

const getPrinterById = (printerId, buildVolumes) => {
    const printer = Object.values(buildVolumes.online).find(el => el.printer_id === printerId);
    if (!printer) {
        return Object.values(buildVolumes.offline).find(el => el.printer_id === printerId);
    }
    return printer;
};

const getPrinterName = printer => {
    if (!printer) {
        return 'UNKNOWN';
    }

    if (BOT_TYPE_ENUM[printer]) {
        return BOT_TYPE_ENUM[printer].label;
    }

    return printer.toUpperCase();
};

const getExtruderName = (extruder, short) => {
    if (!extruder) {
        return 'UNKNOWN';
    }

    if (EXTRUDERS[extruder]) {
        if (short && EXTRUDERS[extruder].shortLabel) {
            return EXTRUDERS[extruder].shortLabel;
        }

        return EXTRUDERS[extruder].label;
    }

    return extruder.toUpperCase();
};

const getMaterialName = (material, short) => {
    if (!material) {
        return 'UNKNOWN';
    }

    if (MATERIAL_TYPES[material]) {
        if (short && MATERIAL_TYPES[material].shortLabel) {
            return MATERIAL_TYPES[material].shortLabel;
        }

        return MATERIAL_TYPES[material].label;
    }

    return material.toUpperCase();
};

const getPrinterBuildVolume = printer => {
    if (BuildVolumes[printer]) {
        return BuildVolumes[printer].size;
    }

    return new Vector3(100, 100, 100);
};

const isPrinterFeatureFlagEnabled = key => {
    // For unreleased printers that are under feature flag return true
    switch (key) {
        default:
            return false;
    }
};

const generateBuildVolumesObject = BuildVolumes => {
    const buildVolumes = {
        online: [],
        offline: {},
    };
    // transforms printer data to acceptible view to Viewer library
    for (const [key, value] of Object.entries(BuildVolumes)) {
        buildVolumes.offline[key] = {
            name: getPrinterName(key),
            volume: value.size,
            printer_id: key,
            type: key,
            isUnreleased: unreleasedPrinters.includes(key) && !isPrinterFeatureFlagEnabled(key),
        };
    }

    return buildVolumes;
};

const isPrinterHasDemoFiles = printerType => printersWithDemoFiles.includes(printerType);

const getExtrudersPairsKey = (modelExtruderId, supportExtruderId) => {
    return `${modelExtruderId}${EXTRUDER_ID_SPLITTER}${supportExtruderId}`;
};

const isKeyMatchForModelExtruder = (modelExtruderId, key) => {
    return key.startsWith(`${modelExtruderId}${EXTRUDER_ID_SPLITTER}`);
};

const isKeyMatchForSupportExtruder = (supportExtruderId, key) => {
    return key.endsWith(`${EXTRUDER_ID_SPLITTER}${supportExtruderId}`);
};

const getAvailableExtrudersPairs = (
    extrudersPairs,
    { modelExtruderId, supportExtruderId, modelMaterialId, supportMaterialId }
) => {
    return extrudersPairs
        .filter(ep => ep.id === getExtrudersPairsKey(modelExtruderId, supportExtruderId))
        .filter(ep => {
            return (
                ep.extrudersList[0].material === modelMaterialId && ep.extrudersList[1].material === supportMaterialId
            );
        });
};

const getPrinterConfig = (botType, printersConfigs) => {
    if (!botType) throw new Error('Printer id is not provided');

    const printerData = printersConfigs[botType];
    const printerConfig = new PrinterConfig();

    if (isSixthGen(botType)) {
        printerConfig.extrudersPairs = printerData.map(conf => {
            return ExtrudersPairConfig.createFormConfigObject(conf);
        });
    } else {
        printerConfig.extruders = printerData.map(conf => {
            return ExtruderConfig.createFromConfigObject(conf);
        });
    }

    return printerConfig;
};

const checkIfModelPreviewPossibleToQueue = (
    device,
    previewBotType,
    printersConfigs,
    previewExtruders,
    previewMaterials
) => {
    if (device.type !== previewBotType) {
        return false;
    }

    if (!isSixthGen(device.type)) {
        return true;
    }

    const config = getPrinterConfig(device.type, printersConfigs);
    const availablePairs = getAvailableExtrudersPairs(config.extrudersPairs, {
        modelExtruderId: previewExtruders[0],
        supportExtruderId: previewExtruders[1],
        modelMaterialId: previewMaterials[0],
        supportMaterialId: previewMaterials[1],
    });

    return !!availablePairs.length;
};

const getAttachedToPrinterExtruders = device => {
    return device.type === 'sketch_large' ? ['sketch_l_extruder'] : getPrinterAttachedExtruders(device.status);
};

export {
    getPrinterById,
    getPrinterByLabel,
    getPrinterName,
    getExtruderName,
    getMaterialName,
    getPrinterBuildVolume,
    generateBuildVolumesObject,
    prettifyPrinterStatus,
    prettifyPrinterError,
    isSixthGen,
    isFifthGen,
    isSketchPrinter,
    isCustomPrintMode,
    getUpdatedExpandAxisXValue,
    canQueue,
    formatFileName,
    isPrinterHasDemoFiles,
    getExtrudersPairsKey,
    isKeyMatchForModelExtruder,
    isKeyMatchForSupportExtruder,
    getAvailableExtrudersPairs,
    checkIfModelPreviewPossibleToQueue,
    getPrinterConfig,
    getAttachedToPrinterExtruders,
    isMainExtruderAttached,
};
