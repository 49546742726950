import { combineEpics } from 'redux-observable';

// import your Home Module epics here and combine them
// Place all epics in same directory
import fetchReflectorPrinters from './fetchReflectorPrinters';
import handleParentPostMessage from './handleParentPostMessage';
import handleTargetDeviceChange from './handleTargetDeviceChange';
import sendJobToSlicer from './sendJobToSlicer';
import cancelSlicerJob from './sendCancelJobToSlicer';
import trackUpdateProgressFromSlicer from './trackUpdateProgressFromSlicer';
import sendPrinterCommand from './sendPrinterCommand';
import fetchUserFromOnion from './fetchUserFromOnion';
import generatePreview from './generatePreview';
import trackUpdateProgressFromPreviewGenerator from './trackUpdateProgressFromPreviewGenerator';
import fetchReconstructedModel from './fetchReconstructedModel';
import getSpoolsInfo from './getSpoolsInfo';
import trackProgressFromKaitenMethodExec from './trackProgressFromKaitenMethodExec';
import uploadFileToStorage from './uploadFileToStorage';
import fetchAboutInfo from './fetchAboutInfo';
import fetchPrinterSchema from './fetchPrinterSchema';
import fetchInitialPrinterSchemas from './fetchInitialPrinterSchemas';
import fetchPrintersConfigs from './fetchPrintersConfigs';
import fetchBasicSliceProto from './fetchBasicSliceProto';
import fetchProfile from './fetchProfile';
import fetchToolpath from './fetchToolpath';
import trackUpdateProgressForBasicSlice from './trackUpdateProgressForBasicSlice';
import fetchPresignedUploadURLs from './fetchPresignedUploadURLs';
import uploadFilesToBucket from './uploadFilesToBucket';
import runZipJob from './runZipJob';
import fetchPresignedDownloadURLs from './fetchPresignedDownloadURLs';
import downloadFileFromSignedUrl from './downloadFileFromSignedUrl';
import runConversionJob from './runConversoinJob';
import fetchAvailableArtifacts from './fetchAvailableArtifacts';
import fetchConvertedFile from './fetchConvertedFile';
import trackUpdateConvertJobProgress from './trackUpdateConvertJobProgress';
import stopConversionJob from './stopConversionJob';
import fetchCustomPrintModes from './fetchCustomPrintModes';
import importCustomPrintMode from './importCustomPrintMode';
import createCustomPrintMode from './createCustomPrintMode';
import fetchCustomPrintSettings from './fetchCustomPrintSettings';
import deleteCustomPrintMode from './deleteCustomPrintMode';
import saveChangesCustomPrintMode from './saveChangesCustomPrintMode';
import exportCustomPrintMode from './exportCustomPrintMode';
import downloadCustomPrintMode from './downloadCustomPrintMode';
import handleUploadJobs from './handleUploadJobs';
import fetchCADsFromUrls from './fetchCADsFromUrls';
import downloadDemoFile from './downloadDemoFile';
import downloadDemoAssemblyFiles from './downloadDemoAssemblyFiles';
import trackJobStatusFromTeams from './trackJobStatusFromTeams';
import trackFailJobStatusFromTeams from './trackFailJobStatusFromTeams';
import trackRestartSliceJobFromTeams from './trackRestartSliceJobFromTeams';
import sendJobToSmartOrient from './sendJobToSmartOrient';
import trackUpdateProgressForSmartOrient from './trackUpdateProgressForSmartOrient';

const viewerEpic = combineEpics(
    fetchReflectorPrinters,
    handleParentPostMessage,
    fetchBasicSliceProto,
    handleTargetDeviceChange,
    sendJobToSlicer,
    cancelSlicerJob,
    trackUpdateProgressFromSlicer,
    sendPrinterCommand,
    fetchUserFromOnion,
    generatePreview,
    trackUpdateProgressFromPreviewGenerator,
    fetchReconstructedModel,
    getSpoolsInfo,
    trackProgressFromKaitenMethodExec,
    uploadFileToStorage,
    fetchAboutInfo,
    fetchPrinterSchema,
    fetchInitialPrinterSchemas,
    fetchPrintersConfigs,
    fetchProfile,
    fetchToolpath,
    trackUpdateProgressForBasicSlice,
    fetchPresignedUploadURLs,
    uploadFilesToBucket,
    runZipJob,
    fetchPresignedDownloadURLs,
    downloadFileFromSignedUrl,
    runConversionJob,
    fetchAvailableArtifacts,
    fetchConvertedFile,
    trackUpdateConvertJobProgress,
    stopConversionJob,
    fetchCustomPrintModes,
    importCustomPrintMode,
    createCustomPrintMode,
    fetchCustomPrintSettings,
    deleteCustomPrintMode,
    saveChangesCustomPrintMode,
    exportCustomPrintMode,
    downloadCustomPrintMode,
    handleUploadJobs,
    fetchCADsFromUrls,
    downloadDemoFile,
    downloadDemoAssemblyFiles,
    trackJobStatusFromTeams,
    trackFailJobStatusFromTeams,
    trackRestartSliceJobFromTeams,
    sendJobToSmartOrient,
    trackUpdateProgressForSmartOrient
);

export default viewerEpic;
