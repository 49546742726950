import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, distinctUntilChanged } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { SEND_JOB_TO_SLICER_START } from '../state/redux/types';
import {
    doSendPrinterCommand,
    doSendJobToSlicerFulfilled,
    doSendJobToSlicerFailed,
    doTrackProgressFromSlicer,
    doTrackProgressForBasicSlice,
    doSendJobToSlicer,
    doUpdateSliceJob,
    doAddOperatingJob,
} from '../state/redux/actions';
import { compareEpicPayload, getFailedSliceJob } from '../helpers/utils';
import { showStatusRing } from '../helpers/utils/environment';
import { sendSliceStartedEvent } from '../helpers/utils/notification-event';
import { EXPORT_STATUSES, JOB_TYPE, PRINT_JOB_ORIGIN } from '../consts';
import { postSliceStatus } from './trackUpdateProgressFromSlicer';

const parseResponseToActions = ({ response }, action, userToken, isEmbedded) => {
    const currentJob = action.payload.currentJob;
    const doBasicSlice = action.payload.doBasicSlice;
    const sliceId = response.jobID || response.url.split('/').slice(-1)[0];

    if (doBasicSlice) {
        console.log(`Queued basic slice job via CloudSlicer...`);
        return of(
            doUpdateSliceJob({ localId: currentJob.localId, basicSliceId: sliceId }),
            doSendJobToSlicerFulfilled(),
            doTrackProgressForBasicSlice({
                currentJob,
                basicSliceJobUrl: response.url,
            })
        );
    }

    console.log(`Queued slice job via CloudSlicer...`);

    currentJob.id = sliceId;
    currentJob.url = response.url;
    currentJob.thingStorageURL = action.payload.thingStorageURL;
    currentJob.status = EXPORT_STATUSES.slicing;
    currentJob.notBasicSliceId = sliceId;

    const { device } = currentJob;
    postSliceStatus('slice_started', currentJob);

    if (currentJob.jobType === JOB_TYPE.print) {
        return of(
            doAddOperatingJob({
                localId: currentJob.localId,
                sliceJobId: currentJob.id,
                jobType: currentJob.jobType,
                printerId: device.printer_id,
                ready: !isEmbedded,
                fileName: currentJob.fileName,
                printerName: device.name,
            }),
            doSendJobToSlicerFulfilled(),
            doUpdateSliceJob(currentJob),
            doTrackProgressFromSlicer(action.payload),
            doSendPrinterCommand({
                // Format data for POST request
                // Check https://github.com/makerbot/reflector/#send-a-printer-command
                // Check Kaiten API https://github.com/makerbot/kaiten/blob/develop/docs/kaiten-API.md#cloud-slice-print
                data: {
                    method: 'cloud_slice_print',
                    params: {
                        url: `https://${urls.cloudslicer}${currentJob.url}`,
                        ensure_build_plate_clear: false,
                    },
                },
                device, // Used in sending printer cmd via reflector
                userToken, // Used in sending printer cmd via reflector
            })
        );
    } else if (currentJob.jobType === JOB_TYPE.preview) {
        currentJob.status = EXPORT_STATUSES.basic;
        return of(
            doSendJobToSlicerFulfilled(),
            doUpdateSliceJob(currentJob),
            doTrackProgressFromSlicer(action.payload),
            doSendJobToSlicer({
                ...action.payload,
                doBasicSlice: true,
                newPayload: { currentJob },
            })
        );
    } else if (currentJob.jobType === JOB_TYPE.queue) {
        console.log('restartedJobId:', currentJob.restartedJobId);
        if (showStatusRing()) {
            sendSliceStartedEvent({
                printer_id: device.printer_id,
                printer_type: device.type,
                print_job_origin: PRINT_JOB_ORIGIN.thing,
                print_now: currentJob.printNow,
                thing_url: currentJob.thingStorageURL,
                slices_url: response.url,
                restarted: !!currentJob.restartedJobId,
                jobId: currentJob.restartedJobId,
            });
        }

        return of(
            doAddOperatingJob({
                localId: currentJob.localId,
                sliceJobId: currentJob.id,
                jobType: currentJob.jobType,
                printerId: device.printer_id,
                ready: !isEmbedded,
                fileName: currentJob.fileName,
                printerName: device.name,
            }),
            doSendJobToSlicerFulfilled(),
            doUpdateSliceJob(currentJob),
            doTrackProgressFromSlicer(action.payload)
        );
    }

    return of(doSendJobToSlicerFulfilled(action.payload), doTrackProgressFromSlicer(action.payload));
};

const parseErrorToActions = (error, action) => {
    console.log(`Error:`, error);
    console.log(`fileStorageUrl`, action.payload.currentJob.url);

    return of(
        doSendJobToSlicerFailed({
            failedSliceJob: getFailedSliceJob(action.payload.currentJob),
        })
    );
};

const sendJobToSlicer = (action$, state$) =>
    action$.pipe(
        ofType(SEND_JOB_TO_SLICER_START),
        //debounceTime(EPICS_DEBOUNCE_TIME.SEND_JOB_TO_SLICER), // Seems like it is not working with "distinctUntilChanged"
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const apiUrl = `https://${urls.cloudslicer}/slices`;
            const data = {
                url: action.payload.thingStorageURL,
                profileKey: action.payload.profileKey,
                doBasicSlice: action.payload.doBasicSlice,
            };
            if (!!action.payload.currentJob && action.payload.currentJob.jobType === JOB_TYPE.queue) {
                data.report = true;
            }

            const { selectedArtifact, cloudslicerToken, userToken } = state$.value?.appState?.userState;
            const { isEmbedded } = state$.value?.appState?.printerState;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cloudslicerToken}`,
            };
            if (selectedArtifact) {
                headers['x-mb-artifacts'] = selectedArtifact;
            }

            if (action.payload.thumbnailsStorageURL) {
                data.thumbnails = action.payload.thumbnailsStorageURL;
            }

            console.log(`Starting a new slice job via CloudSlicer`);
            return ajax({
                url: apiUrl,
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action, userToken, isEmbedded)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default sendJobToSlicer;
