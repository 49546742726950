import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { mergeMap, catchError, debounceTime } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { GET_SPOOLS_INFO_START } from '../state/redux/types';
import {
    doGetSpoolsInfoFulfilled,
    doGetSpoolsInfoFailed,
    doTrackProgressFromKaitenMethodExec,
} from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { isTeamsEnabled } from '../helpers/utils/environment';

const parseResponseToActions = (response, action) => {
    const payload = {
        ...action.payload,
        commandId: response.response.commandId,
    };
    return of(doGetSpoolsInfoFulfilled(response), doTrackProgressFromKaitenMethodExec(payload));
};

const parseErrorToActions = error => {
    console.log('Failed fetching spools info -', error);
    return of(doGetSpoolsInfoFailed(error.message));
};

const getSpoolsInfo = action$ =>
    action$.pipe(
        ofType(GET_SPOOLS_INFO_START),
        debounceTime(EPICS_DEBOUNCE_TIME.GET_SPOOLS_INFO),
        mergeMap(action => {
            if (!isTeamsEnabled()) return EMPTY; // do not call teams

            const {
                payload: { printer_id },
            } = action;
            const apiUrl = `https://${urls.teams}/queue/printer/${printer_id}/commands/run`;
            const data = {
                name: 'getMaterialInfo',
                params: {
                    bayIndex: null,
                },
            };

            return ajax({
                url: apiUrl,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                crossDomain: true,
                withCredentials: true,
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default getSpoolsInfo;
