import { fromEvent, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    IMPORT_CUSTOM_PRINT_MODE_FAIL,
    IMPORT_CUSTOM_PRINT_MODE_START,
    IMPORT_CUSTOM_PRINT_MODE_SUCCESS,
} from '../state/redux/types';
import {
    doImportCustomPrintModeSuccess,
    doImportCustomPrintModeFail,
    doSetModelLoading,
    doSetNotification,
} from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResponseToAction = response => {
    if (response.error) {
        return parseErrorToAction(response.error);
    }

    return of(
        doSetNotification(NOTIFICATION_TYPE.IMPORT_CUSTOM_PRINT_MODE),
        doSetModelLoading({ modelLoading: false }),
        doImportCustomPrintModeSuccess({ printMode: response })
    );
};

const parseErrorToAction = error => {
    console.log('Error importing custom print mode', error);
    return of(
        doSetNotification(NOTIFICATION_TYPE.IMPORT_CUSTOM_PRINT_MODE_ERROR),
        doSetModelLoading({ modelLoading: false }),
        doImportCustomPrintModeFail(error)
    );
};

const importCustomPrintMode = (action$, state$) =>
    action$.pipe(
        ofType(IMPORT_CUSTOM_PRINT_MODE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.IMPORT_CUSTOM_PRINT_MODE),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const { file, fileName, userToken } = action.payload;
            const config = { name: fileName, userToken: `Bearer ${userToken}`, file };
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('print-mode-upload', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'print-mode-upload').pipe(
                        mergeMap(response => parseResponseToAction(response)),
                        catchError(error => parseErrorToAction(error)),
                        takeUntil(action$.pipe(ofType(IMPORT_CUSTOM_PRINT_MODE_SUCCESS, IMPORT_CUSTOM_PRINT_MODE_FAIL)))
                    )
                )
            );
        })
    );

export default importCustomPrintMode;
