import { fromEvent, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    FETCH_CUSTOM_PRINT_MODES_FAIL,
    FETCH_CUSTOM_PRINT_MODES_START,
    FETCH_CUSTOM_PRINT_MODES_SUCCESS,
} from '../state/redux/types';
import { doFetchCustomPrintModesSuccess, doFetchCustomPrintModesFail } from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';

const parseResponseToAction = response => {
    if (response.error) {
        return parseErrorToAction(response.error);
    }

    return of(doFetchCustomPrintModesSuccess(response));
};

const parseErrorToAction = error => {
    console.log(`Error getting custom print modes:`, error);
    return of(doFetchCustomPrintModesFail(error));
};

const fetchCustomPrintModes = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_CUSTOM_PRINT_MODES_START),
        debounceTime(EPICS_DEBOUNCE_TIME.FETCH_PRINTER_SCHEMA),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const { userToken } = action.payload;
            const config = { userToken: `Bearer ${userToken}` };
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('print-modes', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'print-modes').pipe(
                        mergeMap(response => parseResponseToAction(response)),
                        catchError(error => parseErrorToAction(error)),
                        takeUntil(action$.pipe(ofType(FETCH_CUSTOM_PRINT_MODES_SUCCESS, FETCH_CUSTOM_PRINT_MODES_FAIL)))
                    )
                )
            );
        })
    );

export default fetchCustomPrintModes;
