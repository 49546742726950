import { ofType } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { distinctUntilChanged, mergeMap, catchError } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import { UPLOAD_JOB_TYPE } from '../consts';

import { blobToFile, compareEpicPayload, getNameFromUrl } from '../helpers/utils';
import {
    doSetModelLoading,
    doSetUploadJobsCount,
    doSetWrongFormat,
    doStartHandlingUploadJobs,
} from '../state/redux/actions';

import { MODELS_FETCH_CAD_FROM_URL } from '../state/redux/models/types';

const parseResToActions = responses => {
    const tasks = responses.map(response => from(response.blob()));
    return forkJoin(tasks).pipe(
        mergeMap((cadBlobs, index) => {
            const uploadJobs = [];
            const files = cadBlobs.map(blob => blobToFile(blob, getNameFromUrl(responses[index].url)));
            uploadJobs.push({
                files: () => files,
                type: UPLOAD_JOB_TYPE.cadJob,
                id: uuidv4(),
            });
            return of(
                doSetUploadJobsCount(uploadJobs.length),
                doSetModelLoading({ modelLoading: true }),
                doStartHandlingUploadJobs({ uploadJobs })
            );
        }),
        catchError(error => parseErrorToActions(error))
    );
};

const parseErrorToActions = error => {
    console.log('Error loading file ', error);
    return of(doSetWrongFormat(true));
};

const fetchCADsFromUrls = action$ =>
    action$.pipe(
        ofType(MODELS_FETCH_CAD_FROM_URL),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const fileUrls = action.payload;
            const tasks = fileUrls.map(url => fromFetch(url));
            return forkJoin(tasks).pipe(
                mergeMap(responses => parseResToActions(responses)),
                catchError(error => parseErrorToActions(error))
            );
        })
    );

export default fetchCADsFromUrls;
