import { ofType } from 'redux-observable';

import { combineLatest, fromEvent, of } from 'rxjs';
import { mergeMap, catchError, switchMap, takeUntil, filter } from 'rxjs/operators';

import {
    FETCH_INITIAL_PRINTER_SCHEMAS_START,
    FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS,
    FETCH_INITIAL_PRINTER_SCHEMAS_FAILED,
} from '../state/redux/types';
import { doFetchInitialPrinterSchemasFailed, doFetchInitialPrinterSchemasFulfilled } from '../state/redux/actions';

import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';

const parseResponseToActions = responses => {
    for (let i = 0; i < responses.length; i++) {
        const response = responses[i];
        if (response.error) {
            return parseErrorToActions(response.error);
        }
    }

    return of(doFetchInitialPrinterSchemasFulfilled(responses));
};

const parseErrorToActions = error => {
    console.warn(error);
    return of(doFetchInitialPrinterSchemasFailed(error));
};

const fetchInitialPrinterSchemas = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_INITIAL_PRINTER_SCHEMAS_START),
        switchMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const printModes = action.payload;
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            const printerType = state$.value?.appState?.printerState?.printer?.type;
            const extruders = state$.value?.appState?.printerState?.settings?.extruders;
            const materials = state$.value?.appState?.printerState?.settings?.materials;
            const config = {
                printerType,
                extruders,
                materials,
            };

            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            const args = printModes.map(printMode => {
                return of(wsClient.emit('schema', { ...config, overrides: { printMode }, timestamp: printMode })).pipe(
                    switchMap(() =>
                        fromEvent(wsClient, 'schema').pipe(
                            filter(response => {
                                return response.timestamp === printMode;
                            })
                        )
                    )
                );
            });

            return combineLatest(args).pipe(
                mergeMap(response => parseResponseToActions(response)),
                catchError(error => parseErrorToActions(error)),
                takeUntil(
                    action$.pipe(ofType(FETCH_INITIAL_PRINTER_SCHEMAS_SUCCESS, FETCH_INITIAL_PRINTER_SCHEMAS_FAILED))
                )
            );
        })
    );

export default fetchInitialPrinterSchemas;
