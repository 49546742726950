import { v4 as uuidv4 } from 'uuid';

export const createNotification = (type, message, persist) => ({
    notification_id: uuidv4(),
    notification_type: type,
    notification_message: message,
    do_not_hide: persist,
});

export const postToParent = (type, value) =>
    (window.opener || window.parent).postMessage({ __print: true, type, value }, '*');

export const sendAddNotificationEvent = notification => postToParent('add_notification', notification);

export const sendDeleteNotificationEvent = notificationId => postToParent('delete_notification', notificationId);

export const sendOpenNotificationSidebarEvent = () => postToParent('open_notification_sidebar', true);

export const sendSliceStartedEvent = value => postToParent('slice_started', value);

export const sendInitNotificationEvent = value => postToParent('print_init', value);

export const sendSliceCompleteNotificationEvent = value => postToParent('slice_complete', value);

export const sendFailSliceNotificationEvent = value => postToParent('fail_slice', value);

export const sendSliceCancelledNotificationEvent = value => postToParent('slice_cancelled', value);

export const sendCloseButtonNotificationEvent = value => postToParent('close_button_pressed', value);

export const sendWorkspaceButtonNotificationEvent = value => postToParent('workspace_button_pressed', value);

export const sendCloseWithFileReviewNotificationEvent = value => postToParent('close_with_file_review', value);
