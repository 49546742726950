import { EXTRUDERS } from '../consts/index';

class ExtruderConfig {
    constructor(extruderId, material, isDefault = false) {
        this.isDefault = isDefault;
        this.extruderId = extruderId;
        this.label = this.getLabelForExtruder(this.extruderId);
        this.material = material;
        //value identify value property in select object
        this.value = this.extruderId;
    }

    static createFromConfigObject(conf) {
        return new ExtruderConfig(conf['extruder_ids'][0], conf['material_ids'][0], conf['default']);
    }

    getLabelForExtruder(extruderId) {
        return EXTRUDERS[extruderId] ? EXTRUDERS[extruderId].label : extruderId;
    }
}

export default ExtruderConfig;
