import { CLOSE_BUILD_PLATE_CHECK_MODAL, SHOW_BUILD_PLATE_CHECK_MODAL, SETTINGS_INPUT_ERROR, SET_SHOW_MAKERBOT_DETECTED_MODAL, SET_DETECTED_MAKERBOT_FILES, SET_SHOW_BLOCKER_ALERT } from './types';

export const handleOnDisplayBuildPlateCheckModal = () => {
    return {
        type: SHOW_BUILD_PLATE_CHECK_MODAL,
    };
};
export const handleOnCloseBuildPlateCheckModal = () => {
    return {
        type: CLOSE_BUILD_PLATE_CHECK_MODAL,
    };
};
export const doShowSettingsInputError = settingsInputError => {
    return {
        type: SETTINGS_INPUT_ERROR,
        payload: { settingsInputError },
    };
};

export const doSetShowOpenMakerbotInNewTabModal = showOpenMakerbotInNewTab => {
    return {
        type: SET_SHOW_MAKERBOT_DETECTED_MODAL,
        payload: showOpenMakerbotInNewTab,
    };
};
export const doSetDetectedMakerbotFiles = detectedMakerbotFiles => {
    return {
        type: SET_DETECTED_MAKERBOT_FILES,
        payload: detectedMakerbotFiles,
    };
};

export const doSetShowBlockedAlert = showBlockedRedirectAlert => {
    return {
        type: SET_SHOW_BLOCKER_ALERT,
        payload: showBlockedRedirectAlert,
    };
};
