import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import urls from '@makerbot/urls';

import { HANDLE_MODEL_PREVIEW_START } from '../state/redux/types';
import {
    doGenerateModelPreviewFulfilled,
    doGenerateModelPreviewFailed,
    doTrackProgressFromPreviewGenerator,
} from '../state/redux/actions';

import { printerTypeSelector, cloudslicerTokenSelector } from '../state/redux/selectors';

import { compareEpicPayload } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME, BOT_TYPE_ENUM } from '../consts';

const parseResponseToActions = ({ response }, action) => {
    const payload = response;
    payload.fileName = action.payload.fileName;
    payload.fileUrl = action.payload.fileUrl;
    payload.openInNewWindow = action.payload.openInNewWindow;
    payload.jobId = action.payload.localId;

    return of(doGenerateModelPreviewFulfilled(payload), doTrackProgressFromPreviewGenerator(payload));
};

const parseErrorToActions = (error, action) => {
    console.log(`Error:`, error.message);
    return of(doGenerateModelPreviewFailed(error.message));
};

const isGcode = printerType => {
    return printerType === BOT_TYPE_ENUM.sketch.id || printerType === BOT_TYPE_ENUM.sketch_large.id;
};

const generatePreview = (action$, state$) =>
    action$.pipe(
        ofType(HANDLE_MODEL_PREVIEW_START),
        debounceTime(EPICS_DEBOUNCE_TIME.GENERATE_PREVIEW),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const cloudslicerToken = cloudslicerTokenSelector(state$.value);
            const printerType = printerTypeSelector(state$.value);
            return ajax({
                url: `https://${urls.reconstructor}/toolpath`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cloudslicerToken}`,
                },
                body: JSON.stringify({
                    url: action.payload.fileUrl,
                    isGcode: isGcode(printerType),
                }),
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default generatePreview;
