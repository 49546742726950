import { Vector3 } from 'three';

const sixGenBotTypes = ['fire_e', 'lava_f', 'magma_10'];
const fifthGenBotTypes = ['mini_8', 'replicator_5', 'replicator_b', 'z18_6'];

const PrinterStatusTranslationEnum = Object.freeze({
    AttachedExtruder: 'Attach Extruders',
    CleaningUp: 'Cleaning Up',
    ClearBuildPlate: 'Clear Build Plate',
    Done: 'Done',
    DownloadingFile: 'Downloading File',
    DryingCycle: 'Drying Cycle',
    ExtrudingFilament: 'Extruding Filament',
    Error: 'Error',
    FinalHeating: 'Final Heating',
    HeatingChamber: 'Heating Chamber',
    Homing: 'Homing',
    Idle: 'Idle',
    InitialHeating: 'Initial Heating',
    InstallBuildPlate: 'Missing build plate',
    LoadingFilament: 'Loading Filament',
    Printing: 'Printing',
    PrepareFile: 'Preparing File',
    PreHeatingExtruder: 'Preheating Extruder',
    Paused: 'Paused',
    ResumePrint: 'Resuming Print',
    SettingUp: 'Setting Up',
    TransferringFile: 'Transferring File',
    UnloadingFilament: 'Unloading Filament',
    UpdatingFirmaware: 'Updating Firmware',
    VerifyingFirmware: 'Verifying Firmware',
    WaitingForFile: 'Waiting For File',
    WaitCleanup: 'Waiting For Cleanup',
});

const PrinterStatusPrettierEnum = Object.freeze({
    awaitingcloudslice: PrinterStatusTranslationEnum.PrepareFile,
    aborted_wait_cleanup: PrinterStatusTranslationEnum.WaitCleanup,
    cleaning_up: PrinterStatusTranslationEnum.CleaningUp,
    clear_build_plate: PrinterStatusTranslationEnum.ClearBuildPlate,
    completed: PrinterStatusTranslationEnum.Done,
    downloadingext: PrinterStatusTranslationEnum.DownloadingFile,
    drying_spool: PrinterStatusTranslationEnum.DryingCycle,
    extrusion: PrinterStatusTranslationEnum.ExtrudingFilament,
    failed: PrinterStatusTranslationEnum.Error,
    final_heating: PrinterStatusTranslationEnum.FinalHeating,
    handling_recoverable_filament_jam: PrinterStatusTranslationEnum.Printing,
    heating_chamber: PrinterStatusTranslationEnum.HeatingChamber,
    homing: PrinterStatusTranslationEnum.Homing,
    initial_heating: PrinterStatusTranslationEnum.InitialHeating,
    install_build_plate: PrinterStatusTranslationEnum.InstallBuildPlate,
    loading_filament: PrinterStatusTranslationEnum.LoadingFilament,
    preheating: PrinterStatusTranslationEnum.PreHeatingExtruder,
    preheating_loading: PrinterStatusTranslationEnum.PreHeatingExtruder,
    preheating_resuming: PrinterStatusTranslationEnum.PreHeatingExtruder,
    preheating_unloading: PrinterStatusTranslationEnum.PreHeatingExtruder,
    printing: PrinterStatusTranslationEnum.Printing,
    pre_print: PrinterStatusTranslationEnum.Printing,
    suspended: PrinterStatusTranslationEnum.Paused,
    transfer: PrinterStatusTranslationEnum.TransferringFile,
    unloading_filament: PrinterStatusTranslationEnum.UnloadingFilament,
    unsuspending: PrinterStatusTranslationEnum.ResumePrint,
    verify_firmware: PrinterStatusTranslationEnum.VerifyingFirmware,
    waiting_for_file: PrinterStatusTranslationEnum.WaitingForFile,
    writing: PrinterStatusTranslationEnum.UpdatingFirmaware,
    x_calibration_intro: PrinterStatusTranslationEnum.SettingUp,
    y_calibration_intro: PrinterStatusTranslationEnum.SettingUp,
    z_calibration_intro: PrinterStatusTranslationEnum.SettingUp,
    setup_complete: PrinterStatusTranslationEnum.SettingUp,
});

const PrinterStatusProcessNameEnum = Object.freeze({
    Undefined: 'UNDEFINED',
    Offline: 'Offline',
    AttachExtruder: 'Attach Extruder',
    Idle: 'Idle',
    Print: 'PrintProcess',
    LoadFilament: 'LoadFilamentProcess',
    UnloadFilament: 'UnloadFilamentProcess',
    DryingCycle: 'DryingCycleProcess',
    SombreroAssistedLeveling: 'SombreroAssistedLevelingProcess',
    FirmwareBurning: 'FirmwareBurningProcess',
    NozzleCalibration: 'NozzleCalibrationProcess',
    ResetToFactory: 'ResetToFactoryProcess',
    ZipLogs: 'ZipLogsProcess',
    NozzleCleaning: 'NozzleCleaningProcess',
    AnnealPrint: 'AnnealPrintProcess',
});

const BuildVolumes = Object.freeze({
    mini_8: {
        size: new Vector3(101, 126, 126),
        bounds: [63, 70, -63, -70],
        expandX: 0,
    },
    replicator_5: {
        size: new Vector3(252, 199, 148),
        bounds: [126, 99.5, -126, -99.5],
        expandX: 0,
    },
    replicator_b: {
        size: new Vector3(295, 195, 165),
        bounds: [157, 107.5, -157, -107.5],
        expandX: 0,
    },
    z18_6: {
        size: new Vector3(300, 305, 457),
        bounds: [152.5, 187.5, -152.5, -187.5],
        expandX: 0,
    },
    fire_e: {
        size: new Vector3(152.4, 190.5, 196.85),
        bounds: [78.5, 100, -78.5, -100],
        expandX: -38.1,
    },
    lava_f: {
        size: new Vector3(152.4, 190.5, 196.85),
        bounds: [78.5, 100, -78.5, -100],
        expandX: -38.1,
    },
    magma_10: {
        size: new Vector3(309.001, 309.001, 320.001),
        bounds: [175, 154, -175, -154],
        expandX: -37,
    },
    sketch: {
        size: new Vector3(150, 150, 150),
        bounds: [75, 75, -75, -75],
        expandX: 0,
    },
    sketch_large: {
        size: new Vector3(220, 200, 250),
        bounds: [110, 100, -110, -100],
        expandX: 0,
    },
});

const unreleasedPrinters = [];

const printersWithDemoFiles = [
    'fire_e',
    'lava_f',
    'mini_8',
    'replicator_5',
    'replicator_b',
    'z18_6',
    'sketch',
    'magma_10',
    'sketch_large',
];

export {
    sixGenBotTypes,
    fifthGenBotTypes,
    PrinterStatusTranslationEnum,
    PrinterStatusPrettierEnum,
    PrinterStatusProcessNameEnum,
    BuildVolumes,
    unreleasedPrinters,
    printersWithDemoFiles,
};
