import { SMART_ARRANGE_STATE, SMART_ORIENT_STATE } from "../../../consts";

export const systemPreferencesSelector = state => state.appState.viewerState.systemPreferences;
export const displayUnitsSelector = state => state.appState.viewerState.systemPreferences.displayUnits;
export const systemUnitsSelector = state => state.appState.viewerState.systemPreferences.systemUnits;
export const isUploadModalOpenSelector = state => state.appState.viewerState.isUploadModalOpen;
export const showSTLFileSizeModalSelector = state => state.appState.viewerState.showSTLFileSizeModal;
export const showMakerbotFileSizeModalSelector = state => state.appState.viewerState.showMakerbotFileSizeModal;
export const showMakerbotFileCorruptedModalSelector = state => state.appState.viewerState.showMakerbotFileCorruptedModal;
export const showMakerbotErrorNonEmptyPlateModalSelector = state => state.appState.viewerState.showMakerbotErrorNonEmptyPlateModal;
export const showMakerbotFileErrorIncompatibleModalSelector = state => state.appState.viewerState.showMakerbotFileErrorIncompatibleModal;
export const uploadedMakerbotFileSelector = state => state.appState.viewerState.uploadedMakerbotFile;
export const uploadedFileFormatSelector = state => state.appState.viewerState.uploadedFileFormat;
export const shortcutsSelector = state => state.appState.viewerState.shortcuts;
export const undoRedoSelector = state => state.appState.viewerState.undoRedo;
export const isPrinterDropdownOpenSelector = state => state.appState.viewerState.isPrinterDropdownOpen;
export const contextMenuSelector = state => state.appState.viewerState.contextMenu;
export const measureToolSelector = state => state.appState.viewerState.measureTool;
export const placeFaceSelector = state => state.appState.viewerState.placeFace;
export const showAppSettingsSelector = state => state.appState.viewerState.showAppSettings;
export const showTopOverlayBarSelector = state => state.appState.viewerState.showTopOverlayBar;
export const activeJobTypeSelector = state => state.appState.viewerState.activeJobType;
export const fileUploadActionSelector = state => state.appState.viewerState.fileUploadAction;
export const focusedInputNameSelector = state => state.appState.viewerState.focusedInputName;
export const buildVolumesSelector = state => state.appState.viewerState.buildVolumes;
export const isUnitsMismatchingSelector = state => state.appState.viewerState.isUnitsMismatching;
export const validUnitsSelector = state => state.appState.viewerState.validUnits;
export const showMissingPartsModalSelector = state => state.appState.viewerState.showMissingPartsModal;
export const filesUploadedFromFolderSelector = state => state.appState.viewerState.filesUploadedFromFolder;
export const timestampScroolSelector = state => state.appState.viewerState.тimestampScroll;
export const showSaveAppSettingsSelector = state => state.appState.viewerState.showSaveAppSettingsModal;
export const smartOrientSelector = state => state.appState.viewerState.smartOrient;
export const smartArrangeProgressSelector = state => state.appState.viewerState.smartArrange.progress;
export const smartArrangeStateSelector = state => state.appState.viewerState.smartArrange.state;
export const isPerspectiveCameraSelector = state => state.appState.viewerState.isPerspectiveCamera;
export const showGraphicsSettingsChangedModalSelector = state =>
    state.appState.viewerState.showGraphicsSettingsChangedModal;
export const activeAppSettingsSaveButtonSelector = state => state.appState.viewerState.activeAppSettingsSaveButton;
export const showClearBuildPlateModalSelector = state => state.appState.viewerState.showClearBuildPlateModal;
export const showJobErrorModalSelector = state => state.appState.viewerState.showJobErrorModal;
export const featureFlagsSelector = state => state.appState.viewerState.featureFlags;

export const isSmartJobInProgressSelector = state => {
    const smartOrientState = state.appState.viewerState.smartOrient.state;
    const smartArrangeState = state.appState.viewerState.smartArrange.state;
    return smartOrientState !== SMART_ORIENT_STATE.idle || smartArrangeState !== SMART_ARRANGE_STATE.idle;
}
