import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, takeUntil, debounceTime, switchMap, filter } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import {
    doStopUpdateProgressCheckFromSlicerBasicSlice,
    doTrackProgressForBasicSliceFailed,
    doTrackProgressForBasicSliceFulfilled,
    doFetchBasicSlice,
    doUpdateSliceJob,
} from '../state/redux/actions';
import { EPICS_DEBOUNCE_TIME, EXPORT_STATUSES } from '../consts';
import {
    TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_START,
    STOP_UPDATE_POLLING_FROM_SLICER,
    STOP_UPDATE_POLLING_FROM_SLICER_BASIC_SLICE,
    CANCEL_SLICE_JOB,
} from '../state/redux/types';
import { getCloudslicerWsClient } from '../helpers/webSocketUtils';

const parseResToActions = (response, action) => {
    const { failed, chunksCompleted } = response;
    const { currentJob } = action.payload;
    if (failed) {
        console.log(`Basic Slice Job ${response.id} ${EXPORT_STATUSES.failed}...`);
        return of(doTrackProgressForBasicSliceFailed(), doStopUpdateProgressCheckFromSlicerBasicSlice());
    }

    if (chunksCompleted) {
        currentJob.chunksUrl = response.chunksUrl;
        return of(
            doUpdateSliceJob(currentJob),
            doFetchBasicSlice(response),
            doTrackProgressForBasicSliceFulfilled(),
            doStopUpdateProgressCheckFromSlicerBasicSlice()
        );
    }

    console.log(`Polling CloudSlicer for basic slice...`);
    return of(doTrackProgressForBasicSliceFulfilled());
};

const parseErrorToActions = (error, action) => {
    console.log(`Basic Slice Job failed...`);
    console.log(`Error:`, error);
    return of(doTrackProgressForBasicSliceFailed(), doStopUpdateProgressCheckFromSlicerBasicSlice());
};

const trackUpdateProgressForBasicSlice = action$ =>
    action$.pipe(
        ofType(TRACK_PROGRESS_FROM_SLICER_BASIC_SLICE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.TRACK_UPDATE_PROGRESS_FROM_SLICER),
        mergeMap(action => {
            const wsClient = getCloudslicerWsClient();
            const id = action.payload.basicSliceJobUrl.split('/').slice(-1)[0];

            return of(wsClient.emit('subscribe', { jobId: id })).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'jobData').pipe(
                        filter(response => {
                            return response.id === id;
                        }),
                        mergeMap(response => parseResToActions(response, action)),
                        catchError(error => parseErrorToActions(error, action)),
                        takeUntil(
                            action$.pipe(
                                ofType(STOP_UPDATE_POLLING_FROM_SLICER, STOP_UPDATE_POLLING_FROM_SLICER_BASIC_SLICE)
                            )
                        )
                    )
                )
            );
        }),
        takeUntil(action$.pipe(ofType(CANCEL_SLICE_JOB)))
    );

export default trackUpdateProgressForBasicSlice;
