import { LOCAL_STORAGE_KEYS } from '../../consts';
import { crossFrameStorage } from '../CrossFrameStorage';

const DEFAULT_TARGET_FILE_SIZE_ON_SIMPLIFY = 120; // 120mb

export const FEATURE_FLAGS = {
    PER_MODEL_SETTINGS: {
        name: 'per_model_settings_feature_flag',
        event: 'per_model_settings_feature_flag_changed',
        default: process.env.REACT_APP_PER_MODEL_SETTINGS_FEATURE_FLAG === 'true',
        type: 'boolean',
    },
    EXPIRED_LICENSE: {
        name: 'expired_license_flag',
        event: 'expired_license_flag_changed',
        default: process.env.POCKET_PRINT_EXPIRED_LICENSE_FEATURE_FLAG === 'true',
        type: 'boolean',
    },
    FOLDERING: {
        name: 'enable_foldering',
        event: 'enable_foldering_changed',
        default: process.env.REACT_APP_ENABLE_FOLDERING === 'true',
        type: 'boolean',
        uiData: {
            title: 'Foldering',
            description: 'Try a better organization format for your assembly tree.',
            testId: 'enable-foldering-checkbox',
        },
        isSaveToLocalStore: true,
        pendoValue: 'Foldering',
    },
    OPENPATH_SETTINGS: {
        name: 'openpath_settings',
        event: 'openpath_settings_changed',
        default: process.env.OPENPATH_SETTINGS_FEATURE_FLAG === 'true',
        type: 'boolean',
    },
    SMART_ARRANGE: {
        name: 'smart_arrange_feature_flag',
        event: 'smart_arrange_feature_flag_changed',
        default: process.env.REACT_APP_SMART_ARRANGE_FEATURE_FLAG === 'true',
        type: 'boolean',
        uiData: {
            title: 'Smart Arrange',
            description: 'Try new algorithm for the smart arrange.',
        },
        isSaveToLocalStore: true,
    },
    SIMPLIFY_ON_UPLOAD: {
        name: 'simplify_on_upload_feature_flag',
        event: 'simplify_on_upload_feature_flag_changed',
        default: process.env.REACT_APP_SIMPLIFY_ON_UPLOAD_FEATURE_FLAG === 'true',
        type: 'boolean',
        isSaveToLocalStore: true,
    },
    TARGET_FIZE_SIZE_ON_SIMPLIFY: {
        name: 'target_file_size_on_simplify',
        event: 'target_file_size_on_simplify_changed',
        default: process.env.REACT_APP_TARGET_FIZE_SIZE_ON_SIMPLIFY ?? DEFAULT_TARGET_FILE_SIZE_ON_SIMPLIFY,
        type: 'number',
        isSaveToLocalStore: true,
    },
};

export const initFeatureFlags = featureFlags => {
    for (const flag of Object.values(featureFlags)) {
        Object.defineProperty(window, flag.name, {
            get() {
                return this[`_${flag.name}`];
            },
            set(newValue) {
                if (typeof newValue !== flag.type) {
                    console.error(`Invalid value type for ${flag.name}, ${flag.type} type is required.`);
                    return;
                }
                this.dispatchEvent(new CustomEvent(flag.event, { detail: newValue }));
            },
        });

        window[`_${flag.name}`] = flag.default;
    }
};

const featureFlagsFromStorage = {};
crossFrameStorage.fetchItem(LOCAL_STORAGE_KEYS.featureFlags).then(flags => {
    if (flags) {
        for (const k in Object.keys(flags)) {
            featureFlagsFromStorage[k] = flags[k];
        }
    }
});

export const getFeatureFlagsFromStorage = () => featureFlagsFromStorage;

export const setFeatureFlagsToStorage = storeFeatureFlags => {
    crossFrameStorage.setItem(LOCAL_STORAGE_KEYS.featureFlags, storeFeatureFlags);
};

export const getInitialFeatureFlagsForStore = () => {
    const storeFeatureFlags = Object.fromEntries(
        Object.values(FEATURE_FLAGS).map(({ name, default: value }) => [name, value])
    );

    const storageFeatureFlags = getFeatureFlagsFromStorage();

    return { ...storeFeatureFlags, ...storageFeatureFlags };
};

export const updateFeatureFlags = featureFlags => {
    const newFeatureFlagsArr = Object.entries(featureFlags);
    newFeatureFlagsArr.forEach(([name, value]) => {
        window[`_${name}`] = value;
    });

    const allFeatureFlagsArr = Object.values(FEATURE_FLAGS);
    const storageFeatureFlags = getFeatureFlagsFromStorage();

    Object.entries(featureFlags)
        .filter(([name]) => allFeatureFlagsArr.find(feature => feature.name === name)?.isSaveToLocalStore)
        .forEach(([name, value]) => {
            storageFeatureFlags[name] = value;
        });

    setFeatureFlagsToStorage(storageFeatureFlags);
};
