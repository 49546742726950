import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, distinctUntilChanged } from 'rxjs/operators';
import urls from '@makerbot/urls';

import { FETCH_DOWNLOAD_URLS_START } from '../state/redux/types';
import {
    doUploadFileToStorage,
    doFetchPresignedDownloadURLsFulfilled,
    doFetchPresignedDownloadURLsFailed,
} from '../state/redux/actions';
import { compareEpicPayload } from '../helpers/utils';

const parseResponseToActions = ({ response }, action) => {
    if (response.presignedURL.includes('.thing')) {
        // In case of a .thing file, we just need to store `thingLoadURL` for the rest of the app to acess
        const payload = {
            ...action.payload,
            thingLoadURL: response.presignedURL,
        };
        return of(doFetchPresignedDownloadURLsFulfilled(payload));
    }

    const payload = Object.assign({}, action.payload, {
        ...action.payload,
        urls: { ...action.payload.urls, presignedGetURL: response.presignedURL },
    });
    return of(doFetchPresignedDownloadURLsFulfilled(payload), doUploadFileToStorage(payload));
};

const parseErrorToActions = (error, action) => {
    console.log(`Failed to get pre-signed url from files api...`);
    console.log(`Error:`, error);

    return of(doFetchPresignedDownloadURLsFailed(action.payload));
};

const fetchPresignedDownloadURLs = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_DOWNLOAD_URLS_START),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            console.log(`Fetching storage download URLs`);
            const { cloudslicerToken } = state$.value?.appState?.userState;
            return ajax({
                url: `https://${urls.cloudslicer}/api/v3/files?gcsURL=${encodeURIComponent(
                    action.payload.urls.gcsUrl
                )}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${cloudslicerToken}`,
                },
            }).pipe(
                mergeMap(response => parseResponseToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default fetchPresignedDownloadURLs;
