import { fromEvent, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_FAIL,
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_START,
    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_SUCCESS,
} from '../state/redux/types';
import {
    doSaveChangesOnCustomPrintModeSuccess,
    doSaveChangesOnCustomPrintModeFail,
    doUpdateSettingsInCustomPrintMode,
    doClearPrinterOverrides,
    doSetNotification,
} from '../state/redux/actions';

import { EPICS_DEBOUNCE_TIME } from '../consts';
import { getSchemaSettingsWsClient } from '../helpers/webSocketUtils';
import { NOTIFICATION_TYPE } from '../consts/notifications';

const parseResponseToAction = response => {
    if (response.error) {
        return parseErrorToAction(response.error);
    }

    return of(
        doSetNotification(NOTIFICATION_TYPE.SAVE_CHANGES_CUSTOM_PRINT_MODE),
        doUpdateSettingsInCustomPrintMode({ id: response.id, settings: response.settings }),
        doClearPrinterOverrides(true),
        doSaveChangesOnCustomPrintModeSuccess(response)
    );
};

const parseErrorToAction = error => {
    console.log('Error saving changes on custom print mode', error);
    return of(
        doSetNotification(NOTIFICATION_TYPE.SAVE_CHANGES_CUSTOM_PRINT_MODE_ERROR),
        doSaveChangesOnCustomPrintModeFail(error)
    );
};

const saveChangesCustomPrintMode = (action$, state$) =>
    action$.pipe(
        ofType(SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_START),
        debounceTime(EPICS_DEBOUNCE_TIME.SAVE_CHANGES_ON_CUSTOM_PRINT_MODE),
        mergeMap(action => {
            const wsClient = getSchemaSettingsWsClient();
            const { id, name, userToken, settings } = action.payload;
            const blob = new Blob([JSON.stringify(settings)], { type: 'application/octet-stream' });
            const config = { name: `${name}.printmode`, userToken: `Bearer ${userToken}`, file: blob, id };
            const selectedArtifact = state$.value?.appState?.userState?.selectedArtifact;
            if (selectedArtifact) {
                config['x-mb-artifacts'] = selectedArtifact;
            }

            return of(wsClient.emit('print-mode-update', config)).pipe(
                switchMap(() =>
                    fromEvent(wsClient, 'print-mode-update').pipe(
                        mergeMap(response => parseResponseToAction(response)),
                        catchError(error => parseErrorToAction(error)),
                        takeUntil(
                            action$.pipe(
                                ofType(
                                    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_SUCCESS,
                                    SAVE_CHANGES_ON_CUSTOM_PRINT_MODE_FAIL
                                )
                            )
                        )
                    )
                )
            );
        })
    );

export default saveChangesCustomPrintMode;
