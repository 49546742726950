import { EXPORT_STATUSES } from "../../../consts";

export const printPreviewEnabledSelector = state => state.appState.previewState.printPreviewEnabled;
export const printPreviewModeSelector = state => state.appState.previewState.printPreviewMode;
export const jobFileNameSelector = state => state.appState.previewState.jobFileName;
export const sliceJobsSelector = state => state.appState.previewState.sliceJobs;
export const operatingJobsSelector = state => state.appState.previewState.operatingJobs;
export const currentSliceJobIdSelector = state => state.appState.previewState.currentSliceJobId;
export const currentFailedSliceJobIdSelector = state => state.appState.previewState.currentFailedSliceJobId;
export const jobToRestartSelector = state => state.appState.previewState.jobToRestart;
export const modelPreviewSelector = state => state.appState.previewState.modelsPreview[0] || {};
export const modelsPreviewSelector = state => state.appState.previewState.modelsPreview;
export const toolpathStatusSelector = state => state.appState.previewState.toolpathStatus;

export const comparisonModelPreviewFailedSelector = state => state.appState.previewState?.modelsPreview[1]?.failed;

export const modelPreviewCompletedSelector = state => state.appState.previewState.modelsPreview[0]?.completed;
export const modelPreviewIdSelector = state => state.appState.previewState.modelsPreview[0]?.previewId;

export const makerbotAvailableSelector = state => state.appState.previewState?.makerbotAvailable;
export const makerbotUrlSelector = state => state.appState.previewState?.makerbotUrl;
export const thingLoadUrlSelector = state => state.appState.previewState?.thingLoadURL;
export const thingStorageUrlSelector = state => state.appState.previewState?.thingStorageURL;
export const thingExportingSelector = state => state.appState.previewState.thingExporting;
export const thingAvailableSelector = state => state.appState.previewState?.thingAvailable;

export const currentMoveSelector = state => state.appState.previewState?.modelsPreview[0]?.currentMove;
export const currentLayerSelector = state => state.appState.previewState?.modelsPreview[0]?.currentLayer;
export const pathTypeSelector = state => state.appState.previewState?.modelsPreview[0]?.currentLayerInfo?.type;
export const currentToolpathSelector = state => state.appState.previewState?.modelsPreview[0]?.currentToolpath;

export const materialsTypeSelector = state => state.appState.previewState?.modelsPreview[0]?.materialsType;
export const extrudersTypeSelector = state => state.appState.previewState?.modelsPreview[0]?.extrudersType;
export const botTypeSelector = state => state.appState.previewState?.modelsPreview[0]?.botType;

export const comparisonModeEnabledSelector = state => state.appState.previewState.comparisonModeEnabled;
export const comparisonModelsLoadedSelector = state => state.appState.previewState.comparisonModelsLoaded;
export const comparisonModelsSelector = state => state.appState.previewState.comparisonModels;
export const comparisonModelPreviewSelector = state => state.appState.previewState?.modelsPreview[1] || {};
export const comparisonModelPreviewFileNameSelector = state => state.appState.previewState?.modelsPreview[1]?.fileName;
export const comparisonModelPreviewCompletedSelector = state =>
    state.appState.previewState?.modelsPreview[1]?.completed;
export const thumbnailsStorageURLSelector = state => state.appState.previewState.thumbnailsStorageURL;
export const heldSlicePayloadSelector = state => state.appState.previewState.heldSlicePayload;

export const isThumbnailsLinkAvailableSelector = state => {
    if (state.appState.previewState.comparisonModeEnabled) return false;
    return (
        state.appState.previewState.thumbnailsStorageURL !== null &&
        state.appState.previewState.thumbnailsStorageURL !== undefined
    );
};
export const isComparisonModeReadySelector = state => {
    const modelPreivew = state.appState.previewState?.modelsPreview[0];
    const comparisonModelPreview = state.appState.previewState?.modelsPreview[1];
    const previewModeEnabled = state.appState.previewState.printPreviewEnabled;
    const comparisonModeEnabled = state.appState.previewState.comparisonModeEnabled;

    return (
        previewModeEnabled &&
        comparisonModeEnabled &&
        modelPreivew &&
        modelPreivew.completed &&
        comparisonModelPreview &&
        comparisonModelPreview.completed
    );
};

export const isSliceJobInProgressSelector = state => {
    return state.appState.previewState.sliceJobs.some(job => job.status !== EXPORT_STATUSES.completed && job.status !== EXPORT_STATUSES.failed)
}