export const modelsSelector = state => state.appState.modelsState.models;
export const activeModelsSelector = state => state.appState.modelsState.activeModels;
export const copiedModelsSelector = state => state.appState.modelsState.copiedModels;

export const modelSettingsSelector = state => state.appState.modelsState.settings;
export const modelLoadingSelector = state => state.appState.modelsState.modelLoading;
export const modelLoadingPercentSelector = state => state.appState.modelsState.modelLoadingPercent;
export const uploadedModelNameSelector = state => state.appState.modelsState.uploadedModelName;
export const uniformScalingSelector = state => state.appState.modelsState.settings.uniformScaling;
export const showUploadModelErrorSelector = state => state.appState.modelsState.showUploadModelError;
export const isWrongFormatSelector = state => state.appState.modelsState.isWrongFormat;
export const unableToConvertFileTypeSelector = state => state.appState.modelsState.unableToConvertFileType;
export const wrongFilesInfoSelector = state => state.appState.modelsState.wrongFilesInfo;
export const isMakerbotDownloadImpossibleSelector = state => state.appState.modelsState.isMakerbotDownloadImpossible;
export const isWrongExportFileSizeSelector = state => state.appState.modelsState.isWrongExportFileSize;
export const isModelHoveredFromTree = state => state.appState.modelsState.isModelHoveredFromTree;
export const isDraggingSelector = state => state.appState.modelsState.isDragging;
export const isDragRotationSelector = state => state.appState.modelsState.isDragRotation;

export const shouldResetRotationSelector = state => state.appState.modelsState.shouldResetModelRotation;
export const shouldResetScaleSelector = state => state.appState.modelsState.shouldResetModelScale;

export const filesCountSelector = state => state.appState.modelsState.filesCount;

export const convertedModelLinksSelector = state => state.appState.modelsState.convertedModelLinks;
export const metaInfoSelector = state => state.appState.modelsState.metaInfo;
export const convertedModelBlobsSelector = state => state.appState.modelsState.convertedModelBlobs;
export const modelConvertingSelector = state => state.appState.modelsState.modelConverting;
export const convertedModelNameSelector = state => state.appState.modelsState.convertedModelName;
export const isConversionFailedSelector = state => state.appState.modelsState.isConversionFailed;
export const convertIdSelector = state => state.appState.modelsState.convertJobId;
export const isConversionCancelPossibleSelector = state => {
    return state.appState.modelsState.convertJobId !== null || state.appState.modelsState.isConversionFailed;
};

export const uploadJobSelector = state => state.appState.modelsState.uploadJob;
export const loaderDescriptorsSelector = state => state.appState.modelsState.loaderDescriptors;
export const convertJobsCountSelector = state => state.appState.modelsState.convertJobsCount;
export const uploadJobsCountSelector = state => state.appState.modelsState.uploadJobsCount;
export const completedUploadJobsCountSelector = state => state.appState.modelsState.completedUploadJobsCount;
export const jobNameSelector = state => state.appState.modelsState.jobName;
export const isJobNameSetByUserSelector = state => state.appState.modelsState.isJobNameSetByUser;
export const isFileTooOldSelector = state => state.appState.modelsState.isFileTooOld;
export const isFileTooRecentSelector = state => state.appState.modelsState.isFileTooRecent;
export const hoveredModelsIdsSelector = state => state.appState.modelsState.hoveredModelsIds;
export const overlapModelsIdsSelector = state => state.appState.modelsState.overlapModelsIds;
export const isSmartArrangeHoveredSelector = state => state.appState.modelsState.isSmartArrangeHovered;

export const isModelImportFailedSelector = state => state.appState.modelsState.isModelImportFailed;
export const isModelDownloadingSelector = state => state.appState.modelsState.isModelDownloading;
export const isModelDownloadFailedSelector = state => state.appState.modelsState.isModelDownloadFailed;
export const isModelSimplifyingSelector = state => state.appState.modelsState.isModelSimplifying;
export const isModelSimplifyingFailedSelector = state => state.appState.modelsState.isModelSimplifyingFailed;

export const uploadedBinFilesSelector = state => state.appState.modelsState.uploadedBinFiles;
export const largeFilesSelector = state => state.appState.modelsState.largeFiles;
