import React from 'react';
import PropTypes from 'prop-types';

import s from './AppLoader.module.scss';

const AppLoader = ({ isLoading, error }) => {
    let content = '';

    if (isLoading) {
        content = (
            <div className={s.spinnerWrap}>
                <div className={s.spinnerBorder} />
            </div>
        );
    } else if (error) {
        content = <div className={s.spinnerWrap}>Sorry, there was a problem loading the page.</div>;
    }

    return content;
};

AppLoader.propTypes = {
    error: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default AppLoader;
