import { fromEvent, merge, of, timer } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, debounceTime, filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
    doDownloadFileFromSignedUrl,
    doGenerateModelPreview,
    doShowTopOverlayBar,
    doStopUpdateProgressCheckFromSlicer,
    doStopUpdateProgressCheckFromSlicerBasicSlice,
    doTrackProgressForBasicSliceFailed,
    doTrackProgressFromSlicer,
    doTrackProgressFromSlicerFailed,
    doTrackProgressFromSlicerFulfilled,
    doUpdateOperatingJob,
    doUpdateSliceJob,
    doUpdateSliceProgressJob,
} from '../state/redux/actions';
import { getCloudslicerWsClient } from '../helpers/webSocketUtils';
import { getTimestamp } from '../helpers/utils/common';
import { EPICS_DEBOUNCE_TIME, EXPORT_STATUSES, JOB_TYPE, PRINT_JOB_ORIGIN, TIMING } from '../consts';
import { getFailedSliceJob } from '../helpers/utils';
import { STOP_UPDATE_POLLING_FROM_SLICER, TRACK_PROGRESS_FROM_SLICER_START } from '../state/redux/preview/types';
import { shouldSendSliceComplete, isTeamsEnabled } from '../helpers/utils/environment';
import { postToParent } from '../helpers/utils/notification-event';

/**
 * Sends a message to the parent window when the slice job changes status.
 * This is only applicable to jobs in DF.
 */
export const postSliceStatus = (
    type,
    { device, fileUrl, printNow, progress, status, thingStorageURL, id, restartedJobId, url, jobType }
) =>
    shouldSendSliceComplete() &&
    postToParent(type, {
        progress,
        status,
        printer_id: device.printer_id === device.type ? null : device.printer_id,
        printer_type: device?.status?.bot_type || device.type,
        makerbot_url: fileUrl,
        print_now: printNow,
        print_job_origin: PRINT_JOB_ORIGIN.makerbot,
        thing_url: thingStorageURL,
        slices_url: url,
        restarted_job_id: restartedJobId,
        job_id: id,
        job_type: jobType,
    });

const parseResToActions = (response, action, state) => {
    if (Number.isInteger(response)) {
        return of(
            doTrackProgressFromSlicer(action.payload),
            doTrackProgressForBasicSliceFailed(),
            doStopUpdateProgressCheckFromSlicerBasicSlice(),
            doStopUpdateProgressCheckFromSlicer()
        );
    }

    const { failed, completed, storageUrl, progress, result, id: sliceId } = response;
    const currentJob = action.payload.currentJob;
    const isJobAlreadyFailed = state.value?.appState?.previewState?.sliceJobs?.some(
        job => job.id === currentJob.id && job.status === EXPORT_STATUSES.failed
    );

    if (isJobAlreadyFailed) {
        return of(doStopUpdateProgressCheckFromSlicerBasicSlice(), doStopUpdateProgressCheckFromSlicer());
    }

    if (failed) {
        console.log(`Slice Job ${sliceId} ${EXPORT_STATUSES.failed}...`);
        const payload = { failedSliceJob: getFailedSliceJob(currentJob, null) };
        return of(
            doTrackProgressFromSlicerFailed(payload),
            doStopUpdateProgressCheckFromSlicerBasicSlice(),
            doStopUpdateProgressCheckFromSlicer()
        );
    }

    if (!completed) {
        console.log(`Polling CloudSlicer for slice progress...`, `${progress}%`);
        currentJob.progress = progress;
        postSliceStatus('slice_in_progress', currentJob);
        return of(doUpdateSliceProgressJob(currentJob));
    }

    // Update the 'progress' of the slice from response.data
    const unformattedDate = new Date();
    const timestamp = getTimestamp(unformattedDate);
    const { id, jobType } = currentJob;
    const fileUrl = result;

    currentJob.progress = 100;
    currentJob.fileUrl = fileUrl;
    currentJob.storageUrl = storageUrl;
    currentJob.timestamp = timestamp;
    currentJob.status = EXPORT_STATUSES.completed;
    currentJob.unformattedDate = unformattedDate;
    currentJob.isSliced = true;

    console.log(`Slice Job ${sliceId} ${currentJob.status} for ${jobType}...`);

    const isQueue = [JOB_TYPE.queue, JOB_TYPE.immediatePrint].includes(jobType);
    const isExport = ![JOB_TYPE.print, JOB_TYPE.queue, JOB_TYPE.immediatePrint, JOB_TYPE.preview].includes(jobType);
    const isSliced = ![JOB_TYPE.print, JOB_TYPE.queue, JOB_TYPE.immediatePrint].includes(jobType);
    const isPreview = jobType === JOB_TYPE.preview;

    postSliceStatus('slice_complete', currentJob);
    return of(
        isQueue && !isTeamsEnabled() && doUpdateOperatingJob(id, true),
        doUpdateSliceJob(currentJob, true),
        isQueue && doShowTopOverlayBar(false),
        isPreview && doGenerateModelPreview(currentJob),
        isExport && doDownloadFileFromSignedUrl({ fileUrl }),
        doTrackProgressFromSlicerFulfilled(currentJob),
        isSliced && doStopUpdateProgressCheckFromSlicerBasicSlice(),
        doStopUpdateProgressCheckFromSlicer()
    ).pipe(filter(Boolean));
};

const parseErrorToActions = (error, action) => {
    console.log(`Slice Job failed...`);
    console.log(`Error:`, error);
    const failedSliceJob = getFailedSliceJob(action.payload.currentJob, error);
    postSliceStatus('fail_slice', failedSliceJob);
    return of(
        doTrackProgressFromSlicerFailed({ failedSliceJob }),
        doStopUpdateProgressCheckFromSlicerBasicSlice(),
        doStopUpdateProgressCheckFromSlicer()
    );
};

const trackUpdateProgressFromSlicer = (action$, state$) =>
    action$.pipe(
        ofType(TRACK_PROGRESS_FROM_SLICER_START),
        debounceTime(EPICS_DEBOUNCE_TIME.TRACK_UPDATE_PROGRESS_FROM_SLICER),
        mergeMap(action => {
            const { currentJob } = action.payload;
            const wsClient = getCloudslicerWsClient();
            const interval = timer(TIMING.SLICING_TIMEOUT);
            const jobDataEvent = fromEvent(wsClient, 'jobData');
            const reconnectEvent = fromEvent(wsClient.io, 'reconnect');
            const allEvents = merge(
                jobDataEvent,
                reconnectEvent,
                interval.pipe(
                    tap(_ => {
                        throw new Error('Slice was processing for too long, and has been canceled.');
                    })
                )
            );
            const local = process.env.REACT_APP_USE_STAGING_SLICE_ENV || false;

            return of(wsClient.emit('subscribe', { jobId: currentJob.id, local })).pipe(
                switchMap(() =>
                    allEvents.pipe(
                        filter(response => response.id === currentJob.id || Number.isInteger(response)),
                        mergeMap(response => parseResToActions(response, action, state$)),
                        catchError(error => parseErrorToActions(error, action)),
                        takeUntil(action$.pipe(ofType(STOP_UPDATE_POLLING_FROM_SLICER)))
                    )
                )
            );
        })
    );

export default trackUpdateProgressFromSlicer;
