const COMPRESSED_FIELDS = {
    FUNCTION: {
        set_toolhead_temperature: 'stt',
        toggle_fan: 'tf',
        fan_duty: 'fd',
        wait_for_temperature: 'wft',
        change_toolhead: 'ct',
        delay: 'd',
        move: 'm',
        comment: 'c',
    },
    METADATA: {
        relative: 'r',
    },
    PARAMETERS: {
        index: 'i',
        temperature: 't',
        value: 'v',
        x: 'x',
        y: 'y',
        z: 'z',
        seconds: 's',
        a: 'a',
        b: 'b',
        feedrate: 'f',
        comment: 'c',
    }
}

const DECOMPRESSED_FIELDS = {
    FUNCTION: {
        stt: 'set_toolhead_temperature',
        tf: 'toggle_fan',
        fd: 'fan_duty',
        wft: 'wait_for_temperature',
        ct: 'change_toolhead',
        d: 'delay',
        m: 'move',
        c: 'comment',
    },
    METADATA: {
        r: 'relative',
    },
    PARAMETERS: {
        i: 'index',
        t: 'temperature',
        v: 'value',
        x: 'x',
        y: 'y',
        z: 'z',
        s: 'seconds',
        a: 'a',
        b: 'b',
        f: 'feedrate',
        c: 'comment',
    }
}

class ToolpathCompressor {
    compress(command) {
        const compressedCommand = {};

        if (command.function) {
            compressedCommand.f = this.convertFunction(command.function, COMPRESSED_FIELDS.FUNCTION);
        }
    
        if (command.metadata) {
            compressedCommand.m = this.convertMetadata(command.metadata, COMPRESSED_FIELDS.METADATA.relative);
        }
    
        if (command.parameters) {
            compressedCommand.p = this.convertParameters(command.parameters, COMPRESSED_FIELDS.PARAMETERS);
        }
    
        if (command.tags) {
            compressedCommand.t = command.tags;
        }
    
        return compressedCommand;
    }

    decompress(command) {
        const decompressedCommand = {};

        if (command.f) {
            decompressedCommand.function = this.convertFunction(command.f, DECOMPRESSED_FIELDS.FUNCTION);
        }
    
        if (command.m) {
            decompressedCommand.metadata = this.convertMetadata(command.m, DECOMPRESSED_FIELDS.METADATA.r);
        }
    
        if (command.p) {
            decompressedCommand.parameters = this.convertParameters(command.p, DECOMPRESSED_FIELDS.PARAMETERS);
        }
    
        if (command.t) {
            decompressedCommand.tags = command.t;
        }
    
        return decompressedCommand;
    }

    convertFunction(commandFunction, functionTypes) {
        const compressedFunction = functionTypes[commandFunction];
        if (!compressedFunction) {
            throw new Error(`Unknown function type ${commandFunction}`);
        }
        return compressedFunction;
    }

    convertMetadata(metadata, metadataField) {
        const keys = Object.keys(metadata);
        if (!keys.length) {
            return {};
        }
        
        if (keys.length > 1) {
            throw new Error('Unknown metadata fields', keys);
        }

        return {
            [metadataField]: metadata.relative ?? metadata.r,
        }
    }

    convertParameters(parameters, parametersFields) {
        const compressed = {};
        for (const key of Object.keys(parameters)) {
            if (!parametersFields[key]) {
                throw new Error(`Unknown parameter field ${key}`);
            }
            compressed[parametersFields[key]] = parameters[key];
        }
        return compressed;
    }
}

export default ToolpathCompressor;