import forEach from 'lodash/forEach';
import moment from 'moment';

import { JOB_TYPE } from '../consts';
import { filterOutPurgeTower, getAllSubmodelsRecursive } from './utils/viewer';
import { LOCAL_STORAGE_KEYS } from '../consts';
import { crossFrameStorage } from './CrossFrameStorage';

let cachedSliceJobs;
crossFrameStorage.fetchItem(LOCAL_STORAGE_KEYS.sliceJobs).then(sliceJobs => (cachedSliceJobs = sliceJobs));
export const getCachedSliceJobs = () => {
    // filter array of sliceJobs within 30 days or an empty array
    return cachedSliceJobs ? filterJobsSlicedWithin30Days(cachedSliceJobs) : [];
};

export const setSliceJobsToLocalStorage = sliceJobs => {
    crossFrameStorage.setItem(LOCAL_STORAGE_KEYS.sliceJobs, sliceJobs);
};

export const filterJobsSlicedWithin30Days = sliceJobs => {
    const now = new Date();
    const filteredSliceJobs = [];

    const isSlicedWithin30Days = (currDate, slicedDate) => {
        if (!slicedDate) {
            return false;
        }
        return (currDate.getTime() - slicedDate.getTime()) / (1000 * 60 * 60 * 24) <= 30;
    };

    sliceJobs.forEach(sliceJob => {
        const slicedDate =
            typeof sliceJob.unformattedDate === 'string'
                ? new Date(sliceJob.unformattedDate)
                : sliceJob.unformattedDate;

        if (isSlicedWithin30Days(now, slicedDate)) {
            filteredSliceJobs.push(sliceJob);
        }
    });

    return filteredSliceJobs;
};

/**
 * If the user has single_file=true, let's check if they've already sliced a job in the past minute.
 * If they have, they won't be able to slice again.
 */
export const checkIfUserCanSlice = () => {
    const slicedJobs = getCachedSliceJobs();

    const currentTime = moment();
    let canUserSlice = true;
    const jobTypeToSkip = JOB_TYPE.preview;

    forEach(slicedJobs, sliceJob => {
        //If for some reason the job type isn't upper case already.
        const jobType = sliceJob.jobType.toUpperCase();
        if (jobType === jobTypeToSkip) return;

        const timeDiffBetweenSliceAndNow = currentTime.diff(moment(sliceJob.unformattedDate), 'minutes');
        if (timeDiffBetweenSliceAndNow < 1) {
            canUserSlice = false;
            return false;
        }
    });

    return canUserSlice;
};

export const getPrintMode = (overrides, customPrintModes = null) => {
    if (customPrintModes) {
        const customPrintMode = customPrintModes.find(mode => mode.id === overrides.printMode);
        return customPrintMode.printMode;
    }

    return overrides.printMode;
};

export const getOverrides = (overrides, validCustomPrintModeOverrides, customPrintModes = null) => {
    return { ...validCustomPrintModeOverrides, ...overrides, printMode: getPrintMode(overrides, customPrintModes) };
};

export const getGaggles = (models, perModelOverrides) => {
    const arr = {};
    filterOutPurgeTower(models).forEach(model => {
        const subModels = getAllSubmodelsRecursive(model).filter(model => model.assembleCheckbox === undefined);
        subModels.forEach(m => {
            const mesh = m.instance();
            arr[mesh.instancePosition] = perModelOverrides[m.name] || {};
        });
    });

    return arr;
};
