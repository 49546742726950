import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { concatMap, distinctUntilChanged, mergeMap, delay, catchError, takeUntil } from 'rxjs/operators';

import { compareEpicPayload } from '../helpers/utils';
import { doBroadcastUploadJob, doBroadcastUploadJobError } from '../state/redux/actions';

import { MODELS_START_HANDLING_UPLOAD_JOBS, MODELS_STOP_HANDLING_UPLOAD_JOBS } from '../state/redux/models/types';

const handleUploadJob = job => {
    console.log('Start handling uploading job: ', job);
    return of(doBroadcastUploadJob({ uploadJob: job }));
};

const handleJobError = error => {
    console.log('Error handling upload job: ', error);
    return of(doBroadcastUploadJobError(error));
};

const handleUploadJobs = action$ =>
    action$.pipe(
        ofType(MODELS_START_HANDLING_UPLOAD_JOBS),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const { uploadJobs } = action.payload;
            return from(uploadJobs).pipe(
                concatMap(job => of(job).pipe(delay(1000))),
                mergeMap(job => handleUploadJob(job)),
                catchError(error => handleJobError(error)),
                takeUntil(action$.pipe(ofType(MODELS_STOP_HANDLING_UPLOAD_JOBS)))
            );
        })
    );

export default handleUploadJobs;
