const DEMO_FILES = {
    method: [
        {
            name: 'Calibration Check Print',
            originalName: 'Calibration_Check_Print.stl',
        },
        {
            name: 'Mini Maze',
            originalName: 'Mini_Maze.stl',
        },
        {
            name: 'Cylinder Bearing',
            originalName: 'Cylinder_Bearing.stl',
        },
    ],
    other: [
        {
            name: '20mm Calibration Box',
            originalName: '20mm_Calibration_Box.stl',
        },
        {
            name: 'Chain Link',
            originalName: 'Chain_Linked.stl',
        },
        {
            name: 'Mr Jaws',
            originalName: 'Mr_Jaws.stl',
        },
        {
            name: 'Nut and Bolt',
            originalName: 'Makerbot_Set.stl',
        },
        {
            name: 'Large Helper Disk',
            originalName: 'Helper_Disk_Large.stl',
        },
        {
            name: 'Small Helper Disk',
            originalName: 'Helper_Disk_Small.stl',
        },
    ],
};

module.exports = { DEMO_FILES };
