import { forkJoin, from, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { compareEpicPayload } from '../helpers/utils';
import { EPICS_DEBOUNCE_TIME } from '../consts';
import { FETCH_CONVERTED_MODELS_START } from '../state/redux/types';
import { doSetConvertedBlobs, doSetModelLoading } from '../state/redux/actions';

const parseResToActions = (response, action) => {
    const tasks = response.map(r => from(r.blob()));
    return forkJoin(tasks).pipe(
        mergeMap(blobs => {
            //fork join returns values in the same order
            const files = blobs.map((blob, index) => {
                return { name: action.payload.modelData[index].name, blob };
            });
            return of(doSetConvertedBlobs({ data: files }));
        }),
        catchError(error => parseErrorToActions(error))
    );
};

const parseErrorToActions = (error, action) => {
    console.log('Unable to fetch converted file:', error);
    return of(doSetModelLoading({ modelLoading: false, uploadedModelName: '' }));
};

const fetchConvertedFile = action$ =>
    action$.pipe(
        ofType(FETCH_CONVERTED_MODELS_START),
        debounceTime(EPICS_DEBOUNCE_TIME.RUN_CONVERSION_JOB),
        distinctUntilChanged(compareEpicPayload),
        mergeMap(action => {
            const tasks = [];
            action.payload.modelData.forEach(model => {
                tasks.push(fromFetch(model.url));
            });

            return forkJoin(tasks).pipe(
                mergeMap(response => parseResToActions(response, action)),
                catchError(error => parseErrorToActions(error, action))
            );
        })
    );

export default fetchConvertedFile;
