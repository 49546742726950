import { Box3, Matrix4, Quaternion, Shape, Vector3 } from 'three';

import { BUILD_VOLUME_LINES, BUILD_VOLUME_WALLS, MAX_JOB_NAME_LENGTH, PURGE_TOWER_NAME } from '../../consts';
import { getScaleFactorForUnits } from '../utils';
import { removeExtension, round } from './common';
import { toggleConvexHullVisibility } from './convex-hull';

export const isPurgeTower = model => model?.name === PURGE_TOWER_NAME;

export const filterOutPurgeTower = models => {
    if (!models) {
        return [];
    }

    return models.filter(model => !isPurgeTower(model));
};

export const filterOutEmptyFolder = model => !model.isEmptyFolder;

export const checkIsModelFits = (buildPlateBoundingBox, modelBoundingBox) => {
    const { x: xMinPlate, y: yMinPlate, z: zMinPlate } = buildPlateBoundingBox.min;
    const { x: xMaxPlate, y: yMaxPlate, z: zMaxPlate } = buildPlateBoundingBox.max;
    const { x: xMinModel, y: yMinModel, z: zMinModel } = modelBoundingBox.min;
    const { x: xMaxModel, y: yMaxModel, z: zMaxModel } = modelBoundingBox.max;

    const isFrontSideVolumeBreaks = xMaxModel > xMaxPlate;
    const isRightSideVolumeBreaks = yMaxModel > yMaxPlate;
    const isTopSideVolumeBreaks = zMaxModel > zMaxPlate;
    const isBackSideVolumeBreaks = xMinModel < xMinPlate;
    const isLeftSideVolumeBreaks = yMinModel < yMinPlate;
    const isBottomSideVolumeBreaks = zMinModel < zMinPlate;

    const isModelOutsidePlate =
        (isFrontSideVolumeBreaks && xMinModel > xMaxPlate) ||
        (isRightSideVolumeBreaks && yMinModel > yMaxPlate) ||
        (isTopSideVolumeBreaks && zMinModel > zMaxPlate) ||
        (isBackSideVolumeBreaks && xMaxModel < xMinPlate) ||
        (isLeftSideVolumeBreaks && yMaxModel < yMinPlate) ||
        (isBottomSideVolumeBreaks && zMaxModel < zMinPlate);

    return {
        isModelOutsidePlate,
        isFrontSideVolumeBreaks: !isModelOutsidePlate && isFrontSideVolumeBreaks,
        isRightSideVolumeBreaks: !isModelOutsidePlate && isRightSideVolumeBreaks,
        isTopSideVolumeBreaks: !isModelOutsidePlate && isTopSideVolumeBreaks,
        isBackSideVolumeBreaks: !isModelOutsidePlate && isBackSideVolumeBreaks,
        isLeftSideVolumeBreaks: !isModelOutsidePlate && isLeftSideVolumeBreaks,
        isBottomSideVolumeBreaks: !isModelOutsidePlate && isBottomSideVolumeBreaks,
    };
};

export const checkAllSubmodels = model => {
    if (!model) return [];
    let submodels = [model];
    if (!model.submodels || !model.submodels.length || model.assembleCheckbox) return submodels;

    submodels = submodels.filter(m => m.name !== model.name);

    model.submodels.forEach(child => {
        submodels = [...submodels, ...checkAllSubmodels(child)];
    });

    return submodels;
};

export const checkVolume = (model, buildVolume, expandAxisX, isUseHiddenModels = false) => {
    if (!model || !buildVolume) return;

    return checkGroupVolumeRecursive(model, buildVolume, expandAxisX, isUseHiddenModels);
};

export const groupModelsOutsideData = modelsOutsideData => {
    const defaultData = {
        isModelOutsidePlate: true,
        isFrontSideVolumeBreaks: false,
        isRightSideVolumeBreaks: false,
        isTopSideVolumeBreaks: false,
        isBackSideVolumeBreaks: false,
        isLeftSideVolumeBreaks: false,
        isBottomSideVolumeBreaks: false,
    };

    const groupedOutsideData = modelsOutsideData.reduce(
        (prev, current) => ({
            isModelOutsidePlate: prev.isModelOutsidePlate && current.isModelOutsidePlate,
            isFrontSideVolumeBreaks: prev.isFrontSideVolumeBreaks || current.isFrontSideVolumeBreaks,
            isRightSideVolumeBreaks: prev.isRightSideVolumeBreaks || current.isRightSideVolumeBreaks,
            isTopSideVolumeBreaks: prev.isTopSideVolumeBreaks || current.isTopSideVolumeBreaks,
            isBackSideVolumeBreaks: prev.isBackSideVolumeBreaks || current.isBackSideVolumeBreaks,
            isLeftSideVolumeBreaks: prev.isLeftSideVolumeBreaks || current.isLeftSideVolumeBreaks,
            isBottomSideVolumeBreaks: prev.isBottomSideVolumeBreaks || current.isBottomSideVolumeBreaks,
        }),
        defaultData
    );

    return groupedOutsideData;
};

const checkGroupVolumeRecursive = (model, buildVolume, expandAxisX, isUseHiddenModels = false) => {
    const { buildPlateBoundingBox, modelBoundingBox } = getBoundingBoxes(model, buildVolume, expandAxisX);

    if (model.instance().isGroup) {
        const submodels = isUseHiddenModels ? model.submodels : model.submodels.filter(submodel => submodel.shown);
        const modelsOutsideData = submodels.map(submodel =>
            checkGroupVolumeRecursive(submodel, buildVolume, expandAxisX)
        );

        const groupedOutsideData = groupModelsOutsideData(modelsOutsideData);

        return {
            isModelOutsidePlate: groupedOutsideData.isModelOutsidePlate,
            isFrontSideVolumeBreaks:
                !groupedOutsideData.isModelOutsidePlate && groupedOutsideData.isFrontSideVolumeBreaks,
            isRightSideVolumeBreaks:
                !groupedOutsideData.isModelOutsidePlate && groupedOutsideData.isRightSideVolumeBreaks,
            isTopSideVolumeBreaks: !groupedOutsideData.isModelOutsidePlate && groupedOutsideData.isTopSideVolumeBreaks,
            isBackSideVolumeBreaks:
                !groupedOutsideData.isModelOutsidePlate && groupedOutsideData.isBackSideVolumeBreaks,
            isLeftSideVolumeBreaks:
                !groupedOutsideData.isModelOutsidePlate && groupedOutsideData.isLeftSideVolumeBreaks,
            isBottomSideVolumeBreaks:
                !groupedOutsideData.isModelOutsidePlate && groupedOutsideData.isBottomSideVolumeBreaks,
        };
    }

    return checkIsModelFits(buildPlateBoundingBox, modelBoundingBox);
};

const getBBforGeometry = (geometry, matrix4) => {
    const p = new Vector3();
    const q = new Quaternion();
    const s = new Vector3();
    matrix4.decompose(p, q, s);

    const newMatrix = new Matrix4();
    newMatrix.compose(new Vector3(), q, s);

    const key = JSON.stringify(`${q.toArray()}:${s.toArray()}`);

    if (!geometry.boundingBoxCache) {
        geometry.boundingBoxCache = {};
    }

    if (!geometry.boundingBoxCache[key]) {
        const point = new Vector3();
        geometry.boundingBoxCache[key] = new Box3();
        if (geometry.attributes) {
            const positions = geometry.attributes['position'].array;
            for (let i = 0, il = positions.length; i < il; i += 3) {
                point.set(positions[i], positions[i + 1], positions[i + 2]);
                point.applyMatrix4(newMatrix);
                geometry.boundingBoxCache[key].expandByPoint(point);
            }
        } else {
            const vertices = geometry.vertices;
            vertices.forEach(v => {
                point.set(v.x, v.y, v.z);
                point.applyMatrix4(newMatrix);
                geometry.boundingBoxCache[key].expandByPoint(point);
            });
        }
    }

    const result = geometry.boundingBoxCache[key].clone();
    result.translate(p);
    result.min.z = round(result.min.z, 3);

    return result;
};

export const getBuildPlateBoundingBox = (buildVolume, expandAxisX) => {
    let { x } = buildVolume;
    const { y, z } = buildVolume;
    x += Math.abs(expandAxisX);

    const max = new Vector3((x + expandAxisX) / 2, y / 2, z);
    const min = new Vector3(-(x - expandAxisX) / 2, -y / 2, 0);

    return new Box3(min, max);
};

const getBoundingBoxes = (model, buildVolume, expandAxisX) => {
    const buildPlateBoundingBox = getBuildPlateBoundingBox(buildVolume, expandAxisX);
    const modelBoundingBox = getMinfitBB(model);

    return { buildPlateBoundingBox, modelBoundingBox };
};

export const getMinBB = mesh => {
    if (mesh.isMesh) {
        const { geometry } = mesh;
        if (!geometry.boundingBox) {
            geometry.computeBoundingBox();
        }

        return geometry.boundingBox.clone();
    }

    const traverse = object => {
        let box = new Box3();
        const { geometry } = object;

        if (!geometry && object.children.length === 0) {
            return null;
        }

        if (!geometry && object.children.length !== 0) {
            object.children.forEach(child => {
                const result = traverse(child);
                if (!result) return;
                box.union(result);
            });
            box.applyMatrix4(object.matrix);
            return box;
        }

        const threeTransform = object.matrix.clone();
        box = getBBforGeometry(geometry, threeTransform);
        return box;
    };

    const box = new Box3();
    mesh.children.forEach(childMesh => {
        if (!childMesh.visible) return;
        const childBox = traverse(childMesh);
        if (!childBox) return;
        box.union(childBox);
    });

    return box;
};

export const getMinfitBB = (model, discardPosition, discardScale) => {
    if (model.isEmptyFolder) return new Box3(new Vector3(0, 0, 0), new Vector3(0, 0, 0));
    const mesh = model.isMesh ? model : model.instance();
    mesh.updateWorldMatrix(false, false);

    const threeTransform = mesh.matrixWorld.clone();
    const p = new Vector3();
    const q = new Quaternion();
    const s = new Vector3();
    threeTransform.decompose(p, q, s);
    threeTransform.compose(discardPosition ? new Vector3(0, 0, 0) : p, q, discardScale ? new Vector3(1, 1, 1) : s);

    const { geometry } = mesh;
    if (!geometry) {
        return getMinBB(mesh).applyMatrix4(threeTransform);
    }

    return getBBforGeometry(geometry, threeTransform);
};

export const addPositionToModelBB = (position, modelBB) => {
    const copyBB = modelBB.clone();
    copyBB.min.add(position);
    copyBB.max.add(position);

    return copyBB;
};

export const addPositionsToModelsBB = (positions, modelsBB) => {
    const result = {};

    Object.entries(modelsBB).forEach(([name, modelBB]) => {
        if (!positions[name]) return;
        result[name] = addPositionToModelBB(positions[name], modelBB);
    });

    return result;
};

export const mergeModelsBB = modelsBB => {
    const mergedBB = new Box3();

    Object.values(modelsBB).forEach(bb => {
        mergedBB.union(bb);
    });

    return mergedBB;
};

export const getGroupBB = (models, options = {}) => {
    const DEF_OPT = {
        baseLayerAllowance: 0,
        heightAllowance: 0,
        discardPosition: true,
    };
    const { baseLayerAllowance, heightAllowance, discardPosition } = { ...DEF_OPT, ...options };
    const group = new Box3(new Vector3(), new Vector3());
    const modelsBB = {};
    const modelBBOffset = new Vector3(baseLayerAllowance / 2, baseLayerAllowance / 2, 0);

    models.filter(filterOutEmptyFolder).forEach(model => {
        const bb = getMinfitBB(model, discardPosition);

        if (!isPurgeTower(model)) {
            bb.min.sub(modelBBOffset);
            bb.max.add(modelBBOffset);
            bb.max.z += heightAllowance;
        }

        modelsBB[model.name] = bb.clone();
        group.union(bb);
    });

    return [group, modelsBB];
};

export const checkIfModelsFits = (volumeWithOutsets, models, expandAxisX) => {
    if (!volumeWithOutsets || !models || models.length === 0) {
        return { modelsFitsObj: {}, modelsFits: true };
    }

    const modelsFitsObj = getAllVisibleModels(filterOutPurgeTower(models))
        .filter(filterOutEmptyFolder)
        .reduce((acc, model) => {
            const volumeData = checkVolume(model, volumeWithOutsets, expandAxisX);
            acc[model.name] = !isModelBreaksVolume(volumeData);
            return acc;
        }, {});

    const modelsFits = Object.values(modelsFitsObj).every(val => val === true);
    return { modelsFitsObj, modelsFits };
};

export const checkIfModelsOutside = (volume, models, expandAxisX) => {
    if (!volume || !models?.length) {
        return { modelsOutsideArr: [], isModelsOutside: false };
    }

    const modelsOutsideArr = getAllVisibleModels(filterOutPurgeTower(models))
        .filter(filterOutEmptyFolder)
        .map(model => ({
            name: model.name,
            ...checkVolume(model, volume, expandAxisX),
        }));

    const isModelsBreaksVolume = modelsOutsideArr.some(
        model => !model.isModelOutsidePlate && isModelBreaksVolume(model)
    );
    const isModelsOutside = modelsOutsideArr.some(model => model.isModelOutsidePlate);
    return { modelsOutsideArr, isModelsOutside, isModelsBreaksVolume };
};

export const getAllVisibleModels = allModels => {
    if (!allModels) return [];
    const allVisibleModels = [];

    const checkVisibility = models => {
        models.forEach(m => {
            if (!m.shown) return;

            if (m.assembleCheckbox) {
                allVisibleModels.push(m);
                return;
            }

            if (!m.submodels || !m.submodels.length) {
                allVisibleModels.push(m);
            } else {
                checkVisibility(m.submodels);
            }
        });
    };

    checkVisibility(allModels);

    return allVisibleModels;
};

export const getAllMeshChildrenRecursive = mesh => {
    if (!mesh) return [];
    let children = [mesh];

    if (!mesh.children || !mesh.children.length) return children;

    mesh.children.forEach(child => {
        children = [...children, ...getAllMeshChildrenRecursive(child)];
    });

    return children;
};

export const getAllSubmodelsRecursive = model => {
    if (!model) return [];
    let submodels = [model];

    if (!model.submodels || !model.submodels.length) return submodels;

    model.submodels.forEach(child => {
        submodels = [...submodels, ...getAllSubmodelsRecursive(child)];
    });

    return submodels;
};

export const getChildrenOrAssembledModels = model => {
    if (!model) return [];
    let submodels = [];

    if (!model.submodels || !model.submodels.length || model.assembleCheckbox === true) return [model];

    model.submodels.forEach(child => {
        submodels = [...submodels, ...getChildrenOrAssembledModels(child)];
    });

    return submodels;
};

export const getAllUnAssembledSubmodels = model => {
    if (!model) return [];
    if (model.assembleCheckbox === true) return [model];
    const allSubmodels = model.submodels.map(getAllUnAssembledSubmodels).flat();
    return [model, ...allSubmodels];
};

export const getParentModel = (model, allModels) => {
    if (!model || !allModels) return null;
    let parent = model;

    if (parent.getParentName() === undefined) return parent;

    allModels.forEach(m => {
        const submodels = m.submodels.length ? getAllSubmodelsRecursive(m) : [m];
        submodels.forEach(element => {
            if (element.name === parent.getParentName()) {
                parent = element;
            }
        });
    });

    return parent;
};

export const getCorrectScalesValues = (model, units) => {
    const scaleFactor = getScaleFactorForUnits(units);
    let { scaleX, scaleY, scaleZ } = model.settings;

    if (!isPurgeTower(model)) {
        scaleX /= scaleFactor;
        scaleY /= scaleFactor;
        scaleZ /= scaleFactor;
    }
    return { scaleX, scaleY, scaleZ };
};

export const getJobNameFromFileNames = names => {
    const modelsLength = names.length;
    if (!modelsLength) {
        return '';
    }
    let name = removeExtension(names[0]).substring(0, MAX_JOB_NAME_LENGTH);
    for (let i = 1; i < modelsLength; i++) {
        const nextName = removeExtension(names[i]);
        if (name.length + nextName.length > MAX_JOB_NAME_LENGTH) {
            name += `, and ${modelsLength - i} more`;
            break;
        }
        name += `, ${nextName}`;
    }

    return name;
};

export const getOuterAssembledModel = (model, allModels) => {
    if (!allModels.length) {
        return model;
    }

    if (!model.getParentName()) {
        return model;
    }
    const parent = getParentModel(model, allModels);
    if (parent.assembleCheckbox === false || parent.name === model.name) {
        return model;
    }
    return getOuterAssembledModel(parent, allModels);
};

export const getTopAssembledModel = model => {
    if (!model) return null;
    if (!model.parent?.assembleCheckbox && model.assembleCheckbox) return model;
    if (!model.parent) return null;
    return getTopAssembledModel(model.parent);
};

export const getRootModel = (model, allModels) => {
    if (!model.getParentName()) {
        return model;
    }
    const parent = getParentModel(model, allModels);
    if (!parent.getParentName()) {
        return parent;
    }
    return getRootModel(parent, allModels);
};

export const getAllDisassembledSubmodels = model => {
    if (!model || model.assembleCheckbox) return [];
    if (!model.submodels || !model.submodels.length) return [];

    const submodels = model.submodels
        .filter(({ assembleCheckbox }) => !assembleCheckbox)
        .map(m => [m, ...getAllDisassembledSubmodels(m)])
        .flat();

    return submodels;
};

export const getSingleModelOrGroup = (name, models) => {
    const isParentModel = model => !model.getParentName();
    const findModelByName = name => models.find(m => m.name === name);

    const model = findModelByName(name);
    if (isParentModel(model)) {
        return model;
    }

    let parentModel = findModelByName(model.getParentName());
    while (!isParentModel(parentModel)) {
        parentModel = findModelByName(parentModel.getParentName());
    }

    return parentModel.assembleCheckbox ? parentModel : model;
};

export const isModelsHaveEqualScales = (models, units) => {
    const scalesToCompare = getCorrectScalesValues(models[0], units);

    let isEqualScale = true;
    for (const model of models) {
        const modelScales = getCorrectScalesValues(model, units);

        if (
            modelScales.scaleX !== scalesToCompare.scaleX ||
            modelScales.scaleY !== scalesToCompare.scaleY ||
            modelScales.scaleZ !== scalesToCompare.scaleZ
        ) {
            isEqualScale = false;
            break;
        }
    }

    return isEqualScale;
};

export const isLineInBrokenVolumeSide = (lineName, volumeBreaksData) => {
    switch (lineName) {
        case BUILD_VOLUME_LINES.BOTTOM_RIGHT:
            return volumeBreaksData.isBottomSideVolumeBreaks || volumeBreaksData.isRightSideVolumeBreaks;
        case BUILD_VOLUME_LINES.BOTTOM_FRONT:
            return volumeBreaksData.isFrontSideVolumeBreaks || volumeBreaksData.isBottomSideVolumeBreaks;
        case BUILD_VOLUME_LINES.MIDDLE_FRONT_RIGHT:
            return volumeBreaksData.isFrontSideVolumeBreaks || volumeBreaksData.isRightSideVolumeBreaks;
        case BUILD_VOLUME_LINES.MIDDLE_BACK_RIGHT:
            return volumeBreaksData.isBackSideVolumeBreaks || volumeBreaksData.isRightSideVolumeBreaks;
        case BUILD_VOLUME_LINES.BOTTOM_BACK:
            return volumeBreaksData.isBackSideVolumeBreaks || volumeBreaksData.isBottomSideVolumeBreaks;
        case BUILD_VOLUME_LINES.MIDDLE_FRONT_LEFT:
            return volumeBreaksData.isFrontSideVolumeBreaks || volumeBreaksData.isLeftSideVolumeBreaks;
        case BUILD_VOLUME_LINES.BOTTOM_LEFT:
            return volumeBreaksData.isBottomSideVolumeBreaks || volumeBreaksData.isLeftSideVolumeBreaks;
        case BUILD_VOLUME_LINES.TOP_RIGHT:
            return volumeBreaksData.isTopSideVolumeBreaks || volumeBreaksData.isRightSideVolumeBreaks;
        case BUILD_VOLUME_LINES.TOP_FRONT:
            return volumeBreaksData.isTopSideVolumeBreaks || volumeBreaksData.isFrontSideVolumeBreaks;
        case BUILD_VOLUME_LINES.TOP_BACK:
            return volumeBreaksData.isTopSideVolumeBreaks || volumeBreaksData.isBackSideVolumeBreaks;
        case BUILD_VOLUME_LINES.TOP_LEFT:
            return volumeBreaksData.isTopSideVolumeBreaks || volumeBreaksData.isLeftSideVolumeBreaks;
        case BUILD_VOLUME_LINES.MIDDLE_BACK_LEFT:
            return volumeBreaksData.isBackSideVolumeBreaks || volumeBreaksData.isLeftSideVolumeBreaks;
        default:
            return false;
    }
};

export function isModelBreaksVolume(modelOutsideData) {
    return (
        modelOutsideData.isFrontSideVolumeBreaks ||
        modelOutsideData.isRightSideVolumeBreaks ||
        modelOutsideData.isTopSideVolumeBreaks ||
        modelOutsideData.isBackSideVolumeBreaks ||
        modelOutsideData.isLeftSideVolumeBreaks ||
        modelOutsideData.isBottomSideVolumeBreaks
    );
}

export const isLineInHighlightedWall = (lineName, highlightedWalls) => {
    const { BACK, FRONT, LEFT, RIGHT, TOP } = BUILD_VOLUME_WALLS;

    switch (lineName) {
        case BUILD_VOLUME_LINES.BOTTOM_RIGHT:
            return highlightedWalls[RIGHT];
        case BUILD_VOLUME_LINES.BOTTOM_FRONT:
            return highlightedWalls[FRONT];
        case BUILD_VOLUME_LINES.BOTTOM_BACK:
            return highlightedWalls[BACK];
        case BUILD_VOLUME_LINES.BOTTOM_LEFT:
            return highlightedWalls[LEFT];
        case BUILD_VOLUME_LINES.TOP_FRONT:
            return highlightedWalls[FRONT] || highlightedWalls[TOP];
        case BUILD_VOLUME_LINES.TOP_RIGHT:
            return highlightedWalls[RIGHT] || highlightedWalls[TOP];
        case BUILD_VOLUME_LINES.TOP_BACK:
            return highlightedWalls[BACK] || highlightedWalls[TOP];
        case BUILD_VOLUME_LINES.TOP_LEFT:
            return highlightedWalls[LEFT] || highlightedWalls[TOP];
        case BUILD_VOLUME_LINES.MIDDLE_FRONT_RIGHT:
            return highlightedWalls[FRONT] || highlightedWalls[RIGHT];
        case BUILD_VOLUME_LINES.MIDDLE_BACK_RIGHT:
            return highlightedWalls[BACK] || highlightedWalls[RIGHT];
        case BUILD_VOLUME_LINES.MIDDLE_FRONT_LEFT:
            return highlightedWalls[FRONT] || highlightedWalls[LEFT];
        case BUILD_VOLUME_LINES.MIDDLE_BACK_LEFT:
            return highlightedWalls[BACK] || highlightedWalls[LEFT];
        default:
            return false;
    }
};

export const getPurgeShape = (value, dimensions) => {
    const x = dimensions.x;
    const y = dimensions.y;
    const halfOfx = x / 2;
    const halfOfy = y / 2;
    const oneTenthOfy = y * 0.1;
    const oneTenthOfx = x * 0.1;
    switch (value.toLowerCase()) {
        case 'bowtie':
        case 'bowtie2':
            return new Shape()
                .moveTo(halfOfx + oneTenthOfx, halfOfy - oneTenthOfy)
                .lineTo(halfOfx, halfOfy - oneTenthOfy)
                .lineTo(0, 0)
                .lineTo(0, y)
                .lineTo(halfOfx, halfOfy + oneTenthOfy)
                .lineTo(halfOfx + oneTenthOfx, halfOfy + oneTenthOfy)
                .moveTo(halfOfx - oneTenthOfx, halfOfy + oneTenthOfy)
                .lineTo(halfOfx, halfOfy + oneTenthOfy)
                .lineTo(x, y)
                .lineTo(x, 0)
                .lineTo(halfOfx, halfOfy - oneTenthOfy)
                .lineTo(halfOfx - oneTenthOfx, halfOfy - oneTenthOfy);
        case 'square':
            return new Shape()
                .moveTo(-halfOfx, halfOfy)
                .lineTo(halfOfx, halfOfy)
                .lineTo(halfOfx, -halfOfy)
                .lineTo(-halfOfx, -halfOfy)
                .lineTo(-halfOfx, halfOfy - 2)
                .lineTo(halfOfx - 2, halfOfy - 2)
                .lineTo(halfOfx - 2, -(halfOfy - 2))
                .lineTo(-(halfOfx - 2), -(halfOfy - 2))
                .lineTo(-(halfOfx - 2), halfOfy - 4)
                .lineTo(halfOfx - 4, halfOfy - 4)
                .lineTo(halfOfx - 4, -(halfOfy - 4))
                .lineTo(-(halfOfx - 4), -(halfOfy - 4))
                .lineTo(-(halfOfx - 4), halfOfy - 5)
                .lineTo(-(halfOfx - 3), halfOfy - 5)
                .lineTo(-(halfOfx - 3), -(halfOfy - 3))
                .lineTo(halfOfx - 3, -(halfOfy - 3))
                .lineTo(halfOfx - 3, halfOfy - 3)
                .lineTo(-(halfOfx - 1), halfOfy - 3)
                .lineTo(-(halfOfx - 1), -(halfOfy - 1))
                .lineTo(halfOfx - 1, -(halfOfy - 1))
                .lineTo(halfOfx - 1, halfOfy - 1)
                .lineTo(-halfOfx, halfOfy - 1);
        case 'zigzag':
            return new Shape()
                .moveTo(-halfOfx, halfOfy)
                .lineTo(-halfOfx, 0)
                .lineTo(-halfOfx + 3, -halfOfy)
                .lineTo(halfOfx, -halfOfy)
                .lineTo(halfOfx, halfOfy);
        default:
            return new Shape()
                .moveTo(0, 0)
                .lineTo(0, y)
                .lineTo(x, y)
                .lineTo(x, 0);
    }
};

export const toggleModelVisibility = (model, visible) => {
    const mesh = model.instance();
    model.shown = visible;

    if (mesh.type === 'Mesh') {
        mesh.visible = visible;
    }

    toggleConvexHullVisibility(model, visible);
};

export const toggleModelsVisibility = (models, visible) => {
    models
        .map(getAllSubmodelsRecursive)
        .flat()
        .forEach(model => toggleModelVisibility(model, visible));
};
